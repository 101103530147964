let defaultState = {
  prediction_input_values: [],
  prediction_output_values: [],
  selected_model_name: "",
  prediction_table_data: [],
  prediction_display_columns: [],
  prediction_plot_data: [],
  legend_text: "",
  prediction_violated_constraints: [],
  batch_prediction_table_data: []
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_MODEL_PREDICTION_VALUES":
      return {
        ...state,
        prediction_input_values: action.data.prediction_input_values
      };
    case "UPDATE_MODEL_PREDICTION_SELECTED_MODEL":
      return {
        ...state,
        selected_model_name: action.data.selected_model_name
      };
    case "UPDATE_MODEL_PREDICTION_OUTPUT_VALUES":
      return {
        ...state,
        prediction_output_values: action.data.prediction_output_values,
        prediction_input_values: action.data.prediction_input_values,
        prediction_table_data: action.data.prediction_table_data,
        prediction_plot_data: action.data.prediction_plot_data,
        legend_text: action.data.legend_text,
        prediction_violated_constraints: action.data.prediction_violated_constraints
      };
    case "UPDATE_PREDICTION_DISPLAY_COLUMNS":
      return {
        ...state,
        prediction_display_columns: action.data.prediction_display_columns,
        legend_text: action.data.legend_text
      };
    case "UPDATE_PREDICTION_VIOLATED_CONSTRAINTS":
      return {
        ...state,
        prediction_violated_constraints: action.data.prediction_violated_constraints
      };
    case "UPDATE_BATCH_PREDICTION_TABLE_DATA":
      return {
        ...state,
        batch_prediction_table_data: action.data.batch_prediction_table_data
      }
    case "RESET_ALL":
      return {
        ...state,
        prediction_input_values: [],
        prediction_output_values: [],
        selected_model_name: "",
        prediction_table_data: [],
        prediction_display_columns: [],
        prediction_plot_data: [],
        legend_text: "",
        prediction_violated_constraints: [],
        batch_prediction_table_data: []
      };
    default:
      return state;
  }
}

export default views;
