import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles, createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import 'handsontable/dist/handsontable.full.css'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";

//Dev
import * as usageActions from '../actions/modelusageActions';


const MuiTooltipTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14 pt"
      }
    }
  }
});

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            // width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        flexGrow: 1,
    },
    formControl: {
        margin: 0,
        minWidth: 120,
        flexWrap: 'wrap',
        // underline: 'red',

    },
    paperBody:
    {
        marginTop: 'auto',
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.25),
    },
});


class ModelSettings extends Component {

    state = {
        slidervals: {},
    }

    //keep slider values in a local state
    componentDidMount() {

    }

    shouldComponentUpdate(newProps) {
        if (newProps.views.currentView !== 'modelpred' && newProps.views.currentView !== 'modelopt') {
            return false;
        }
        //additional logic here would be good
        return true;
    }

    componentDidUpdate() {

    }

    updateRangeMinVal = (column, event) => {
        if (isNaN(event.target.value)) {
            return;
        }
        let dataRanges = Object.assign([], this.props.modelusage.data_ranges);
        let currentCol = dataRanges.filter(function (el) { return el.column === column })[0];
        let newUserMin = Math.min(parseFloat(event.target.value), currentCol.usermax);
        let newUserMax = Math.max(parseFloat(event.target.value), currentCol.usermax);
        currentCol.usermin = newUserMin;
        currentCol.usermax = newUserMax;
        this.props.dispatch(usageActions.UpdateModelUsageDataRanges(dataRanges));
        document.getElementById(column + "_pred_min_select").value = newUserMin;
        document.getElementById(column + "_pred_max_select").value = newUserMax;
    }

    updateRangeMaxVal = (column, event) => {
        if (isNaN(event.target.value)) {
            return;
        }
        let dataRanges = Object.assign([], this.props.modelusage.data_ranges);
        let currentCol = dataRanges.filter(function (el) { return el.column === column })[0];
        let newUserMin = Math.min(parseFloat(event.target.value), currentCol.usermin);
        let newUserMax = Math.max(parseFloat(event.target.value), currentCol.usermin);
        currentCol.usermin = newUserMin;
        currentCol.usermax = newUserMax;
        this.props.dispatch(usageActions.UpdateModelUsageDataRanges(dataRanges));
        document.getElementById(column + "_pred_min_select").value = newUserMin;
        document.getElementById(column + "_pred_max_select").value = newUserMax;
    }

    constraintSignChange = (event) => {
        this.props.dispatch(usageActions.UpdateConstraintSignSelection(event.target.value));
    }
    ConstraintTypeChange = (event) => {
        this.props.dispatch(usageActions.UpdateConstraintTypeSelection(event.target.value));
    }

    constraintValueChange = (event) => {
        if (isNaN(event.target.value) || event.target.value === '') {
            this.props.dispatch(usageActions.UpdateConstraintValue(100));
            document.getElementById("constraint_value_input").value = 100;
            return;
        }
        this.props.dispatch(usageActions.UpdateConstraintValue(event.target.value));
    }

    columnConstraintChanged = (column) => {
        let newColumns = Object.assign([], this.props.modelusage.settings_selected_columns);
        let index = newColumns.indexOf(column);
        if (index === -1) {
            newColumns.push(column);
            this.props.dispatch(usageActions.UpdateConstraintSelectedColumns(newColumns));
            return
        }
        newColumns.splice(index, 1);
        this.props.dispatch(usageActions.UpdateConstraintSelectedColumns(newColumns));
    }

    ConstraintColumnAChanged = (column) => {
        let newColumns = Object.assign([], this.props.modelusage.settings_selected_columns_a);
        let index = newColumns.indexOf(column);
        if (index === -1) {
            newColumns.push(column);
            this.props.dispatch(usageActions.UpdateConstraintSelectedColumns_PartA(newColumns));
            return
        }
        newColumns.splice(index, 1);
        this.props.dispatch(usageActions.UpdateConstraintSelectedColumns_PartA(newColumns));
    }
    ConstraintColumnBChanged = (column) => {
        let newColumns = Object.assign([], this.props.modelusage.settings_selected_columns_b);
        let index = newColumns.indexOf(column);
        if (index === -1) {
            newColumns.push(column);
            this.props.dispatch(usageActions.UpdateConstraintSelectedColumns_PartB(newColumns));
            return
        }
        newColumns.splice(index, 1);
        this.props.dispatch(usageActions.UpdateConstraintSelectedColumns_PartB(newColumns));
    }

    addConstraint = (event) => {
        let currDisplaySign = "≤";
        if (this.props.modelusage.settings_selected_sign === '==') {
            currDisplaySign = "=";
        }
        if (this.props.modelusage.settings_selected_sign === '>=') {
            currDisplaySign = "≥";
        }
        let newConstraint = {
            type: this.props.modelusage.settings_selected_constraint_type,
            columns: Object.assign([], this.props.modelusage.settings_selected_columns_a),
            columns_b: Object.assign([], this.props.modelusage.settings_selected_columns_b ),
            value: this.props.modelusage.settings_constraint_value,
            sign: this.props.modelusage.settings_selected_sign,
            displaySign: currDisplaySign,
            use: true
        };
        let allConstraints = Object.assign([], this.props.modelusage.settings_all_constraints);
        allConstraints.push(newConstraint);
        //document.getElementById("constraint_value_input").value = 100;
        this.props.dispatch(usageActions.AddNewUserConstraint(allConstraints));
    }

    updateConstraintUse = (index) => {
        let allConstraints = JSON.parse(JSON.stringify(this.props.modelusage.settings_all_constraints));
        allConstraints[index].use = !allConstraints[index].use;
        this.props.dispatch(usageActions.UpdateUserConstraints(allConstraints));
    }

    AddConstraintDisabled = () => {
        let returnVal = false; 
        if (this.props.modelusage.settings_selected_columns_a.length === 0) {
            returnVal = true;
        }
        if (isNaN(this.props.modelusage.settings_constraint_value) || this.props.modelusage.settings_constraint_value==='') {
            returnVal = true;
        }
        if (this.props.modelusage.settings_selected_sign==='')
        {
            returnVal = true;
        }
        if (this.props.modelusage.settings_selected_constraint_type === 'ratio' && this.props.modelusage.settings_selected_columns_b.length === 0) {
            returnVal = true;
        }
        return returnVal;
    }

    render() {
        const { classes } = this.props;
        return (
            // <div>
                <div className={classes.layout}>


                    {/* <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}> */}
            <Typography variant="h6" gutterBottom style={{ textAlign: 'left' }}>
                        Input Constraints
                </Typography>
                    <Typography variant='subtitle1' gutterBottom style={{ textAlign: 'left', marginBottom: 0 }}>
                        Note: all changes made here are reflected in both the 'Model Prediction' & 'Experiment Optimization' tabs.
                </Typography>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ marginTop: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Grid container justify="flex-start" alignItems="flex-start" spacing={8}>
                                <Grid item sm={12} md={4}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor={"settings_constraint_type_select"}>Constraint Type</InputLabel>
                                        <Select
                                            style={{ textAlign: 'left' }}
                                            value={this.props.modelusage.settings_selected_constraint_type}
                                            onChange={this.ConstraintTypeChange}
                                            input={<Input name="settings_constraint_type_select" id="settings_constraint_type_select"></Input>}>
                                            {this.props.modelusage.constraint_types.map(type => (
                                                <MenuItem key={type.value} value={type.value}>{type.display}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Typography variant="button" style={{ textAlign: 'left', marginBottom: 8, marginTop: 24 }}>
                                        {this.props.modelusage.settings_selected_constraint_type === 'sum' ? 'Summed Columns' : 'Part A Columns (A/B)'}
                                    </Typography>
                                    <FormGroup style={{ textAlign: 'left' }} >
                                        {this.props.modelprediction.prediction_display_columns.filter(function (el) { return el.type === 'input' && el.categorical === false }).map((col, index) => (
                                            <MuiThemeProvider theme={MuiTooltipTheme} key={index}>
                                            <Tooltip title={col.col} placement="top">
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={this.props.modelusage.settings_selected_columns_a.indexOf(col.col) > -1}
                                                        onChange={() => this.ConstraintColumnAChanged(col.col)}
                                                        value={col.col}
                                                        color="primary"
                                                        style={{height:24, paddingTop:0, paddingBottom:0}}
                                                        disableRipple
                                                    />
                                                }
                                                label={col.col.substring(0, 6)}
                                            />
                                           </Tooltip>
                                           </MuiThemeProvider>
                                        ))}
                                </FormGroup>
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor={"settings_sign_select"}>Constraint Sign</InputLabel>
                                    <Select
                                        style={{ textAlign: 'left' }}
                                        value={this.props.modelusage.settings_selected_sign}
                                        onChange={this.constraintSignChange}
                                        input={<Input name="settings_sign_select" id="settings_sign_select"></Input>}>
                                        {this.props.modelusage.settings_sign_options.map(sign => (
                                            <MenuItem key={sign.value} value={sign.value}>{sign.display}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {this.props.modelusage.settings_selected_constraint_type === 'ratio' &&
                                    <Typography variant="button" style={{ textAlign: 'left', marginBottom: 8, marginTop: 24 }}>
                                        Part B Columns (A/B)
                                    </Typography>
                                }
                                {this.props.modelusage.settings_selected_constraint_type === 'ratio' &&
                                    <FormGroup style={{ textAlign: 'left' }}>
                                        {this.props.modelprediction.prediction_display_columns.filter(function (el) { return el.type === 'input' && el.categorical === false }).map(col => (
                                            <FormControlLabel
                                                key={col.col}
                                                control={
                                                    <Checkbox
                                                        checked={this.props.modelusage.settings_selected_columns_b.indexOf(col.col) > -1}
                                                        onChange={() => this.ConstraintColumnBChanged(col.col)}
                                                        value={col.col}
                                                        color="primary"
                                                        style={{height:24, paddingTop:0, paddingBottom:0}}
                                                        disableRipple
                                                    />
                                                }
                                                label={col.col}
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    fullWidth
                                    id="constraint_value_input"
                                    label={"Constraint Value"}
                                    //defaultValue={100}
                                    onBlur={(ev) => this.constraintValueChange(ev)}
                                    type="number"
                                    //className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    disabled={false}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            ev = this.constraintValueChange(ev);
                                        }
                                    }}
                                    style={{ marginTop: 0, marginRight: 0 }}
                                />
                                <Button variant="contained" color="primary" component="span" className={classes.button}
                                disabled={this.AddConstraintDisabled()}
                                    //disabled={this.props.modelusage.settings_selected_columns.length === 0 || isNaN(this.props.modelusage.settings_constraint_value)}
                                    //   boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
                                    onClick={this.addConstraint} style={{ float: 'right', marginRight: 0 }}>

                                    Add Constraint
                                </Button>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant="button" style={{ textAlign: 'left', marginBottom: 16, marginTop: 24 }}>
                                        Constraints to Use...
                                        </Typography>
                                    <FormGroup >
                                        {
                                            this.props.modelusage.settings_all_constraints.map((constraint, index) => (
                                                <FormControlLabel
                                                    key={"constraint" + index}
                                                    control={
                                                        <Checkbox
                                                            checked={constraint.use}
                                                            onChange={() => this.updateConstraintUse(index)}
                                                            value={"Constraint " + index + " Checkbox"}
                                                            color="primary"
                                                            style={{height:24, paddingTop:0, paddingBottom:0}}
                                                            disableRipple
                                                        />
                                                    }
                                                    label={ constraint.type==='sum' ? "Σ(" + constraint.columns.join(', ') + ") " + constraint.displaySign + " " + constraint.value : 
                                                    "Σ(" + constraint.columns.join(', ') + ") / Σ(" + constraint.columns_b.join(', ') + ") " + constraint.displaySign + " " + constraint.value }
                                                />
                                            ))
                                        }
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
             
            // </div>
        );
    }
}

ModelSettings.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default connect((state, props) => {
    return {
        views: state.views,
        modelusage: state.modelusage,
        modelprediction: state.modelprediction,
        modelpredictioninputs: state.modelpredictioninputs,
        modeldata: state.modeldata,
    }

})(withStyles(styles)(ModelSettings));
