export function UpdateModelPredictionSelectedAxes(x_axis, y_axis) {
  return {
    type: "UPDATE_PREDICTION_CONTOUR_AXES",
    data: { prediction_contour_selected_x: x_axis, prediction_contour_selected_y: y_axis }
  };
}

export function UpdateModelPredictionSelectedXAxis(x_axis) {
  return {
    type: "UPDATE_PREDICTION_CONTOUR_X_AXIS",
    data: { prediction_contour_selected_x: x_axis }
  };
}

export function UpdateModelPredictionSelectedYAxis(y_axis) {
  return {
    type: "UPDATE_PREDICTION_CONTOUR_Y_AXIS",
    data: { prediction_contour_selected_y: y_axis }
  };
}

export function UpdateModelPredictionContourData(data) {
  return {
    type: "UPDATE_PREDICTION_CONTOUR_DATA",
    data: { prediction_contour_data: data }
  };
}

export function UpdateModelPredictionContourThresholds(thresholds) {
  return {
    type: "UPDATE_PREDICTION_CONTOUR_THRESHOLDS",
    data: { prediction_contour_output_thresholds: thresholds }
  };
}

export function UpdateModelPredictionContourThresholdSigns(signs) {
  return {
    type: "UPDATE_PREDICTION_CONTOUR_THRESHOLD_SIGNS",
    data: { prediction_contour_output_threshold_signs: signs }
  };
}
