export function UpdateAllFileHeaders(headers, filename, source_id) {
    return {
        type: 'UPDATE_ALL_FILE_HEADERS',
        data: {
            all_file_headers: headers,
            uploaded_file_name: filename,
            source_id: source_id
        }
    }
}

export function UpdateDataSnapshot(headers, tableData, recommendations, rows, encodedHeaders, encodedTableData, encodedRows, excludedColumns=[]) {
    return {
        type: 'UPDATE_DATA_SNAPSHOT',
        data: {
            data_snapshot_headers: headers,
            data_snapshot_table: tableData,
            data_organization_recommendations: recommendations.sort((a, b) => (b.count > a.count) ? 1 : ((a.count > b.count) ? -1 : 0)),
            data_snapshot_rows: rows,
            data_snapshot_encoded_headers: encodedHeaders,
            data_snapshot_encoded_table: encodedTableData,
            data_snapshot_encoded_rows: encodedRows,
            data_snapshot_excluded_columns: excludedColumns
        }
    }
}

export function UpdateModelData(models, pca, linearReg, dataRanges, categoricals, modelsArePresent, dataColumns, plottableData, outputDataRanges, modelSaved, modelID) {
    return {
        type: 'UPDATE_MODEL_DATA',
        data: {
            models_are_present: modelsArePresent,
            models: models,
            pca_data: pca,
            linear_regression_data: linearReg,
            data_ranges: dataRanges,
            categorical_variables: categoricals,
            data_columns: dataColumns,
            plottable_data:plottableData,
            output_data_ranges:outputDataRanges,
            model_saved: modelSaved,
            model_id: modelID,
        }
    }
}

export function UpdateModelSavedState(modelSaved){
    return{
        type: 'UPDATE_MODEL_DATA_SAVED_STATE',
        data:{
            model_saved: modelSaved,
        }
    }
}

export function UpdateModelAccessSettings(visibility, permission, canShare, canEdit){
    return{
        type: 'UPDATE_MODEL_ACCESS',
        data: {
            model_visibility: visibility,
            user_permission: permission,
            user_can_share: canShare,
            user_can_edit: canEdit
        }
    }
}

export function UpdateSourceId(source_id){
    return{
        type: 'UPDATE_SOURCE_ID',
        data: {
            source_id: source_id
        }
    }
}

export function UpdateModelDataModelList(models){
    return{
        type:'UPDATE_MODEL_DATA_MODEL_LIST',
        data:{
            models: models
        }
    }
}

export function ResetAll() {
    return {
        type: 'RESET_ALL',
        data: {}
    }
}

export function UpdateAllData(all_data){
    return{
        type:'UPDATE_ALL_DATA',
        data:{
            all_data: all_data
        }
    }
}