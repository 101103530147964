import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import * as actions from '../actions/viewActions'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import TuneIcon from '@material-ui/icons/Tune';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BarChartIcon from '@material-ui/icons/BarChart';
import AppsIcon from '@material-ui/icons/Apps';
import InsertChartIcon from '@material-ui/icons/InsertChart';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    color: 'inherit',

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected:
  {
    color: 'dodgerblue',
  },
  unselected:
  {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  delete:
  {
    color:'red'
  }
});

class ExploreMenuList extends React.Component {

  state = {
    analyzeopen: true,
    visualizeopen: true,
  };
  componentDidMount(){
    this.setState({
      analyzeopen: true,
      visualizeopen: true,
    });
  };

  handleOptionClick = (newVal) => {
    this.props.dispatch(actions.changeViewType(newVal));
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* <MuiThemeProvider theme={theme}> */}
        <Divider />
        <List component="nav" dense={true} subheader={<ListSubheader component="div">Data Preprocessing
        </ListSubheader>}>
          <MenuItem button
            onClick={() => this.handleOptionClick('dataviz') }
            disabled={!this.props.modeldata.all_data}
          >
            <ListItemIcon>
              <InsertChartIcon className={this.props.views.currentView==='dataviz'? classes.selected : classes.unselected } />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={<Typography style={{ fontSize:'.9rem', fontWeight:500 }}
                                   className={this.props.views.currentView==='dataviz'? classes.selected : classes.unselected }
                                   data-cy='page_modeleval'>Data Visualization</Typography>}/>
          </MenuItem>
        </List>

        <Divider />
        <List component="nav" dense={true} subheader={<ListSubheader component="div">Predictive Modeling
        </ListSubheader>}>

          <MenuItem button
            onClick={() => this.handleOptionClick('modeleval') }
            disabled={!this.props.modeldata.models_are_present}
            >
             <ListItemIcon>
              <BarChartIcon className={this.props.views.currentView==='modeleval'? classes.selected : classes.unselected } />
            </ListItemIcon>
            <ListItemText
            disableTypography
            primary={<Typography style={{ fontSize:'.9rem', fontWeight:500 }}
                                  className={this.props.views.currentView==='modeleval'? classes.selected : classes.unselected }
                                  data-cy='page_modeleval'>Model Evaluation</Typography>}/>
          </MenuItem>

          <MenuItem button
            onClick={() => this.handleOptionClick('modelpred')}
            disabled={!this.props.modeldata.models_are_present}
            >
             <ListItemIcon>
              <TuneIcon className={this.props.views.currentView==='modelpred'? classes.selected : classes.unselected } />
            </ListItemIcon>
            <ListItemText
            disableTypography
            primary={<Typography style={{ fontSize:'.9rem', fontWeight:500 }}
                                className={this.props.views.currentView==='modelpred'? classes.selected : classes.unselected }
                                data-cy='page_modelpred'>Model Prediction</Typography>}/>
          </MenuItem>

          <MenuItem button
            onClick={() => this.handleOptionClick('modelopt') }
            disabled={!this.props.modeldata.models_are_present}
            >
             <ListItemIcon>
              <TrendingUpIcon className={this.props.views.currentView==='modelopt'? classes.selected : classes.unselected } />
            </ListItemIcon>
            <ListItemText
            disableTypography
            primary={<Typography style={{ fontSize:'.9rem', fontWeight:500 }}
                                className={this.props.views.currentView==='modelopt'? classes.selected : classes.unselected }
                                data-cy='page_modelopt'>Experiment Optimization</Typography>}/>
          </MenuItem>



          <MenuItem button
            onClick={() => this.handleOptionClick('datadist')}
            disabled={!this.props.modeldata.models_are_present}
            >
             <ListItemIcon>
              <AppsIcon className={this.props.views.currentView==='datadist'? classes.selected : classes.unselected } />
            </ListItemIcon>
            <ListItemText
            disableTypography
            primary={<Typography style={{ fontSize:'.9rem', fontWeight:500 }}
                                className={this.props.views.currentView==='datadist'? classes.selected : classes.unselected }
                                data-cy='page_datadist'>Pairwise Relations</Typography>}/>
          </MenuItem>
        </List>
            {/*<MenuItem button
              onClick={() => this.handleOptionClick('data3d')}
               disabled={!this.props.modeldata.models_are_present}
              >
               <ListItemIcon>
                <BubbleChartIcon className={this.props.views.currentView==='data3d'? classes.selected : classes.unselected } />
              </ListItemIcon>
              <ListItemText
              disableTypography
              primary={<Typography style={{ fontSize:'.9rem', fontWeight:500 }}
                                  className={this.props.views.currentView==='data3d'? classes.selected : classes.unselected }
                                  data-cy='page_data3d'>3D Visualization</Typography>}/>
            </MenuItem>*/}
      </div>
    );
  }
}

ExploreMenuList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect((state, props) => {
  return {
    views: state.views,
    models: state.models,
    newmodel: state.newmodel,
    modeldata: state.modeldata,
  }

})(withStyles(styles)(withRouter(ExploreMenuList)));
