import React from "react";
import { Router } from "react-router-dom";
import "./App.css";
import history from "./utils/history";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Dashboard from './components/Dashboard.js';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import moment from 'moment';

const cookies = new Cookies();

function App() {
  const { logout, user, getAccessTokenSilently } = useAuth0();

  // get url params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let token = urlParams.get('token') ? urlParams.get('token') : "";
  let debugMode = urlParams.get('debug') ? urlParams.get('debug')=== 'true' || urlParams.get('debug')=== 'True' : false;

  const logoutWithRedirect = () => {
    cookies.remove("token");
    cookies.remove("graph_token");
    logout({
      returnTo: window.location.origin,
      federated: true,
    });
  };

  const axPost = async (endPoint, data, config) => {
    const baseURL = process.env.REACT_APP_BASE_AICHEMY_URL;
    var headers = {};

    var token = await getAccessTokenSilently();

    var decoded = jwt_decode(token);
    const graphDecoded = jwt_decode(decoded['https://azuread#ad_access_token']);
    if ((moment().unix() + 30) > graphDecoded.exp) {
      token = await getAccessTokenSilently({ignoreCache:true});
      decoded = jwt_decode(token);
    }
    cookies.set('graph_token', decoded['https://azuread#ad_access_token'], { path: '/' })

    if ((new URL(process.env.REACT_APP_BASE_AICHEMY_URL).host).includes("localhost")) {
      headers.Authorization = `Bearer ${token}`
    } else {
      headers.authorizationToken = `Bearer ${token}`
    }

    const instance = axios.create({
      baseURL: baseURL,
      timeout: 300000, // unit: ms
      headers: headers
    });

    const res = await instance.post(endPoint, data, config);
    return res;
  };

  return (
    <Router history={history}>
      <div className="App">
        <Dashboard
          onLogoutClick={logoutWithRedirect}
          user={user}
          token={{ token }}
          axPost={axPost}
          debugMode={debugMode}
        />
      </div>
    </Router>
  );
}

export default withAuthenticationRequired(App);
