export function UpdateModelOptimizationInputValues(optimizations) {
  return {
    type: "UPDATE_MODEL_OPTIMIZATION_VALUES",
    data: { optimization_input_values: optimizations }
  };
}

export function UpdateModelOptimizationOutputValues(values, tableData, optimizationPlotData, legendText) {
  return {
    type: "UPDATE_MODEL_OPTIMIZATION_OUTPUT_VALUES",
    data: {
      optimization_output_values: values,
      optimization_table_data: tableData,
      optimization_plot_data: optimizationPlotData,
      legend_text: legendText
    }
  };
}

export function UpdateSelectedModel(modelName) {
  return {
    type: "UPDATE_MODEL_OPTIMIZATION_SELECTED_MODEL",
    data: { selected_model_name: modelName }
  };
}

export function UpdateOptimizationDisplayColumns(displayColumns, legendText) {
  return {
    type: "UPDATE_OPTIMIZATION_DISPLAY_COLUMNS",
    data: {
      optimization_display_columns: displayColumns,
      legend_text: legendText
    }
  };
}

export function UpdateOptimizationTargetSettings(settings) {
  return {
    type: "UPDATE_OPTIMIZATION_OUTPUT_TARGET_SETTINGS",
    data: {
      optimization_output_target_settings: settings
    }
  };
}

export function UpdateOptimizationData(tableData, tableHeaders, constraints, targets, plotData, stabilityEvaluations, stabilityBands) {
  return {
    type: "UPDATE_OPTIMIZATION_DATA",
    data: {
      optimization_table_data: tableData,
      optimization_table_headers: tableHeaders,
      optimization_used_constraints: constraints,
      optimization_used_targets: targets,
      optimization_plot_data: plotData,
      optimization_stability_evaluation_data: stabilityEvaluations,
      optimization_stability_band_data: stabilityBands
    }
  };
}

export function UpdateSelectedOptimization(index, data) {
  return {
    type: "UPDATE_OPTIMIZATION_SELECTED_OPTIMIZATION",
    data: {
      selected_optimization_row_index: index,
      selected_optimization_row_data: data
    }
  };
}

export function UpdateOptimizationStabilityVariations(inputVariations) {
  return {
    type: "UPDATE_OPTIMIZATION_STABILITY_VARIATIONS",
    data: {
      optimization_stability_variations: inputVariations
    }
  };
}

export function UpdateOptimizationStabilityTable(headers, data) {
  return {
    type: "UPDATE_OPTIMIZATION_STABILITY_TABLE_DATA",
    data: {
      optimization_stability_table_headers: headers,
      optimization_stability_table_data: data
    }
  };
}

export function UpdateOptimizationRandomSettings(useRandom, seedValue) {
  return {
    type: "UPDATE_OPTIMIZATION_RANDOMIZATION",
    data: {
      optimization_use_random_seed: useRandom,
      optimization_random_seed_value: seedValue
    }
  };
}
