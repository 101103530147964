import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import d3Tip from "d3-tip";
import _ from "lodash";
import "./MultiLineChart.css";

class CoefficientsBarChart extends Component {
  constructor(props) {
    super(props);

    this.fullWidth = 0;
    this.chartWidth = 0;
    this.chartHeight = 0;
    this.titleHeight = 15;
    this.chartMargins = null;

    this.yScale = null;
    this.yAxis = null;
    this.yAxisGroup = null;
    this.yDomain = [1, 10];

    this.xScale = null;
    this.xAxis = null;
    this.xAxisGroup = null;
    this.xDomain = [1, 10];

    this.xAxisLabel = null;
    this.yAxisLabel = null;
    this.chartTitle = null;

    this.lineGroup = null;
    this.barGroup = null;
    this.barLabelGroup = null;

    this.tip = null;

    this.brush = null;
    this.clippingMaskBrush = null;
    this.idleDelay = 350;

    this.animationDuration = 1500;
    this.zoomDuration = 500;

    this.chart = null;
    this.clippingMask = null;
    this.lineOfBestFit = null;

    this.colorScheme = d3.scaleOrdinal(d3.schemeCategory10);

    this.resize = this.resize.bind(this);
    this.debouncedResize = _.debounce(this.resize, 250);
    this.current_props = null;
    //this.resizeFlag = true;
  }

  axesAreValid() {
    if (
      !isNaN(this.props.xAxisMin) &&
      this.props.xAxisMin !== "" &&
      !isNaN(this.props.xAxisMax) &&
      this.props.xAxisMax !== "" &&
      !isNaN(this.props.yAxisMin) &&
      this.props.yAxisMin !== "" &&
      !isNaN(this.props.yAxisMax) &&
      this.props.yAxisMax !== ""
    ) {
      return this.props.xAxisMin < this.props.xAxisMax && this.props.yAxisMin < this.props.yAxisMax;
    }
    return false;
  }

  resize() {
    if (document.getElementById(this.props.appendDiv).clientWidth === 0 || this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();
    this.updateAxes(0);
    this.updateAxisLabels();
    this.updateLines(0);
    this.updateDataBars(0);
    this.updateBarLabels(0);
    this.current_props = this.props;
  }

  componentDidMount() {
    this.current_props = this.props;
    const el = ReactDOM.findDOMNode(this);
    this.updateDimensions();
    this.createChart(el);
    this.updateAxisLabels();
    window.addEventListener("resize", this.debouncedResize);
    this.updateLines(this.animationDuration);
    this.updateDataBars(this.animationDuration);
    this.updateBarLabels(this.animationDuration);
    this.updateTooltips();
  }

  shouldComponentUpdate(newProps) {
    if (document.getElementById(this.props.appendDiv).clientWidth !== this.fullWidth && newProps.currentView === this.current_props.currentView) {
      this.animationDuration = 0;
      return true;
    }
    if (_.isEqual(newProps, this.current_props)) {
      return false;
    }
    if (newProps.currentView !== this.current_props.currentView) {
      //if this is not true it suggest tab change which doesnt need an update
      return false;
    }
    this.current_props = newProps;
    return true;
  }

  componentDidUpdate() {
    if (this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();
    this.updateAxes(this.animationDuration);
    this.updateLines(this.animationDuration);
    this.updateDataBars(this.animationDuration);
    this.updateBarLabels(this.animationDuration);
    this.updateAxisLabels();
    this.updateTooltips();
    this.animationDuration = 1500;
  }

  componentWillUnmount() {
    //this.debouncedResize.cancel();
    window.removeEventListener("resize", this.debouncedResize);
  }

  wrapTitle() {
    // shrink fontsize to have title fit inside block
    let words = this.chartTitle
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      x = this.chartTitle.attr("x"),
      y = this.chartTitle.attr("y"),
      fontsize=17,
      tspan = this.chartTitle
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .style("font-size", fontsize.toString() + "px");
    
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
    }
    while (tspan.node().getComputedTextLength() > this.chartWidth) {
      tspan = this.chartTitle
        .text(this.props.chartTitle)
        .style("font-size", (--fontsize).toString() + "px")
    }
    this.titleHeight = fontsize;
  }

  updateDimensions() {
    //Figure out how much space you need for your longest label...
    let currentWidth = document.getElementById(this.props.appendDiv).clientWidth;
    this.chartMargins = { left: currentWidth * 0.07, right: currentWidth * 0.05, top: 20, bottom: currentWidth * 0.1 };

    this.fullWidth = currentWidth;
    this.chartWidth = currentWidth * 0.9;
    this.chartHeight = currentWidth * this.props.heightToWidthRatio;

    if (this.chartMargins.left < 54) {
      let tmpChartWidth = currentWidth - 64;
      let tmpChartHeight = currentWidth * this.props.heightToWidthRatio - 64;
      if (tmpChartHeight > 0 && tmpChartWidth > 0) {
        this.chartMargins = { left: 54, right: 10, top: 10, bottom: 64 };
        this.chartWidth = tmpChartWidth;
        this.chartHeight = tmpChartHeight;
      }
    }
  }

  updateAxisLabels() {
    let maxHeights = [];
    this.xAxisLabel.text(this.props.xLabel);
    this.yAxisLabel.text(this.props.yLabel).each(function(d, j) {
      let thisWidth = this.getComputedTextLength();
      maxHeights.push(thisWidth);
    });
    this.chartTitle.text(this.props.chartTitle);
    this.chartTitle.call(() => this.wrapTitle());

    var maxHeight = d3.max(maxHeights);
    if (maxHeight > this.chartHeight) {
      this.mainBody.attr("height", maxHeight + this.chartMargins.top + this.chartMargins.bottom);
    }
  }

  updateDomains() {
    this.xDomain = [1, 10];
    this.yDomain = [1, 10];
    if (this.props.scaleAllAxes && this.axesAreValid()) {
      let currXRange = Number(this.props.xAxisMax) - Number(this.props.xAxisMin);
      let currYRange = Number(this.props.yAxisMax) - Number(this.props.yAxisMin);
      this.xDomain =
        this.props.xScaleType === "linear"
          ? [Number(this.props.xAxisMin) - currXRange * 0.03, Number(this.props.xAxisMax) + currXRange * 0.03]
          : [Number(this.props.xAxisMin) / 1.3, Number(this.props.xAxisMax) * 1.3];
      this.yDomain =
        this.props.yScaleType === "linear"
          ? [Number(this.props.yAxisMin) - currYRange * 0.03, Number(this.props.yAxisMax) + currYRange * 0.03]
          : [Number(this.props.yAxisMin) / 1.3, Number(this.props.yAxisMax) * 1.3];
      return;
    }
    if (this.props.data.length > 0) {
      let yMin = 0;
      let yMax = 0;
      let xMin = 0;
      let xMax = this.props.data.length + 0.25;
      this.props.data.forEach(function(coeff) {
        yMin = Math.min(coeff.coeff, yMin);
        yMax = Math.max(coeff.coeff, yMax);
      });

      let yRange = yMax - yMin;	
      if (this.props.yScaleType === "linear") {	
        yMin -= yRange * 0.1;	
      } else {	
        yMin /= 1.3;	
      }

      this.yDomain = [yMin, yMax];
      this.xDomain = [xMin, xMax];
      this.lineOfBestFit = [];
      this.lineOfBestFit.push({ x: 0, y: 0 });
      this.lineOfBestFit.push({ x: xMax + 0.5, y: 0 });
    }
  }

  updateScales() {
    this.xScale =
      this.props.xScaleType === "linear"
        ? d3
            .scaleLinear()
            .range([0, this.chartWidth])
            .domain(this.xDomain)
        : d3
            .scaleLog()
            .base(10)
            .range([0, this.chartWidth])
            .domain(this.xDomain);
    this.yScale =
      this.props.yScaleType === "linear"
        ? d3
            .scaleLinear()
            .range([this.chartHeight, 0])
            .domain(this.yDomain)
        : d3
            .scaleLog()
            .base(10)
            .range([this.chartHeight, 0])
            .domain(this.yDomain);
  }

  updateAxes(duration) {
    // this.xAxis.scale(this.xScale);
    //this.xAxisGroup.transition().ease(d3.easeCubic).duration(duration).call(this.xAxis);
    this.yAxis.scale(this.yScale);
    this.yAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .call(this.yAxis);
  }

  updateLines(duration) {
    var currXScale = this.xScale;
    var currYScale = this.yScale;
    var currXDomain = this.xDomain;
    var currYDomain = this.yDomain;
    var lineFunction = d3
      .line()
      .x(function(d) {
        return currXScale(d.x);
      })
      .y(function(d) {
        return currYScale(d.y);
      });
    var lineFunctionInitial = d3
      .line()
      .x(function(d) {
        return currXScale(currXDomain[0]);
      })
      .y(function(d) {
        return currYScale(currYDomain[0]);
      });
    var linePts = this.lineOfBestFit;

    this.lineGroup
      .selectAll(".line")
      .data([linePts])
      .enter()
      .append("path")
      .attr("class", "line")
      .attr("d", d => lineFunctionInitial(d))
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("d", d => lineFunction(d))
      .style("stroke", "black")
      .style("stroke-width", 1)
      .attr("shape-rendering", "crispEdges");

    this.lineGroup	
      .selectAll(".line")	
      .data([linePts])	
      .transition()	
      .ease(d3.easeCubic)	
      .duration(duration)	
      .attr("d", d => lineFunction(d))	
      .style("stroke", "black")	
      .style("stroke-width", 1)	
      .attr("shape-rendering", "crispEdges");

    this.lineGroup
      .selectAll(".line")
      .data([linePts])
      .exit()
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("d", d => lineFunctionInitial(d.dataPoints))
      .remove();
  }

  updateDataBars(duration) {
    var currXScale = this.xScale;
    var currYScale = this.yScale;

    this.barGroup
      .selectAll(".bar")
      .data(this.props.data, function(d, i) {
        return d.key;
      })
      .enter()
      .append("rect")
      .attr("height", 0)
      .attr("x", function(d, i) {
        return currXScale(i + 0.25);
      })
      .attr("y", function(d, i) {
        return d.coeff < 0 ? currYScale(0) : currYScale(d.coeff);
      })
      .attr("width", function(d) {
        return currXScale(0.8);
      })
      .attr("fill", "lightgrey")
      .attr("class", "bar")
      .attr("id", function(d) {
        return d.key;
      })
      .attr("shape-rendering", "crispEdges")
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("x", function(d, i) {
        return currXScale(i + 0.25);
      })
      .attr("height", function(d) {
        return d.coeff < 0 ? currYScale(d.coeff) - currYScale(0) : currYScale(0) - currYScale(d.coeff);
      })
      .attr("y", function(d, i) {
        return d.coeff < 0 ? currYScale(0) : currYScale(d.coeff);
      })
      .attr("width", function(d) {
        return currXScale(0.8);
      })
      .style("fill", function(d) {
        return "lightgrey";
      })
      .attr("opacity", "1")
      .style("stroke-width", "1")
      .style("stroke", "black")
      .attr("shape-rendering", "crispEdges");

    this.barGroup	
      .selectAll(".bar")	
      .data(this.props.data, function(d, i) {	
        return d.key;	
      })	
      .transition()	
      .ease(d3.easeCubic)	
      .duration(duration)	
      .attr("x", function(d, i) {	
        return currXScale(i + 0.25);	
      })	
      .attr("height", function(d) {	
        return d.coeff < 0 ? currYScale(d.coeff) - currYScale(0) : currYScale(0) - currYScale(d.coeff);	
      })	
      .attr("y", function(d, i) {	
        return d.coeff < 0 ? currYScale(0) : currYScale(d.coeff);	
      })	
      .attr("width", function(d) {	
        return currXScale(0.8);	
      })	
      .style("fill", function(d) {	
        return "lightgray";	
      })	
      .attr("shape-rendering", "crispEdges");

    this.barGroup
      .selectAll(".bar")
      .data(this.props.data, function(d, i) {
        return d.key;
      })
      .exit()
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("height", 0)
      .remove();
  }

  updateBarLabels(duration) {
    let currXScale = this.xScale;
    let currYScale = this.yScale;
    let yRange = this.yDomain[1] - this.yDomain[0];
    let toolTip = this.tip;

    this.barLabelGroup
      .selectAll(".barlabel")
      .on("mouseover", function(d) {
        toolTip.html(function() {
          return d.key;
        });
        toolTip.show(d, this);
      })
      .on("mouseout", function(d) {
        toolTip.hide(d, this);
      })
      .data(this.props.data, function(d, i) {
        return d.key;
      })
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("text-anchor", function(d) {
        return d.coeff < 0 ? "start" : "end";
      })
      .attr("transform", function(d, i) {
        return d.coeff < 0
          ? "translate(" + currXScale(i + 0.75) + " " + currYScale(yRange * 0.01) + ") rotate(-90)"
          : "translate(" + currXScale(i + 0.75) + " " + currYScale(-(yRange * 0.01)) + ") rotate(-90)";
      })
      .attr("class", "barlabel")
      .attr("id", function(d) {
        return d.key;
      })
      .text(function(d) {
        return d.key.length > 11 ? " " + d.key.substring(0, 6) + "..." : " " + d.key;
      })
      .style("fill", "black")
      .style("font-size", "14px")
      .style("font-family", "Roboto,Sans-Serif");

    this.barLabelGroup	
      .selectAll(".barlabel")	
      .data(this.props.data, function(d, i) {	
        return d.key;	
      })	
      .enter()	
      .append("text")	
      .on("mouseover", function(d) {
        toolTip.html(function() {
          return d.key;
        });
        toolTip.show(d, this);
      })
      .on("mouseout", function(d) {
        toolTip.hide(d, this);
      })
      .attr("transform", function(d, i) {	
        return d.coeff < 0	
          ? "translate(" + currXScale(i + 0.75) + " " + currYScale(yRange * 0.01) + ") rotate(-90)"	
          : "translate(" + currXScale(i + 0.75) + " " + currYScale(-(yRange * 0.01)) + ") rotate(-90)";	
      })	
      .attr("class", "barlabel")	
      .attr("id", function(d) {	
        return d.key;	
      })	
      .attr("text-anchor", function(d) {	
        return d.coeff < 0 ? "start" : "end";	
      })	
      .text(function(d) {	
        return d.key.length > 11 ? " " + d.key.substring(0, 6) + "..." : " " + d.key;
      })	
      .style("fill", "black")	
      .style("font-size", "14px")
      .style("font-family", "Roboto,Sans-Serif");	

    this.barLabelGroup
      .selectAll(".barlabel")
      .data(this.props.data, function(d, i) {
        return d.key;
      })
      .exit()
      .remove();
  }

  updateTooltips() {
    if (!this.props.showToolTips) {
      return;
    }
    let toolTip = this.tip;

    this.barGroup
      .selectAll(".bar")
      .on("mouseover", function(el) {
        let parentID = d3.select(this)._groups[0][0].id;
        d3.select(this).style("fill", "gold");
        //let selectedVar = data.filter(function (d) { return d.key === parentID })[0];
        toolTip.html(function(i) {
          let tipText =
            "<strong style='color:red;font-size:13px;'>Variable: </strong><span style='color:white;font-size:13px;font-weight:400'>" +
            parentID +
            "</span> <br>";
          tipText +=
            "<strong style='color:red;font-size:13px;'>Value: </strong> <span style='color:white;font-size:13px;font-weight:400'>" +
            Number.parseFloat(el.coeff).toFixed(3) +
            "</span> <br>";
          return tipText;
        });
        toolTip.show(el, this);
      })
      .on("mouseout", function(el) {
        d3.select(this).style("fill", "lightgray");
        toolTip.hide(el, this);
      });
  }

  chartZoom() {
    this.updateDataPoints(this.zoomDuration);
    this.updateLines(this.zoomDuration);
    //this.xAxisGroup.transition().ease(d3.easeCubic).duration(this.zoomDuration).call(this.xAxis);
    this.yAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(this.zoomDuration)
      .call(this.yAxis);
  }

  brushEnded = () => {
    let s = d3.event.selection;
    if (!s) {
      if (!this.idleTimeout) {
        return (this.idleTimeout = setTimeout(
          function() {
            this.idled();
          }.bind(this),
          this.idleDelay
        ));
      }
      this.updateDomains();
      this.updateScales();
    } else {
      this.xDomain = [s[0][0], s[1][0]].map(this.xScale.invert, this.xScale);
      this.yDomain = [s[1][1], s[0][1]].map(this.yScale.invert, this.yScale);
      this.xScale.domain(this.xDomain);
      this.yScale.domain(this.yDomain);
      this.clippingMask.select(".brush").call(this.brush.move, null);
    }
    this.xAxis.scale(this.xScale);
    this.yAxis.scale(this.yScale);
    this.chartZoom();
  };

  idled = () => {
    this.idleTimeout = null;
  };

  updateChartSize = () => {
    this.mainBody
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("width", this.fullWidth);

    this.chart
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    // this.xAxisGroup.attr("transform", "translate(0," + this.chartHeight + ")");
    // this.xAxisGroup.call(this.xAxis);

    this.yAxisGroup.call(this.yAxis);

    //Append a clipping mask to avoid data from falling off the chart
    this.clippingMask
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight);

    //Initialize tooltip here

    //Append axis labels here
    this.xAxisLabel.attr("y", this.chartHeight + 35).attr("x", this.chartWidth);

    this.yAxisLabel
      .attr("y", -this.chartMargins.left + 10)
      .attr("x", -1)
      .attr("transform", "rotate(-90)");

    // this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded),
    //     this.idleTimeout,
    //     this.idleDelay = 350;

    // this.clippingMaskBrush.call(this.brush);
    this.barGroup.moveToFront();
    this.barLabelGroup.moveToFront();
    this.lineGroup.moveToFront();
  };

  createChart = el => {
    //Create scales and initialize with domain 0-10
    this.updateDomains();
    this.updateScales();

    //Append a larger svg and create the graph region
    this.mainBody = d3
      .select(el)
      .append("svg")
      .attr("class", "multiLineMultiAxisChart")
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("width", this.fullWidth)
      .attr("id", this.props.chartID);

    this.chartTitle = this.mainBody
      .append("text")
      .attr("id", "chartTitle")
      .attr("class", "axisLabel")
      .attr("y", 15)
      .attr("x", 1)
      .style("fill", "black")
      .style("font-size", "17px")
      .style("font-family", "Roboto,Sans-Serif")
      .style("font-weight", 500)
      .text(this.props.chartTitle);
    this.chartTitle.call(() => this.wrapTitle());

    this.chart = this.mainBody
      .append("g")
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    //Create axes and append to chart.
    // this.xAxis = d3.axisBottom(this.xScale);
    // this.xAxisGroup = this.chart.append("g").attr("class", "axis xaxis").attr("transform", "translate(0," + this.chartHeight + ")");
    // this.xAxisGroup.call(this.xAxis);

    this.yAxis = d3.axisLeft(this.yScale);
    this.yAxisGroup = this.chart.append("g").attr("class", "axis yaxis");
    this.yAxisGroup.call(this.yAxis);

    //Append a clipping mask to avoid data from falling off the chart
    this.clippingMask = this.chart
      .append("svg")
      .attr("id", "cmask")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .style("fill", "transparent");

    //Initialize tooltip here

    //Append axis labels here
    this.xAxisLabel = this.chart
      .append("text")
      .attr("id", "xAxisLabel")
      .attr("class", "axisLabel")
      .attr("text-anchor", "end")
      .attr("y", this.chartHeight + 45)
      .attr("x", this.chartWidth)
      .style("fill", "black")
      .style("font-size", "17px")
      .style("font-family", "Roboto,Sans-Serif")
      .text(this.props.xLabel);

    this.yAxisLabel = this.chart
      .append("text")
      .attr("id", "yAxisLabel")
      .attr("class", "axisLabel")
      .attr("text-anchor", "end")
      .attr("y", -this.chartMargins.left + 20)
      .attr("x", -1)
      .attr("transform", "rotate(-90)")
      .style("fill", "black")
      .style("font-size", "17px")
      .style("font-family", "Roboto,Sans-Serif")
      .text(this.props.yLabel);

    this.lineGroup = this.clippingMask.append("g").attr("id", "yLines");
    this.barGroup = this.clippingMask.append("g").attr("id", "yBars");
    this.barLabelGroup = this.clippingMask.append("g").attr("id", "yBarLabels");

    this.tip = d3Tip()
      .attr("class", "d3-tip")
      .style("z-index", 10000)
      .offset([-10, 0]);
    this.chart.call(this.tip);

    // this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded),
    //     this.idleTimeout,
    //     this.idleDelay = 350;

    // this.clippingMaskBrush = this.clippingMask.append("g").attr("class", "brush");
    // this.clippingMaskBrush.call(this.brush);
    this.barGroup.moveToFront();
    this.barLabelGroup.moveToFront();
    this.lineGroup.moveToFront();
  };

  render() {
    return <div style={{ color: "black" }} />;
  }
}

d3.selection.prototype.moveToFront = function() {
  return this.each(function() {
    this.parentNode.appendChild(this);
  });
};

d3.selection.prototype.moveToBack = function() {
  return this.each(function() {
    var firstChild = this.parentNode.firstChild;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  });
};

export default CoefficientsBarChart;
