import React, { useRef, useState } from "react";
import * as PropTypes from "prop-types";
import IdleTimer from "react-idle-timer";
import IdleTimeoutAlert from "./IdleTimeOutAlert";

let countdownTimer;
let timeoutTimer;

export default function IdleTimeout({
  timeoutDuration,
  continueDuration,
  logout
}) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertCountdown, setAlertCountdown] = useState(0);
  const idleTimer = useRef(null);

  const clearTimeoutTimer = () => {
    clearTimeout(timeoutTimer);
  };

  const clearContinueTimer = () => {
    clearInterval(countdownTimer);
  };

  const handleLogout = () => {
    // clear the modal/reset the timers
    setAlertOpen(false);
    clearTimeoutTimer();
    clearContinueTimer();
    // logout
    logout();
  };

  const handleContinue = () => {
    // clear the modal/reset the timers
    setAlertOpen(false);
    clearTimeoutTimer();
    clearContinueTimer();
  };

  const onActive = () => {
    // clear the timers
    if (!alertOpen) {
      clearTimeoutTimer();
      clearContinueTimer();
    }
  };

  const onIdle = () => {
    // timers are in ms
    const delay = timeoutDuration * 1000;

    if (!alertOpen) {
      // set a timer to trigger the alert box to open
      timeoutTimer = setTimeout(() => {
        let countdown = continueDuration;
        setAlertOpen(true);
        setAlertCountdown(countdown);
        // increment the timer for the logout countdown
        countdownTimer = setInterval(() => {
          if (countdown > 0) {
            setAlertCountdown(--countdown);
          } else {
            handleLogout();
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <div>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={timeoutDuration}
      />
      <IdleTimeoutAlert
        open={alertOpen}
        countdown={alertCountdown}
        onLogout={handleLogout}
        onContinue={handleContinue}
      />
    </div>
  );
}

IdleTimeout.propTypes = {
  timeoutDuration: PropTypes.number.isRequired,
  continueDuration: PropTypes.number.isRequired,
  logout: PropTypes.func.isRequired
};