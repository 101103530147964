import React, {useState} from "react";
import { connect } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as viewActions from "../../actions/viewActions";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core/styles";
import * as currentModelActions from "../../actions/currentmodelsActions";
import * as modelDataActions from "../../actions/modeldataActions";

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
        // height: '100vh',
        minHeight: "95vh",
        margin: "auto",
        zIndex: 1,
        //overflow: 'hidden',
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        position: "fixed",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        color: "white"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "fixed"
        },
        minHeight: "100vh"
    },
    content: {
        minHeight: `calc(100vh - 48px)`,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    loader: {
        // position: 'absolute',
        // top: '40%',
        // left: '50%',
        // margin: '0px',
        marginLeft: "0px",
        [theme.breakpoints.up("md")]: {
            //left: `calc(50% - ${drawerWidth/2}px)`,
            marginLeft: `calc(${drawerWidth}px)`
        }
    }
});

function DeleteModelDialog(props)  {

    const { classes } = props;

    const [ state, setState ] = useState({
        mobileOpen: false,
        view: "upload",
        createProjOpen: false,
        modelDeleteName: "",
        modelSaveName: "",
        modelDescription: ""
    });

    let deleteModelConfirm = event => {
        setState({ ...state, modelDeleteName: "", modelSaveName: "", modelDescription: "" });
        props.dispatch(viewActions.UpdateConfirmDialog(false, "", ""));
        props.dispatch(viewActions.changeLoadingState(true));
        props.dispatch(viewActions.updateLoadingMessage("Removing " + props.savemodel.save_model_name + "..."));
        let selectedModelIndex = props.currentmodels.current_models_selected_model_rows;
        let post_id = props.currentmodels.current_models[selectedModelIndex].post_id;
        let url = `model/delete`;
        props.axPost(url, JSON.stringify({data: { user_pin: props.user.nickname, post_id: post_id }}), {
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
            .then(res => {
                let curr_models = JSON.parse(res.data.CurrentModels);
                props.dispatch(currentModelActions.UpdateCurrentModels(curr_models));
                props.dispatch(viewActions.changeLoadingState(false));
                props.dispatch(viewActions.updateLoadingMessage(""));
                props.dispatch(modelDataActions.ResetAll());
                props.dispatch(currentModelActions.UpdateSelectedModel('', []));
                props.dispatch(viewActions.changeViewType("newmodel"));
            })
            .catch(error => {
                props.dispatch(viewActions.changeLoadingState(false));
                props.dispatch(viewActions.updateLoadingMessage(""));
                props.dispatch(viewActions.UpdateAlert(true, "Model Deletion Failed", error.toString()));
            });
    };

    return(
        <Dialog open={props.views.showConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.views.confirmTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{props.views.confirmText}</DialogContentText>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.dispatch(viewActions.UpdateConfirmDialog(false, "", ""))} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => deleteModelConfirm()}
                    color="secondary"
                    autoFocus
                    data-cy="delete_model_btn"
                >
                    Delete Model
                </Button>
            </DialogActions>
        </Dialog>
    )

}

// Alerts.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired
// };

export default connect((state, props) => {
    return {
        views: state.views,
        savemodel: state.savemodel,
        modeldata: state.modeldata,
        currentmodels: state.currentmodels
    };
})(withStyles(styles, { withTheme: true })(DeleteModelDialog));