/**
 * Generates a prediction from sklearn trained PLS model using a set of prediction values (predVals--dictionary
 * of key value pairs), sklearn model parameters (x_mean_, y_mean_, x_std_, coef_), and data organization
 * parameters (inputOrganization, outputOrganization, dataRange, categoricalOrganization). Checks whether
 * prediction values violate any user contraints (constraints). Returns data in tabular format, as well as
 * a dictionary that can be used to render a parallel coordinates chart.
 */
//UNIT TEST NEEDED
export function CalculatePLSRes(predVals, x_mean_, y_mean_, x_std_, coef_, inputOrganization,
    outputOrganization, dataRange, categoricalOrganization, constraints) {
    /* PLS source code for reference:
    https://github.com/scikit-learn/scikit-learn/blob/master/sklearn/cross_decomposition/_pls.py
    Search for the `predict` method
    */
    //you need to get your data into a format that matches the inputOrganization array
    //tranform predvals
    let inputData = new Array(inputOrganization.length);
    Object.keys(predVals).forEach(key => {
        let index = inputOrganization.indexOf(key);
        if (index !== -1) {
            inputData[index] = predVals[key];
        }
    });

    Object.keys(categoricalOrganization['Inputs']).forEach(function (key) {

        let values = categoricalOrganization['Inputs'][key];
        values.forEach(function (val) {
            let updatedKey = val;
            let index = inputOrganization.indexOf(updatedKey);
            if (index !== -1) {
                inputData[index] = 0;
            }
        });
        let useKey = key + "_" + predVals[key];
        let useIndex = inputOrganization.indexOf(useKey);
        if (useIndex !== -1) {
            inputData[useIndex] = 1;
        }
    });

    //alright data should now be in the appropriate order in the array.
    //need to scale.
    let scaledData = new Array(inputData.length);
    for (let i = 0; i < inputData.length; i++) {
        let col = inputOrganization[i];
        let currRange = dataRange.filter(x => {return x.column===col})[0];
        scaledData[i] = (inputData[i] - currRange.min) / (currRange.max - currRange.min);
    }
    //start here...
    //plug that into an analog of the python code you put together

    //Calculated values are saved in this object
    let calculatedOutput = {};

    outputOrganization.forEach((col, index) => {
        let pred_one_copy = scaledData.slice(0);
        let curr_x_mean = x_mean_[col];
        let curr_y_mean = y_mean_[col];
        let curr_x_std = x_std_[col];
        let curr_coef = coef_[col];
        // standardize the input data first
        for (let i=0; i < pred_one_copy.length; i++){
            pred_one_copy[i] -= curr_x_mean[i]
            pred_one_copy[i] /= curr_x_std[i]
        }
        let y_out = 0
        // now apply the model coefficients
        for (let i=0; i < curr_coef.length; i++){
            y_out += (pred_one_copy[i] * curr_coef[i])
        }
        // finally, add the mean of this column
        y_out += curr_y_mean[0]
        calculatedOutput[col] = y_out.toFixed(4);

    });

    let tableData = [];
    let completePredObj = { '_dataRow': 'PredictionPLS' };
    Object.keys(calculatedOutput).forEach(function (key) {
        tableData.push([key, 'Output', calculatedOutput[key]]);
        completePredObj[key] = calculatedOutput[key];
    });
    Object.keys(predVals).forEach(function (key) {
        tableData.push([key, 'Input', predVals[key]]);
        completePredObj[key] = predVals[key]
    });
    let violatedConstraints = checkConstraints(completePredObj, constraints);
    let returnObj = { outputValues: calculatedOutput, inputValues: predVals, tableData: tableData, violatedConstraints: violatedConstraints, completePredObject: completePredObj };
    return returnObj;
}

/**
 * Tests whether any user-specified constraints are violated.
 */

export function checkConstraints(predictionInputSettings, constraints) {
    let currConstraints = constraints.filter(function (el) { return el.use });
    let violatedConstraints = [];
    currConstraints.forEach(function (el) {
        let total = 0;

        if (el.type === 'sum') {
            switch (el.sign) {
                case '==':
                    el.columns.forEach(function (col) {
                        total += parseFloat(predictionInputSettings[col]);
                    });
                    if (total !== parseFloat(el.value)) {
                        violatedConstraints.push("Σ(" + el.columns.join(', ') + ") " + el.displaySign + " " + el.value + ". Column Sum = " + total);
                    }
                    break;
                case '<=':
                    el.columns.forEach(function (col) {
                        total += parseFloat(predictionInputSettings[col]);
                    });
                    if (total > parseFloat(el.value)) {
                        violatedConstraints.push("Σ(" + el.columns.join(', ') + ") " + el.displaySign + " " + el.value + ". Column Sum = " + total);
                    }
                    break;
                case '>=':
                    el.columns.forEach(function (col) {
                        total += parseFloat(predictionInputSettings[col]);
                    });
                    if (total < parseFloat(el.value)) {
                        violatedConstraints.push("Σ(" + el.columns.join(', ') + ") " + el.displaySign + " " + el.value + ". Column Sum = " + total);
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            let partA = 0;
            let partB = 0;
            el.columns.forEach(function (col) {
                partA += predictionInputSettings[col];
            });
            el.columns_b.forEach(function (col) {
                partB += predictionInputSettings[col];
            });
            let ratio = partA / partB;
            switch (el.sign) {
                case '==':
                    if (ratio !== parseFloat(el.value)) {
                        violatedConstraints.push("Σ(" + el.columns.join(', ') + ") / Σ(" + el.columns_b.join(', ') + ") " + el.displaySign + " " + el.value + ". Ratio = " + (Math.round(ratio * 1000) / 1000));
                    }
                    break;
                case '<=':
                    if (ratio > parseFloat(el.value)) {
                        violatedConstraints.push("Σ(" + el.columns.join(', ') + ") / Σ(" + el.columns_b.join(', ') + ") " + el.displaySign + " " + el.value + ". Ratio = " + (Math.round(ratio * 1000) / 1000));
                    }
                    break;
                case '>=':
                    if (ratio < parseFloat(el.value)) {
                        violatedConstraints.push("Σ(" + el.columns.join(', ') + ") / Σ(" + el.columns_b.join(', ') + ") " + el.displaySign + " " + el.value + ". Ratio = " + (Math.round(ratio * 1000) / 1000));
                    }
                    break;
                default:
                    break;
            }
        }
    });
    return violatedConstraints;
}

/**
 * Generates a prediction from sklearn trained NN model using a set of prediction values (predVals--dictionary
 * of key value pairs), sklearn model parameters (coeffs, intercepts), and data organization
 * parameters (inputOrganization, outputOrganization, dataRange, categoricalOrganization). Checks whether
 * prediction values violate any user contraints (constraints). Returns data in tabular format, as well as
 * a dictionary that can be used to render a parallel coordinates chart.
 */
//UNIT TEST NEEDED
export function CalculateNNPred(predVals, outputCount, coeffs, intercepts, layerCount,
    inputOrganization, outputOrganization, categoricalOrganization, dataMin, dataRange, constraints) {
    //you need to get your data into a format that matches the inputOrganization array

    //tranform predvals
    let inputData = new Array(inputOrganization.length).fill(null);
    Object.keys(predVals).forEach(key => {
        let index = inputOrganization.indexOf(key);
        if (index !== -1) {
            inputData[index] = predVals[key];
        }
    });

    let validInputData = true;
    inputData.forEach(el => {
        if (isNaN(el)){
            validInputData = false;
        }
    })

    Object.keys(categoricalOrganization['Inputs']).forEach(function (key) {

        let values = categoricalOrganization['Inputs'][key];
        values.forEach(function (val) {
            let updatedKey = val;
            let index = inputOrganization.indexOf(updatedKey);
            if (index !== -1) {
                inputData[index] = 0;
            }
        });
        let useKey = key + "_" + predVals[key];
        let useIndex = inputOrganization.indexOf(useKey);
        if (useIndex !== -1) {
            inputData[useIndex] = 1;
        }
    });

    //alright data should now be in the appropriate order in the array.
    //need to scale.
    let scaledData = new Array(inputData.length).fill(null);
    for (let i = 0; i < inputData.length; i++) {
        scaledData[i] = (inputData[i] - dataMin[i]) / dataRange[i];
    }
    //start here...
    //plug that into an analog of the python code you put together
    let outputValues = new Array(outputCount).fill(null);

    outputOrganization.forEach((col, index) => {
        let currCoefficients = coeffs[col];
        let currIntercepts = intercepts[col];
        let activeLayer = JSON.parse(JSON.stringify(scaledData));
        let layerCount = currCoefficients.length;
        for (let i = 0; i < layerCount; i++) {
            let newLayer = Array(currCoefficients[i][0].length).fill(0);
            for (var j = 0; j < activeLayer.length; j++) {
                for (let x = 0; x < newLayer.length; x++) {
                    newLayer[x] += ((activeLayer[j]) * (currCoefficients[i][j][x]))
                }
            }
            for (let x = 0; x < newLayer.length; x++) {
                newLayer[x] += currIntercepts[i][x]
                newLayer[x] = Math.max(newLayer[x], 0) //relu logic
            }
            activeLayer = JSON.parse(JSON.stringify(newLayer));
        }
        outputValues[index] = activeLayer[0].toFixed(4);
    });

    let activeLayer = outputValues;

    let predObj = { '_dataRow': 'PredictionNN' };
    let outputObj = {};
    let tableData = [];
    for (let i = 0; i < outputOrganization.length; i++) {
        predObj[outputOrganization[i]] = validInputData ? activeLayer[i] : NaN;
        outputObj[outputOrganization[i]] = validInputData ? activeLayer[i] : NaN;
        tableData.push([outputOrganization[i], 'Output',  validInputData ? activeLayer[i] : NaN]);
    }
    for (let i = 0; i < inputOrganization.length; i++) {
        predObj[inputOrganization[i]] = inputData[i];
    }

    Object.keys(predVals).forEach(function (key) {
        tableData.push([key, 'Input', predVals[key]]);
    });

    let violatedConstraints = checkConstraints(predObj, constraints);
    let returnObj = {
        outputValues: outputObj,
        inputValues: predVals,
        tableData: tableData,
        violatedConstraints: violatedConstraints,
        completePredObject: predObj
    };
    return returnObj;
}

/**
 * Generates a prediction from sklearn trained KRR model using a set of prediction values (predVals--dictionary
 * of key value pairs), sklearn model parameters (dual_coef, x_fit, gamma), and data organization
 * parameters (inputOrganization, outputOrganization, dataRange, categoricalOrganization). Checks whether
 * prediction values violate any user contraints (constraints). Returns data in tabular format, as well as
 * a dictionary that can be used to render a parallel coordinates chart.
 */
//UNIT TEST NEEDED
export function CalculateKRRPred(predVals, outputCount, dual_coef, x_fit, gamma,
    inputOrganization, outputOrganization, categoricalOrganization, dataMin, dataRange, constraints) {
    //you need to get your data into a format that matches the inputOrganization array

    //tranform predvals
    let inputData = new Array(inputOrganization.length);
    Object.keys(predVals).forEach(key => {
        let index = inputOrganization.indexOf(key);
        if (index !== -1) {
            inputData[index] = predVals[key];
        }
    });

    Object.keys(categoricalOrganization['Inputs']).forEach(function (key) {

        let values = categoricalOrganization['Inputs'][key];
        values.forEach(function (val) {
            let updatedKey = val;
            let index = inputOrganization.indexOf(updatedKey);
            if (index !== -1) {
                inputData[index] = 0;
            }
        });
        let useKey = key + "_" + predVals[key];
        let useIndex = inputOrganization.indexOf(useKey);
        if (useIndex !== -1) {
            inputData[useIndex] = 1;
        }
    });

    //alright data should now be in the appropriate order in the array.
    //need to scale.
    let scaledData = new Array(inputData.length);
    for (let i = 0; i < inputData.length; i++) {
        scaledData[i] = (inputData[i] - dataMin[i]) / dataRange[i];
    }

    //start here...
    //plug that into an analog of the python code you put together

    //Get kernel
    let kernel = [];
    for(var h = 0; h < outputOrganization.length; h++)
    {
        kernel[outputOrganization[h]] = [];

        for(let i = 0; i < x_fit[outputOrganization[h]].length; i++)
        {
            let b_row = x_fit[outputOrganization[h]][i];
            let a_row = scaledData;
            let curr_result = new Array(x_fit[outputOrganization[h]][0].length);
            let curr_sum = 0;
            for (var j=0; j < x_fit[outputOrganization[h]][0].length; j++)
            {
                curr_result[j] = Math.pow(Math.abs(a_row[j]-b_row[j]), 2)
                curr_sum += Math.pow(Math.abs(a_row[j]-b_row[j]), 2)
            }
            curr_sum *= -gamma[outputOrganization[h]];
            kernel[outputOrganization[h]][i] = Math.exp(curr_sum);
        }
    }

    //Calculate dot product of kernel (based on input values) and dual_coef_ from model
    let prediction = [];
    for(let i = 0; i < outputOrganization.length; i++)
    {
        prediction[outputOrganization[i]] = [];
            let curr_sum = 0;
            for (var k = 0; k < kernel[outputOrganization[i]].length; k++)
            {
                curr_sum += (kernel[outputOrganization[i]][k] * dual_coef[outputOrganization[i]][k]);
            }
            prediction[outputOrganization[i]] = Math.round(curr_sum*1000)/1000;
    }

    let predObj = { '_dataRow': 'PredictionKRR' };
    let outputObj = {};
    let tableData = [];


    for (let i = 0; i < outputOrganization.length; i++) {
        predObj[outputOrganization[i]] = prediction[outputOrganization[i]];
        outputObj[outputOrganization[i]] = prediction[outputOrganization[i]];
        tableData.push([outputOrganization[i], 'Output', prediction[outputOrganization[i]]]);
    }
    for (let i = 0; i < inputOrganization.length; i++) {
        predObj[inputOrganization[i]] = inputData[i];
    }

    Object.keys(predVals).forEach(function (key) {
        tableData.push([key, 'Input', predVals[key]]);
    });

    let violatedConstraints = checkConstraints(predObj, constraints);
    let returnObj = {
        outputValues: outputObj,
        inputValues: predVals,
        tableData: tableData,
        violatedConstraints: violatedConstraints,
        completePredObject: predObj
    };
    return returnObj;
}

/**
 * Creates a contour map by varying user-specified x- and y- variables between their lowest and highest levels with
 * 39 evenly spaced intervals in between. Base input values (predVals) are held constant and the selected x and y
 * variables (selected_x_var, selected_y_var) are adjusted, and a PLS prediction is obtained for each. These are
 * added to an array of x,y,z coordinates which is used to render a ContourPlot component.
 */

export function CalculateContourPLS(selected_x_var, selected_y_var, predVals, selModel, currConstraints, categoricalVars, dataRanges)
{
    let colDict = {};
      let splitCount = 40;
      let _xvar_name = selected_x_var;
      let _yvar_name = selected_y_var;
      let _xvar =dataRanges.filter(el => {
        return el.column === _xvar_name;
      })[0];
      let _yvar = dataRanges.filter(el => {
        return el.column === _yvar_name;
      })[0];
      let _xvar_diff = (_xvar.max - _xvar.min) / (splitCount - 1);
      let _yvar_diff = (_yvar.max - _yvar.min) / (splitCount - 1);
      let _xvar_ranges = [];
      let _yvar_ranges = [];
      for (let i = 0; i < splitCount; i++) {
        _xvar_ranges.push(_xvar.min + i * _xvar_diff);
        _yvar_ranges.push(_yvar.min + i * _yvar_diff);
      }
      function addCoordinate(col) {
        if (colDict[col] === undefined) {
          colDict[col] = [];
        }
        colDict[col].push({
          x: _xvar_ranges[this.i],
          y: _yvar_ranges[this.j],
          z: this.results.outputValues[col]
        });
      }
      for (let i = 0; i < splitCount; i++) {
        for (var j = 0; j < splitCount; j++) {
          var newPredVals = Object.assign({}, predVals);
          newPredVals[_xvar.column] = _xvar_ranges[j];
          newPredVals[_yvar.column] = _yvar_ranges[i];
          let results = null;

            results = CalculatePLSRes(
              newPredVals,
              selModel.x_mean_,
              selModel.y_mean_,
              selModel.x_std_,
              selModel.coef_,
              selModel.inputOrganization,
              selModel.outputOrganization,
              dataRanges,
              categoricalVars,
              currConstraints
            );

          Object.keys(results.outputValues).forEach(addCoordinate, { i: i, j: j, results: results });
        }
      }
      return colDict;
}

/**
 * Creates a contour map by varying user-specified x- and y- variables between their lowest and highest levels with
 * 39 evenly spaced intervals in between. Base input values (predVals) are held constant and the selected x and y
 * variables (selected_x_var, selected_y_var) are adjusted, and a NN prediction is obtained for each. These are
 * added to an array of x,y,z coordinates which is used to render a ContourPlot component.
 */

export function CalculateContourNN(selected_x_var, selected_y_var, predVals, selModel, currConstraints, categoricalVars, dataRanges)
{
    let colDict = {};
      let splitCount = 40;
      let _xvar_name = selected_x_var;
      let _yvar_name = selected_y_var;
      let _xvar =dataRanges.filter(el => {
        return el.column === _xvar_name;
      })[0];
      let _yvar = dataRanges.filter(el => {
        return el.column === _yvar_name;
      })[0];
      let _xvar_diff = (_xvar.max - _xvar.min) / (splitCount - 1);
      let _yvar_diff = (_yvar.max - _yvar.min) / (splitCount - 1);
      let _xvar_ranges = [];
      let _yvar_ranges = [];
      for (let i = 0; i < splitCount; i++) {
        _xvar_ranges.push(_xvar.min + i * _xvar_diff);
        _yvar_ranges.push(_yvar.min + i * _yvar_diff);
      }
      function addCoordinate(col) {
        if (colDict[col] === undefined) {
          colDict[col] = [];
        }
        colDict[col].push({
          x: _xvar_ranges[this.i],
          y: _yvar_ranges[this.j],
          z: this.results.outputValues[col]
        });
      }
      for (let i = 0; i < splitCount; i++) {
        for (var j = 0; j < splitCount; j++) {
          var newPredVals = Object.assign({}, predVals);
          newPredVals[_xvar.column] = _xvar_ranges[j];
          newPredVals[_yvar.column] = _yvar_ranges[i];
          let results = null;

            results = CalculateNNPred(
                newPredVals,
                selModel.outputOrganization.length,
                selModel.coefficients,
                selModel.intercepts,
                selModel.layerCount,
                selModel.inputOrganization,
                selModel.outputOrganization,
                categoricalVars,
                selModel.dataMin,
                selModel.dataRange,
                currConstraints
              );

          Object.keys(results.outputValues).forEach(addCoordinate, { i: i, j: j, results: results });
        }
      }
      return colDict;
}

/**
 * Creates a contour map by varying user-specified x- and y- variables between their lowest and highest levels with
 * 39 evenly spaced intervals in between. Base input values (predVals) are held constant and the selected x and y
 * variables (selected_x_var, selected_y_var) are adjusted, and a KRR prediction is obtained for each. These are
 * added to an array of x,y,z coordinates which is used to render a ContourPlot component.
 */

export function CalculateContourKRR(selected_x_var, selected_y_var, predVals, selModel, currConstraints, categoricalVars, dataRanges)
{
    let colDict = {};
      let splitCount = 40;
      let _xvar_name = selected_x_var;
      let _yvar_name = selected_y_var;
      let _xvar =dataRanges.filter(el => {
        return el.column === _xvar_name;
      })[0];
      let _yvar = dataRanges.filter(el => {
        return el.column === _yvar_name;
      })[0];
      let _xvar_diff = (_xvar.max - _xvar.min) / (splitCount - 1);
      let _yvar_diff = (_yvar.max - _yvar.min) / (splitCount - 1);
      let _xvar_ranges = [];
      let _yvar_ranges = [];
      for (let i = 0; i < splitCount; i++) {
        _xvar_ranges.push(_xvar.min + i * _xvar_diff);
        _yvar_ranges.push(_yvar.min + i * _yvar_diff);
      }
      function addCoordinate(col) {
        if (colDict[col] === undefined) {
          colDict[col] = [];
        }
        colDict[col].push({
          x: _xvar_ranges[this.i],
          y: _yvar_ranges[this.j],
          z: this.results.outputValues[col]
        });
      }
      for (let i = 0; i < splitCount; i++) {
        for (let j = 0; j < splitCount; j++) {
          let newPredVals = Object.assign({}, predVals);
          newPredVals[_xvar.column] = _xvar_ranges[j];
          newPredVals[_yvar.column] = _yvar_ranges[i];
          let results = null;

            results = CalculateKRRPred(
                newPredVals,
                selModel.outputOrganization.length,
                selModel.dual_coef,
                selModel.x_fit,
                selModel.gamma,
                selModel.inputOrganization,
                selModel.outputOrganization,
                categoricalVars,
                selModel.dataMin,
                selModel.dataRange,
                currConstraints
              );

		  Object.keys(results.outputValues).forEach(addCoordinate, { i: i, j: j, results: results });
        }
      }
      return colDict;
}

/**
 * Generates a prediction from trained XGBoost model using a set of prediction values (predVals--dictionary
 * of key value pairs), model parameters (trees), and data organization
 * parameters (inputOrganization, outputOrganization, dataRange, categoricalOrganization). Checks whether
 * prediction values violate any user contraints (constraints). Returns data in tabular format, as well as
 * a dictionary that can be used to render a parallel coordinates chart.
 */
//UNIT TEST NEEDED
export function CalculateXGBPred(predVals, trees,
    inputOrganization, outputOrganization, categoricalOrganization, dataMin, dataRange, constraints) {
    //you need to get your data into a format that matches the inputOrganization array

    //tranform predvals
    let predCopy = Object.assign({}, predVals);
    Object.keys(predCopy).forEach(key => {
        let index = inputOrganization.indexOf(key);
        let adjVal = (predCopy[key] - dataMin[index]) / dataRange[index];
        predCopy[key] = adjVal;
    });


    //start here...
    //plug that into an analog of the python code you put together
    let predObj = { '_dataRow': 'PredictionXGB' };
    let outputObj = {};
    let tableData = [];
    for (let i = 0; i < outputOrganization.length; i++) {
        let curr_col = outputOrganization[i];
        let tree = JSON.parse(trees[curr_col]);
        let pred_val = 0.5;
        tree.forEach(el => {
            let curr_val = TraverseTree(el, predCopy);
            pred_val += curr_val;
        });
        pred_val = Math.round(pred_val* 1e3)/1e3;
        predObj[curr_col] = pred_val;
        outputObj[curr_col] = pred_val;
        tableData.push([outputOrganization[i], 'Output', pred_val]);
    }
    for (let i = 0; i < inputOrganization.length; i++) {
        predObj[inputOrganization[i]] = predVals[inputOrganization[i]];
        tableData.push([inputOrganization[i], 'Input', predVals[inputOrganization[i]]]);
    }

    let violatedConstraints = checkConstraints(predObj, constraints);
    let returnObj = { outputValues: outputObj,
        inputValues: predVals,
        tableData: tableData,
        violatedConstraints: violatedConstraints,
        completePredObject: predObj };
    return returnObj;
}

/**
 * Helper function for CalculateXGBPred. Traverses a particular tree (node) using input values (stored in data)
 * to determine how to travel down the tree. Terminal node value is returned.
 */

function TraverseTree (node, data){
    if (node.children === undefined) {
        return node.leaf;
    }
    let value = data[node.split];
    if (value > node.split_condition) {
        return TraverseTree(node.children[1], data)
    }
    else {
        return TraverseTree(node.children[0], data);
    }
}

/**
 * Generates a prediction from sklearn trained SVG model using a set of prediction values (predVals--dictionary
 * of key value pairs), sklearn model parameters (attrs, gamma), and data organization
 * parameters (inputOrganization, outputOrganization, dataRange, categoricalOrganization). Checks whether
 * prediction values violate any user contraints (constraints). Returns data in tabular format, as well as
 * a dictionary that can be used to render a parallel coordinates chart.
 */
//UNIT TEST NEEDED
export function CalculateSVRPred(predVals, outputCount, supportVectors, dual_coef, intercept, gamma,
    inputOrganization, outputOrganization, categoricalOrganization, dataMin, dataRange, constraints) {
    //you need to get your data into a format that matches the inputOrganization array

    //tranform predvals
    let inputData = new Array(inputOrganization.length);
    Object.keys(predVals).forEach(key => {
        let index = inputOrganization.indexOf(key);
        if (index !== -1) {
            inputData[index] = predVals[key];
        }
    });

    Object.keys(categoricalOrganization['Inputs']).forEach(function (key) {

        let values = categoricalOrganization['Inputs'][key];
        values.forEach(function (val) {
            let updatedKey = val;
            let index = inputOrganization.indexOf(updatedKey);
            if (index !== -1) {
                inputData[index] = 0;
            }
        });
        let useKey = key + "_" + predVals[key];
        let useIndex = inputOrganization.indexOf(useKey);
        if (useIndex !== -1) {
            inputData[useIndex] = 1;
        }
    });

    //alright data should now be in the appropriate order in the array.
    //need to scale.
    let scaledData = new Array(inputData.length);
    for (let i = 0; i < inputData.length; i++) {
        scaledData[i] = (inputData[i] - dataMin[i]) / dataRange[i];
    }
    //start here...
    //plug that into an analog of the python code you put together
    let outputValues = new Array(outputCount);

    outputOrganization.forEach((col, index) => {
        let curr_dual_coef = dual_coef[col][0];
        let curr_intercept = intercept[col][0];
        let curr_supportVectors = supportVectors[col];
        let curr_gamma = gamma[col]
        let prediction_value = ManualSVRPrediction(scaledData, curr_supportVectors, curr_dual_coef, curr_intercept, curr_gamma);
        outputValues[index] = prediction_value;
    });

    let activeLayer = outputValues;

    let predObj = { '_dataRow': 'PredictionSVR' };
    let outputObj = {};
    let tableData = [];
    for (let i = 0; i < outputOrganization.length; i++) {
        predObj[outputOrganization[i]] = activeLayer[i];
        outputObj[outputOrganization[i]] = activeLayer[i];
        tableData.push([outputOrganization[i], 'Output', activeLayer[i]]);
    }
    for (let i = 0; i < inputOrganization.length; i++) {
        predObj[inputOrganization[i]] = inputData[i];
    }

    Object.keys(predVals).forEach(function (key) {
        tableData.push([key, 'Input', predVals[key]]);
    });

    let violatedConstraints = checkConstraints(predObj, constraints);
    let returnObj = {
        outputValues: outputObj,
        inputValues: predVals,
        tableData: tableData,
        violatedConstraints: violatedConstraints,
        completePredObject: predObj
    };
    return returnObj;
}

/**
 * Creates a contour map by varying user-specified x- and y- variables between their lowest and highest levels with
 * 39 evenly spaced intervals in between. Base input values (predVals) are held constant and the selected x and y
 * variables (selected_x_var, selected_y_var) are adjusted, and a KRR prediction is obtained for each. These are
 * added to an array of x,y,z coordinates which is used to render a ContourPlot component.
 */

export function CalculateContourSVR(selected_x_var, selected_y_var, predVals, selModel, currConstraints, categoricalVars, dataRanges)
{
    let colDict = {};
      let splitCount = 40;
      let _xvar_name = selected_x_var;
      let _yvar_name = selected_y_var;
      let _xvar =dataRanges.filter(el => {
        return el.column === _xvar_name;
      })[0];
      let _yvar = dataRanges.filter(el => {
        return el.column === _yvar_name;
      })[0];
      let _xvar_diff = (_xvar.max - _xvar.min) / (splitCount - 1);
      let _yvar_diff = (_yvar.max - _yvar.min) / (splitCount - 1);
      let _xvar_ranges = [];
      let _yvar_ranges = [];
      for (let i = 0; i < splitCount; i++) {
        _xvar_ranges.push(_xvar.min + i * _xvar_diff);
        _yvar_ranges.push(_yvar.min + i * _yvar_diff);
      }
      function addCoordinate(col) {
        if (colDict[col] === undefined) {
          colDict[col] = [];
        }
        colDict[col].push({
          x: _xvar_ranges[this.i],
          y: _yvar_ranges[this.j],
          z: this.results.outputValues[col]
        });
      }
      for (let i = 0; i < splitCount; i++) {
        for (let j = 0; j < splitCount; j++) {
          let newPredVals = Object.assign({}, predVals);
          newPredVals[_xvar.column] = _xvar_ranges[j];
          newPredVals[_yvar.column] = _yvar_ranges[i];
          let results = null;

            results = CalculateSVRPred(
                newPredVals,
                selModel.outputOrganization.length,
                selModel.support_vectors_,
                selModel.dual_coef_,
                selModel.intercept_,
                selModel._gamma,
                selModel.inputOrganization,
                selModel.outputOrganization,
                categoricalVars,
                selModel.dataMin,
                selModel.dataRange,
                currConstraints
              );

		  Object.keys(results.outputValues).forEach(addCoordinate, { i: i, j: j, results: results });
        }
      }
      return colDict;
}


/**
 * SVR prediction helper function. Calculates a predicted value from an trained svr model.
 * See sklearn source code for details (https://github.com/scikit-learn/scikit-learn/blob/1495f6924/sklearn/svm/base.py#L307)
 */
//UNIT TEST NEEDED
function ManualSVRPrediction(prediction_inputs, support_vectors, dual_coef, intercept, gamma){
    let pred_val = 0;
    for (let i = 0; i < support_vectors.length; i++){
        let euc_dist = 0;
        for (var j = 0; j < prediction_inputs.length; j++){
            euc_dist += Math.pow(Math.abs(prediction_inputs[j] - support_vectors[i][j]),2);
        }
        let curr_val = Math.exp(-1 * euc_dist * gamma);
        pred_val += (curr_val * dual_coef[i]);
    }
    pred_val += intercept;
    return Math.round(pred_val * 1e4)/1e4;
}
