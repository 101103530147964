import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Plot from 'react-plotly.js';

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    flexGrow: 1,
  },
  formControl: {
    margin: 2,
    minWidth: 120,
    flexWrap: "wrap",
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto",
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  plot: {
    width:'90%'
  }
});


function CorrelationHeatMapPlot(props) {

  const { classes, data } = props;
  const [plotWidth, setPlotWidth] = useState(200)

  const parentRef = useRef(null);

  // get plot width
  useEffect ( () => {
    if(parentRef.current){
      const parentWidth  = parentRef.current.offsetWidth;
      setPlotWidth(parentWidth)
    }

  }, [parentRef]);

  // get the trace of plotly plot
  const getTrace = () => {
    // transpose data.data so it displays better
    let data_t = JSON.parse(JSON.stringify(data.data));
    for ( var i = 0; i < data.colNames.length; i++ ) {
      for ( var j = 0; j < data.colNames.length; j++ ) {
        data_t[j][i] = data.data[i][j]
      }
    }
    let colNames = data.colNames
    let colNames_reverse = [...data.colNames].reverse()
    const trace = []
    trace.push({
      z: data_t,
      x: colNames,
      y: colNames_reverse,
      type: 'heatmap',
      showscale: true
    })
    return trace
  }

  // get the layout of plotly plot
  const getLayout = () => {
    let layout = {
      width:plotWidth,
      title: "Heatmap of data correlations (r2)",
      annotations:[],
      xaxis: {
        ticks: '',
        side: 'top'
      },
      yaxis: {
        ticks: '',
        ticksuffix: ' ',
        // width: 700,
        // height: 700,
        // autosize: false
      }
    }

    for ( var i = 0; i < data.colNames.length; i++ ) {
      for ( var j = 0; j < data.colNames.length; j++ ) {
        let result = {
          xref: 'x1',
          yref: 'y1',
          x: data.colNames[j],
          y: data.colNames[i],
          text: data.data[i][data.colNames.length - j - 1],
          showarrow: false,
          font: {
            color: 'rgb(255, 255, 255)'
          }
        };
        layout.annotations.push(result);
      }
    }


    return layout
  }

  return (
    <>
      {/*Plotly figure*/}
      <div className={classes.plot} ref={parentRef}>
        <Plot
          data={getTrace()}
          layout={getLayout()}
        />
      </div>
    </>
  );
}

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels,
  };
})(withStyles(styles)(CorrelationHeatMapPlot));