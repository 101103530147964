import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import 'dropzone/dist/dropzone.css';
import Card from "@material-ui/core/Card";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';
import * as DataDistHelperFunctions from "./DataDistributionHelperFunctions";
import {
  CalculatePLSRes,
  CalculateNNPred,
  CalculateKRRPred,
  CalculateSVRPred,
  CalculateContourPLS,
  CalculateContourNN,
  CalculateContourKRR,
  CalculateContourSVR
} from "./ModelCalculationCode";
//Dev
import * as viewActions from "../actions/viewActions";
import * as modelDataActions from "../actions/modeldataActions";
import * as evaluationActions from "../actions/modelevaluationActions";
import * as usageActions from "../actions/modelusageActions";
import * as predictionActions from "../actions/modelPredictionActions";
import * as predictionInputActions from "../actions/modelpredictioninputsActions";
import * as optimizationActions from "../actions/modeloptimizationActions";
import * as distributionActions from "../actions/datadistributionActions";
import * as predictionContourActions from "../actions/modelPredictionContourActions";
import * as currentmodelsActions from "../actions/currentmodelsActions";
import * as savemodelActions from "../actions/savemodelActions";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
  palette: {
    primary: {
      light: "#4dabf5",
      main: "#2196f3",
      dark: "#1769aa"
    },
    secondary: {
      light: "#4dabf5",
      main: "#2196f3",
      dark: "#1769aa"
    }
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: "none"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 500
      }
    },
    MUIDataTableToolbar: {
      titleText: {
        float: "left",
        fontFamily: "Roboto"
      }
    },
    MuiToolbar: {
      regular: {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  }
});

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

class LoadModel extends Component {
  componentDidMount() {}
    // this piece of code is duplicated in other files: LoadModel, ModelEvalulation, NewModel!!!
    updateTitlesAndLegends = (key, slope, rValue, modelName) => {
    let residualsChartTitle = "R-squared Value: " + (rValue*rValue).toFixed(2) + " | Slope: " + slope.toFixed(2);
    let residualsLegend =
        "Scatter plot displaying observed (experimental Y value) vs predicted (model predicted Y value) generated by'" +
        modelName +
        "' model. Observed and predicted values, and their source data row can be seen by hovering over individual data points.";
    let coefficientsChartTitle = "Relative importance (weight) of each input X on output Y";
    let coefficientsLegend =
        "Bar chart displaying the magnitude of effect (coefficients) for input variables. Positive or negative magnitude shows whether the input X are positively or negatively correlated with the output Y.";
    this.props.dispatch(evaluationActions.UpdateTitlesAndLegends(residualsChartTitle, residualsLegend, coefficientsChartTitle, coefficientsLegend));
  };

  RowSelectionChange = (currentRow, allRows) => {
    // all rows will have the specific value of the row we want
    let curr_model_id = "";
    
    // the actual row of data we are addressing
    let selectedDataIndexes = allRows.map(item => item.dataIndex);

    // pull from the models list
    if (selectedDataIndexes.length > 0) {
      curr_model_id = this.props.currentmodels.current_models[selectedDataIndexes[0]].id;
    }
    this.props.dispatch(currentmodelsActions.UpdateSelectedModel(curr_model_id, selectedDataIndexes));
  };

  LoadModelsClick = event => {
    this.props.dispatch(modelDataActions.ResetAll());
    this.props.dispatch(viewActions.updateLoadingMessage("Loading your model..."));
    this.props.dispatch(viewActions.changeLoadingState(true));
    // Add name and description to save menu
    var currentID = this.props.currentmodels.current_selected_model_id;
    console.log(currentID);
    var selected_save_model = this.props.currentmodels.current_models.filter(el => {
      return el.train_id === currentID;
    })[0];
    // document.getElementById('save_name_input').value = selected_save_model.name;
    // document.getElementById('save_description_input').value = selected_save_model.description;
    this.props.dispatch(savemodelActions.UpdateSaveModelDescription(selected_save_model.model_description));
    this.props.dispatch(savemodelActions.UpdateSaveModelName(selected_save_model.model_title));
    console.log(this.props.user.nickname)
    let url = `model/load`;
    let params = {user_id: this.props.user.nickname, model_id: currentID, model_name: selected_save_model.model_title }
    this.props.axPost(url, JSON.stringify({ data: params }), {
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(res => {
        if (res.data.ErrorMessage === undefined) {
          this.props.dispatch(viewActions.changeLoadingState(false));
          let modelData = JSON.parse(res.data.ModelData);
          let userCanShare = false;
          if ((modelData.Permission==='Owner') ||
          (modelData.Visibility==='private' && modelData.Permission==='Share')){
            userCanShare = true;
          }
          let userCanEdit = false;
          if (userCanShare || (modelData.Visibility==='private' && modelData.Permission==='Write')){
            userCanEdit = true;
          }
          this.props.dispatch(modelDataActions.UpdateModelAccessSettings(modelData.Visibility,
            modelData.Permission, userCanShare, userCanEdit));
          this.props.dispatch(modelDataActions.UpdateSourceId(res.data.source_id));
          this.props.dispatch(viewActions.UpdateCurrentlySharedWith(modelData.SharedWith));
          this.props.dispatch(viewActions.UpdateModifiedSharedWith(modelData.SharedWith));
          this.props.dispatch(viewActions.UpdateSaveModelVisibility(modelData.Visibility==='private'));
          let dataRanges = modelData.DataRanges;
          let outputDataRanges = modelData.OutputDataRanges;
          outputDataRanges.forEach(function(el) {
            el.col = el.column;
          });
          let predictions = modelData.InitialInputs;
          let dataColumns = modelData.DataColumns;
          let displayColumns = Object.assign([], dataColumns);
          displayColumns.forEach(function(col) {
            col.display = col.type === "output" && col.categorical === false;
            col.hide = false;
          });
          if (displayColumns.filter(x => x.display).length===1){
            let modInputCols = displayColumns.filter(x => x.type==='input' && x.categorical===false);
            if (modInputCols.length>0){
                modInputCols[0].display = true;
            }
        }
          this.props.dispatch(predictionActions.UpdatePredictionDisplayColumns(displayColumns, ""));
          let displayColumnsOpt = JSON.parse(JSON.stringify(displayColumns));
          displayColumnsOpt.forEach(function(col) {
            col.display = col.categorical === false;
          });
          let nonCategoricalDisplayCols = displayColumns.filter(el => {
            return !el.categorical && el.type !== "output";
          });
          let contour_x = "";
          let contour_y = "";
          if (nonCategoricalDisplayCols.length === 1) {
            contour_x = nonCategoricalDisplayCols[0].col;
            contour_y = nonCategoricalDisplayCols[0].col;
          }
          if (nonCategoricalDisplayCols.length > 1) {
            contour_x = nonCategoricalDisplayCols[0].col;
            contour_y = nonCategoricalDisplayCols[1].col;
          }
          this.props.dispatch(predictionContourActions.UpdateModelPredictionSelectedAxes(contour_x, contour_y));
          this.props.dispatch(optimizationActions.UpdateOptimizationDisplayColumns(displayColumnsOpt, ""));
          dataRanges.forEach(function(range) {
            range.usermin = range.min;
            range.usermax = range.max;
            range.userval = range.min;
          });
          let optimizationSettings = [];
          let predictionContourThresholds = {};
          let predictionContourSigns = {};

          // setting opt params. Need to be the same with NewModel.js
          dataColumns
            .filter(function(el) {
              return el.categorical === false && el.type === "output";
            })
            .forEach(function(el) {
              let colName = el.col;
              let colData = modelData.PlottableData.map(
                  (el) => el[colName]
              );
              let min_data = Math.min(...colData)
              let max_data = Math.max(...colData)
              let mean_data = 0.5 * (min_data + max_data)
              let data_range = max_data - min_data;
              let newOptSettings = {
                column: el.col,
                opttype: "target",
                mintargetvalue: parseFloat(
                    (mean_data - 0.1 * data_range).toPrecision(5)
                ),
                maxtargetvalue: parseFloat(
                    (mean_data + 0.1 * data_range).toPrecision(5)
                ),
                minvalue: min_data,
                maxvalue: max_data,
                use: true,
              };
              optimizationSettings.push(newOptSettings);
              predictionContourThresholds[el.col] = (newOptSettings.maxvalue - newOptSettings.minvalue) / 2 + newOptSettings.minvalue;
              predictionContourSigns[el.col] = ">";
            });
          this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(optimizationSettings));
          this.props.dispatch(predictionContourActions.UpdateModelPredictionContourThresholds(predictionContourThresholds));
          this.props.dispatch(predictionContourActions.UpdateModelPredictionContourThresholdSigns(predictionContourSigns));
          let stabilityVariations = dataColumns
            .filter(el => {
              return el.categorical === false && el.type === "input";
            })
            .map(el => ({ column: el.col, percent: 10 }));
          this.props.dispatch(optimizationActions.UpdateOptimizationStabilityVariations(stabilityVariations));
          let categoricalVariables = modelData.Categoricals;
          this.props.dispatch(
            modelDataActions.UpdateModelData(
              modelData.models,
              modelData.PCA,
              modelData.LinearRegression,
              dataRanges,
              categoricalVariables,
              true,
              dataColumns,
              modelData.PlottableData,
              outputDataRanges,
              true,
              modelData.id
            )
          );
          this.props.dispatch(usageActions.UpdateModelUsageData(dataRanges, categoricalVariables));
          this.props.dispatch(predictionInputActions.UpdateModelPredictionInputValues(predictions));
          let inputColNames = dataColumns
            .filter(function(el) {
              return el.type === "input" && el.categorical === false;
            })
            .map(col => {
              return col.col;
            });
          let outputColNames = dataColumns
            .filter(function(el) {
              return el.type === "output" && el.categorical === false;
            })
            .map(col => {
              return col.col;
            });
          let dataDistributionSourceData = DataDistHelperFunctions.OrganizePairwiseData(
            modelData.PlottableData,
            inputColNames,
            outputColNames,
            modelData.LinearRegression["pairwise_regression"],
            dataRanges.concat(outputDataRanges)
          );
          this.props.dispatch(distributionActions.UpdateDistributionSourceData(dataDistributionSourceData));
          this.props.dispatch(distributionActions.UpdateSelectedInputColumns(inputColNames.length > 8 ? inputColNames.slice(0, 8) : inputColNames));
          this.props.dispatch(
            distributionActions.UpdateSelectedOutputColumns(outputColNames.length > 8 ? outputColNames.slice(0, 8) : outputColNames)
          );
          let currentModel = modelData.models[0];
          let currentOutputKeys = Object.keys(currentModel.residuals);
          let selectedOutputKey = currentOutputKeys[0];
          let currentResiduals = currentModel.residuals[selectedOutputKey];
          let selectedResidualsRValue = currentResiduals.R_Value;
          let selectedResidualsSlope = currentResiduals.Slope;
          let currentCoefficients = [];

          if (currentModel.type === "PLS") {
            currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
            let result = CalculatePLSRes(
              modelData.InitialInputs,
              currentModel.x_mean_,
              currentModel.y_mean_,
              currentModel.x_std_,
              currentModel.coef_,
              currentModel.inputOrganization,
              currentModel.outputOrganization,
              dataRanges,
              modelData.Categoricals,
              this.props.modelusage.settings_all_constraints
            );
            //need prediction text and plottable data
            let newPredText =
              "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
              displayColumns
                .filter(function(el) {
                  return el.display;
                })
                .map(x => x.col)
                .join(", ") +
              ". The prediction was generated using the '" +
              currentModel.name +
              "' model and inputs/outputs are reflected in the table to the right.";
            let predictionPlotData = [];
            predictionPlotData = predictionPlotData.concat(modelData.PlottableData);
            predictionPlotData.push(result.completePredObject);
            this.props.dispatch(
              predictionActions.UpdateModelPredictionOutputValues(
                result.outputValues,
                result.inputValues,
                result.tableData,
                predictionPlotData,
                newPredText,
                result.violatedConstraints
              )
            );
            currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
            let colDict = CalculateContourPLS(
              contour_x,
              contour_y,
              modelData.InitialInputs,
              currentModel,
              this.props.modelusage.settings_all_constraints,
              modelData.Categoricals,
              dataRanges
            );
            this.props.dispatch(predictionContourActions.UpdateModelPredictionContourData(colDict));
          }
          if (currentModel.type === "NN") {
            let results = CalculateNNPred(
              modelData.InitialInputs,
              currentModel.outputOrganization.length,
              currentModel.coefficients,
              currentModel.intercepts,
              1,
              currentModel.inputOrganization,
              currentModel.outputOrganization,
              modelData.Categoricals,
              currentModel.dataMin,
              currentModel.dataRange,
              this.props.modelusage.settings_all_constraints
            );
            let predictionPlotData = [];
            predictionPlotData = predictionPlotData.concat(modelData.PlottableData);
            predictionPlotData.push(results.completePredObject);
            let newPredText =
              "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (green line) within the following dimensions: " +
              displayColumns
                .filter(function(el) {
                  return el.display;
                })
                .map(x => x.col)
                .join(", ") +
              ". The prediction was generated using the '" +
              currentModel.name +
              "' model and inputs/outputs are reflected in the table to the right.";
            this.props.dispatch(
              predictionActions.UpdateModelPredictionOutputValues(
                results.outputValues,
                results.inputValues,
                results.tableData,
                predictionPlotData,
                newPredText,
                results.violatedConstraints
              )
            );
            currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
            let colDict = CalculateContourNN(
              contour_x,
              contour_y,
              modelData.InitialInputs,
              currentModel,
              this.props.modelusage.settings_all_constraints,
              modelData.Categoricals,
              dataRanges
            );
            this.props.dispatch(predictionContourActions.UpdateModelPredictionContourData(colDict));
          }
          if (currentModel.type === "KRR") {
            let results = CalculateKRRPred(
              modelData.InitialInputs,
              currentModel.outputOrganization.length,
              currentModel.dual_coef,
              currentModel.x_fit,
              currentModel.gamma,
              currentModel.inputOrganization,
              currentModel.outputOrganization,
              modelData.Categoricals,
              currentModel.dataMin,
              currentModel.dataRange,
              this.props.modelusage.settings_all_constraints
            );
            let predictionPlotData = [];
            predictionPlotData = predictionPlotData.concat(modelData.PlottableData);
            predictionPlotData.push(results.completePredObject);
            let newPredText =
              "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (purple line) within the following dimensions: " +
              displayColumns
                .filter(function(el) {
                  return el.display;
                })
                .map(x => x.col)
                .join(", ") +
              ". The prediction was generated using the '" +
              currentModel.name +
              "' model and inputs/outputs are reflected in the table to the right.";
            this.props.dispatch(
              predictionActions.UpdateModelPredictionOutputValues(
                results.outputValues,
                results.inputValues,
                results.tableData,
                predictionPlotData,
                newPredText,
                results.violatedConstraints
              )
            );
            currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
            let colDict = CalculateContourKRR(
              contour_x,
              contour_y,
              modelData.InitialInputs,
              currentModel,
              this.props.modelusage.settings_all_constraints,
              modelData.Categoricals,
              dataRanges
            );
            this.props.dispatch(predictionContourActions.UpdateModelPredictionContourData(colDict));
          }
          if(currentModel.type === 'SVR'){
            let results = CalculateSVRPred(modelData.InitialInputs, currentModel.outputOrganization.length, currentModel.support_vectors_, currentModel.dual_coef_, currentModel.intercept_, currentModel._gamma,
                currentModel.inputOrganization, currentModel.outputOrganization, modelData.Categoricals, currentModel.dataMin, currentModel.dataRange, this.props.modelusage.settings_all_constraints)
                let predictionPlotData = [];
                predictionPlotData = predictionPlotData.concat(modelData.PlottableData);
                predictionPlotData.push(results.completePredObject);
                let newPredText =
                  "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (pink line) within the following dimensions: " +
                  displayColumns.filter(function (el) { return el.display }).map(x => x.col).join(', ') + ". The prediction was generated using the '" +
                  currentModel.name +
                  "' model and inputs/outputs are reflected in the table to the right.";
                this.props.dispatch(
                  predictionActions.UpdateModelPredictionOutputValues(
                    results.outputValues,
                    results.inputValues,
                    results.tableData,
                    predictionPlotData,
                    newPredText,
                    results.violatedConstraints
                  ))
                currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
                let colDict = CalculateContourSVR(
                    contour_x,
                    contour_y,
                    modelData.InitialInputs,
                    currentModel,
                    this.props.modelusage.settings_all_constraints,
                    modelData.Categoricals,
                    dataRanges
                  );
                  this.props.dispatch(predictionContourActions.UpdateModelPredictionContourData(colDict));
        }

          // this.props.dispatch(
          //   evaluationActions.UpdateModelData(
          //     currentModel.name,
          //     currentOutputKeys,
          //     selectedOutputKey,
          //     currentResiduals,
          //     selectedResidualsSlope,
          //     selectedResidualsRValue,
          //     currentCoefficients
          //   )
          // );
          // this.props.dispatch(viewActions.changeViewType("modeleval"));
          // this.props.dispatch(viewActions.UpdateModelCanBeSaved(true));
          this.props.dispatch(predictionActions.UpdateSelectedModel(currentModel.name));
          this.props.dispatch(optimizationActions.UpdateSelectedModel(currentModel.name));
          this.updateTitlesAndLegends(selectedOutputKey, selectedResidualsSlope, selectedResidualsRValue, currentModel.name);
          this.props.dispatch(
            evaluationActions.UpdateModelData(
              currentModel.name,
              currentOutputKeys,
              selectedOutputKey,
              currentResiduals,
              selectedResidualsSlope,
              selectedResidualsRValue,
              currentCoefficients
            )
          );
          this.props.dispatch(viewActions.changeViewType("modeleval"));
          this.props.dispatch(viewActions.UpdateModelCanBeSaved(true));
        }
      });
    // .catch(error => {
    //   this.props.dispatch(viewActions.changeLoadingState(false));
    // });
  };

  deleteModel = event => {
    let selectedModelIndex = this.props.currentmodels.current_models_selected_model_rows;
    let title = "Confirm Model Delete";
    let body = "Are you sure you want to delete " + this.props.currentmodels.current_models[selectedModelIndex]["model_title"] + "? This action is permanent and cannot be undone.";
    this.props.dispatch(viewActions.UpdateConfirmDialog(true, title, body));
  }

  render() {
    const { classes } = this.props;
    const tableoptions = {
      filter: false,
      filterType: "dropdown",
      responsive: "standard",
      selectableRows: "single",
      viewColumns: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50, 100],
      print: false,
      download: false,
      //onCellClick: this.rowClick,
      //selectableRowsOnClick: true,
      rowsSelected: this.props.currentmodels.current_models_selected_model_rows,
      textLabels: {
        selectedRows: {
          text: "Model Selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows"
        }
      },
      onRowsSelect: (currentRow, allRows) => this.RowSelectionChange(currentRow, allRows),
      customToolbarSelect: selectedRows => <div style={{ height: 48 }} />
    };

    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.layout}>
          <Card className={classes.paperBody} elevation={3}>
            <div>
              <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
                Load Existing Models
                {this.props.currentmodels.current_models_loading && (
                  <CircularProgress color="secondary" style={{ margin: 10, height: 20, width: 20 }} thickness={5} />
                )}
              </Typography>
            </div>
            <Typography style={{ textAlign: "left", marginLeft: 24, marginBottom: 0 }}>
              Select models to load, explore, and export from the list below.
            </Typography>
            <div style={{ margin: 24, marginTop: 6 }}>
              <Grid container style={{ margin: "auto" }} justify="flex-start" alignItems="center">
                <Grid item xs={12}>
                  <MUIDataTable
                    title={"Your Saved Models"}
                    data={this.props.currentmodels.current_models.map(function(model) {
                      return [
                        model.model_title,
                        model.model_description,
                        model.model_visibility.charAt(0).toUpperCase() + model.model_visibility.slice(1),
                        model.creator_name,
                        model.creation_time,
                        model.train_id
                      ];
                    })}
                    columns={[
                      { name: "Name", options: { filter: true } },
                      { name: "Description", options: { filter: true } },
                      { name: "Visibility", options: { filter: true } },
                      { name: "Creator", options: { filter: true } },
                      { name: "Creation Date", options: { filter: false } },
                      { name: "ID", options: { display: "excluded", filter: false } }
                    ]}
                    options={tableoptions}
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 0, marginTop: 0 }} direction="row" justify="flex-end" alignItems="flex-end">
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    className={classes.button}
                    disabled={this.props.currentmodels.current_models_selected_model_rows.length === 0}
                    onClick={this.LoadModelsClick}
                    style={{
                      background: "linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)",
                      float: "right"
                    }}
                    data-cy="load_model_btn"
                  >
                    Load Selected Model
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    color="secondary"
                    component="span"
                    className={classes.button}
                    disabled={this.props.currentmodels.current_models_selected_model_rows.length === 0}
                    onClick={() => this.deleteModel()}
                    style={{
                      background: "linear-gradient(45deg, #e8093a 30%, #e80980 90%)",
                      float: "right"
                    }}
                    data-cy="delete_wo_load_model_btn"
                  >
                    Delete Selected Model
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </MuiThemeProvider>
    );
  }
}

LoadModel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    currentmodels: state.currentmodels,
    modeloptimization: state.modeloptimization,
    modelusage: state.modelusage
  };
})(withStyles(styles)(LoadModel));
