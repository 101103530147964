export function UpdateModelUsageData(ranges, categoricals, predictions) {
    return {
        type: 'UPDATE_MODEL_USAGE_DATA',
        data: {
            data_ranges: ranges,
            categorical_variables: categoricals,
            prediction_input_values: predictions
        }
    }
}

export function UpdateModelUsageDataRanges(ranges) {
    return {
        type: 'UPDATE_MODEL_USAGE_DATA_RANGES',
        data: { data_ranges: ranges }
    }
}

export function UpdateConstraintTypeSelection(type){
    return{
        type: 'UPDATE_CONSTRAINT_TYPE',
        data: {settings_selected_constraint_type: type}
    }
}

export function UpdateConstraintSignSelection(selection) {
    return {
        type: 'UPDATE_CONSTRAINT_SELECTED_SIGN',
        data: { settings_selected_sign: selection }
    }
}

export function UpdateConstraintValue(value) {
    return {
        type: 'UPDATE_CONSTRAINT_VALUE',
        data: { settings_constraint_value: value }
    }
}

export function UpdateUserConstraints(newConstraints){
    return {
        type: 'UPDATE_USER_CONSTRAINTS',
        data: { settings_all_constraints: newConstraints}
    }
}

export function AddNewUserConstraint(newConstraints){
    return{
        type: 'ADD_NEW_USER_CONSTRAINT',
        data: { settings_all_constraints: newConstraints, settings_selected_columns: [], settings_selected_sign: '==' }
    }
}

export function UpdateConstraintSelectedColumns(columns) {
    return {
        type: 'UPDATE_CONSTRAINT_SELECTED_COLUMNS',
        data: { settings_selected_columns: columns }
    }
}

export function UpdateConstraintSelectedColumns_PartA(columns){
    return{
        type: 'UPDATE_CONSTRAINT_SELECTED_COLUMNS_A',
        data: {
            settings_selected_columns_a: columns
        }
    }
}

export function UpdateConstraintSelectedColumns_PartB(columns){
    return{
        type: 'UPDATE_CONSTRAINT_SELECTED_COLUMNS_B',
        data: {
            settings_selected_columns_b: columns
        }
    }
}

export function ResetAll() {
    return {
        type: 'RESET_ALL',
        data: {}
    }
}
