import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../actions/viewActions";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/Info";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FeedbackIcon from '@material-ui/icons/Feedback';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  link: {
    color: "inherit"
  },
  selected: {
    color: "dodgerblue"
  },
  unselected: {
    color: "rgba(0, 0, 0, 0.87)"
  }
});

const isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

class InfoMenuList extends React.Component {
  state = {
    anchorEl: null,
    selectedIndex: 1
  };

  handleInfoClick = event => {
    this.props.dispatch(actions.changeViewType("information"));
    //this.props.history.push('/information');
  };

  handleGamesClick = event => {
    this.props.dispatch(actions.changeViewType("games"));
    //this.props.history.push('/information'); 
  };

  downloadJupyterNotebook = event => {
    this.props.dispatch(actions.updateLoadingMessage("Prepping your notebook..."));
    this.props.dispatch(actions.changeLoadingState(true));
    let url = `jupyterexport`;
    this.props.axPost(url, JSON.stringify({ data: { source_id: this.props.modeldata.source_id } }), {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      responseType: "arraybuffer"
    })
      .then(res => {
        console.log(res);
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        var currDate = new Date();
        let filename = `Mixing_Studio_Export_${currDate.getFullYear()}_${currDate.getMonth()}` +
          `_${currDate.getDate()}_${currDate.getHours()}_${currDate.getMinutes()}_${currDate.getSeconds()}.ipynb`
        this.saveNonImageFile(blob, filename);
        this.props.dispatch(actions.updateLoadingMessage(""));
        this.props.dispatch(actions.changeLoadingState(false));
      })
      .catch(error => {
        this.props.dispatch(actions.updateLoadingMessage(""));
        this.props.dispatch(actions.changeLoadingState(false));
      });
  };

  deleteModel = event => {
    let title = "Confirm Model Delete";
    let body = "Are you sure you want to delete " + this.props.savemodel.save_model_name + "?";
    this.props.dispatch(actions.UpdateConfirmDialog(true, title, body));
  }

  saveNonImageFile = (blob, filename) => {
    let objectURL = URL.createObjectURL(blob);
    if (isEdge || isIE) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      anchor.href = objectURL;
      anchor.target = "_blank";
      anchor.download = filename;
      anchor.click();
      document.body.removeChild(anchor);
    }
  };

  openNewTab = url => {
    let win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* <MuiThemeProvider theme={theme}> */}
        <Divider />
        <List component="nav" dense={true}>
          <ListItem button onClick={this.handleGamesClick}> {/* dummy push? */}
            <ListItemIcon>
              <SportsEsportsIcon className={this.props.views.currentView === "games" ? classes.selected : classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{ fontSize: ".9rem", fontWeight: 500 }}
                  className={this.props.views.currentView === "games" ? classes.selected : classes.unselected}
                >
                  Games!
                </Typography>
              }
            />
          </ListItem>
          <ListItem button onClick={this.handleInfoClick}>
            <ListItemIcon>
              <InfoIcon className={this.props.views.currentView === "information" ? classes.selected : classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{ fontSize: ".9rem", fontWeight: 500 }}
                  className={this.props.views.currentView === "information" ? classes.selected : classes.unselected}
                >
                  Site Information
                </Typography>
              }
            />
          </ListItem>
          <ListItem button onClick={() => this.openNewTab("https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u")}>
            <ListItemIcon>
              <FeedbackIcon className={classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography style={{ fontSize: ".9rem", fontWeight: 500 }} className={classes.unselected}>
                  Submit Feedback
                </Typography>
              }
            />
          </ListItem>
          <ListItem button
            disabled={!this.props.modeldata.models_are_present}
            //disabled={true}
            onClick={this.downloadJupyterNotebook}>
            <ListItemIcon>
              <GetAppIcon className={classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography style={{ fontSize: ".9rem", fontWeight: 500 }} className={classes.unselected}>
                  Export to Jupyter Notebook (Beta)
                </Typography>
              }
            />
          </ListItem>
          <ListItem button disabled={
            (!this.props.modeldata.model_saved || this.props.modeldata.user_permission !== 'Owner')
          } onClick={this.deleteModel}>
            <ListItemIcon>
              <DeleteForeverIcon className={classes.unselected} style={{ color: '#f44336' }} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography style={{ fontSize: ".9rem", fontWeight: 500, color: '#f44336' }}
                  className={classes.unselected}
                  data-cy="delete_model">
                  Delete Model
                </Typography>
              }
            />
          </ListItem>
        </List>
        {/* </MuiThemeProvider> */}
      </div>
    );
  }
}

InfoMenuList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    savemodel: state.savemodel
  };
})(withStyles(styles)(withRouter(InfoMenuList)));
