import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import _ from "lodash";
import axios from "axios";
import blue from "@material-ui/core/colors/blue";
import * as viewActions from "../actions/viewActions";
import Cookies from 'universal-cookie';

const styles = theme => ({
  root: {
    flexGrow: 1,
    "& div": {
      zIndex: 1
    }
  },
  input: {
    display: "flex",
    padding: 0,
    height: 36,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    height: 36
    // overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    marginBottom: 0,
    marginTop: 0,
    backgroundColor: blue[500],
    color: "white"
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === "light" ? blue[300] : blue[700], 0.08)
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "relative",
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: 0
  }
});

function NoOptionsMessage(props) {
  return props.selectProps.textFieldProps.sourceprops.views.shareUserSearching ? (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {"Searching..."}
    </Typography>
  ) : (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      data-cy="share_model_name"
      onChange={ev => SearchUserName(ev.target.value, props.selectProps.textFieldProps.sourceprops)}
      {...props.selectProps.textFieldProps}
    />
  );
}

let SearchUserName = (name, sourceprops) => {
  sourceprops.dispatch(viewActions.UpdateShareUserSearching(true));
  if (name.length > 2) {
    const cookies = new Cookies();
    var graph_token = cookies.get('graph_token')

    let url = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${name}')&$select=displayName,userPrincipalName,department`;
    axios.get(url,
          {headers: {'Content-Type': 'application/json',
          'Authorization': `Bearer ${graph_token}`
          }}).then( res => {
            console.log(res['data']);
            var users = [];
            res['data']['value'].forEach(element => {
              if (element['userPrincipalName'].includes('@mmm.com')) {
                var userPrincipalName = element.userPrincipalName.replace("@mmm.com", "");
                users.push({
                  userPrincipalName: userPrincipalName,
                  label: element.displayName + "–" + element.department + " (" + userPrincipalName + ")",
                  value: element.displayName + "–" + element.department + " (" + userPrincipalName + ")",
                  displayName: element.displayName
                });
              }
            });
            let selected = Object.assign([], sourceprops.views.selectedShareUsers);
            let options = users.concat(selected);
            sourceprops.dispatch(viewActions.UpdateShareUsers(options));
          });
  }
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      primary
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function IndicatorSeparator(props) {
  return null;
}

function DropdownIndicator(props) {
  return null;
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator
};

class ShareUserMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    SearchUserName = _.debounce(SearchUserName.bind(this), 250);
  }

  handleChange = name => value => {
    let newSelected = Object.assign([], this.props.views.shareUsersSelected);
    let newArray = value;
    newArray.concat(newSelected);
    this.props.dispatch(viewActions.UpdateSelectedShareUsers(newArray));
    this.props.dispatch(viewActions.UpdateShareUsers([]));
  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    return (
      <div className={classes.root}>
        <Select
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            sourceprops: this.props
          }}
          options={this.props.views.shareUsers}
          components={components}
          value={this.props.views.selectedShareUsers}
          onChange={this.handleChange("multi")}
          placeholder="Enter names here"
          isMulti
        />
      </div>
    );
  }
}

ShareUserMultiSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels
  };
})(withStyles(styles, { withTheme: true })(ShareUserMultiSelect));
