import React, { useState } from "react";
import { connect } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as viewActions from "../../actions/viewActions";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core/styles";
import * as currentModelActions from "../../actions/currentmodelsActions";
import * as modelDataActions from "../../actions/modeldataActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import * as saveActions from "../../actions/savemodelActions";

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
        // height: '100vh',
        minHeight: "95vh",
        margin: "auto",
        zIndex: 1,
        //overflow: 'hidden',
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        position: "fixed",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        color: "white"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "fixed"
        },
        minHeight: "100vh"
    },
    content: {
        minHeight: `calc(100vh - 48px)`,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    loader: {
        // position: 'absolute',
        // top: '40%',
        // left: '50%',
        // margin: '0px',
        marginLeft: "0px",
        [theme.breakpoints.up("md")]: {
            //left: `calc(50% - ${drawerWidth/2}px)`,
            marginLeft: `calc(${drawerWidth}px)`
        }
    }
});

function DeleteModelDialog(props)  {

    const { classes } = props;
    
    const [ state, setState ] = useState({
        mobileOpen: false,
        view: "upload",
        createProjOpen: false,
        modelDeleteName: "",
        modelSaveName: "",
        modelDescription: ""
    });

    let updateSaveModelName = event => {
        let currVal = event.target.value;
        props.dispatch(saveActions.UpdateSaveModelName(event.target.value));
        setState({ ...state, modelSaveName: currVal });
    };

    //Saving both model name and description name to local state to enable automatic disabled/enabled changing of buttons...
    //update values still get pulled from the state however
    let updateLocalSaveModelName = event => {
        let currVal = event.target.value;
        setState({ ...state, modelSaveName: currVal });
    };

    let updateLocalSaveModelDescription = event => {
        let currVal = event.target.value;
        setState({ ...state, modelDescription: currVal });
    };

    let updateSaveModelDescription = event => {
        let currVal = event.target.value;
        setState({ ...state, modelDescription: currVal });
        props.dispatch(saveActions.UpdateSaveModelDescription(event.target.value));
    };

    let saveModel = event => {
        props.dispatch(viewActions.UpdateSaveConfirmDialog(false));
        props.dispatch(viewActions.updateLoadingMessage("Saving " + props.savemodel.save_model_name + "..."));
        props.dispatch(viewActions.changeLoadingState(true));
        setState({ ...state, modelDescription: "", modelSaveName: "", modelDeleteName: "" });
        let url = `model/save`;
        const params = { user_id: props.user.nickname,
            user_name: props.user.name,
            source_id: props.modeldata.source_id,
            name: props.savemodel.save_model_name,
            description: props.savemodel.save_model_description };
        props.axPost(url, JSON.stringify({data: params}), {
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
            .then(res => {
                props.dispatch(viewActions.changeLoadingState(false));
                let curr_models = JSON.parse(res.data.CurrentModels);
                props.dispatch(currentModelActions.UpdateCurrentModels(curr_models));
                props.dispatch(modelDataActions.UpdateModelSavedState(true));
                // props.dispatch(currentModelActions.UpdateSelectedModel())
                //update currentmodels.current_model_selected_id
            })
            .catch(error => {
                props.dispatch(viewActions.changeLoadingState(false));
            });
    };

    let updateModel = event => {
        props.dispatch(viewActions.UpdateSaveConfirmDialog(false));
        props.dispatch(viewActions.updateLoadingMessage("Updating " + props.savemodel.save_model_name + "..."));
        props.dispatch(viewActions.changeLoadingState(true));
        setState({ modelDeleteName: "", mobileOpen: false, view: "upload", createProjOpen: false, modelSaveName: "" });
        let url = `model/update`;
        let post_id = props.currentmodels.current_models.filter(el => {
            return props.modeldata.model_id === el.id;
        })[0].post_id;
        props.axPost(
            url,
            JSON.stringify({data: {
                    user_id: props.user.nickname,
                    name: props.savemodel.save_model_name,
                    description: props.savemodel.save_model_description,
                    post_id: post_id
                }}),
            {
                headers: { "Content-Type": "application/json; charset=utf-8" }
            }
        )
            .then(res => {
                let curr_models = JSON.parse(res.data.CurrentModels);
                props.dispatch(currentModelActions.UpdateCurrentModels(curr_models));
                // let updateModels = JSON.parse(JSON.stringify(props.modeldata.models));
                // updateModels.forEach(el => {
                //   let parts = el.name.split('–');
                //   el.name = parts[0] + '–' + props.savemodel.save_model_name;
                //   if (el.id===props.modeldata.model_id)
                //   {
                //     updated_selected_model_name = el.name;
                //   }
                // });
                // props.dispatch(modelDataActions.UpdateModelDataModelList(updateModels));
                props.dispatch(viewActions.changeLoadingState(false));
            })
            .catch(error => {
                props.dispatch(viewActions.changeLoadingState(false));
            });
    };

    let modelHasChanged = () => {
        let curr_id = props.modeldata.model_id;
        let sel_curr_model = props.currentmodels.current_models.filter(el => {
            return el.id === curr_id;
        });
        if (sel_curr_model.length !== 1) {
            return false;
        }
        let currModel = sel_curr_model[0];
        if (
            (state.modelSaveName !== currModel.model_title && state.modelSaveName !== "") ||
            (state.modelDescription !== currModel.model_description && state.modelDescription !== "")
        ) {
            return false;
        }
        return true;
    };

    return(
        <Dialog open={props.views.showSaveConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.modeldata.model_saved ? "Update Current Model" : "Save Current Model"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please specify a model name and provide a brief description (optional). After saving, you can reload your model anytime from the 'Load
                    Existing Model' tab.
                </DialogContentText>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                    <TextField
                        id="save_name_input"
                        label="Model Name"
                        placeholder="Enter a Model Name"
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        onBlur={ev => updateSaveModelName(ev)}
                        onKeyPress={ev => {
                            if (ev.key === "Enter") {
                                updateSaveModelName(ev);
                            }
                        }}
                        onChange={updateLocalSaveModelName}
                        data-cy="model_name_txt"
                    />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                    <TextField
                        id="save_description_input"
                        label="Model Description"
                        placeholder="Enter a Model Description"
                        className={classes.textField}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        onBlur={ev => updateSaveModelDescription(ev)}
                        onKeyPress={ev => {
                            if (ev.key === "Enter") {
                                updateSaveModelDescription(ev);
                            }
                        }}
                        onChange={updateLocalSaveModelDescription}
                        data-cy="model_desc_txt"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.dispatch(viewActions.UpdateSaveConfirmDialog(false))} color="secondary">
                    Cancel
                </Button>
                {!props.modeldata.model_saved && (
                    <Button onClick={saveModel} color="primary" autoFocus
                            disabled={state.modelSaveName.length === 0}
                            data-cy="save_model_btn">
                        Save Model
                    </Button>
                )}
                {props.modeldata.model_saved && (
                    <Button onClick={updateModel} color="primary" autoFocus
                            disabled={modelHasChanged()}
                            data-cy="update_model_btn">
                        Update Model
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )

}


export default connect((state, props) => {
    return {
        views: state.views,
        savemodel: state.savemodel,
        modeldata: state.modeldata,
        currentmodels: state.currentmodels
    };
})(withStyles(styles, { withTheme: true })(DeleteModelDialog));