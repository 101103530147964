import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import _ from "lodash";
import "./MultiLineChart.css";


class DataDistributionChart extends Component {
  constructor(props) {
    super(props);

    this.fullWidth = 0;
    this.chartWidth = 0;
    this.chartHeight = 0;
    this.titleHeight = 15;
    this.chartMargins = null;

    this.yDomain = [1, 10];
    this.xDomain = [1, 10];

    this.chartTitle = null;
    this.chartLabel = null;

    this.lineGroup = null;
    this.pointGroup = null;

    this.tip = null;

    this.brush = null;
    this.clippingMaskBrush = null;
    this.idleDelay = 350;

    this.animationDuration = 401;
    this.zoomDuration = 500;

    this.chart = null;
    this.clippingMask = null;
    this.lineOfBestFit = null;

    this.colorScheme = d3.scaleOrdinal(d3.schemeCategory10);

    this.resize = this.resize.bind(this);
    this.debouncedResize = _.debounce(this.resize, 250);
    this.current_props = null;
    //this.resizeFlag = true;

    this.xDomain_Plots = null;
    this.yDomain_Plots = null;
    this.plotDomains = null;
    this.plotScales = null;
    this.masterXScale = null;
    this.masterYScale = null;
    this.axesGroup = null;
    this.scalesGroup = null;
    this.xAxisLabelsGroup = null;
    this.yAxisLabelsGroup = null;
    this.linesGroup = null;
    this.pointsGroup = null;
    this.columnIndexMap = {};
    this.squareGroup = null;
    this.defs = null;
    this.canvas = null;
    this.timer = null;
    this.hoverindex = "";
  }

  axesAreValid() {
    if (
      !isNaN(this.props.xAxisMin) &&
      this.props.xAxisMin !== "" &&
      !isNaN(this.props.xAxisMax) &&
      this.props.xAxisMax !== "" &&
      !isNaN(this.props.yAxisMin) &&
      this.props.yAxisMin !== "" &&
      !isNaN(this.props.yAxisMax) &&
      this.props.yAxisMax !== ""
    ) {
      return this.props.xAxisMin < this.props.xAxisMax && this.props.yAxisMin < this.props.yAxisMax;
    }
    return false;
  }

  resize() {
    if (document.getElementById(this.props.appendDiv).clientWidth === 0 || this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateColumnIndexMap();
    this.updateDomainPlots();
    this.updateScalesPlots();
    this.updateSquares2();
    this.animationDuration = 400;
    this.current_props = this.props;
  }

  componentDidMount() {
    // console.log(this.props);
    this.current_props = this.props;
    const el = ReactDOM.findDOMNode(this);
    this.updateDimensions();
    this.createChart(el);
    this.updateAxisLabels();
    window.addEventListener("resize", this.debouncedResize);
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateColumnIndexMap();
    this.updateDomainPlots();
    this.updateScalesPlots();
    this.updateSquares2();
    this.animationDuration = 400;
    // this.updateLines(this.animationDuration);
    // this.updateDataPoints(this.animationDuration);
    // this.updateTooltips();
  }

  shouldComponentUpdate(newProps) {
    if (document.getElementById(this.props.appendDiv).clientWidth !== this.fullWidth && newProps.currentView === this.current_props.currentView) {
      this.animationDuration = 301;
      return true;
    }
    if (_.isEqual(newProps, this.current_props)) {
      return false;
    }
    if (newProps.currentView !== this.current_props.currentView) {
      //if this is not true it suggest tab change which doesnt need an update
      return false;
    }
    this.current_props = newProps;
    return true;
  }

  componentDidUpdate() {
    if (this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateColumnIndexMap();
    this.updateDomainPlots();
    this.updateScalesPlots();
    this.updateSquares2();
    this.animationDuration = 400;
  }

  componentWillUnmount() {
    //this.debouncedResize.cancel();
    window.removeEventListener("resize", this.debouncedResize);
  }

  updateColumnIndexMap() {
    this.columnIndexMap = this.props.columns.reduce((map, obj, index) => {
      map[obj] = index;
      return map;
    }, {});
  }

  updateDomainPlots() {
    this.plotDomains = [];
    let plotDomains = this.plotDomains;
    let columnIndexMap = this.columnIndexMap;
    this.props.columnRanges.forEach(function(el) {
      let index = columnIndexMap[el.column];
      if (index !== undefined) {
        let range = el.max - el.min;
        plotDomains[index] = [el.min - 0.1 * range, el.max + 0.1 * range];
      }
    });
  }

  updateScalesPlots() {
    this.plotScales = [];
    let plotScales = this.plotScales;
    let columnIndexMap = this.columnIndexMap;
    let plotDomains = this.plotDomains;
    let masterScale = this.masterXScale;
    this.props.columnRanges.forEach(function(el) {
      let index = columnIndexMap[el.column];
      if (index !== undefined) {
        let domain = plotDomains[index];
        // let minPos = masterScale(index + .1);
        // let maxPos = masterScale(index + 0.9);
        let minPos = masterScale(index + 0.1);
        let maxPos = masterScale(index + 0.9);
        let scale = d3
          .scaleLinear()
          .range([minPos, maxPos])
          .domain(domain);
        plotScales[index] = scale;
      }
    });
  }

  animateInHoverSquare() {}

  updateSquares2() {
    //append canvas, draw everything on the canvas...
    let canva = document.getElementsByClassName("canvas-plot");
    while (canva[0]) {
      canva[0].parentNode.removeChild(canva[0]);
    }
    canva = document.getElementsByClassName("canvas-plot2");
    while (canva[0]) {
      canva[0].parentNode.removeChild(canva[0]);
    }
    this.mainBody.selectAll(".dimension").remove();
    // console.log(this.chartMargins);
    this.canvas = this.clippingMask
      .append("foreignObject")
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .append("xhtml:body")
      .style("background-color", "none")
      .append("canvas")
      .attr("id", "myCanvas")
      .attr("width", this.chartWidth + this.chartMargins.left + this.chartMargins.right)
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("class", "canvas-plot")
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")");
    this.canvas2 = this.clippingMask
      .append("foreignObject")
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .attr("pointer-events", "none")
      .append("xhtml:body")
      .style("background-color", "none")
      .append("canvas")
      .attr("id", "myCanvas")
      .attr("width", this.chartWidth + this.chartMargins.left + this.chartMargins.right)
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("class", "canvas-plot2")
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")");

    let masterXScale = this.masterXScale;
    let masterYScale = this.masterYScale;
    let plotScales = this.plotScales;
    let plotDomains = this.plotDomains;
    let scatterData = this.props.sourceScatterData;
    let densityData = this.props.sourceDensityData;
    let allDataPoints = [];
    let chartMargins = this.chartMargins;
    let titleHeight = this.titleHeight;
    let columns = this.props.columns;
    let timer = this.timer;
    let hoverindex = this.hoverindex;
    let animationDuration = this.animationDuration;
    let inputCols = this.props.inputColumns;
    this.rects = [];
    let context = document.getElementsByClassName("canvas-plot")[0].getContext("2d");
    for (let i = 0; i < this.props.columns.length; i++) {
      for (let j = 0; j < this.props.columns.length; j++) {
        let rect_x1 = masterXScale(i);
        let rect_w = masterXScale(0.8);
        let rect_y1 = masterYScale(j);
        let rect_h = masterXScale(0.8);
        this.rects.push([rect_x1, rect_y1, rect_w, rect_h]);
      }
    }

    this.rects.forEach(rect => {
      context.beginPath();
      context.rect(rect[0], rect[1], rect[2], rect[3]);
      context.fillStyle = "white";
      context.fill();
    });
    let curr_canvas = document.getElementsByClassName("canvas-plot")[0];
    curr_canvas.onmousemove = e => {
      var rect = curr_canvas.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      let xIndex = Math.floor(masterXScale.invert(x));
      let yIndex = Math.floor(masterYScale.invert(y));

      let newHoverIndex = xIndex + "_" + yIndex;

      let bonus = 800;
      if (xIndex >= 0 && xIndex < columns.length && yIndex >= 0 && yIndex < columns.length && newHoverIndex !== hoverindex) {
        if (timer !== null) {
          timer.stop();
        }
        timer = d3.timer(elapsed => {
          if (elapsed > animationDuration + bonus) {
            timer.stop();
          }
          let canvas2 = document.getElementsByClassName("canvas-plot2")[0];
          let c2ctx = canvas2.getContext("2d");
          c2ctx.clearRect(0, 0, canvas2.width, canvas2.height);
          c2ctx.beginPath();
          let ogWidth = masterXScale(0.8);
          let widthAddition = ogWidth * 0.35;
          let currWidth = ogWidth + widthAddition * ((elapsed - bonus) / animationDuration);
          let widthOffset = (currWidth - ogWidth) / 2;
          let shadowScaler = Number(((elapsed - bonus) / animationDuration) * 10) + 1;
          let lineScaler = ((elapsed - bonus) / animationDuration) * 0.35;
          if (elapsed <= bonus) {
            currWidth = ogWidth;
            widthOffset = (currWidth - ogWidth) / 2;
            shadowScaler = 1;
            lineScaler = 0;
          }
          let rect_x1 = masterXScale(xIndex) - widthOffset;
          let rect_w = currWidth;
          let rect_y1 = masterYScale(yIndex) - widthOffset;
          let rect_h = currWidth;

          // let rect_x1 = (masterXScale(xIndex)) + chartMargins.left - (widthOffset); let rect_w = currWidth;
          // let rect_y1 = (masterYScale(yIndex)) + chartMargins.top + titleHeight - widthOffset; let rect_h = currWidth;

          c2ctx.rect(rect_x1, rect_y1, rect_w, rect_h);
          c2ctx.fillStyle = "white";
          c2ctx.shadowColor = "black";
          c2ctx.shadowBlur = shadowScaler;
          c2ctx.fill();
          c2ctx.shadowBlur = 0;
          if (xIndex === yIndex) {
            let xCol = this.props.columns[xIndex];
            let minPos = masterXScale(xIndex + 0.1);
            let maxPos = masterXScale(xIndex + lineScaler + 0.9);
            let currDomain = plotDomains[xIndex];
            let currXScale = d3
              .scaleLinear()
              .range([minPos, maxPos])
              .domain(currDomain);
            let currDensityData = densityData.filter(function(el) {
              return el.col === xCol;
            })[0].data;
            let densityHistogramData = d3
              .histogram()
              .domain(currDomain)
              .thresholds(currXScale.ticks(30))(currDensityData);
            let yMin = 0;
            let yMax =
              d3.max(
                densityHistogramData.map(d => {
                  return d.length;
                })
              ) * 1.05;
            let densityLinePts = [];
            let updatedDomain = [yMax, yMin];
            let updatedScale = d3
              .scaleLinear()
              .range([minPos, maxPos])
              .domain(updatedDomain);
            densityHistogramData.forEach(el => {
              let midX = (el.x0 + el.x1) / 2;
              let prob = el.length;
              // densityLinePts.push({ x: Math.round(currXScale(midX) + chartMargins.left - widthOffset), y: Math.round(updatedScale(prob) + (chartMargins.top + titleHeight) - widthOffset) })
              densityLinePts.push({ x: Math.round(currXScale(midX) - widthOffset), y: Math.round(updatedScale(prob) - widthOffset) });
            });
            c2ctx.beginPath();
            c2ctx.lineWidth = "2";
            c2ctx.strokeStyle = "#1976d2";
            c2ctx.fillStyle = "#bbdefb";
            c2ctx.moveTo(densityLinePts[0].x, densityLinePts[0].y);
            let updatedDensityPts = [];
            updatedDensityPts.push(densityLinePts[0]);
            updatedDensityPts.push(densityLinePts[0]);
            updatedDensityPts.push(densityLinePts[0]);
            for (let x = 1; x < densityLinePts.length - 1; x++) {
              updatedDensityPts.push(densityLinePts[x]);
            }
            updatedDensityPts.push(densityLinePts[densityLinePts.length - 1]);
            updatedDensityPts.push(densityLinePts[densityLinePts.length - 1]);
            updatedDensityPts.push(densityLinePts[densityLinePts.length - 1]);
            for (let x = 1; x < updatedDensityPts.length - 1; x++) {
              let pt1 = updatedDensityPts[x - 1];
              let pt2 = updatedDensityPts[x];
              let pt3 = updatedDensityPts[x + 1];
              c2ctx.bezierCurveTo(
                (2 * pt1.x + pt2.x) / 3,
                (2 * pt1.y + pt2.y) / 3,
                (pt1.x + 2 * pt2.x) / 3,
                (pt1.y + 2 * pt2.y) / 3,
                (pt1.x + 4 * pt2.x + pt3.x) / 6,
                (pt1.y + 4 * pt2.y + pt3.y) / 6
              );
            }
            c2ctx.closePath();
            c2ctx.stroke();
            c2ctx.fill();
          } else {
            let xCol = this.props.columns[xIndex];
            let yCol = this.props.columns[yIndex];
            let minPos = masterXScale(xIndex + 0.1);
            let maxPos = masterXScale(xIndex + lineScaler + 0.9);
            let currDomain = plotDomains[xIndex];
            let currXScale = d3
              .scaleLinear()
              .range([minPos, maxPos])
              .domain(currDomain);

            let currYDomain = plotDomains[yIndex];
            let updatedDomain = [currYDomain[1], currYDomain[0]];
            minPos = masterYScale(yIndex + 0.1);
            maxPos = masterYScale(yIndex + 0.9 + lineScaler);
            let scale = d3
              .scaleLinear()
              .range([minPos, maxPos])
              .domain(updatedDomain);
            let dataPts = scatterData.filter(function(el) {
              return el.xCol === xCol && el.yCol === yCol;
            })[0];
            let allDataPoints = [];

            dataPts.datapoints.forEach(function(el) {
              allDataPoints.push([currXScale(el.x) - widthOffset, scale(el.y) - widthOffset]);
            });

            // dataPts.datapoints.forEach(function (el) {
            //     allDataPoints.push([currXScale(el.x) + chartMargins.left - widthOffset, scale(el.y) + (chartMargins.top + titleHeight)- widthOffset])
            // });

            let ptSize = 1.99;
            let strokeWidth = 0.49;
            if (this.props.columns.length < 12) {
              ptSize = 1.99;
            }
            if (this.props.columns.length < 8) {
              ptSize = 2.99;
              strokeWidth = 0.99;
            }
            if (this.props.columns.length < 5) {
              ptSize = 3.99;
              strokeWidth = 0.99;
            }
            allDataPoints.forEach(pt => {
              c2ctx.beginPath();
              c2ctx.strokeStyle = "white";
              c2ctx.fillStyle = "#1e88e5"; //'#2196f3';
              c2ctx.lineWidth = strokeWidth;
              c2ctx.arc(Math.round(pt[0]) + 0.5, Math.round(pt[1]) + 0.5, ptSize, 0, 2 * Math.PI, false);
              c2ctx.fill();
              c2ctx.stroke();
              c2ctx.closePath();
            });
          }
          let linePts = [
            { x: xIndex + 0.1, y: yIndex + 0.1 },
            { x: xIndex + 0.1, y: yIndex + lineScaler + 0.9 },
            { x: xIndex + lineScaler + 0.9, y: yIndex + lineScaler + 0.9 }
          ];
          c2ctx.beginPath();
          c2ctx.lineWidth = "0.99";
          c2ctx.strokeStyle = "black";
          // c2ctx.moveTo(Math.round(masterXScale(linePts[0].x) + chartMargins.left - widthOffset) + .5, Math.round(masterYScale(linePts[0].y) + (chartMargins.top + titleHeight) - widthOffset) + .5);
          // c2ctx.lineTo(Math.round(masterXScale(linePts[1].x) + chartMargins.left - widthOffset) + .5, Math.round(masterYScale(linePts[1].y) + (chartMargins.top + titleHeight) - widthOffset) + .5);
          // c2ctx.lineTo(Math.round(masterXScale(linePts[2].x) + chartMargins.left - widthOffset) + .5, Math.round(masterYScale(linePts[2].y) + (chartMargins.top + titleHeight) - widthOffset) + .5);
          c2ctx.moveTo(Math.round(masterXScale(linePts[0].x) - widthOffset) + 0.5, Math.round(masterYScale(linePts[0].y) - widthOffset) + 0.5);
          c2ctx.lineTo(Math.round(masterXScale(linePts[1].x) - widthOffset) + 0.5, Math.round(masterYScale(linePts[1].y) - widthOffset) + 0.5);
          c2ctx.lineTo(Math.round(masterXScale(linePts[2].x) - widthOffset) + 0.5, Math.round(masterYScale(linePts[2].y) - widthOffset) + 0.5);
          c2ctx.stroke();
        });
      }
      hoverindex = newHoverIndex;
    };

    curr_canvas.ondblclick = e => {
      e.preventDefault();
      let parts = hoverindex.split("_");
      let col1 = this.props.columns[Number(parts[0])];
      let col2 = this.props.columns[Number(parts[1])];
      this.props.gridClick(col1, col2);
      return false;
    };

    curr_canvas.onmouseout = e => {
      if (timer !== null) {
        timer.stop();
      }
      let canvas2 = document.getElementsByClassName("canvas-plot2")[0];
      let c2ctx = canvas2.getContext("2d");
      c2ctx.clearRect(0, 0, canvas2.width, canvas2.height);
    };
// Moved X labels from bottom to top. THis is how to set back to bottom change line 560 and 561 to: 
//return "translate(" + (masterXScale(i + 0.55) + chartMargins.left) + "," + (masterYScale(columns.length) + chartMargins.top + titleHeight + 5) + ")";
    for (let i = 0; i < this.props.columns.length; i++) {
      this.mainBody
        .append("g")
        .attr("class", "dimension")
        .attr("transform", function(d) {
          return "translate(" + (masterXScale(i + 0.55) + chartMargins.left) + "," +
            (chartMargins.top + titleHeight + 5) + ")";
        })
        .append("text")
        .html(
          this.props.columns[i].length > 7
            ? "<tspan> "  + this.props.columns[i].substring(0, 6) + "...</tspan><tspan fill='" +  //TODO Tooltip
            (inputCols.indexOf(this.props.columns[i])!==-1 ? "#00c853" : "#f50057") + "' font-size='larger'>▲</tspan>"
            : "<tspan> "  + this.props.columns[i] + "</tspan><tspan fill='" + 
            (inputCols.indexOf(this.props.columns[i])!==-1 ? "#00c853" : "#f50057") + "' font-size='larger'>▲</tspan>"
        )
        .style("text-anchor", "end")
        .attr("transform", "rotate(-30)")
        .style("fill", "black")
        .style("font-size", "14px")
        .style("font-family", "Roboto,Sans-Serif");

        //#e91e63 pink
        //#9c27b0 purple
      this.mainBody
        .append("g")
        .attr("class", "dimension")
        .attr("transform", function(d) {
          return "translate(" + (masterXScale(0) + chartMargins.left) + "," + 
                (masterYScale(i + 0.55) + chartMargins.top + titleHeight) + ")";
        })
        .append("text")
        .html(
          this.props.columns[i].length > 7
            ? "<tspan> "  + this.props.columns[i].substring(0, 6) + "...</tspan><tspan fill='" + 
            (inputCols.indexOf(this.props.columns[i])!==-1 ? "#00c853" : "#f50057") + "' font-size='larger'>▲</tspan>"
            : "<tspan> "  + this.props.columns[i] + "</tspan><tspan fill='" + 
            (inputCols.indexOf(this.props.columns[i])!==-1 ? "#00c853" : "#f50057") + "' font-size='larger'>▲</tspan>"
        )
        .style("text-anchor", "end")
        .attr("transform", "rotate(-30)")
        .style("fill", "black")
        .style("font-size", "14px")
        .style("font-family", "Roboto,Sans-Serif");

      for (let j = 0; j < this.props.columns.length; j++) {
        let currXScale = plotScales[i];
        let currYDomain = plotDomains[j];
        let updatedDomain = [currYDomain[1], currYDomain[0]];
        let minPos = masterYScale(j + 0.1);
        let maxPos = masterYScale(j + 0.9);
        let scale = d3
          .scaleLinear()
          .range([minPos, maxPos])
          .domain(updatedDomain);
        let xCol = this.props.columns[i];
        let yCol = this.props.columns[j];
        let dataPts = scatterData.filter(function(el) {
          return el.xCol === xCol && el.yCol === yCol;
        })[0];
        if (i !== j) {
          dataPts.datapoints.forEach(function(el) {
            // allDataPoints.push([currXScale(el.x) + chartMargins.left, scale(el.y) + (chartMargins.top + titleHeight)])
            allDataPoints.push([currXScale(el.x), scale(el.y)]);
          });
        } else {
          let currDensityData = densityData.filter(function(el) {
            return el.col === xCol;
          })[0].data;
          let currDomain = plotDomains[i];
          let densityHistogramData = d3
            .histogram()
            .domain(currDomain)
            .thresholds(currXScale.ticks(30))(currDensityData);
          let yMin = 0;
          let yMax =
            d3.max(
              densityHistogramData.map(d => {
                return d.length;
              })
            ) * 1.05;
          let densityLinePts = [];
          let minPos = masterXScale(i + 0.1);
          let maxPos = masterXScale(i + 0.9);
          let updatedDomain = [yMax, yMin];
          let updatedScale = d3
            .scaleLinear()
            .range([minPos, maxPos])
            .domain(updatedDomain);
          densityHistogramData.forEach(el => {
            let midX = (el.x0 + el.x1) / 2;
            let prob = el.length;
            // densityLinePts.push({ x: Math.round(currXScale(midX) + chartMargins.left), y: Math.round(updatedScale(prob) + (chartMargins.top + titleHeight)) })
            densityLinePts.push({ x: Math.round(currXScale(midX)), y: Math.round(updatedScale(prob)) });
          });
          context.beginPath();
          context.lineWidth = 1.99;
          context.strokeStyle = "#1976d2";
          context.fillStyle = "#bbdefb";
          context.moveTo(densityLinePts[0].x, densityLinePts[0].y);
          let updatedDensityPts = [];
          updatedDensityPts.push(densityLinePts[0]);
          updatedDensityPts.push(densityLinePts[0]);
          updatedDensityPts.push(densityLinePts[0]);
          for (let x = 1; x < densityLinePts.length - 1; x++) {
            updatedDensityPts.push(densityLinePts[x]);
          }
          updatedDensityPts.push(densityLinePts[densityLinePts.length - 1]);
          updatedDensityPts.push(densityLinePts[densityLinePts.length - 1]);
          updatedDensityPts.push(densityLinePts[densityLinePts.length - 1]);
          for (let x = 1; x < updatedDensityPts.length - 1; x++) {
            let pt1 = updatedDensityPts[x - 1];
            let pt2 = updatedDensityPts[x];
            let pt3 = updatedDensityPts[x + 1];
            context.bezierCurveTo(
              (2 * pt1.x + pt2.x) / 3,
              (2 * pt1.y + pt2.y) / 3,
              (pt1.x + 2 * pt2.x) / 3,
              (pt1.y + 2 * pt2.y) / 3,
              (pt1.x + 4 * pt2.x + pt3.x) / 6,
              (pt1.y + 4 * pt2.y + pt3.y) / 6
            );
          }
          context.closePath();
          context.stroke();
          context.fill();
        }
        let linePts = [{ x: i + 0.1, y: j + 0.1 }, { x: i + 0.1, y: j + 0.9 }, { x: i + 0.9, y: j + 0.9 }];
        context.beginPath();
        context.lineWidth = 0.99;
        context.strokeStyle = "black";
        // context.moveTo(Math.round(masterXScale(linePts[0].x) + chartMargins.left) + .5, Math.round(masterYScale(linePts[0].y) + (chartMargins.top + titleHeight)) + .5);
        // context.lineTo(Math.round(masterXScale(linePts[1].x) + chartMargins.left) + .5, Math.round(masterYScale(linePts[1].y) + (chartMargins.top + titleHeight)) + .5);
        // context.lineTo(Math.round(masterXScale(linePts[2].x) + chartMargins.left) + .5, Math.round(masterYScale(linePts[2].y) + (chartMargins.top + titleHeight)) + .5);
        context.moveTo(Math.round(masterXScale(linePts[0].x)) + 0.5, Math.round(masterYScale(linePts[0].y)) + 0.5);
        context.lineTo(Math.round(masterXScale(linePts[1].x)) + 0.5, Math.round(masterYScale(linePts[1].y)) + 0.5);
        context.lineTo(Math.round(masterXScale(linePts[2].x)) + 0.5, Math.round(masterYScale(linePts[2].y)) + 0.5);
        context.stroke();
      }
    }
    let ptSize = 1.99;
    let strokeWidth = 0.49;
    if (this.props.columns.length < 12) {
      ptSize = 1.99;
    }
    if (this.props.columns.length < 8) {
      ptSize = 2.99;
      strokeWidth = 0.99;
    }
    if (this.props.columns.length < 5) {
      ptSize = 3.99;
      strokeWidth = 0.99;
    }
    allDataPoints.forEach(pt => {
      context.beginPath();
      context.strokeStyle = "white";
      context.fillStyle = "#1e88e5"; //'#2196f3';
      context.lineWidth = strokeWidth;
      context.arc(Math.round(pt[0]) + 0.5, Math.round(pt[1]) + 0.5, ptSize, 0, 2 * Math.PI, true);
      context.fill();
      context.stroke();
      context.closePath();
    });
    //context.translate(0.5, 0.5);
  }

  wrapText() {
    let words = this.chartLabel
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      x = this.chartLabel.attr("x"),
      y = this.chartLabel.attr("y"),
      dy = 0, //parseFloat(text.attr("dy")),
      tspan = this.chartLabel
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
    let maxHeight = 0;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > this.chartWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = this.chartLabel
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
        maxHeight = d3.max([maxHeight, parseFloat(y) + parseFloat(lineNumber * 24)]);
      }
    }
    maxHeight += this.titleHeight;
    if (maxHeight > this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight) {
      this.mainBody.attr("height", maxHeight);
    }
  }

  wrapTitle() {
    let words = this.chartTitle
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      x = this.chartTitle.attr("x"),
      y = this.chartTitle.attr("y"),
      dy = 0, //parseFloat(text.attr("dy")),
      tspan = this.chartTitle
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
    let maxHeight = 0;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > this.chartWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = this.chartTitle
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
        maxHeight = d3.max([maxHeight, parseFloat(y) + parseFloat(lineNumber * 24)]);
      }
    }
    this.titleHeight = maxHeight + 15;

    // if (maxHeight > (this.chartHeight + this.chartMargins.top + this.chartMargins.bottom)) {
    //     this.mainBody.attr('height', maxHeight);
    // }
  }

  updateDimensions() {
    //Figure out how much space you need for your longest label...
    let currentWidth = document.getElementById(this.props.appendDiv).clientWidth;
    this.chartMargins = { left: currentWidth * 0.09, right: currentWidth * 0.01, top: 0, bottom: currentWidth * 0.1 };

    this.fullWidth = currentWidth;
    this.chartWidth = currentWidth - this.chartMargins.left - this.chartMargins.right;
    this.chartHeight = this.chartWidth * this.props.heightToWidthRatio;
  }

  updateAxisLabels() {
    let maxHeights = [];
    this.chartTitle.text(this.props.chartTitle);
    this.chartTitle.call(() => this.wrapTitle());
    this.chartLabel.text(this.props.legendText);
    this.chartLabel.call(() => this.wrapText());
    var maxHeight = d3.max(maxHeights);
    if (maxHeight > this.chartHeight) {
      this.mainBody.attr("height", maxHeight + this.chartMargins.top + this.chartMargins.bottom);
    }
  }

  updateDomains() {
    this.xDomain = [-0.2, this.props.columns.length + 0.2];
    //this.yDomain = [-.2, this.props.columns.length + .2];
    this.yDomain = [this.props.columns.length + 0.2, -0.2];
    this.masterXScale = d3
      .scaleLinear()
      .range([0, this.chartWidth])
      .domain(this.xDomain);
    this.masterYScale = d3
      .scaleLinear()
      .range([this.chartHeight, 0])
      .domain(this.yDomain);
  }

  updateIndividualDomains() {}

  // updateScales() {
  //     this.xScale = this.props.xScaleType === 'linear' ? d3.scaleLinear().range([0, this.chartWidth]).domain(this.xDomain) : d3.scaleLog().base(10).range([0, this.chartWidth]).domain(this.xDomain);
  //     this.yScale = this.props.yScaleType === 'linear' ? d3.scaleLinear().range([this.chartHeight, 0]).domain(this.yDomain) : d3.scaleLog().base(10).range([this.chartHeight, 0]).domain(this.yDomain);
  // };

  updateAxes(duration) {
    this.xAxis.scale(this.xScale);
    this.xAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .call(this.xAxis);
    this.yAxis.scale(this.yScale);
    this.yAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .call(this.yAxis);
  }

  updateTooltips() {
    if (!this.props.showToolTips) {
      return;
    }
    let toolTip = this.tip;
    let xAxisName = this.props.xLabel;
    let yAxisName = this.props.yLabel;

    this.lineGroup
      .selectAll(".line")
      .on("mouseover", function(el) {
        d3.select(this).style("stroke-width", "4px");
      })
      .on("mouseout", function(el) {
        d3.select(this).style("stroke-width", "2px");
      });

    this.pointGroup
      .selectAll("circle")
      .on("mouseover", function(el) {
        d3.select(this)
          .attr("r", "6px")
          .style("stroke", "black")
          .style("fill", "gold")
          .style("stroke-width", "2px");
        let parentID = d3.select(this)._groups[0][0].id;
        toolTip.html(function(i) {
          let tipText =
            "<strong style='color:red;font-size:13px;'>Data Row: </strong><span style='color:white;font-size:13px;font-weight:400'>" +
            parentID +
            "</span> <br>";
          tipText +=
            "<strong style='color:red;font-size:13px;'>" +
            xAxisName +
            ": </strong> <span style='color:white;font-size:13px;font-weight:400'>" +
            Number.parseFloat(el.Obs).toFixed(3) +
            "</span> <br>";
          tipText +=
            "<strong style='color:red;font-size:13px;'>" +
            yAxisName +
            ": </strong> <span style='color:white;font-size:13px;font-weight:400'>" +
            Number.parseFloat(el.Pred).toFixed(3) +
            "</span> <br>";
          return tipText;
        });
        toolTip.show(el, this);
      })
      .on("mouseout", function(el) {
        d3.select(this)
          .attr("r", 5)
          .style("stroke", "black")
          .style("fill", "#35baf6")
          .style("stroke-width", "1px");
        toolTip.hide(el, this);
      });
  }

  updateChartSize = () => {
    this.mainBody
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("width", this.fullWidth);

    //this.chartTitle.call(() => this.wrapText());

    this.chart
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    // this.xAxisGroup.attr("transform", "translate(0," + this.chartHeight + ")");
    // this.xAxisGroup.call(this.xAxis);

    // this.yAxisGroup.call(this.yAxis);

    //Append a clipping mask to avoid data from falling off the chart
    this.clippingMask
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight);

    //Initialize tooltip here

    //Append axis labels here
    // this.xAxisLabel
    //     .attr("y", this.chartHeight + 35)
    //     .attr("x", this.chartWidth);

    // this.yAxisLabel.attr("y", -this.chartMargins.left + 10)
    //     .attr("x", -1)
    //     .attr("transform", "rotate(-90)");

    this.chartLabel.attr("y", this.chartHeight + 55).attr("x", this.chartWidth / 2);

    // this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded),
    //     this.idleTimeout,
    //     this.idleDelay = 350;

    // this.clippingMaskBrush.call(this.brush);
    // this.lineGroup.moveToFront();
    // this.pointGroup.moveToFront();
  };

  createChart = el => {
    //Create scales and initialize with domain 0-10
    this.updateDomains();
    //this.updateScales();

    //Append a larger svg and create the graph region
    this.mainBody = d3
      .select(el)
      .append("svg")
      .attr("class", "multiLineMultiAxisChart")
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("width", this.fullWidth)
      .attr("id", this.props.chartID);

    this.chartTitle = this.mainBody
      .append("text")
      .attr("id", "chartTitle")
      .attr("class", "axisLabel")
      .attr("text-anchor", "start")
      .attr("y", 10)
      .attr("x", 0)
      .style("fill", "black")
      .style("font-size", "14px")
      .style("font-family", "Roboto,Sans-Serif")
      .style("font-weight", 500)
      .text(this.props.chartTitle);
    this.chartTitle.call(() => this.wrapTitle());

    this.chart = this.mainBody
      .append("g")
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    //Create axes and append to chart.
    // this.xAxis = d3.axisBottom(this.xScale);
    // this.xAxisGroup = this.chart.append("g").attr("class", "axis xaxis").attr("transform", "translate(0," + this.chartHeight + ")");
    // this.xAxisGroup.call(this.xAxis);

    // this.yAxis = d3.axisLeft(this.yScale);
    // this.yAxisGroup = this.chart.append("g").attr("class", "axis yaxis");
    // this.yAxisGroup.call(this.yAxis);

    //Append a clipping mask to avoid data from falling off the chart
    this.clippingMask = this.chart
      .append("svg")
      .attr("id", "cmask")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.chartWidth)
      .attr("height", this.chartHeight)
      .style("fill", "transparent");

    //Initialize tooltip here

    //Append axis labels here
    // this.xAxisLabel = this.chart.append("text")
    //     .attr("id", "xAxisLabel")
    //     .attr("class", "axisLabel")
    //     .attr("text-anchor", "end")
    //     .attr("y", this.chartHeight + 35)
    //     .attr("x", this.chartWidth)
    //     .style("fill", "black")
    //     .style("font-size", "14px")
    //     .style("font-family", "Roboto,Sans-Serif")
    //     .text(this.props.xLabel);

    // this.yAxisLabel = this.chart.append("text")
    //     .attr("id", "yAxisLabel")
    //     .attr("class", "axisLabel")
    //     .attr("text-anchor", "end")
    //     .attr("y", -this.chartMargins.left + 10)
    //     .attr("x", -1)
    //     .attr("transform", "rotate(-90)")
    //     .style("fill", "black")
    //     .style("font-size", "14px")
    //     .style("font-family", "Roboto,Sans-Serif")
    //     .text(this.props.yLabel);

    this.chartLabel = this.chart
      .append("text")
      .attr("id", "chartTitle")
      .attr("class", "axisLabel")
      .attr("text-anchor", "middle")
      .attr("y", this.chartHeight + 55)
      .attr("x", this.chartWidth / 2)
      .style("fill", "black")
      .style("font-size", "12px")
      .style("font-family", "Roboto,Sans-Serif")
      .text(this.props.legendText);
    this.chartLabel.call(() => this.wrapText());

    this.defs = this.mainBody.append("defs");
    let filter = this.defs
      .append("filter")
      .attr("id", "drop-shadow")
      .attr("height", "200%");

    // SourceAlpha refers to opacity of graphic that this filter will be applied to
    // convolve that with a Gaussian with standard deviation 3 and store result
    // in blur
    filter
      .append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 3)
      .attr("result", "blur");
    filter
      .append("feOffset")
      .attr("in", "blur")
      .attr("dx", 2)
      .attr("dy", 2)
      .attr("result", "offsetBlur");
    filter
      .append("feFlood")
      .attr("in", "offsetBlur")
      .attr("flood-color", "black")
      .attr("flood-opacity", "1")
      .attr("result", "offsetColor");
    filter
      .append("feComposite")
      .attr("in", "offsetColor")
      .attr("in2", "offsetBlur")
      .attr("operator", "in")
      .attr("result", "offsetBlur");

    // overlay original SourceGraphic over translated blurred opacity by using
    // feMerge filter. Order of specifying inputs is important!
    let feMerge = filter.append("feMerge");

    feMerge.append("feMergeNode").attr("in", "offsetBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");

    // console.log(this.props.y2Label.getComputedTextLength());

    this.lineGroup = this.clippingMask.append("g").attr("id", "yLines");
    this.pointGroup = this.clippingMask.append("g").attr("id", "yPoints");

    // this.tip = d3Tip().attr('class', 'd3-tip').style('z-index', 10000).offset([-10, 0]);
    // this.chart.call(this.tip);

    // this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded),
    //     this.idleTimeout,
    //     this.idleDelay = 350;

    // this.clippingMaskBrush = this.clippingMask.append("g").attr("class", "brush");
    // this.clippingMaskBrush.call(this.brush);
    // this.lineGroup.moveToFront();
    // this.pointGroup.moveToFront();
  };

  render() {
    return <div style={{ color: "black" }} />;
  }
}

d3.selection.prototype.moveToFront = function() {
  return this.each(function() {
    this.parentNode.appendChild(this);
  });
};

d3.selection.prototype.moveToBack = function() {
  return this.each(function() {
    var firstChild = this.parentNode.firstChild;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  });
};

export default DataDistributionChart;
