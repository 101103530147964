let defaultState = {
  selected_model_name: "",
  current_output_keys: [],
  selected_output_key: "",
  current_coefficients: null,
  current_residuals: null,
  selected_residuals_slope: "",
  selected_residuals_rvalue: "",

  residuals_chart_title: "",
  coefficients_chart_title: "",
  residuals_legend_text: "",
  coefficients_legend_text: ""
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_MODEL_EVALUATION_DATA":
      return {
        ...state,
        selected_model_name: action.data.selected_model_name,
        current_residuals: action.data.current_residuals,
        current_output_keys: action.data.current_output_keys,
        selected_residuals_slope: action.data.selected_residuals_slope,
        selected_residuals_rvalue: action.data.selected_residuals_rvalue,
        selected_output_key: action.data.selected_output_key,
        current_coefficients: action.data.current_coefficients
      };
    case "UPDATE_MODEL_EVALUATION_KEY":
      return {
        ...state,
        current_residuals: action.data.current_residuals,
        selected_residuals_slope: action.data.selected_residuals_slope,
        selected_residuals_rvalue: action.data.selected_residuals_rvalue,
        selected_output_key: action.data.selected_output_key,
        current_coefficients: action.data.current_coefficients
      };
    case "UPDATE_MODEL_EVALUATION_TITLES":
      return {
        ...state,
        residuals_chart_title: action.data.residuals_chart_title,
        coefficients_chart_title: action.data.coefficients_chart_title,
        residuals_legend_text: action.data.residuals_legend_text,
        coefficients_legend_text: action.data.coefficients_legend_text
      };
    case "RESET_ALL":
      return {
        ...state,
        selected_model_name: "",
        current_output_keys: [],
        selected_output_key: "",
        current_coefficients: null,
        current_residuals: null,
        selected_residuals_slope: "",
        selected_residuals_rvalue: "",
        residuals_chart_title: "",
        coefficients_chart_title: "",
        residuals_legend_text: "",
        coefficients_legend_text: ""
      };
    default:
      return state;
  }
}

export default views;
