let defaultState = {
  selected_input_headers: [],
  selected_output_headers: [],
  unselected_all_headers: [],
  all_headers_highlighted: [],
  input_headers_highlighted: [],
  output_headers_highlighted: [],
  recommended_outputs: [],
  omitted_headers: [],
  selected_table_view: "raw",
  table_view_options: [
    { display: "Raw Data", value: "raw" },
    { display: "Encoded Data", value: "encoded" }
  ],
  data_is_loading: false,

  selected_file: "",
  file_is_present: false,
  upload_progress: 0,
  completed: false,
  file_sheets: [],
  file_selected_sheet: "",

  gen_pls: true,
  gen_nn: false,
  gen_krr: false,
  gen_svr: false,

  run_hpo: false
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_PLS":
      return {
        ...state,
        gen_pls: action.data.gen_pls
      };
    case "UPDATE_NN":
      return {
        ...state,
        gen_nn: action.data.gen_nn
      };
    case "UPDATE_KRR":
      return {
        ...state,
        gen_krr: action.data.gen_krr
      };
    case "UPDATE_SVR":
      return {
        ...state,
        gen_svr: action.data.gen_svr
      };
    case "UPDATE_HPO":
      return {
        ...state,
        run_hpo: action.data.run_hpo
      };
    case "UPDATE_PROGRESS":
      return {
        ...state,
        upload_progress: action.data.upload_progress,
        completed: action.data.upload_progress === 100
      };
    case "UPDATE_LOADED_FILE_SHEETS":
      return {
        ...state,
        file_sheets: action.data.file_sheets
      };
    case "UPDATE_SELECTED_SHEET":
      return {
        ...state,
        file_selected_sheet: action.data.file_selected_sheet,
        unselected_all_headers: action.data.unselected_all_headers,
        omitted_headers: action.data.omitted_headers,
        selected_input_headers: [],
        selected_output_headers: [],
        all_headers_highlighted: [],
        input_headers_highlighted: [],
        output_headers_highlighted: [],
        recommended_outputs: []
      };
    case "UPDATE_SELECTED_FILE":
      return {
        ...state,
        selected_file: action.data.selected_file,
        upload_progress: 0,
        file_is_present: action.data.selected_file !== "",
        completed: false
      };
    case "UPDATE_UNSELECTED_ALL_HEADERS":
      return {
        ...state,
        unselected_all_headers: action.data.unselected_all_headers
      };
    case "UPDATE_INPUT_HEADERS":
      return {
        ...state,
        selected_input_headers: action.data.selected_input_headers
      };
    case "UPDATE_OUTPUT_HEADERS":
      return {
        ...state,
        selected_output_headers: action.data.selected_output_headers
      };
    case "UPDATE_ALL_HEADERS_HIGHLIGHTED":
      return {
        ...state,
        all_headers_highlighted: action.data.all_headers_highlighted
      };
    case "UPDATE_INPUT_HEADERS_HIGHLIGHTED":
      return {
        ...state,
        input_headers_highlighted: action.data.input_headers_highlighted
      };
    case "UPDATE_OUTPUT_HEADERS_HIGHLIGHTED":
      return {
        ...state,
        output_headers_highlighted: action.data.output_headers_highlighted
      };
    case "UPDATE_RECOMMENDED_HEADERS":
      return {
        ...state,
        recommended_outputs: action.data.recommended_outputs
      };
    case "UPDATE_SELECTED_TABLEVIEW":
      return {
        ...state,
        selected_table_view: action.data.selected_table_view
      };
    case "UPDATE_DATA_LOADING":
      return {
        ...state,
        data_is_loading: action.data.data_is_loading
      };
    case "RESET_ALL":
      return {
        ...state,
        selected_input_headers: [],
        selected_output_headers: [],
        unselected_all_headers: [],
        all_headers_highlighted: [],
        input_headers_highlighted: [],
        output_headers_highlighted: [],
        recommended_outputs: [],
        omitted_headers: [],
        selected_table_view: "raw",
        table_view_options: [
          { display: "Raw Data", value: "raw" },
          { display: "Encoded Data", value: "encoded" }
        ],
        data_is_loading: false,
        selected_file: "",
        file_is_present: false,
        upload_progress: 0,
        completed: false,
        file_sheets: [],
        file_selected_sheet: "",
        gen_pls: true,
        gen_nn: false,
        gen_krr: false,
        gen_svr: false,
        run_hpo: false
      };
    default:
      return state;
  }
}

export default views;
