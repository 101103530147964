
export function UpdateSelectedOutputColumns(columns) {
    return {
        type: 'UPDATE_DATA_DIST_SELECTED_OUTPUT_COLUMNS',
        data: {
            distribution_selected_output_columns: columns,
        }
    }
}

export function UpdateSelectedInputColumns(columns) {
    return {
        type: 'UPDATE_DATA_DIST_SELECTED_INPUT_COLUMNS',
        data: {
            distribution_selected_input_columns: columns,
        }
    }
}

export function UpdateDistributionSourceData(data) {
    return {
        type: 'UPDATE_DATA_DIST_SOURCE_DATA',
        data: {
            distribution_source_data: data
        }
    }
}

export function UpdateDistributionDensitySelection(densityColumn, densityObject){
    return {
        type: 'UPDATE_DATA_DIST_SELECTION',
        data: {
            distribution_selected_density_object: densityObject,
            distribution_selected_density_column: densityColumn,
            distribution_selected_regression_object: {},
            distribution_selected_regression_column1: '',
            distribution_selected_regression_column2: '',
            distribution_regression_selected: false,
            distribution_density_selected: true,
        }
    }
}

export function UpdateDistributionRegressionSelection(regressionColumn1, regressionColumn2, regressionObject){
    return {
        type: 'UPDATE_DATA_DIST_SELECTION',
        data: {
            distribution_selected_density_object: {},
            distribution_selected_density_column: '',
            distribution_selected_regression_object: regressionObject,
            distribution_selected_regression_column1: regressionColumn1,
            distribution_selected_regression_column2: regressionColumn2,
            distribution_regression_selected: true,
            distribution_density_selected: false,
        }
    }
}

export function UpdateDistributionSelectedRowData(rowData){
    return{
        type: 'UPDATE_DATA_DIST_SELECTED_ROW_DATA',
        data:{
            distribution_selected_row_data: rowData
        }
    }
}
