import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ParallelCoordinatesChart from "./ParallelCoordinatesChart";
import ModelSettings from "./ModelSettings";
import ModelInputSlider from "./ModelInputSlider";
import * as HelperFunctions from "./ModelCalculationCode";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import SaveIcon from "@material-ui/icons/Save";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import fs from "file-saver";
import "./handsontable.full.css";
import {sleep} from './utils'

//Dev
import * as viewActions from "../actions/viewActions";
import * as usageActions from "../actions/modelusageActions";
import * as predictionActions from "../actions/modelPredictionActions";
import * as utils from "./utils.js";
import JSZip from "jszip";
import SaveSVGasPNG from "save-svg-as-png";

const styles = theme => ({
  htRoot: { // for handsontable (hot) overrides
    '& tr': {
      background: theme.palette.background.paper
    },
    '& th': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["200"],
      color: theme.palette.text.primary,
    },
    '& .htPlaceholder': {
      color: theme.palette.text.secondary,
    },
    '& tbody th.ht__highlight, thead th.ht__highlight': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["500"] : theme.palette.grey["300"]
    },
    '& td':{
      background: theme.palette.background.paper,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    '& td.noWrap': {
      whiteSpace: "nowrap",
    },
    '& .handsontableInput': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '& .handsontable.listbox .ht_master table': {
      background: theme.palette.background.paper,
    },
    '& .handsontable.listbox tr td.current, .handsontable.listbox tr:hover td': {
      background: theme.palette.action.hover,
    },
    '& .htMobileEditorContainer': {
      background: theme.palette.background.paper,
    },
    '& .htDimmed': {
      color: theme.palette.text.secondary,
    },
    '& .htSubmenu :after': {
      color: theme.palette.text.secondary,
    },
    '& td.currentRow': {
      backgroundColor: theme.palette.primary.main + "10",
    },
    '& td.outputCell': {
      backgroundColor: "#dcedc8",
    },
    '& td.inputCell': {
      backgroundColor: "#e1f5fe",
    },
    '& td.innerColHeader': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
      color: theme.palette.text.primary,
    },
    '& td.innerColSubHeader': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
    },
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: "33.33%",
    flexShrink: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

const LargeTooltip = withStyles({
  tooltip: {
    fontSize: '1.5em'
  }
})(Tooltip);

class ModelPrediction extends Component {
  //keep slider values in a local state
  componentDidMount() {}

  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "modelpred") {
      return false;
    }
    if (this.props.views.currentView !== "modelpred") {
      this.forcePredictionUpdate();
    }
    return true;
  }

  modelChange = event => {
    let newModelName = event.target.value;
    this.props.dispatch(predictionActions.UpdateSelectedModel(newModelName));
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === newModelName;
    })[0];
    let newpreds = Object.assign({}, this.props.modelprediction.prediction_input_values);
    this.updatePrediction(newpreds, selModel);
    // this.props.dispatch(predictionActions.UpdateBatchPredictionTableData([]));
    setTimeout(() => {
      this.BeforeChange([], "modelchange");
    }, 0);
  };

  updateRangeMinVal = (column, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let dataRanges = Object.assign([], this.props.modelusage.data_ranges);
    let currentCol = dataRanges.filter(function(el) {
      return el.column === column;
    })[0];
    let newUserMin = Math.min(parseFloat(event.target.value), currentCol.usermax);
    let newUserMax = Math.max(parseFloat(event.target.value), currentCol.usermax);
    currentCol.usermin = newUserMin;
    currentCol.usermax = newUserMax;
    this.props.dispatch(usageActions.UpdateModelUsageDataRanges(dataRanges));
    document.getElementById(column + "_pred_min_select").value = newUserMin;
    document.getElementById(column + "_pred_max_select").value = newUserMax;
  };

  updateRangeMaxVal = (column, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let dataRanges = Object.assign([], this.props.modelusage.data_ranges);
    let currentCol = dataRanges.filter(function(el) {
      return el.column === column;
    })[0];
    let newUserMin = Math.min(parseFloat(event.target.value), currentCol.usermin);
    let newUserMax = Math.max(parseFloat(event.target.value), currentCol.usermin);
    currentCol.usermin = newUserMin;
    currentCol.usermax = newUserMax;
    this.props.dispatch(usageActions.UpdateModelUsageDataRanges(dataRanges));
    document.getElementById(column + "_pred_min_select").value = newUserMin;
    document.getElementById(column + "_pred_max_select").value = newUserMax;
  };

  columnChartDisplayChanged = column => {
    let newDisplayCols = JSON.parse(JSON.stringify(this.props.modelprediction.prediction_display_columns));
    let selectedCol = newDisplayCols.filter(function(el) {
      return el.col === column;
    })[0];
    selectedCol.display = !selectedCol.display;
    let newPredText =
      "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
      newDisplayCols
        .filter(function(el) {
          return el.display;
        })
        .map(x => x.col)
        .join(", ") +
      ". The prediction was generated using the '" +
      this.props.modelprediction.selected_model_name +
      "' model and inputs/outputs are reflected in the table to the right.";
    this.props.dispatch(predictionActions.UpdatePredictionDisplayColumns(newDisplayCols, newPredText));
  };

  columnEditDisplayChanged = column => {
    let newDisplayCols = JSON.parse(JSON.stringify(this.props.modelprediction.prediction_display_columns));
    let selectedCol = newDisplayCols.filter(function(el) {
      return el.col === column;
    })[0];
    selectedCol.hide = !selectedCol.hide;
    let newPredText =
      "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
      newDisplayCols
        .filter(function(el) {
          return el.display;
        })
        .map(x => x.col)
        .join(", ") +
      ". The prediction was generated using the '" +
      this.props.modelprediction.selected_model_name +
      "' model and inputs/outputs are reflected in the table to the right.";
    this.props.dispatch(predictionActions.UpdatePredictionDisplayColumns(newDisplayCols, newPredText));
  };

  categoricalColumnChange = (column, event) => {
    let newpreds = Object.assign({}, this.props.modelprediction.prediction_input_values);
    newpreds[column] = event.target.value;
    //this.props.dispatch(predictionActions.UpdateModelPredictionInputValues(newpreds));
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];
    this.updatePrediction(newpreds, selModel);
    // this.updatePrediction(newpreds);
  };

  sliderFinish = (column, event, value) => {
    let newpreds = Object.assign({}, this.props.modelprediction.prediction_input_values);
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];
    this.updatePrediction(newpreds, selModel);
  };

  updatePrediction = (predVals, selModel) => {
    // let currModelName = this.props.modelprediction.selected_model_name;
    // let selModel = this.props.modeldata.models.filter(function (el) { return el.name === currModelName })[0];
    let responseDelay = this.props.views.responseDelay
    sleep(responseDelay)
    let currConstraints = this.props.modelusage.settings_all_constraints.filter(function(el) {
      return el.use;
    });
    let displayColumns = this.props.modelprediction.prediction_display_columns.filter(function(el) {
      return el.display;
    });
    if (selModel.type === "PLS") {
      let results = HelperFunctions.CalculatePLSRes(
        predVals,
        selModel.x_mean_,
        selModel.y_mean_,
        selModel.x_std_,
        selModel.coef_,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modelusage.data_ranges,
        this.props.modelusage.categorical_variables,
        this.props.modelusage.settings_all_constraints
      );
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
        displayColumns
          .filter(function(el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }
    if (selModel.type === "NN") {
      let results = HelperFunctions.CalculateNNPred(
        predVals,
        selModel.outputOrganization.length,
        selModel.coefficients,
        selModel.intercepts,
        selModel.layerCount,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modeldata.categorical_variables,
        selModel.dataMin,
        selModel.dataRange,
        currConstraints
      );
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (green line) within the following dimensions: " +
        displayColumns
          .filter(function(el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }
    if (selModel.type === "KRR") {
      let results = HelperFunctions.CalculateKRRPred(
        predVals,
        selModel.outputOrganization.length,
        selModel.dual_coef,
        selModel.x_fit,
        selModel.gamma,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modeldata.categorical_variables,
        selModel.dataMin,
        selModel.dataRange,
        currConstraints
      );
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (purple line) within the following dimensions: " +
        displayColumns
          .filter(function(el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }
    if (selModel.type === "SVR") {
      let results = HelperFunctions.CalculateSVRPred(
        predVals,
        selModel.outputOrganization.length,
        selModel.support_vectors_,
        selModel.dual_coef_,
        selModel.intercept_,
        selModel._gamma,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modeldata.categorical_variables,
        selModel.dataMin,
        selModel.dataRange,
        currConstraints
      );
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (pink line) within the following dimensions: " +
        displayColumns
          .filter(function(el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }
  };

  AfterColumnSort = (currentSortConfig, destinationSortConfigs) => {
    if (destinationSortConfigs.length === 1) {
      let colIndex = destinationSortConfigs[0].column;
      let sortOrder = destinationSortConfigs[0].sortOrder;
      let sourceRowData = JSON.parse(JSON.stringify(this.props.modelprediction.batch_prediction_table_data));
      let sourceRowDataNotNull = sourceRowData.filter(el => {
        return el[colIndex] !== null;
      });
      let sourceRowDataNull = sourceRowData.filter(el => {
        return el[colIndex] === null;
      });
      if (sortOrder === "asc") {
        sourceRowDataNotNull = sourceRowDataNotNull.sort((a, b) => {
          return a[colIndex] - b[colIndex];
        });
      } else {
        sourceRowDataNotNull = sourceRowDataNotNull.sort((a, b) => {
          return b[colIndex] - a[colIndex];
        });
      }
      sourceRowData = sourceRowDataNotNull.concat(sourceRowDataNull);
      this.props.dispatch(predictionActions.UpdateBatchPredictionTableData(sourceRowData));
    }
  };
  BeforeChange = (changes, source) => {
    let responseDelay = this.props.views.responseDelay
    sleep(responseDelay)
    //Game plan
    //0. Get existing model
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];

    let useConstraints =
      this.props.modelusage.settings_all_constraints.filter(el => {
        return el.use;
      }).length > 0;

    //1. make copy of existing data
    let sourceRowData = useConstraints ? this.refs.batchpredicttableconstraints.props.data.slice() : this.refs.batchpredicttable.props.data.slice();

    //1.1 exclude the first row because it's from single point prediction
    // sourceRowData[0] = Array(sourceRowData[0].length).fill("")

    //2. update existing rows of data - track indexes of changed rows
    let updatedIndexes = {};
    changes.forEach(el => {
      if (sourceRowData[el[0]] === undefined) {
        sourceRowData[el[0]] = [];
      }
      sourceRowData[el[0]][el[1]] = this.props.modelprediction.prediction_display_columns[el[1]].categorical ? el[3] : parseFloat(el[3]);
      if (!this.props.modelprediction.prediction_display_columns[el[1]].categorical && isNaN(parseFloat(sourceRowData[el[0]][el[1]]))) {
        sourceRowData[el[0]][el[1]] = "";
      }
    });
    sourceRowData.forEach((row, i) => {
      updatedIndexes[i] = "";
      let rowCopy = row.slice();
      this.props.modelprediction.prediction_display_columns.forEach((col, index) => {
        rowCopy[index] = col.categorical ? row[index] : parseFloat(row[index]);
        if (!col.categorical && isNaN(parseFloat(row[index]))) {
          rowCopy[index] = "";
        }
        //Check for a valid categorical
        if (col.categorical) {
          if (this.props.modelusage.categorical_variables["Inputs"][col.col].indexOf(col.col + "_" + row[index]) === -1) {
            rowCopy[index] = "";
          }
        }
      });
      sourceRowData[i] = rowCopy;
    });
    //3. update predictions
    updatedIndexes = Object.keys(updatedIndexes);
    updatedIndexes.forEach(index => {
      let currRowData = sourceRowData[index];
      let row = {};
      let validInputs = true;
      this.props.modelprediction.prediction_display_columns.forEach((col, colIndex) => {
        if (currRowData[colIndex] !== undefined) {
          row[col.col] = col.categorical ? currRowData[colIndex] : parseFloat(currRowData[colIndex]);
        }
        else
        {
          validInputs = false;
        }
        if (col.categorical && row[col.col] === "" ) {
          validInputs = false;
        }
      });
      let results = {};
      try {
        if (selModel.type === "PLS") {
          results = HelperFunctions.CalculatePLSRes(
            row,
            selModel.x_mean_,
            selModel.y_mean_,
            selModel.x_std_,
            selModel.coef_,
            selModel.inputOrganization,
            selModel.outputOrganization,
            this.props.modelusage.data_ranges,
            this.props.modelusage.categorical_variables,
            this.props.modelusage.settings_all_constraints
          );
        }
        if (selModel.type === "NN") {
          results = HelperFunctions.CalculateNNPred(
            row,
            selModel.outputOrganization.length,
            selModel.coefficients,
            selModel.intercepts,
            selModel.layerCount,
            selModel.inputOrganization,
            selModel.outputOrganization,
            this.props.modeldata.categorical_variables,
            selModel.dataMin,
            selModel.dataRange,
            this.props.modelusage.settings_all_constraints
          );
        }
        if (selModel.type === "KRR") {
          results = HelperFunctions.CalculateKRRPred(
            row,
            selModel.outputOrganization.length,
            selModel.dual_coef,
            selModel.x_fit,
            selModel.gamma,
            selModel.inputOrganization,
            selModel.outputOrganization,
            this.props.modeldata.categorical_variables,
            selModel.dataMin,
            selModel.dataRange,
            this.props.modelusage.settings_all_constraints
          );
        }
        if (selModel.type === "SVR") {
          results = HelperFunctions.CalculateSVRPred(
            row,
            selModel.outputOrganization.length,
            selModel.support_vectors_,
            selModel.dual_coef_,
            selModel.intercept_,
            selModel._gamma,
            selModel.inputOrganization,
            selModel.outputOrganization,
            this.props.modeldata.categorical_variables,
            selModel.dataMin,
            selModel.dataRange,
            this.props.modelusage.settings_all_constraints
          );
        }
        let aggResults = { ...results.inputValues, ...results.outputValues };
        if (!validInputs) {
          Object.keys(results.outputValues).forEach(el => {
            aggResults[el] = "";
          });
        }
        Object.keys(aggResults).forEach(key => aggResults[key] === "NaN" && delete aggResults[key]);
        let currRow = [];
        this.props.modelprediction.prediction_display_columns.forEach(el => {
          currRow.push(aggResults[el.col]);
        });
        let violatedConstraint = results.violatedConstraints.length > 0 ? "FALSE" : "TRUE";
        violatedConstraint =
          Object.values(results.outputValues).filter(el => {
            return el !== "NaN";
          }).length > 0
            ? violatedConstraint
            : "";
        violatedConstraint = validInputs ? violatedConstraint : '';
        currRow.push(violatedConstraint);
        sourceRowData[index] = currRow;
      } catch (error) {
        console.log(error);
      }
    });
    sourceRowData = Array.from(sourceRowData, item =>
      typeof item === "undefined" ? Array(this.props.modelprediction.prediction_display_columns.length).fill("") : item
    );

    updatedIndexes.forEach(index => {
      for (var i = 0; i < sourceRowData[index].length; i++) {
        if (typeof sourceRowData[index][i] === "number" && isNaN(sourceRowData[index][i])) {
          sourceRowData[index][i] = undefined;
        }
      }
    });

    //4. update underlying data
    this.props.dispatch(predictionActions.UpdateBatchPredictionTableData(sourceRowData));
    // setTimeout(() => {
    //   if (useConstraints) {
    //     this.refs.batchpredicttableconstraints.hotInstance.loadData(sourceRowData);
    //     this.refs.batchpredicttableconstraints.hotInstance.render();
    //   } else {
    //     this.refs.batchpredicttable.hotInstance.loadData(sourceRowData);
    //     this.refs.batchpredicttable.hotInstance.render();
    //   }
    // }, 100);
  };

  ExportResultsExcel = event => {
    let data = this.props.modelprediction.batch_prediction_table_data;
    let headers = this.props.modelprediction.prediction_display_columns.map(x => {
      return x.col;
    });
    this.props.dispatch(viewActions.changeLoadingState(true));
    this.props.dispatch(viewActions.updateLoadingMessage("Assembling Results for Export..."));
    let url = `exportbatchexcel`;
    let use_constraints =
      this.props.modelusage.settings_all_constraints.filter(el => {
        return el.use;
      }).length > 0;
    this.props.axPost(url, JSON.stringify({ table_headers: headers, table_data: data, constraints: use_constraints }), {
        headers: { "Content-Type": "application/json; charset=utf-8" },
        responseType: "arraybuffer"
      })
      .then(res => {
        this.props.dispatch(viewActions.changeLoadingState(false));
        this.props.dispatch(viewActions.updateLoadingMessage(""));
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        let filename = "MixingStudio_PredictionData.xlsx"
        utils.SaveNonImageFile(blob, filename);
      });
  };

  forcePredictionUpdate = () => {
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];
    let predVals = this.props.modelprediction.prediction_input_values;
    this.updatePrediction(predVals, selModel);
  };

  singlePredDataToList = () => {
    let singlePredData = this.props.modelprediction.prediction_table_data
    let singlePredList = []
    let headers = this.props.modelprediction.prediction_display_columns.map(x => {return [x.col];})
    headers.map( (header) => {
      let data = singlePredData.filter( (col => { return col.includes(header[0]) }))
      singlePredList.push(data[0][2])
      return ''
    })
    return [singlePredList]
  }

  batchPredDataToList = () => {
    return this.props.modelprediction.batch_prediction_table_data.length > 0
      ? this.props.modelprediction.batch_prediction_table_data.map(el => {
          return el.slice();
        })
      : [
          Array(
            this.props.modelprediction.prediction_display_columns.length + 1
          ).fill("")
        ];
  }

  getTableData = () => {
    let batchPredData = this.batchPredDataToList()
    batchPredData[0] = this.singlePredDataToList()

    return batchPredData
  }

  handleSaveData = () => {
    let currentSingleData = this.singlePredDataToList()[0]
    let currentBatchData = this.batchPredDataToList()
    currentBatchData.splice(0, 0, currentSingleData)
    this.props.dispatch(predictionActions.UpdateBatchPredictionTableData(currentBatchData));
  }

  downloadAllPNGs = () => {
    let chartID = "predParallelCoords_ChartSVG"
    let chartTitle ="PredictionChartPNG"

    var zip = new JSZip();
    var buf = SaveSVGasPNG.svgAsPngUri(document.getElementById(chartID), { scale: 1, backgroundColor: 'white' }, function (dataURI) {
    }).then(function (el) {
      var data = el.replace(/^data:image\/\w+;base64,/, "");
      var buf = new Buffer(data, 'base64');
      return buf;
    });

    zip.file(chartTitle + ".png", buf);
    let dataString = "";
    this.props.modelprediction.prediction_table_data.forEach(row => {
      if(row[0]!==null)
      {
        dataString +=  row[0] + ',' + row[1] + ',' + row[2] + ',\n';
      }
    });
    zip.file('PredictionInputs.csv', dataString)

    zip.generateAsync({ type: "blob" })
        .then(function (blob) {
          //updateLoading();
          fs.saveAs(blob, "Model_Prediction_Chart.zip");
        });
  };

  cellStyle = (columnsInfo) => {
    let nInput = 0
    this.props.modelprediction.prediction_display_columns.map( x => {
      if(x.type === 'input'){
        nInput += 1
      }
      return ''})
    return (rowIndex, colIndex, prop) => {
      // Group Header row on top
      if (colIndex >= nInput) {
        return {
          className: "htCenter outputCell"
        }
      } else {
        return {
          className: "htCenter inputCell"
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Model Prediction
          </Typography>

          <Typography gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
            Generate predictions using your trained models below.
          </Typography>
          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 24 }}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="modelpred_model_select">Selected Regression Model</InputLabel>
                  <Select
                    style={{ textAlign: "left" }}
                    value={this.props.modelprediction.selected_model_name}
                    onChange={this.modelChange}
                    input={<Input name="modelpred_model_select" id="modelpred_model_select" />}
                    data-cy="select_models2"
                  >
                    {this.props.modeldata.models.map(model => (
                      <MenuItem key={model.name} value={model.name}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Card>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
              {/*<Grid item xs={12} sm={12} md={12} lg={9}>*/}
              {this.props.modelprediction.prediction_plot_data.length > 0 && (
                <Grid item xs={8} id="predParallelCoordsDiv">
                  <ParallelCoordinatesChart
                    data={this.props.modelprediction.prediction_plot_data}
                    dimensions={this.props.modelprediction.prediction_display_columns}
                    appendDiv={"predParallelCoordsDiv"}
                    chartID={"predParallelCoords_ChartSVG"}
                    chartType={"modelpred"}
                    currentView={this.props.views.currentView}
                    heightToWidthRatio={0.35}
                    legendText={this.props.modelprediction.legend_text}
                    useFullDataRanges={false}
                    fullDataRanges={this.props.modelusage.data_ranges.concat(this.props.modelusage.output_data_ranges)}
                    useOptimizationScheme={false}
                  />
                </Grid>
              )}
              {/*</Grid>*/}
              <Grid item xs>
                <>
                  <Typography className={classes.heading}>Input/Output Display Settings</Typography>
                  <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="paralle-chart-settings1"
                    >
                      <Typography variant='body2'>Input Columns to Display in Chart</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {this.props.modelprediction.prediction_display_columns
                            .filter(function(el) {
                              return el.type === "input" && el.categorical === false;
                            })
                            .map(col => (
                                <FormControlLabel
                                    key={col.col}
                                    control={
                                      <Checkbox
                                          checked={col.display}
                                          onChange={() => this.columnChartDisplayChanged(col.col)}
                                          value={col.col}
                                          color="primary"
                                          style={{ height: 24, paddingTop: 0, paddingBottom: 0 }}
                                          disableRipple
                                      />
                                    }
                                    label={col.col}
                                />
                            ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="paralle-chart-settings1"
                    >
                      <Typography variant='body2'>Output Columns to Display in Chart</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {this.props.modelprediction.prediction_display_columns
                            .filter(function(el) {
                              return el.type === "output" && el.categorical === false;
                            })
                            .map(col => (
                                <FormControlLabel
                                    key={col.col}
                                    control={
                                      <Checkbox
                                          checked={col.display}
                                          onChange={() => this.columnChartDisplayChanged(col.col)}
                                          value={col.col}
                                          color="primary"
                                          style={{ height: 24, paddingTop: 0, paddingBottom: 0 }}
                                          disableRipple
                                      />
                                    }
                                    label={col.col}
                                />
                            ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="paralle-chart-settings1"
                    >
                      <Typography variant='body2'>Input Fields Available to Edit</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {this.props.modelprediction.prediction_display_columns
                            .filter(function(el) {
                              return el.type === "input";
                            })
                            .map(col => (
                                <FormControlLabel
                                    key={col.col + "showinput"}
                                    control={
                                      <Checkbox
                                          checked={!col.hide}
                                          onChange={() => this.columnEditDisplayChanged(col.col)}
                                          value={col.col}
                                          color="primary"
                                          style={{ height: 24, paddingTop: 0, paddingBottom: 0 }}
                                          disableRipple
                                      />
                                    }
                                    label={col.col}
                                />
                            ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </>
              </Grid>
            </Grid>

            {this.props.modelprediction.prediction_violated_constraints.length > 0 && (
              <Grid container variant="body1" justify="flex-start" alignItems="center" spacing={10}>
                <Grid item xs={12} sm={12} md={12} lg={9} style={{ textAlign: "center" }}>
                  <Typography gutterBottom style={{ textAlign: "center", marginLeft: 24, marginBottom: 15, color: "red" }}>
                    <i>Warning!</i> Constraints Violated: {this.props.modelprediction.prediction_violated_constraints.join("; ")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} />
              </Grid>
            )}
            <Grid container justify="flex-start" alignItems="center" spacing={5}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24}}>
                  Single Point Model Prediction:
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
                  Use the slider to choose input value
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <LargeTooltip placement="top" title="Save current prediction results to batch prediction table">
                  <IconButton color="primary" onClick={this.handleSaveData}>
                    <AddShoppingCartIcon fontSize="large" />
                  </IconButton>
                </LargeTooltip>
                <LargeTooltip placement="top" title="Save Prediction Chart PNG">
                  <IconButton color="primary" onClick={this.downloadAllPNGs}>
                    <SaveIcon fontSize="large" />
                  </IconButton>
                </LargeTooltip>
              </Grid>
            </Grid>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={5}>
              {this.props.modelusage.data_ranges
                .filter(function(el) {
                  return el.categorical === false;
                })
                .map(range => {
                  if (
                    !this.props.modelprediction.prediction_display_columns.filter(function(el) {
                      return el.col === range.column;
                    })[0].hide
                  ) {
                    return (
                        <Grid item xs={2} key={range.column + "as"}>
                          <ModelInputSlider
                            key={range.column + "input"}
                            column={range.column}
                            currValue={this.props.modelprediction.prediction_input_values[range.column]}
                            usermin={range.usermin}
                            usermax={range.usermax}
                            contour={false}
                          />
                        </Grid>
                    );
                  }
                    else {
                        return null;
                    }
                })}
              {this.props.modelusage.categorical_variables.Inputs !== undefined &&
                Object.keys(this.props.modelusage.categorical_variables.Inputs).map(col => {
                  if (
                    !this.props.modelprediction.prediction_display_columns.filter(function(el) {
                      return el.col === col;
                    })[0].hide
                  ) {
                    return (
                      <Grid item xs={2} key={col + "as"}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel htmlFor={col + "_pred_select"}>{col}</InputLabel>
                          <Select
                            style={{ textAlign: "left" }}
                            value={this.props.modelprediction.prediction_input_values[col]}
                            onChange={ev => this.categoricalColumnChange(col, ev)}
                            input={<Input name={col + "_pred_select"} id={col + "_pred_select"} />}
                          >
                            {this.props.modelusage.categorical_variables.Inputs[col].map(encodedCol => (
                              <MenuItem key={encodedCol.replace(col + "_", "")} value={encodedCol.replace(col + "_", "")}>
                                {encodedCol.replace(col + "_", "")}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    );
                  }
                    else {
                        return null;
                    }
                })}

            </Grid>

          </div>
          <div style={{ margin: 24 }}>
            <Grid container style={{ marginTop: 8 }} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                {(this.props.modelprediction.prediction_display_columns.length > 0 &&
                  this.props.modelusage.settings_all_constraints.filter(el => {
                    return el.use;
                  }).length) > 0 && (
                  <div  className={classes.htRoot}>
                    <HotTable
                        data={this.singlePredDataToList()}
                        colHeaders={this.props.modelprediction.prediction_display_columns
                            .map(x => {
                              return [x.col];
                            })
                            .concat(["Constraints Satisified"])}
                        // Make predictions and populate the columns
                        columns={this.props.modelprediction.prediction_display_columns
                            .map(x => {
                              return { type: "text", readOnly: x.type === "output" };
                            })
                            .concat({ type: "text", readOnly: true })}
                        rowHeaders={true}
                        columnSorting={false}
                        height="75"
                        stretchH="all"
                        minRows="1"
                        cells={this.cellStyle()}
                        settings={{ outsideClickDeselects: false }}
                        root="hot"
                        ref="singlepredicttableconstraints"
                        beforeChange={this.BeforeChange}
                        // afterColumnSort={this.AfterColumnSort}
                        style={{ fontSize: "smaller", fontFamily: "Roboto" }}
                        id = "predict_table1"
                    />
                    <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 30  }}>
                      Batch Prediction:
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
                      Add full rows of input data to the table below to generate predictions using the selected model.
                    </Typography>
                    <HotTable
                      data={this.batchPredDataToList()}
                      colHeaders={this.props.modelprediction.prediction_display_columns
                        .map(x => {
                          return [x.col];
                        })
                        .concat(["Constraints Satisified"])}
                      // Make predictions and populate the columns
                      columns={this.props.modelprediction.prediction_display_columns
                        .map(x => {
                          return { type: "text", readOnly: x.type === "output" };
                        })
                        .concat({ type: "text", readOnly: true })}
                      rowHeaders={true}
                      columnSorting={false}
                      height="500"
                      stretchH="all"
                      minRows="20"
                      cells={this.cellStyle()}
                      settings={{ outsideClickDeselects: false }}
                      root="hot"
                      ref="batchpredicttableconstraints"
                      beforeChange={this.BeforeChange}
                      // afterColumnSort={this.AfterColumnSort}
                      style={{ fontSize: "smaller", fontFamily: "Roboto" }}
                      id = "predict_table2"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.margin}
                      onClick={this.ExportResultsExcel}
                      // disabled={this.props.prediction.selected_existing_table_rows.length===0}
                      style={{ background: "linear-gradient(45deg, #00c853 30%, #00e676 90%)", float: "right", marginTop: 6 }}
                    >
                      <DescriptionIcon style={{ marginRight: 12 }} />
                      Export Results to Excel
                    </Button>
                  </div>
                )}
                {(this.props.modelprediction.prediction_display_columns.length > 0 &&
                  this.props.modelusage.settings_all_constraints.filter(el => {
                    return el.use;
                  }).length) === 0 && (
                  <div className={classes.htRoot}>
                    <HotTable
                        data={this.singlePredDataToList()}
                        colHeaders={this.props.modelprediction.prediction_display_columns
                            .map(x => {return [x.col];})}
                        // Make predictions and populate the columns
                        columns={this.props.modelprediction.prediction_display_columns
                            .map(x => {return { type: "text", readOnly: x.type === "output" };})}
                        rowHeaders={true}
                        columnSorting={false}
                        height="75"
                        stretchH="all"
                        minRows="1"
                        settings={{ outsideClickDeselects: false }}
                        cells={this.cellStyle()}
                        root="hot"
                        ref="singlepredicttableconstraints"
                        beforeChange={this.BeforeChange}
                        // afterColumnSort={this.AfterColumnSort}
                        style={{ fontSize: "smaller", fontFamily: "Roboto" }}
                        id = "predict_table1"
                    />
                    <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 30  }}>
                      Batch Prediction:
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
                      Add full rows of input data to the table below to generate predictions using the selected model.
                    </Typography>
                    <HotTable
                      data={this.batchPredDataToList()}
                      colHeaders={this.props.modelprediction.prediction_display_columns.map(x => {
                        return [x.col];
                      })}
                      columns={this.props.modelprediction.prediction_display_columns.map(x => {
                        return { type: "text", readOnly: x.type === "output" };
                      })}
                      rowHeaders={true}
                      columnSorting={false}
                      height="500"
                      stretchH="all"
                      minRows="20"
                      cells={this.cellStyle()}
                      settings={{ outsideClickDeselects: false }}
                      root="hot"
                      ref="batchpredicttable"
                      beforeChange={this.BeforeChange}
                      // afterColumnSort={this.AfterColumnSort}
                      style={{ fontSize: "smaller", fontFamily: "Roboto" }}
                      id = "predict_table2"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.margin}
                      onClick={this.ExportResultsExcel}
                      // disabled={this.props.prediction.selected_existing_table_rows.length===0}
                      style={{ background: "linear-gradient(45deg, #00c853 30%, #00e676 90%)", float: "right", marginTop: 6 }}
                    >
                      <DescriptionIcon style={{ marginRight: 12 }} />
                      Export Results to Excel
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <div style={{ margin: 24 }}>
            <ModelSettings />
          </div>
        </Card>
      </div>
    );
  }
}

ModelPrediction.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modelusage: state.modelusage,
    modelprediction: state.modelprediction,
    //modelpredictioninputs: state.modelpredictioninputs,
    modeldata: state.modeldata
  };
})(withStyles(styles)(ModelPrediction));
