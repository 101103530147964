import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    borderRadius: 1,
  },
  content: {
    color: "#fff"
  }
}));

const REDIRECT_DURATION = 60; // in seconds
const MM_PATH = process.env.REACT_APP_MM_IN_MS_REDIRECT;

export function RedirectModal() {
  const classes = useStyles();
  const [redirectCountdown, setRedirectCountdown] = useState(REDIRECT_DURATION);

  const redirect = () => {
    window.location.href=MM_PATH;
  }
  useEffect(() => {
    setTimeout(() => {
      if (redirectCountdown > 0) {
        setRedirectCountdown(redirectCountdown-1)
      } else {
        redirect();
      }
    }, 1000);
  });
  
  return (
    <Dialog 
      open
      className={classes.paper}
      >
      <MuiDialogTitle className={classes.title}>
        Mixing Studio is moving homes!
      </MuiDialogTitle>
      <MuiDialogContent dividers={true}>
        <Typography variant="body1">
          Please adjust your bookmarks to:<br/>
          <a href={MM_PATH}>{MM_PATH}</a> <br/><br/>
          You will be automatically redirected in {redirectCountdown} seconds <br/>
          or you can click the link above to navigate there directly.
        </Typography>
      </MuiDialogContent>
    </Dialog>
  )
}