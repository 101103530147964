export function UpdateSaveModelName(name) {
    return {
        type: 'UPDATE_SAVE_MODEL_NAME',
        data: {
            save_model_name: name,
        }
    }
}

export function UpdateSaveModelDescription(description) {
    return {
        type: 'UPDATE_SAVE_MODEL_DESCRIPTION',
        data: {
            save_model_description: description,
        }
    }
}
