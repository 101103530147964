import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import ResidualsScatterChart from "./ResidualsScatterChart";
import CoefficientsBarChart from "./CoefficientsBarChart";
import ChartSpeedDial from "./ChartSpeedDial";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

class ResidualsCoefficientsLarge extends Component {
  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "modeleval") {
      return false;
    }
    //additional logic here would be good
    return true;
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Sensitivity Analysis
          </Typography>

          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={24}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
                {this.props.modelevaluation.current_coefficients !== null && (
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item xs={11} sm={11} md={11} lg={11} id="modelEvalCoefficients">
                      <CoefficientsBarChart
                        data={this.props.modelevaluation.current_coefficients}
                        xLabel={"Input (X) Variables"}
                        yLabel={"Magnitude of Effect on Output"}
                        xScaleType={"linear"}
                        yScaleType={"linear"}
                        showPoints={true}
                        showToolTips={true}
                        extraLabels={[]}
                        appendDiv={"modelEvalCoefficients"}
                        chartID={"modelEvalcoefficients_ChartSVG"}
                        chartTitle={this.props.modelevaluation.coefficients_chart_title}
                        chartType={"modeleval"}
                        currentView={this.props.views.currentView}
                        heightToWidthRatio={0.4}
                        scaleAllAxes={false}
                        xAxisMin={0}
                        xAxisMax={0}
                        yAxisMin={0}
                        yAxisMax={0}
                        legendText={this.props.modelevaluation.coefficients_legend_text}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} id="">
                      <ChartSpeedDial
                        chartID={"modelEvalcoefficients_ChartSVG"}
                        chartTitle={"ChartPNG"}
                        chartType="modeleval"
                        direction="up"
                        marginBottom={15}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Residuals
          </Typography>
          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={24}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
                {this.props.modelevaluation.current_residuals !== null && (
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item xs={11} sm={11} md={11} lg={11} id="modelEvalResiduals">
                      <ResidualsScatterChart
                        data={this.props.modelevaluation.current_residuals}
                        xLabel={"Observed"}
                        yLabel={"Predicted"}
                        xScaleType={"linear"}
                        yScaleType={"linear"}
                        showPoints={true}
                        showToolTips={true}
                        extraLabels={[]}
                        appendDiv={"modelEvalResiduals"}
                        chartID={"modelEvalResiduals_ChartSVG"}
                        chartTitle={this.props.modelevaluation.residuals_chart_title}
                        chartType={"modeleval"}
                        currentView={this.props.views.currentView}
                        heightToWidthRatio={0.33}
                        scaleAllAxes={false}
                        xAxisMin={0}
                        xAxisMax={0}
                        yAxisMin={0}
                        yAxisMax={0}
                        legendText={this.props.modelevaluation.residuals_legend_text}
                        slope={this.props.modelevaluation.current_residuals.Slope}
                        intercept={this.props.modelevaluation.current_residuals.Intercept}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} id="">
                      <ChartSpeedDial
                        chartID={"modelEvalResiduals_ChartSVG"}
                        chartTitle={"ChartPNG"}
                        chartType="modeleval"
                        direction="up"
                        marginBottom={15}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>

      </div>
    );
  }
}

ResidualsCoefficientsLarge.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    modelevaluation: state.modelevaluation
  };
})(withStyles(styles)(ResidualsCoefficientsLarge));
