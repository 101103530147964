let defaultState = {
  current_models: [],
  current_models_loading: true,
  current_selected_model_id: "",
  current_models_selected_model_rows: [],
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_CURRENT_MODELS":
      return {
        ...state,
        current_models: action.data.current_models,
        current_models_loading: action.data.current_models_loading
      };
    case "UPDATE_CURRENT_SELECTED_MODEL":
      return {
        ...state,
        current_selected_model_id: action.data.current_selected_model_id,
        current_models_selected_model_rows: action.data.current_models_selected_model_rows,
      };
    case "RESET_ALL_CURRENT_MODELS":
      return {
        ...state,
        current_selected_model_id: "",
        current_models_selected_model_rows: [],
      };
    default:
      return state;
  }
}

export default views;
