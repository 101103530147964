import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "react-dropzone-component/styles/filepicker.css";
import "./FileUpload.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./handsontable.full.css";
import TextField from "@material-ui/core/TextField";
import * as viewActions from "../actions/viewActions";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import { poll } from "./utils";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(4)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    margin: theme.spacing(1),
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});



function DebugSettingsCard(props) {
  const [state, setState] = useState({
    task_input: null,
    task_type: "",
    task_results: "Click button to run task",
    task_status: "",
  });
  const [task_id, setTaskID] = useState("");
  const [loading, setLoading] = useState(false);
  const [delay, setDelay] = useState(0);
  const [success, setSuccess] = React.useState(false);
  const { classes } = props;
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
    [classes.button]: !success
  });

  let checkTaskStatus = (task_id) => {
    let url = `task/get`;
    console.log(`checkTaskStatus - task_id:${task_id}`);
    if (task_id === "") {
      console.error("No task id found");
      return "";
    }
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };
    return props
      .axPost(url, JSON.stringify({ task_id: task_id }), config)
      .then((res) => {
        console.log("task results from API");
        console.log(res);
        return res;
      })
      .catch((res) => {
        console.error(res);
      });
  };

  let handleTaskInputChange = (event) => {
    setState({ ...state, task_input: event.target.value });
  };

  let submitTask = (task_input) => {
    let url = `task/add_dummy_task`;
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };
    setState({...state, task_results: 'Evaluating...'})
    setLoading(true);
    setSuccess(false);
    console.log(JSON.stringify({ data: { task_time: task_input } }));
    props
      .axPost(
        url,
        JSON.stringify({ data: { task_time: Number(task_input) } }),
        config
      )
      .then((res) => {
        setTaskID(res.data.task_id);
        console.log(`task id: ${res.data.task_id}`);
      })
      .catch((res) => {
        console.error(res);
        props.dispatch(
          viewActions.UpdateAlert(
            true,
            "Submit task failed",
            `Failed to create task. If issues persist, please report the issue using the link below.`
          )
        );
        setLoading(false);
        setSuccess(false);
      });
  };



  let handleTaskSubmit = () => {
    let task_input = state.task_input;

    // submit task
    submitTask(task_input);
  };

  // check task status once task_id is returned
  useEffect(() => {
    if (task_id) {
      // check task status
      let interval = 1000; // ms
      poll(
        checkTaskStatus,
        (res) => {
          return res ? (res.data.data.task_status === "finished" || res.data.data.task_status === "failed") : false;
        },
        interval,
        task_id
      )
        .then((result) => {
          let task_status = result.data.data.task_status
          let task_result = result.data.data.task_result
          console.log(`task status = ${task_status}`);
          console.log(`task result = ${task_result}`);
          if(task_status === 'finished'){
            setLoading(false);
            setSuccess(true);
            setState({ ...state, task_results: task_result, task_status: task_status });

          } else if (task_status === 'failed') {
            setLoading(false);
            setSuccess(false);
            setState({ ...state, task_results: 'task failed...', task_status: task_status });
            props.dispatch(
                viewActions.UpdateAlert(
                    true,
                    "Task failed",
                    `The submitted task failed. If issues persist, please report the issue using the link below.`
                )
            );
          }
        })
        .catch((res) => console.error(res));
    }
    // This code will be removed eventually, so the warning below is just disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id]);

  let changeResponseDelay = (event) => {
    setDelay(event.target.value)
  }

  let handleSetResponseDelay = () => {
    props.dispatch(
      viewActions.changeResponseDelay(delay))
  }  
  
  let handleSetRandomResponseDelay = () => {
    let randomDelay = Math.floor((Math.random() * 2000) + 1)
    setDelay(randomDelay)
    props.dispatch(
      viewActions.changeResponseDelay(randomDelay))
  }
  
  

  return (
    <div className={classes.layout}>
      <Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginBottom: 24 }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
        >
          Submit jobs to Redis Queue
        </Typography>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={4}>
            <div className={classes.wrapper}>
            <TextField
              id="task_input"
              label="Task input"
              type="number"
              onChange={handleTaskInputChange}
            />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={buttonClassname}
              disabled={loading}
              onClick={handleTaskSubmit}
              margin={12}
            >
              Submit task
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}</div>
          </Grid>
          <Grid item xs={4}>
            <div/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{marginTop:10}}>{state.task_results}</Typography>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
        >
          Test response delay
        </Typography>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={4}>
            <div className={classes.wrapper}>
              <TextField
                id="task_input"
                label="Response delay (ms)"
                type="number"
                value={delay}
                inputProps={{step: 100}}
                onChange={changeResponseDelay}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={buttonClassname}
                onClick={handleSetResponseDelay}
                margin={12}
              >
                Set delay
              </Button>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={buttonClassname}
                onClick={handleSetRandomResponseDelay}
                margin={12}
              >
                Set Random delay
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels,
  };
})(withStyles(styles)(DebugSettingsCard));
