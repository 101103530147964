import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Plot from 'react-plotly.js';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import {plotlyColorSchemeRGBA} from './utils'

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    flexGrow: 1,
  },
  formControl: {
    margin: 2,
    minWidth: 120,
    flexWrap: "wrap",
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto",
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  plot: {
    width:'90%'
  }
});


function BoxPlot(props) {

  const { classes } = props;
  const [currentSheet, setCurrentSheet] = useState('')
  const [headers, setHeaders] = useState([])
  const [plotData, setPlotData] = useState({})
  const [selectedAxis, setAxis] = useState([])
  const [plotWidth, setPlotWidth] = useState(200)

  const parentRef = useRef(null);

  // get plot width
  useEffect ( () => {
    if(parentRef.current){
      const parentWidth  = parentRef.current.offsetWidth;
      setPlotWidth(parentWidth)
    }
  }, [parentRef]);

  const handleSheetChange = (event) => {
    const selectedSheet = event.target.value;
    if (!props.data[selectedSheet]) return
    const currentHeaders = props.data[selectedSheet].columns
    const currentData = props.data[selectedSheet].data
    setCurrentSheet(selectedSheet)
    setHeaders(currentHeaders)
    const currentPlotData = {}
    // add headers
    currentHeaders.map(header  => currentPlotData[header] = [])
    // add data
    currentData.forEach((row) => {
      row.forEach((value, idx) => {
        currentPlotData[currentHeaders[idx]].push(value)
      })
    })
    setPlotData(currentPlotData)
    setAxis([currentHeaders[0]])
  }

  // assign initial data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSheetChange({target:{value: Object.keys(props.data)[0]}}), [])

  const handleHeaderChange = (event) => {
    const selectedHeader = event.target.value;
    setAxis(selectedHeader)
  }

  // plot
  const getTrace = () => {
    const trace = []
    selectedAxis.forEach((axis, idx) => {
      const x = plotData[axis]
      if (!x) return []
      const newTrace = {
        x: x,
        type: 'box',
        name: axis,
        boxpoints: 'all',
        jitter: 0.3,
        pointpos: 1.3,
        marker:{
          color: plotlyColorSchemeRGBA(idx, 1)
        }
      }
      trace.push(newTrace)
    })
    return trace
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>sheet</InputLabel>
            <Select
              autoWidth
              style={{ textAlign: "left" }}
              value={currentSheet}
              onChange={handleSheetChange}
            >
              {Object.keys(props.data).map(sheet => (
                <MenuItem key={sheet} value={sheet}>
                  {sheet}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>headers</InputLabel>
            <Select
              autoWidth
              style={{ textAlign: "left" }}
              value={selectedAxis}
              input={<Input />}
              onChange={handleHeaderChange}
              renderValue={(selected) => selected.join(', ')}
              multiple
            >
              {headers.map(header => (
                <MenuItem key={header} value={header}>
                  <Checkbox checked={selectedAxis.indexOf(header) > -1} />
                  <ListItemText primary={header} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              props.handleRemovePlot(props.idx)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <div className={classes.plot} ref={parentRef}>
        <Plot
          data={getTrace()}
          layout={{width:plotWidth, title: "Box plot of " + selectedAxis }}
        />
      </div>
    </>
  );
}

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels,
  };
})(withStyles(styles)(BoxPlot));