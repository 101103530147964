import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DDDScatterPlot from './DDDScatterPlot';

//Dev
import * as threedActions from '../actions/threedvizActions';

const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            // width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        flexGrow: 1,
    },
    formControl: {
        margin: 0,
        minWidth: 120,
        flexWrap: 'wrap',
        // underline: 'red',

    },
    paperBody:
        {
            marginTop: 'auto',
        },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    canvas: {
        outline: 'gray 0.5px solid'
    }
});


class DDDVisualization extends Component {
    constructor(props) {
        super(props);
        this.state = {xaxis:'', yaxis:'', zaxis:'', coloraxis:''};
    }

    componentDidMount() {

    }

    shouldComponentUpdate(newProps) {
        if (newProps.views.currentView !== 'data3d') {
            return false;
        }
        //additional logic here would be good
        return true;
    }

    columnChanged(column) {
        let newColumns = JSON.parse(JSON.stringify(this.props.threedviz._3d_selected_columns));
        if (newColumns.indexOf(column) > -1) {
            let index = newColumns.indexOf(column);
            newColumns.splice(index, 1);
        } else {
            if (newColumns.length === 3) {
                newColumns.splice(0, 1);
            }
            newColumns.push(column);
        }
        let newData = [];
        this.props.modeldata.plottable_data.forEach(function (el) {
            let newPt = {
                x: newColumns.length > 0 ? el[newColumns[0]] : 0,
                y: newColumns.length > 1 ? el[newColumns[1]] : 0,
                z: newColumns.length > 2 ? el[newColumns[2]] : 0
            };
            newData.push(newPt);
        });
        let ranges = {}

        if (newColumns.length > 0) {
            let min = Math.min(...newData.map(x => x.x));
            let max = Math.max(...newData.map(x => x.x));
            let range = this.heckbertNiceNum(max - min, false);
            let step = this.heckbertNiceNum(range / 5, true);
            // fix the display precision
            let updatedMin = Number((Math.floor(min / step) * step).toPrecision(2));
            let updatedMax = Number((Math.floor(max / step) * step).toPrecision(2));
            ranges[newColumns[0]] = {min: updatedMin, max: updatedMax, step: step, range: updatedMax - updatedMin};
        }
        if (newColumns.length > 1) {
            let min = Math.min(...newData.map(x => x.y));
            let max = Math.max(...newData.map(x => x.y));
            let range = this.heckbertNiceNum(max - min, false);
            let step = this.heckbertNiceNum(range / 5, true);
            let updatedMin = Number((Math.floor(min / step) * step).toPrecision(2));
            let updatedMax = Number((Math.floor(max / step) * step).toPrecision(2));
            ranges[newColumns[1]] = {min: updatedMin, max: updatedMax, step: step, range: updatedMax - updatedMin};
        }
        if (newColumns.length > 2) {
            let min = Math.min(...newData.map(x => x.z));
            let max = Math.max(...newData.map(x => x.z));
            let range = this.heckbertNiceNum(max - min, false);
            let step = this.heckbertNiceNum(range / 5, true);
            let updatedMin = Number((Math.floor(min / step) * step).toPrecision(2));
            let updatedMax = Number((Math.floor(max / step) * step).toPrecision(2));
            ranges[newColumns[2]] = {min: updatedMin, max: updatedMax, step: step, range: updatedMax - updatedMin};
        }

        this.props.dispatch(threedActions.UpdateSelectedColumns(newColumns, newData, ranges));
    }

    columnSelected(column, axis) {
        // collect columns to be plotted
        let plotColumns = [this.state.xaxis, this.state.yaxis, this.state.zaxis]
        let colorAxis = this.state.coloraxis
        if (axis === 'X') {
            this.setState({...this.state, xaxis: column})
            plotColumns[0] = column;
        }
        if (axis === 'Y') {
            this.setState({...this.state, yaxis: column})
            plotColumns[1] = column;
        }
        if (axis === 'Z') {
            this.setState({...this.state, zaxis: column})
            plotColumns[2] = column;
        }
        if (axis === 'Color') {
            this.setState({...this.state, coloraxis: column})
            colorAxis = column;
        }


        let newData = [];
        this.props.modeldata.plottable_data.forEach(function (el) {
            let newPt = {
                x: plotColumns[0] ? el[plotColumns[0]] : 0,
                y: plotColumns[1] ? el[plotColumns[1]] : 0,
                z: plotColumns[2] ? el[plotColumns[2]] : 0,
                color: colorAxis ? el[colorAxis] : 0,
            };
            newData.push(newPt);
        });

        // scale color column to [0, 1]
        newData = this.scaleColorData(newData)

        // compute ranges
        let ranges = {}

        if (plotColumns[0]) {
            let min = Math.min(...newData.map(x => x.x));
            let max = Math.max(...newData.map(x => x.x));
            let range = this.heckbertNiceNum(max - min, false);
            let step = this.heckbertNiceNum(range / 5, true);
            // fix the display precision
            let updatedMin = Number((Math.floor(min / step) * step).toPrecision(2));
            let updatedMax = Number((Math.ceil(max / step) * step).toPrecision(2));
            let diff = Number((updatedMax - updatedMin).toPrecision(2))
            ranges[plotColumns[0]] = {min: updatedMin, max: updatedMax, step: step, range: diff};
        }
        if (plotColumns[1]) {
            let min = Math.min(...newData.map(x => x.y));
            let max = Math.max(...newData.map(x => x.y));
            let range = this.heckbertNiceNum(max - min, false);
            let step = this.heckbertNiceNum(range / 5, true);
            // fix the display precision
            let updatedMin = Number((Math.floor(min / step) * step).toPrecision(2));
            let updatedMax = Number((Math.ceil(max / step) * step).toPrecision(2));
            let diff = Number((updatedMax - updatedMin).toPrecision(2))
            ranges[plotColumns[1]] = {min: updatedMin, max: updatedMax, step: step, range: diff};
        }
        if (plotColumns[2]) {
            let min = Math.min(...newData.map(x => x.z));
            let max = Math.max(...newData.map(x => x.z));
            let range = this.heckbertNiceNum(max - min, false);
            let step = this.heckbertNiceNum(range / 5, true);
            // fix the display precision
            let updatedMin = Number((Math.floor(min / step) * step).toPrecision(2));
            let updatedMax = Number((Math.ceil(max / step) * step).toPrecision(2));
            let diff = Number((updatedMax - updatedMin).toPrecision(2))
            ranges[plotColumns[2]] = {min: updatedMin, max: updatedMax, step: step, range: diff};
        }
        // remove empty strings and from newColumns
        // plotColumns = plotColumns.filter(function(el) { return el; });
        this.props.dispatch(threedActions.UpdateSelectedColumns(plotColumns, newData, ranges));
    }

    // Scale data in column to [0, 1]
    scaleColorData = (data) => {
        let colorData = []
        data.forEach(el => {
            colorData.push(el['color'])
        })
        let minData = Math.min(...colorData)
        let maxData = Math.max(...colorData)
        let range = (maxData - minData) * 2
        if (range === 0) data.map(el => el['color'] = 0);
        else data.map(el => el['color'] = (el['color'] - minData) / range);

        return data
    }

    heckbertNiceNum = (x, round) => {
        let e = Math.floor(Math.log10(x));
        let f = x / (Math.pow(10, e));
        let nf = 0;
        if (round) {
            if (f < 1.5) {
                nf = 1
            } else if (f < 3) {
                nf = 2
            } else if (f < 7) {
                nf = 5
            } else {
                nf = 10
            }
        } else {
            if (f <= 1) {
                nf = 1
            } else if (f <= 2) {
                nf = 2
            } else if (f <= 5) {
                nf = 5
            } else {
                nf = 10
            }
        }
        return Number((nf * Math.pow(10, e)).toFixed(8));
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.layout}>
                    <Card className={classes.paperBody} elevation={3}>
                        <Typography variant="h6" gutterBottom
                                    style={{textAlign: 'left', marginTop: 24, marginLeft: 24}}>
                            3D Data Visualization
                        </Typography>

                        <Typography gutterBottom style={{textAlign: 'left', marginLeft: 24, marginBottom: 15}}>
                            Select any three variables below to explore your data in three dimensions.
                        </Typography>
                        <div style={{margin: 24}}>
                            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                                <Grid item xs={12} sm={12} md={3} lg={3} style={{paddingBottom: 24}}>
                                    <Typography variant="button"
                                                style={{marginTop: 8, textAlign: 'left', marginBottom: 16}}>
                                        Columns to Display in Chart
                                    </Typography>
                                    <FormGroup>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="3D-viz-input-label-X">X-axis</InputLabel>
                                            <Select
                                                labelid="3D-viz-input-label-X"
                                                id="3D-viz-select-label-X"
                                                value={this.state.xaxis}
                                                onChange={(event) => this.columnSelected(event.target.value, 'X')}
                                                data-cy="select_3d_x"
                                            >
                                                {this.props.modeldata.data_columns.map((col, index) => (
                                                    <MenuItem key={index} value={col.col}>{col.col}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>

                                    <FormGroup>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="3D-viz-input-label-Y">Y-axis</InputLabel>
                                            <Select
                                                labelid="3D-viz-select-label-Y"
                                                id="3D-viz-select-label-Y"
                                                value={this.state.yaxis}
                                                onChange={(event) => this.columnSelected(event.target.value, 'Y')}
                                                data-cy="select_3d_y"
                                            >
                                                {this.props.modeldata.data_columns.map((col, index) => (
                                                    <MenuItem key={index} value={col.col}>{col.col}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>

                                    <FormGroup>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="3D-viz-input-label-Z">Z-axis</InputLabel>
                                            <Select
                                                labelid="3D-viz-input-label-Z"
                                                id="3D-viz-select-label-Z"
                                                value={this.state.zaxis}
                                                onChange={(event) => this.columnSelected(event.target.value, 'Z')}
                                                data-cy="select_3d_z"
                                            >
                                                {this.props.modeldata.data_columns.map((col, index) => (
                                                    <MenuItem key={index} value={col.col}>{col.col}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>

                                    <FormGroup>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="3D-viz-input-label-Z">Color</InputLabel>
                                            <Select
                                                labelid="3D-viz-input-label-Color"
                                                id="3D-viz-select-label-Color"
                                                value={this.state.coloraxis}
                                                onChange={(event) => this.columnSelected(event.target.value, 'Color')}
                                                data-cy="select_3d_color"
                                            >
                                                {this.props.modeldata.data_columns.map((col, index) => (
                                                    <MenuItem key={index} value={col.col}>{col.col}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Typography variant="body2" align="left">Red indicates smaller value while cyan indicates larger value</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9} style={{paddingBottom: 24, paddingRight: 24}}>
                                    <div id="dddscatterdiv">
                                        <DDDScatterPlot
                                            appendDiv={'dddscatterdiv'}
                                            currentView={this.props.views.currentView}
                                            chartData={this.props.threedviz._3d_data_points}
                                            chartType={'data3d'}
                                            columnLabels={this.props.threedviz._3d_selected_columns}
                                            ranges={this.props.threedviz._3d_data_ranges}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

DDDVisualization.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default connect((state, props) => {
    return {
        views: state.views,
        modeldata: state.modeldata,
        threedviz: state.threedviz,
    }

})(withStyles(styles)(DDDVisualization));
