import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";

const cookies = new Cookies();
const baseURL = process.env.REACT_APP_BASE_AICHEMY_URL;
const ax = axios.create({ baseURL: baseURL });
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
// https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/

ax.interceptors.request.use(
    async config => {
        if ((new URL(process.env.REACT_APP_BASE_AICHEMY_URL).host).includes("localhost")) {
            const token = cookies.get('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
        } else {
            const token = cookies.get('token');
            if (token) {
                config.headers.authorizationToken = `Bearer ${token}`
            }
        }


        return config
    },
    error => {
        console.error(error);
        return Promise.reject(error)
    }
);

ax.interceptors.response.use(
    function (response) {
        // If the request succeeds, we don't have to do anything and just return the response
        return response
    },
    function (error) {
        if (isTokenExpiredError(error)) {
            return resetTokenAndReattemptRequest(error)
        }
        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error)
    }
)
function isTokenExpiredError(error) {
    if (error.message === 'Network Error') {
        return true;
    }

    return false;
}


async function resetTokenAndReattemptRequest(error) {
    try {

        var { response: errorResponse } = error;
        if (errorResponse === undefined) {
            errorResponse = error;
        }
        //   const resetToken = true;// await TokenUtils.getResetToken(); // Your own mechanism to get the refresh token to refresh the JWT token
        //   if (!resetToken) {
        //     // We can't refresh, throw the error anyway
        //     return Promise.reject(error);
        //   }
        /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
        const retryOriginalRequest = new Promise(resolve => {
            /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
            addSubscriber(access_token => {
                errorResponse.config.headers.authorizationtoken = 'Bearer ' + access_token;
                resolve(axios(errorResponse.config));
            });

        });
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            // const response = await axios({
            //   method: 'post',
            //   url: `<YOUR TOKEN REFREH ENDPOINT>`,
            //   data: {
            //     token: resetToken // Just an example, your case may vary
            //   }
            // });
            // if (!response.data) {
            //   return Promise.reject(error);
            // }
            // const newToken = response.data.token;
            // TokenUtils.saveRefreshToken(newToken); // save the newly refreshed token for other requests to use

            const newToken = cookies.get('token');
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(newToken);
        }
        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}

function onAccessTokenFetched(access_token) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach(callback => callback(access_token));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

export const useTokenLoader = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getToken = async () => {
        const token = await getAccessTokenSilently();
        var decoded = jwt_decode(token);

        cookies.set('token', token, { path: '/' });
        cookies.set('graph_token', decoded['https://azuread#ad_access_token'], { path: '/' })
    }

    useEffect(() => {
        getToken();

    });

    return;
}

export async function axGet(endPoint) {
    return await ax.get(endPoint)
        .then(res => res.data)
        .catch(error => {
            return [];
        })
}

export async function axPost(endPoint, data, config) {
    return await ax.post(endPoint, data, config);
}

export function formatAIChemyModelList(models) {
    var return_models = [];
    models.forEach(function (model, index) {
        const time = model['creation_time'].split(' ')[0];
        model['creation_time'] = time;
        model['post_id'] = model['id'];
        model['id'] = model['train_id'];
        return_models.push(model);
    });
    return return_models;
}
