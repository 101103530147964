let defaultState = {
    prediction_input_values: [],
};

function views(state = defaultState, action) {
    switch (action.type) {
        case ('UPDATE_MODEL_PREDICTION_VALUES'):
            return {
                ...state,
                prediction_input_values: action.data.prediction_input_values,
            }
        case ('RESET_ALL'):
            return {
                ...state,
                prediction_input_values: [],
            }
        default:
            return state;
    }
}

export default views;