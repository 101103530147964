import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import SaveSVGasPNG from 'save-svg-as-png';
import JSZip from 'jszip';
import fs from 'file-saver';

const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            // width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        flexGrow: 1,
    },
    formControl: {
        margin: 0,
        minWidth: 120,
        flexWrap: 'wrap',
        // underline: 'red',

    },
    paperBody:
    {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    input: {
        display: 'none',
    },
    palette: {
        primary: {
            light: '#cccccc',
            main: '#ffffff',
            dark: '#cccccc',
        },
        secondary: {
            light: '#4dabf5',
            main: '#2196f3',
            dark: '#1769aa',
        },

    },
    button: {
        margin: theme.spacing(1),
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.25),
    },
});


class SurfaceChartSpeedDial extends Component {

    constructor(props) {
        super(props);
        this.shouldUpdate = true;
    }

    state = {
        open: false,
        hidden: false,
    };

    handleVisibility = () => {
        //this.shouldUpdate = true;
        this.setState(state => ({
            open: false,
            hidden: !state.hidden,
        }));
    };

    handleClick = () => {
        this.shouldUpdate = true;
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleOpen = () => {
        if (!this.state.hidden) {
            this.setState({
                open: true,
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    downloadExcel = () => {
        if (!this.state.hidden) {
            this.props.fileDownload(this.props.downloadSettings, this.props.samples, this.props.tests, this.props.filename);
        }
    };

    downloadPNG = () => {
        if (!this.state.hidden) {
            SaveSVGasPNG.saveSvgAsPng(document.getElementById(this.props.chartID), this.props.chartTitle + ".png", { scale: 1, backgroundColor: 'white' });
        }
    };

    downloadAllPNGs = () => {
        let baseName = this.props.chartID;
        let selX = this.props.selectedX;
        let selY = this.props.selectedY;
        let data = this.props.setPoints;
        let allChartIDs = this.props.cols.map(x => {return baseName + x + '_SVG' })
        let allSetPointIDs = Object.keys(this.props.setPoints).filter(x => {return x!==selX && x!==selY});
        let dataString = "Surface Plot Set Points\n";
        allSetPointIDs.forEach(el => {
            dataString += el + ',' + data[el] + '\n';
        });
        
        //props.dispatch(menuActions.changeLoadingState(true));
        var zip = new JSZip();
        allChartIDs.forEach(id => {
            var buf = SaveSVGasPNG.svgAsPngUri(document.getElementById(id), { scale: 1, backgroundColor: 'white' }, function (dataURI) {
                    }).then(function (el) {
                        var data = el.replace(/^data:image\/\w+;base64,/, "");
                        var buf = new Buffer(data, 'base64');
                        return buf;
                    });
                    zip.file(id.replace(baseName, '').replace('_SVG', '') + ".png", buf);
        });
        zip.file('SurfaceSetPoints.csv', dataString)

        zip.generateAsync({ type: "blob" })
            .then(function (blob) {
                //updateLoading();
                fs.saveAs(blob, "Model_Surface_Chart(s).zip");
            });
    };

    actions = [
        // { icon: <FileCopyIcon />, name: 'Save Excel', value: 'excel', action: this.downloadExcel },
        { icon: <SaveIcon />, name: 'Save Charts PNG', value: 'png', action: this.downloadAllPNGs },
    ];


    shouldComponentUpdate() {
        if (this.shouldUpdate) {
            this.shouldUpdate = false;
            return true;
        }
        return false;
    }

    render() {

        const { classes } = this.props;
        const { hidden, open } = this.state;
        this.shouldUpdate = false;
        return (
            <SpeedDial className={classes.SpeedDial} style={{ float: 'right', marginBottom: this.props.marginBottom, height:this.props.height !== undefined ? this.props.height : 128 }}
                ariaLabel="SpeedDial example"
                direction={this.props.direction}
                color='secondary'
                hidden={hidden}
                icon={<SpeedDialIcon />}
                onClick={this.handleClick}
                onClose={this.handleClose}
                open={open}
            >
                {this.actions.map(act => (
                    <SpeedDialAction
                        key={act.value}
                        id={act.value}
                        onClick={act.action}
                        icon={act.icon}
                        tooltipTitle={act.name}
                        tooltipOpen

                    />
                ))}
            </SpeedDial>
        );
    }
}
export default withStyles(styles)(SurfaceChartSpeedDial);
