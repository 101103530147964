let defaultState = {
  prediction_contour_input_values: [],
  prediction_contour_output_values: [],
  selected_model_name: "",
  prediction_contour_display_columns: [],
  prediction_contour_selected_x: "",
  prediction_contour_selected_y: "",
  prediction_contour_data: {},
  prediction_contour_output_thresholds: {},
  prediction_contour_output_threshold_signs: {}
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_PREDICTION_CONTOUR_AXES":
      return {
        ...state,
        prediction_contour_selected_x: action.data.prediction_contour_selected_x,
        prediction_contour_selected_y: action.data.prediction_contour_selected_y
      };
    case "UPDATE_PREDICTION_CONTOUR_X_AXIS":
      return {
        ...state,
        prediction_contour_selected_x: action.data.prediction_contour_selected_x
      };
    case "UPDATE_PREDICTION_CONTOUR_Y_AXIS":
      return {
        ...state,
        prediction_contour_selected_y: action.data.prediction_contour_selected_y
      };
    case "UPDATE_PREDICTION_CONTOUR_DATA":
      return {
        ...state,
        prediction_contour_data: action.data.prediction_contour_data
      };
    case "UPDATE_PREDICTION_CONTOUR_THRESHOLDS":
      return {
        ...state,
        prediction_contour_output_thresholds: action.data.prediction_contour_output_thresholds
      };
    case "UPDATE_PREDICTION_CONTOUR_THRESHOLD_SIGNS":
      return {
        ...state,
        prediction_contour_output_threshold_signs: action.data.prediction_contour_output_threshold_signs
      };
    case "RESET_ALL":
      return {
        ...state,
        prediction_contour_input_values: [],
        prediction_contour_output_values: [],
        selected_model_name: "",
        prediction_contour_display_columns: [],
        prediction_contour_selected_x: "",
        prediction_contour_selected_y: "",
        prediction_contour_data: {},
        prediction_contour_output_thresholds: {},
        prediction_contour_output_threshold_signs: {}
      };
    default:
      return state;
  }
}

export default views;
