import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "react-dropzone-component/styles/filepicker.css";
import "./FileUpload.css";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { HotTable } from "@handsontable/react";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  CalculateContourKRR,
  CalculateContourNN,
  CalculateContourPLS,
  CalculateContourSVR,
  CalculateKRRPred,
  CalculateNNPred,
  CalculatePLSRes,
  CalculateSVRPred,
} from "./ModelCalculationCode";
import * as DataDistHelperFunctions from "./DataDistributionHelperFunctions";
import "./handsontable.full.css";
import EditData from './EditData.js'

//Dev
import * as viewActions from "../actions/viewActions";
import * as newModelActions from "../actions/newmodelActions";
import * as modelDataActions from "../actions/modeldataActions";
import * as evaluationActions from "../actions/modelevaluationActions";
import * as usageActions from "../actions/modelusageActions";
import * as predictionActions from "../actions/modelPredictionActions";
import * as predictionInputActions from "../actions/modelpredictioninputsActions";
import * as optimizationActions from "../actions/modeloptimizationActions";
import * as distributionActions from "../actions/datadistributionActions";
import * as predictionContourActions from "../actions/modelPredictionContourActions";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import {formatAIChemyModelList} from "../api/alchemyAxios";
import * as currentModelActions from "../actions/currentmodelsActions";
import * as actions from "../actions/viewActions";
import {poll} from "./utils";

const MuiTooltipTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14 pt",
      },
    },
  },
});

const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    flexGrow: 1,
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap",
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto",
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  plscheck: {
    "&$checked": {
      color: "#0087ec",
    },
  },
  nncheck: {
    "&$checked": {
      color: "#4caf50",
    },
  },
  krrcheck: {
    "&$checked": {
      color: "#9c27b0",
    },
  },
  svrcheck: {
    "&$checked": {
      color: "#e91e63",
    },
  },
  hpocheck: {
    "&$checked": {
      color: "#00695c",
    },
  },
  checked: {},
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    margin: theme.spacing(1),
    color: green[500],
    position: "absolute",
    top: "100%",
    left: "100%",
    marginTop: 12,
    marginLeft: -90,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  htRoot: { // for handsontable (hot) overrides
    '& td.outputCell': {
      backgroundColor: "#dcedc8",
    },
    '& td.inputCell': {
      backgroundColor: "#e1f5fe",
    },
  },
});

class NewModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_results: null,
      task_status: "",
      task_id: "",
      loading: false,
      success: false,
      save_after_train: false,
      showEditDataButton: false
    };
  }

  componentDidMount() {
    if (this.props.token.token) {
      this.loadFileFromToken(this.props.token, true);
    }
  }

  //UNIT TEST NEEDED
  // this piece of code is duplicated in other files: LoadModel, ModelEvalulation, NewModel!!!
  updateTitlesAndLegends = (key, slope, rValue, modelName) => {
    let residualsChartTitle =
      "R-squared Value: " +
      (rValue * rValue).toFixed(2) +
      " | Slope: " +
      slope.toFixed(2);
    let residualsLegend =
      "Scatter plot displaying observed (experimental Y value) vs predicted (model predicted Y value) generated by'" +
      modelName +
      "' model. Observed and predicted values, and their source data row can be seen by hovering over individual data points.";
    let coefficientsChartTitle =
      "Relative importance (weight) of each input X on output Y";
    let coefficientsLegend =
      "Bar chart displaying the magnitude of effect (coefficients) for input variables. Positive or negative magnitude shows whether the input X are positively or negatively correlated with the output Y.";
    this.props.dispatch(
      evaluationActions.UpdateTitlesAndLegends(
        residualsChartTitle,
        residualsLegend,
        coefficientsChartTitle,
        coefficientsLegend
      )
    );
  };

  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "newmodel") {
      return false;
    }
    //additional logic here would be good
    return true;
  }

  componentDidUpdate() {
    let aht = this.refs.allheaderstable;
    if (aht !== undefined) {
      aht.hotInstance.render();
    }
    let iht = this.refs.inputheaderstable;
    if (iht !== undefined) {
      iht.hotInstance.render();
    }
    let oht = this.refs.outputheaderstable;
    if (oht !== undefined) {
      oht.hotInstance.render();
    }
    let dst = this.refs.datasnapshottable;
    if (dst !== undefined) {
      dst.hotInstance.render();
    }

    // Check task status
    if (this.state.task_id) {
      // check task status
      let interval = 1000; // ms
      poll(
        this.checkTaskStatus,
        (res) => {
          return res
            ? res.data.data.task_status === "finished" ||
                res.data.data.task_status === "failed"
            : false;
        },
        interval,
        this.state.task_id
      )
        .then((result) => {
          let task_status = result.data.data.task_status;
          if (task_status === "finished") {
            let res = result.data.data.task_result[0];
            this.setState({
              ...this.state,
              loading: false,
              success: true,
              task_results: res,
              task_status: task_status,
            });
            // this.props.dispatch(viewActions.changeLoadingState(false));
            let modelData = JSON.parse(res.ModelData);
            let userCanShare = false;
            if (
                modelData.Permission === "Owner" ||
                (modelData.Visbility === "private" &&
                    modelData.Permission === "Share")
            ) {
              userCanShare = true;
            }
            let userCanEdit = false;
            if (
                userCanShare ||
                (modelData.Visibility === "private" &&
                    modelData.Permission === "Write")
            ) {
              userCanEdit = true;
            }
            this.props.dispatch(
                modelDataActions.UpdateModelAccessSettings(
                    modelData.Visibility,
                    modelData.Permission,
                    userCanShare,
                    userCanEdit
                )
            );
            this.props.dispatch(
                viewActions.UpdateCurrentlySharedWith(modelData.SharedWith)
            );
            this.props.dispatch(
                viewActions.UpdateModifiedSharedWith(modelData.SharedWith)
            );
            this.props.dispatch(
                viewActions.UpdateSaveModelVisibility(true)
            );
            let dataRanges = modelData.DataRanges;
            let outputDataRanges = modelData.OutputDataRanges;
            outputDataRanges.forEach(function (el) {
              el.col = el.column;
            });
            let predictions = modelData.InitialInputs;
            let dataColumns = modelData.DataColumns;
            let displayColumns = Object.assign([], dataColumns);
            displayColumns.forEach(function (col) {
              col.display =
                  col.type === "output" && col.categorical === false;
              col.hide = false;
            });
            if (displayColumns.filter((x) => x.display).length === 1) {
              let modInputCols = displayColumns.filter(
                  (x) => x.type === "input" && x.categorical === false
              );
              if (modInputCols.length > 0) {
                modInputCols[0].display = true;
              }
            }
            this.props.dispatch(
                predictionActions.UpdatePredictionDisplayColumns(
                    displayColumns,
                    ""
                )
            );
            let displayColumnsOpt = JSON.parse(
                JSON.stringify(displayColumns)
            );
            displayColumnsOpt.forEach(function (col) {
              col.display = col.categorical === false;
            });
            let nonCategoricalDisplayCols = displayColumns.filter((el) => {
              return !el.categorical && el.type !== "output";
            });
            let contour_x = "";
            let contour_y = "";
            if (nonCategoricalDisplayCols.length === 1) {
              contour_x = nonCategoricalDisplayCols[0].col;
              contour_y = nonCategoricalDisplayCols[0].col;
            }
            if (nonCategoricalDisplayCols.length > 1) {
              contour_x = nonCategoricalDisplayCols[0].col;
              contour_y = nonCategoricalDisplayCols[1].col;
            }
            this.props.dispatch(
                predictionContourActions.UpdateModelPredictionSelectedAxes(
                    contour_x,
                    contour_y
                )
            );
            this.props.dispatch(
                optimizationActions.UpdateOptimizationDisplayColumns(
                    displayColumnsOpt,
                    ""
                )
            );
            dataRanges.forEach(function (range) {
              range.usermin = range.min;
              range.usermax = range.max;
              range.userval = range.min;
            });
            let optimizationSettings = [];
            let predictionContourThresholds = {};
            let predictionContourSigns = {};

            // setting opt params. Need to be the same with LoadModel.js
            dataColumns
                .filter(function (el) {
                  return el.categorical === false && el.type === "output";
                })
                .forEach(function (el) {
                  let colName = el.col;
                  let colData = modelData.PlottableData.map(
                      (el) => el[colName]
                  );
                  let min_data = Math.min(...colData)
                  let max_data = Math.max(...colData)
                  let mean_data = 0.5 * (min_data + max_data)
                  let data_range = max_data - min_data;
                  let newOptSettings = {
                    column: el.col,
                    opttype: "target",
                    mintargetvalue: parseFloat(
                        (mean_data - 0.1 * data_range).toPrecision(5)
                    ),
                    maxtargetvalue: parseFloat(
                        (mean_data + 0.1 * data_range).toPrecision(5)
                    ),
                    minvalue: min_data,
                    maxvalue: max_data,
                    use: true,
                  };
                  optimizationSettings.push(newOptSettings);
                  predictionContourThresholds[el.col] =
                      (newOptSettings.maxvalue - newOptSettings.minvalue) / 2 +
                      newOptSettings.minvalue;
                  predictionContourSigns[el.col] = ">";
                });
            console.log(optimizationSettings)
            this.props.dispatch(
                optimizationActions.UpdateOptimizationTargetSettings(
                    optimizationSettings
                )
            );
            this.props.dispatch(
                predictionContourActions.UpdateModelPredictionContourThresholds(
                    predictionContourThresholds
                )
            );
            this.props.dispatch(
                predictionContourActions.UpdateModelPredictionContourThresholdSigns(
                    predictionContourSigns
                )
            );
            let stabilityVariations = dataColumns
                .filter((el) => {
                  return el.categorical === false && el.type === "input";
                })
                .map((el) => ({column: el.col, percent: 10}));
            this.props.dispatch(
                optimizationActions.UpdateOptimizationStabilityVariations(
                    stabilityVariations
                )
            );
            let categoricalVariables = modelData.Categoricals;
            this.props.dispatch(
                modelDataActions.UpdateModelData(
                    modelData.models,
                    modelData.PCA,
                    modelData.LinearRegression,
                    dataRanges,
                    categoricalVariables,
                    true,
                    dataColumns,
                    modelData.PlottableData,
                    outputDataRanges,
                    false,
                    modelData.id
                )
            );
            this.props.dispatch(
                usageActions.UpdateModelUsageData(
                    dataRanges,
                    categoricalVariables
                )
            );
            this.props.dispatch(
                predictionInputActions.UpdateModelPredictionInputValues(
                    predictions
                )
            );
            let inputColNames = dataColumns
                .filter(function (el) {
                  return el.type === "input" && el.categorical === false;
                })
                .map((col) => {
                  return col.col;
                });
            let outputColNames = dataColumns
                .filter(function (el) {
                  return el.type === "output" && el.categorical === false;
                })
                .map((col) => {
                  return col.col;
                });
            let dataDistributionSourceData = DataDistHelperFunctions.OrganizePairwiseData(
                modelData.PlottableData,
                inputColNames,
                outputColNames,
                modelData.LinearRegression["pairwise_regression"],
                dataRanges.concat(outputDataRanges)
            );
            this.props.dispatch(
                distributionActions.UpdateDistributionSourceData(
                    dataDistributionSourceData
                )
            );
            this.props.dispatch(
                distributionActions.UpdateSelectedInputColumns(
                    inputColNames.length > 8
                        ? inputColNames.slice(0, 8)
                        : inputColNames
                )
            );
            this.props.dispatch(
                distributionActions.UpdateSelectedOutputColumns(
                    outputColNames.length > 8
                        ? outputColNames.slice(0, 8)
                        : outputColNames
                )
            );
            let currentModel = modelData.models[0];
            let currentOutputKeys = Object.keys(currentModel.residuals);
            let selectedOutputKey = currentOutputKeys[0];
            let currentResiduals =
                currentModel.residuals[selectedOutputKey];
            let selectedResidualsRValue = currentResiduals.R_Value;
            let selectedResidualsSlope = currentResiduals.Slope;
            let currentCoefficients = [];
            if (currentModel.type === "PLS") {
              currentCoefficients =
                  currentModel.relativeImportances[selectedOutputKey];
              let result = CalculatePLSRes(
                  modelData.InitialInputs,
                  currentModel.x_mean_,
                  currentModel.y_mean_,
                  currentModel.x_std_,
                  currentModel.coef_,
                  currentModel.inputOrganization,
                  currentModel.outputOrganization,
                  dataRanges,
                  modelData.Categoricals,
                  this.props.modelusage.settings_all_constraints
              );
              //need prediction text and plottable data
              let newPredText =
                  "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
                  displayColumns
                      .filter(function (el) {
                        return el.display;
                      })
                      .map((x) => x.col)
                      .join(", ") +
                  ". The prediction was generated using the '" +
                  currentModel.name +
                  "' model and inputs/outputs are reflected in the table to the right.";
              let predictionPlotData = [];
              predictionPlotData = predictionPlotData.concat(
                  modelData.PlottableData
              );
              predictionPlotData.push(result.completePredObject);
              this.props.dispatch(
                  predictionActions.UpdateModelPredictionOutputValues(
                      result.outputValues,
                      result.inputValues,
                      result.tableData,
                      predictionPlotData,
                      newPredText,
                      result.violatedConstraints
                  )
              );
              currentCoefficients =
                  currentModel.relativeImportances[selectedOutputKey];
              let colDict = CalculateContourPLS(
                  contour_x,
                  contour_y,
                  modelData.InitialInputs,
                  currentModel,
                  this.props.modelusage.settings_all_constraints,
                  modelData.Categoricals,
                  dataRanges
              );
              this.props.dispatch(
                  predictionContourActions.UpdateModelPredictionContourData(
                      colDict
                  )
              );
            }
            if (currentModel.type === "NN") {
              let results = CalculateNNPred(
                  modelData.InitialInputs,
                  currentModel.outputOrganization.length,
                  currentModel.coefficients,
                  currentModel.intercepts,
                  1,
                  currentModel.inputOrganization,
                  currentModel.outputOrganization,
                  modelData.Categoricals,
                  currentModel.dataMin,
                  currentModel.dataRange,
                  this.props.modelusage.settings_all_constraints
              );
              let predictionPlotData = [];
              predictionPlotData = predictionPlotData.concat(
                  modelData.PlottableData
              );
              predictionPlotData.push(results.completePredObject);
              let newPredText =
                  "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (green line) within the following dimensions: " +
                  displayColumns
                      .filter(function (el) {
                        return el.display;
                      })
                      .map((x) => x.col)
                      .join(", ") +
                  ". The prediction was generated using the '" +
                  currentModel.name +
                  "' model and inputs/outputs are reflected in the table to the right.";
              this.props.dispatch(
                  predictionActions.UpdateModelPredictionOutputValues(
                      results.outputValues,
                      results.inputValues,
                      results.tableData,
                      predictionPlotData,
                      newPredText,
                      results.violatedConstraints
                  )
              );
              currentCoefficients =
                  currentModel.relativeImportances[selectedOutputKey];
              let colDict = CalculateContourNN(
                  contour_x,
                  contour_y,
                  modelData.InitialInputs,
                  currentModel,
                  this.props.modelusage.settings_all_constraints,
                  modelData.Categoricals,
                  dataRanges
              );
              this.props.dispatch(
                  predictionContourActions.UpdateModelPredictionContourData(
                      colDict
                  )
              );
            }
            if (currentModel.type === "KRR") {
              let results = CalculateKRRPred(
                  modelData.InitialInputs,
                  currentModel.outputOrganization.length,
                  currentModel.dual_coef,
                  currentModel.x_fit,
                  currentModel.gamma,
                  currentModel.inputOrganization,
                  currentModel.outputOrganization,
                  modelData.Categoricals,
                  currentModel.dataMin,
                  currentModel.dataRange,
                  this.props.modelusage.settings_all_constraints
              );
              let predictionPlotData = [];
              predictionPlotData = predictionPlotData.concat(
                  modelData.PlottableData
              );
              predictionPlotData.push(results.completePredObject);
              let newPredText =
                  "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (purple line) within the following dimensions: " +
                  displayColumns
                      .filter(function (el) {
                        return el.display;
                      })
                      .map((x) => x.col)
                      .join(", ") +
                  ". The prediction was generated using the '" +
                  currentModel.name +
                  "' model and inputs/outputs are reflected in the table to the right.";
              this.props.dispatch(
                  predictionActions.UpdateModelPredictionOutputValues(
                      results.outputValues,
                      results.inputValues,
                      results.tableData,
                      predictionPlotData,
                      newPredText,
                      results.violatedConstraints
                  )
              );
              currentCoefficients =
                  currentModel.relativeImportances[selectedOutputKey];
              let colDict = CalculateContourKRR(
                  contour_x,
                  contour_y,
                  modelData.InitialInputs,
                  currentModel,
                  this.props.modelusage.settings_all_constraints,
                  modelData.Categoricals,
                  dataRanges
              );
              this.props.dispatch(
                  predictionContourActions.UpdateModelPredictionContourData(
                      colDict
                  )
              );
            }
            if (currentModel.type === "SVR") {
              let results = CalculateSVRPred(
                  modelData.InitialInputs,
                  currentModel.outputOrganization.length,
                  currentModel.support_vectors_,
                  currentModel.dual_coef_,
                  currentModel.intercept_,
                  currentModel._gamma,
                  currentModel.inputOrganization,
                  currentModel.outputOrganization,
                  modelData.Categoricals,
                  currentModel.dataMin,
                  currentModel.dataRange,
                  this.props.modelusage.settings_all_constraints
              );
              let predictionPlotData = [];
              predictionPlotData = predictionPlotData.concat(
                  modelData.PlottableData
              );
              predictionPlotData.push(results.completePredObject);
              let newPredText =
                  "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (pink line) within the following dimensions: " +
                  displayColumns
                      .filter(function (el) {
                        return el.display;
                      })
                      .map((x) => x.col)
                      .join(", ") +
                  ". The prediction was generated using the '" +
                  currentModel.name +
                  "' model and inputs/outputs are reflected in the table to the right.";
              this.props.dispatch(
                  predictionActions.UpdateModelPredictionOutputValues(
                      results.outputValues,
                      results.inputValues,
                      results.tableData,
                      predictionPlotData,
                      newPredText,
                      results.violatedConstraints
                  )
              );
              currentCoefficients =
                  currentModel.relativeImportances[selectedOutputKey];
              let colDict = CalculateContourSVR(
                  contour_x,
                  contour_y,
                  modelData.InitialInputs,
                  currentModel,
                  this.props.modelusage.settings_all_constraints,
                  modelData.Categoricals,
                  dataRanges
              );
              this.props.dispatch(
                  predictionContourActions.UpdateModelPredictionContourData(
                      colDict
                  )
              );
            }
            this.props.dispatch(
                predictionActions.UpdateSelectedModel(currentModel.name)
            );
            this.props.dispatch(
                optimizationActions.UpdateSelectedModel(currentModel.name)
            );
            this.updateTitlesAndLegends(
                selectedOutputKey,
                selectedResidualsSlope,
                selectedResidualsRValue,
                currentModel.name
            );
            this.props.dispatch(
                evaluationActions.UpdateModelData(
                    currentModel.name,
                    currentOutputKeys,
                    selectedOutputKey,
                    currentResiduals,
                    selectedResidualsSlope,
                    selectedResidualsRValue,
                    currentCoefficients
                )
            );
            this.props.dispatch(viewActions.changeViewType("modeleval"));
            this.props.dispatch(viewActions.UpdateModelCanBeSaved(true));
            if(this.state.save_after_train) {
              this.updateModelList()
            }
          } else if (task_status === "failed") {
            this.setState({
              ...this.state,
              task_results: "task failed...",
              task_status: task_status,
              loading: false,
              success: false,
            });
            this.props.dispatch(
              viewActions.UpdateAlert(
                true,
                "Task failed",
                `The submitted task failed. If issues persist, please report the issue using the link below.`
              )
            );
          }
        })
        // fail-safe
        .catch((error) => {
          this.setState({
            ...this.state,
            task_results: "task failed...",
            task_status: "error",
            loading: false,
            success: false,
          });
          console.error(error)
          this.props.dispatch(
            viewActions.UpdateAlert(
              true,
              "Model Creation Failed",
              "Your model was not created successfully. Please check your input settings and/or refresh the page and reload your data before trying again. If issues persist, please report the issue using the link below."
            )
          );
        });

      // prevent loop
      this.setState({...this.state, task_id: ''})
    }

  }

  updateModelList = () => {
    let params = new URLSearchParams({
      mode: "list",
      user_name: this.props.user.name,
      user_pin: this.props.user.nickname,
    }).toString();
    let url = `modelAccess?${params}`;
    this.props.axPost(url).then((res) => {
      if (res.data !== undefined) {
        let curr_models = formatAIChemyModelList(res.data);
        this.props.dispatch(currentModelActions.UpdateCurrentModels(curr_models));
      }
    });
  }


  fileSelected = (event) => {
    this.props.dispatch(newModelActions.ResetAll());
    let selectedFile = document.getElementById("singleFileInput").files[0].name;
    this.props.dispatch(newModelActions.UpdateSelectedFile(selectedFile));
  };

  loadFile = (event) => {
    this.props.dispatch(newModelActions.UpdateDataLoading(true));
    let data = new FormData();
    data.append("file", document.getElementById("singleFileInput").files[0]);
    data.append("name", this.props.newmodel.selected_file);
    data.append("user_id", this.props.user.nickname);

    let config = {
      onUploadProgress: (event) => this.progressUpdate(event),
    };
    this.props
      .axPost("dataFile/add", data, config)
      .then((res) => {
        let response = res.data;
        if (response.Sheets !== undefined) {
          let filesheets = JSON.parse(response.Sheets);
          let filename = response.FileName;
          let allData = JSON.parse(response.all_data)
          this.props.dispatch(modelDataActions.UpdateAllData(allData))
          this.props.dispatch(
            newModelActions.UpdateLoadedFileSheets(filesheets)
          );
          let selectedSheet = filesheets[0];
          this.props.dispatch(
            newModelActions.UpdateSelectedFileSheet(
              selectedSheet.sheet,
              selectedSheet.columns,
              selectedSheet.excludedColumns
            )
          );
          this.props.dispatch(
            modelDataActions.UpdateAllFileHeaders(
              selectedSheet.columns,
              filename,
              response.source_id
            )
          );
          this.props.dispatch(viewActions.UpdateModelCanBeSaved(false));
          this.props.dispatch(newModelActions.UpdateDataLoading(false));
        } else {
          let errorMessage = response.ErrorMessage;
          this.props.dispatch(
            viewActions.UpdateAlert(true, "File Upload Fail", errorMessage)
          );
          this.props.dispatch(newModelActions.UpdateSelectedFile(""));
        }
        // Enable editData button
        this.setState({...this.state, showEditDataButton: true})
      })
      .catch((error) => {
        console.log(error);
        this.props.dispatch(
          viewActions.UpdateAlert(
            true,
            "File Upload Fail",
            "Error in processing uploaded file. Please upload an excel/csv file with input variables as columns, and experiments as rows. If issues persist, please report the issue using the link below."
          )
        );
      });
  };
  loadFileFromToken = (uuid, updateLocal) => {
    // uuid: token to retrieve data response
    // updateLocal: whether or update local variables and states
    const currentToken = uuid.token;
    this.props.dispatch(newModelActions.UpdateDataLoading(true));
    let data = new FormData();
    data.append("user_id", this.props.user.nickname);
    data.append("token", currentToken);

    let config = {
      onUploadProgress: (event) => this.progressUpdate(event),
    };
    this.props
      .axPost("data/get", data, config)
      .then((res) => {
        if (updateLocal) {
          let response = res.data;
          console.log(response);
          if (response.Sheets !== undefined) {
            let filesheets = JSON.parse(response.Sheets);
            let filename = response.FileName;
            let allData = JSON.parse(response.all_data)
            this.props.dispatch(modelDataActions.UpdateAllData(allData))
            this.props.dispatch(
              newModelActions.UpdateLoadedFileSheets(filesheets)
            );
            let selectedSheet = filesheets[0];
            this.props.dispatch(
              newModelActions.UpdateSelectedFileSheet(
                selectedSheet.sheet,
                selectedSheet.columns,
                selectedSheet.excludedColumns
              )
            );
            this.props.dispatch(
              modelDataActions.UpdateAllFileHeaders(
                selectedSheet.columns,
                filename,
                response.source_id
              )
            );
            this.props.dispatch(viewActions.UpdateModelCanBeSaved(false));
            this.props.dispatch(newModelActions.UpdateDataLoading(false));
          } else {
            let errorMessage = response.ErrorMessage;
            this.props.dispatch(
              viewActions.UpdateAlert(true, "Retrieve data Fail", errorMessage)
            );
            this.props.dispatch(newModelActions.UpdateSelectedFile(""));
            setTimeout(function () {
              window.location = "/";
            }, 5000);
          }
          // Enable editData button
          this.setState({...this.state, showEditDataButton: true})
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.dispatch(
          viewActions.UpdateAlert(
            true,
            "Get data fail",
            "Invalid data token. If issues persist, please report the issue using the link below."
          )
        );
      });
  };
  // For future use
  // deleteToken = (uuid) => {
  //   // uuid: token to retrieve data response
  //   const currentToken = uuid.token
  //   let config = {
  //     onUploadProgress: event => this.progressUpdate(event)
  //   };
  //   axios
  //     .get("app/delete_data", {params: {token: currentToken}}, config)
  //     .then(res => {
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //       this.props.dispatch(
  //         viewActions.UpdateAlert(
  //           true,
  //           "Delete data fail",
  //           "Invalid data token. If issues persist, please report the issue using the link below."
  //         )
  //       );
  //     });
  // };
  sheetChange = (event) => {
    if (this.props.newmodel.file_selected_sheet === event.target.value) {
      return;
    }
    let currentSheet = this.props.newmodel.file_sheets.filter(function (el) {
      return el.sheet === event.target.value;
    })[0];
    let filename = this.props.newmodel.filename;
    this.props.dispatch(
      newModelActions.UpdateSelectedFileSheet(
        currentSheet.sheet,
        currentSheet.columns,
        currentSheet.excludedColumns
      )
    );
    this.props.dispatch(
      modelDataActions.UpdateAllFileHeaders(
        currentSheet.columns,
        filename,
        this.props.modeldata.source_id
      )
    );
    this.props.dispatch(
      modelDataActions.UpdateDataSnapshot([], [], [], [], [], [], [])
    );
  };

  removeFile = (event) => {
    document.getElementById("singleFileInput").value = null;
    this.props.dispatch(modelDataActions.UpdateAllFileHeaders([], ""));
    this.props.dispatch(newModelActions.ResetAll());
    // Disable editData button
    this.setState({...this.state, showEditDataButton: false})
  };

  progressUpdate = (event) => {
    let percentCompleted = Math.round((event.loaded * 100) / event.total);
    this.props.dispatch(newModelActions.UpdateProgress(percentCompleted));
  };

  tableTypeChange = (event) => {
    this.props.dispatch(
      newModelActions.UpdateSelectedTableView(event.target.value)
    );
  };
  SwitchToRecColumns = (cols) => {
    let new_output_cols = Object.assign(
      [],
      this.props.newmodel.selected_output_headers
    );
    let new_all_cols = Object.assign(
      [],
      this.props.newmodel.unselected_all_headers
    );
    let keep_output_cols = new_output_cols.filter(function (el) {
      return cols.indexOf(el.header) !== -1;
    });
    let discard_output_cols = new_output_cols.filter(function (el) {
      return cols.indexOf(el.header) === -1;
    });
    new_all_cols = new_all_cols.concat(discard_output_cols);
    new_all_cols.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    this.props.dispatch(
      newModelActions.UpdateUnselectedAllHeaders(new_all_cols)
    );
    this.props.dispatch(newModelActions.UpdateOutputHeaders(keep_output_cols));
    this.props.dispatch(newModelActions.UpdateHighlightedAllHeaders([]));
    this.props.dispatch(newModelActions.UpdateHighlightedOutputHeaders([]));
    this.refs.allheaderstable.hotInstance.deselectCell();
    this.refs.outputheaderstable.hotInstance.deselectCell();
    this.UpdateSnapshot(
      this.props.newmodel.selected_input_headers,
      keep_output_cols
    );
  };

  //All code below is designed to make sure only rows populated with values are selected. Empty rows are omitted.
  AllHeadersSelect = (
    row,
    column,
    row2,
    column2,
    preventSelection,
    selectionLayerLevel
  ) => {
    let rowStart = Math.min(row, row2);
    let rowEnd = Math.max(row, row2);
    if (
      this.props.newmodel.unselected_all_headers.length === 0 ||
      rowStart > this.props.newmodel.unselected_all_headers.length - 1
    ) {
      this.props.dispatch(newModelActions.UpdateHighlightedAllHeaders([]));
      return;
    }
    let updatedRowEnd = Math.min(
      rowEnd,
      this.props.newmodel.unselected_all_headers.length - 1
    );
    this.props.dispatch(
      newModelActions.UpdateHighlightedAllHeaders([rowStart, updatedRowEnd])
    );
  };
  InputHeadersSelect = (
    row,
    column,
    row2,
    column2,
    preventSelection,
    selectionLayerLevel
  ) => {
    let rowStart = Math.min(row, row2);
    let rowEnd = Math.max(row, row2);
    if (
      this.props.newmodel.selected_input_headers.length === 0 ||
      rowStart > this.props.newmodel.selected_input_headers.length - 1
    ) {
      this.props.dispatch(newModelActions.UpdateHighlightedInputHeaders([]));
      return;
    }
    let updatedRowEnd = Math.min(
      rowEnd,
      this.props.newmodel.selected_input_headers.length - 1
    );
    this.props.dispatch(
      newModelActions.UpdateHighlightedInputHeaders([rowStart, updatedRowEnd])
    );
  };
  OutputHeadersSelect = (
    row,
    column,
    row2,
    column2,
    preventSelection,
    selectionLayerLevel
  ) => {
    let rowStart = Math.min(row, row2);
    let rowEnd = Math.max(row, row2);
    if (
      this.props.newmodel.selected_output_headers.length === 0 ||
      rowStart > this.props.newmodel.selected_output_headers.length - 1
    ) {
      this.props.dispatch(newModelActions.UpdateHighlightedOutputHeaders([]));
      return;
    }
    let updatedRowEnd = Math.min(
      rowEnd,
      this.props.newmodel.selected_output_headers.length - 1
    );
    this.props.dispatch(
      newModelActions.UpdateHighlightedOutputHeaders([rowStart, updatedRowEnd])
    );
  };

  //Shifts columns around according to initial column organization in the spreadsheet.
  //All cells are automatically deselected after columns are moved.
  AllHeadersToInputs = (event) => {
    let all_headers = Object.assign(
      [],
      this.props.newmodel.unselected_all_headers
    );
    let input_headers = Object.assign(
      [],
      this.props.newmodel.selected_input_headers
    );
    let highlighted = this.props.newmodel.all_headers_highlighted;
    all_headers
      .filter(function (el, index) {
        return index >= highlighted[0] && index <= highlighted[1];
      })
      .forEach(function (el) {
        input_headers.push(el);
      });
    all_headers = all_headers.filter(function (el, index) {
      return index < highlighted[0] || index > highlighted[1];
    });
    input_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    all_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    this.props.dispatch(
      newModelActions.UpdateUnselectedAllHeaders(all_headers)
    );
    this.props.dispatch(newModelActions.UpdateInputHeaders(input_headers));
    this.props.dispatch(newModelActions.UpdateHighlightedAllHeaders([]));
    this.props.dispatch(newModelActions.UpdateHighlightedInputHeaders([]));
    this.refs.allheaderstable.hotInstance.deselectCell();
    this.refs.inputheaderstable.hotInstance.deselectCell();
    this.UpdateSnapshot(
      input_headers,
      this.props.newmodel.selected_output_headers
    );
  };

  AllHeadersToOutputs = (event) => {
    let all_headers = Object.assign(
      [],
      this.props.newmodel.unselected_all_headers
    );
    let output_headers = Object.assign(
      [],
      this.props.newmodel.selected_output_headers
    );
    let highlighted = this.props.newmodel.all_headers_highlighted;
    all_headers
      .filter(function (el, index) {
        return index >= highlighted[0] && index <= highlighted[1];
      })
      .forEach(function (el) {
        output_headers.push(el);
      });
    all_headers = all_headers.filter(function (el, index) {
      return index < highlighted[0] || index > highlighted[1];
    });
    output_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    all_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    this.props.dispatch(
      newModelActions.UpdateUnselectedAllHeaders(all_headers)
    );
    this.props.dispatch(newModelActions.UpdateOutputHeaders(output_headers));
    this.props.dispatch(newModelActions.UpdateHighlightedAllHeaders([]));
    this.props.dispatch(newModelActions.UpdateHighlightedOutputHeaders([]));
    this.refs.allheaderstable.hotInstance.deselectCell();
    this.refs.outputheaderstable.hotInstance.deselectCell();
    this.UpdateSnapshot(
      this.props.newmodel.selected_input_headers,
      output_headers
    );
  };

  OutputsToAllHeaders = (event) => {
    let all_headers = Object.assign(
      [],
      this.props.newmodel.unselected_all_headers
    );
    let output_headers = Object.assign(
      [],
      this.props.newmodel.selected_output_headers
    );
    let highlighted = this.props.newmodel.output_headers_highlighted;
    output_headers
      .filter(function (el, index) {
        return index >= highlighted[0] && index <= highlighted[1];
      })
      .forEach(function (el) {
        all_headers.push(el);
      });
    output_headers = output_headers.filter(function (el, index) {
      return index < highlighted[0] || index > highlighted[1];
    });
    output_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    all_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    this.props.dispatch(
      newModelActions.UpdateUnselectedAllHeaders(all_headers)
    );
    this.props.dispatch(newModelActions.UpdateOutputHeaders(output_headers));
    this.props.dispatch(newModelActions.UpdateHighlightedAllHeaders([]));
    this.props.dispatch(newModelActions.UpdateHighlightedOutputHeaders([]));
    this.refs.allheaderstable.hotInstance.deselectCell();
    this.refs.outputheaderstable.hotInstance.deselectCell();
    this.UpdateSnapshot(
      this.props.newmodel.selected_input_headers,
      output_headers
    );
  };

  InputsToAllHeaders = (event) => {
    let all_headers = Object.assign(
      [],
      this.props.newmodel.unselected_all_headers
    );
    let input_headers = Object.assign(
      [],
      this.props.newmodel.selected_input_headers
    );
    let highlighted = this.props.newmodel.input_headers_highlighted;
    input_headers
      .filter(function (el, index) {
        return index >= highlighted[0] && index <= highlighted[1];
      })
      .forEach(function (el) {
        all_headers.push(el);
      });
    input_headers = input_headers.filter(function (el, index) {
      return index < highlighted[0] || index > highlighted[1];
    });
    input_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    all_headers.sort((a, b) =>
      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
    );
    this.props.dispatch(
      newModelActions.UpdateUnselectedAllHeaders(all_headers)
    );
    this.props.dispatch(newModelActions.UpdateInputHeaders(input_headers));
    this.props.dispatch(newModelActions.UpdateHighlightedAllHeaders([]));
    this.props.dispatch(newModelActions.UpdateHighlightedInputHeaders([]));
    this.refs.allheaderstable.hotInstance.deselectCell();
    this.refs.inputheaderstable.hotInstance.deselectCell();
    this.UpdateSnapshot(
      input_headers,
      this.props.newmodel.selected_output_headers
    );
  };

  UpdateSnapshot(inputs, outputs) {
    if (inputs.length > 0 && outputs.length > 0) {
      this.props.dispatch(
        viewActions.updateLoadingMessage("Updating Data Snapshot")
      );
      this.props.dispatch(viewActions.changeLoadingState(true));
      let submitInputs = inputs.map((x) => x.header);
      let submitOutputs = outputs.map((x) => x.header);
      let selectedSheet = this.props.newmodel.file_selected_sheet;

      const data = {
        source_id: this.props.modeldata.source_id,
        inputs: submitInputs,
        outputs: submitOutputs,
        sheetname: selectedSheet,
      };
      this.props
        .axPost("dataFile/get", JSON.stringify({ data: data }), {
          headers: { "Content-Type": "application/json; charset=utf-8" },
        })
        .then((res) => {
          if (res.data.ErrorMessage === undefined) {
            this.props.dispatch(viewActions.changeLoadingState(false));
            this.props.dispatch(
              modelDataActions.UpdateDataSnapshot(
                JSON.parse(res.data.Headers),
                JSON.parse(res.data.TableData),
                JSON.parse(res.data.Recommendations),
                JSON.parse(res.data.Rows),
                JSON.parse(res.data.EncodedHeaders),
                JSON.parse(res.data.EncodedTableData),
                JSON.parse(res.data.Rows),
                JSON.parse(res.data.ExcludedColumns)
              )
            );
          }
        })
        .catch((thrown) => {
          this.props.dispatch(viewActions.changeLoadingState(false));
          console.log(thrown);
          this.props.dispatch(
            viewActions.UpdateAlert(
              true,
              "Update Snapshot Fail",
              "Error in processing uploaded file. Please upload an excel/csv file with input variables as columns, and experiments as rows. If issues persist, please report the issue using the link below."
            )
          );
        });
    } else {
      this.props.dispatch(
        modelDataActions.UpdateDataSnapshot([], [], [], [], [], [], [])
      );
    }
  }

  handlePLSChange = (event) => {
    this.props.dispatch(
      newModelActions.UpdateGeneratePLSModel(!this.props.newmodel.gen_pls)
    );
  };

  handleNNChange = (event) => {
    this.props.dispatch(
      newModelActions.UpdateGenerateNeuralNetwork(!this.props.newmodel.gen_nn)
    );
  };

  handleKRRChange = (event) => {
    this.props.dispatch(
      newModelActions.UpdateGenerateKRRModel(!this.props.newmodel.gen_krr)
    );
  };

  handleSVRChange = (event) => {
    this.props.dispatch(
      newModelActions.UpdateGenerateSVRModel(!this.props.newmodel.gen_svr)
    );
  };

  handleHPOChange = (event) => {
    this.props.dispatch(
      newModelActions.UpdateRunHyperparameterOptimization(
        !this.props.newmodel.run_hpo
      )
    );
  };

  submitModelTrainingTask = (data) => {
    // submit a task to redis queue
    this.props
      .axPost("task/train", JSON.stringify({ data: data }), {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .then((res) => {
        this.setState({ ...this.state, task_id: res.data.task_id });
        this.setState({ ...this.state, loading: true, success: false });
        console.log(`task id: ${res.data.task_id}`);
      })
      .catch((err) => {
        this.props.dispatch(
          viewActions.UpdateAlert(
            true,
            "Model training failed",
            `Failed to submit training task. If issues persist, please report the issue using the link below.`
          )
        );
        this.setState({ ...this.state, loading: false, success: false });
      });
  };

  checkTaskStatus = (task_id) => {
    let url = `task/get`;
    console.log(`checkTaskStatus - task_id:${task_id}`);
    if (task_id === "") {
      console.error("No task id found");
      return "";
    }
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };
    return this.props
      .axPost(url, JSON.stringify({ task_id: task_id }), config)
      .then((res) => res)
      .catch((res) => {
        console.error(res);
      });
  };

  handleChangeSaveCheckbox = () => {
    this.setState({...this.state, save_after_train: !this.state.save_after_train})
  }

  generateModels = (event) => {
    // prepare data
    // this.props.dispatch(viewActions.changeLoadingState(true));
    let count = 0;
    if (this.props.newmodel.gen_nn) {
      count++;
    }
    if (this.props.newmodel.gen_pls) {
      count++;
    }
    if (this.props.newmodel.gen_krr) {
      count++;
    }
    if (this.props.newmodel.gen_xgb) {
      count++;
    }
    if (this.props.newmodel.gen_svr) {
      count++;
    }
    let message =
      count > 1
        ? "Building Predictive Models..."
        : "Building Predictive Model...";
    this.props.dispatch(viewActions.updateLoadingMessage(message));
    const data = {
      source_id: this.props.modeldata.source_id,
      user_name: this.props.user.name,
      user_id: this.props.user.nickname,
      genNN: this.props.newmodel.gen_nn,
      genPLS: this.props.newmodel.gen_pls,
      genSVR: this.props.newmodel.gen_svr,
      genXGB: false,
      genKRR: this.props.newmodel.gen_krr,
      runHPO: this.props.newmodel.run_hpo,
      saveAfterTrain: this.state.save_after_train
    };

    // Submit task
    this.submitModelTrainingTask(data);

    // Task status is checked in ComponentDidUpdate()
  };

  getSnapshotCategoricalColumns = () => {
    let encodedHeaders = this.props.modeldata.data_snapshot_encoded_headers;
    return this.props.modeldata.data_snapshot_headers.filter(function (el) {
      return encodedHeaders.indexOf(el) === -1;
    });
  };

  getSnapshotContinuousColumns = () => {
    let encodedHeaders = this.props.modeldata.data_snapshot_encoded_headers;
    return this.props.modeldata.data_snapshot_headers.filter(function (el) {
      return encodedHeaders.indexOf(el) !== -1;
    });
  };

  cellStyle = (columnsInfo) => {
    let inputHeaders = this.props.newmodel.selected_input_headers
    let excludedHeaders = this.props.modeldata.data_snapshot_excluded_columns
    inputHeaders = inputHeaders.filter(( {header} ) => { return !excludedHeaders.includes(header)})
    let nInput = inputHeaders.length
    return (rowIndex, colIndex, prop) => {
      // Group Header row on top
      if (colIndex >= nInput) {
        return {
          className: "htCenter outputCell"
        }
      } else {
        return {
          className: "htCenter inputCell"
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    const buttonClassname = clsx({
      [classes.buttonSuccess]: this.state.success,
      [classes.button]: !this.state.success,
    });
    return (
      <div>
        <div className={classes.layout}>
          <Card className={classes.paperBody} elevation={3}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
            >
              File Upload
            </Typography>

            <Typography
              variant="subtitle1"
              style={{
                textAlign: "left",
                marginLeft: 24,
                marginRight: 24,
                marginBottom: 0
              }}
            >
              Add an Excel file containing your data to generate new machine
              learning models. After upload all column headers should appear in
              the select window below, where you can choose inputs & outputs and
              confirm your selections before creating a new model.
            </Typography>
            <div style={{ margin: 24 }}>
              <Grid
                container
                style={{ marginBottom: 24 }}
                justify="flex-start"
                alignItems="center"
              >
                <input
                  // accept="image/*"
                  className={classes.input}
                  id="singleFileInput"
                  type="file"
                  onChange={this.fileSelected}
                  data-cy="choose_file_btn"
                />
                <label htmlFor="singleFileInput">
                  <Button variant="contained" component="span">
                    Choose File
                  </Button>
                </label>
                <Typography variant="body2" style={{ marginLeft: 15 }}>
                  {this.props.newmodel.selected_file}
                </Typography>
              </Grid>
              <Typography variant="subtitle1" align="left">
                File Upload Progress
              </Typography>
              <LinearProgress
                variant="determinate"
                color="primary"
                value={this.props.newmodel.upload_progress}
                style={{ marginTop: 10 }}
              />
              <Grid
                container
                style={{ marginBottom: 14, marginTop: 10 }}
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={() => {
                    this.props.dispatch(actions.changeViewType('dataviz'));
                  }}
                  disabled={!this.state.showEditDataButton}
                  data-cy="edit_file_btn"
                >
                  Data Visualization
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={() => {
                    this.props.dispatch(actions.UpdateEditDataDialog(true));
                  }}
                  disabled={!this.state.showEditDataButton}
                  data-cy="edit_file_btn"
                >
                  Edit Data
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  className={classes.button}
                  onClick={this.removeFile}
                  disabled={!this.props.newmodel.file_is_present}
                  data-cy="remove_file_btn"
                >
                  Remove File
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  style={{ marginRight: 0 }}
                  disabled={
                    !this.props.newmodel.file_is_present ||
                    this.props.newmodel.completed
                  }
                  onClick={this.loadFile}
                  data-cy="upload_file_btn"
                >
                  Upload File
                </Button>
              </Grid>
            </div>
          </Card>
          {/* <Divider style={{ marginTop: 16 }} /> */}
          {(this.props.newmodel.unselected_all_headers.length > 0 ||
            this.props.newmodel.selected_input_headers.length > 0 ||
            this.props.newmodel.selected_output_headers.length > 0) && (
            <div>
              {this.props.views.showEditData  && (
                  <Card
                      className={classes.paperBody}
                      elevation={3}
                      style={{ marginTop: 24 }}
                  >
                    <EditData user={this.props.user} axPost={this.props.axPost} />
                  </Card>)}
              <Card
                className={classes.paperBody}
                elevation={3}
                style={{ marginTop: 24 }}
              >
                <div style={{ margin: 24 }}>
                  <Grid
                    container
                    style={{ marginTop: 0, paddingTop: 0 }}
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={10}
                    fullwidth="true"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: 12,
                        marginTop: 0,
                        paddingTop: 0
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "left" }}
                      >
                        Data Selection
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "left", marginBottom: 16 }}
                      >
                        Select input and output columns below.
                      </Typography>
                      <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-end"
                        spacing={8}
                        style={{ marginBottom: 16 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={5}>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel htmlFor="newmodel_sheet_select">
                              Use dropdown menu to select an Excel tab
                            </InputLabel>{" "}
                            {/*TODO increase font size*/}
                            <Select
                              autoWidth
                              style={{ textAlign: "left" }}
                              value={this.props.newmodel.file_selected_sheet}
                              onChange={this.sheetChange}
                              input={
                                <Input
                                  name="newmodel_sheet_select"
                                  id="newmodel_sheet_select"
                                />
                              }
                            >
                              {this.props.newmodel.file_sheets.map(sheet => (
                                <MenuItem key={sheet.sheet} value={sheet.sheet}>
                                  {sheet.sheet}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={2}></Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={7}>
                          {this.props.newmodel.omitted_headers.length > 0 && (
                            <Typography
                              gutterBottom
                              style={{ textAlign: "right" }}
                            >
                              <b>Omitted Columns:</b>{" "}
                              {this.props.newmodel.omitted_headers.join(", ")}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ marginTop: 8 }}
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item xs={5} style={{ height: 500 }}>
                          <HotTable
                            data={this.props.newmodel.unselected_all_headers.map(
                              x => [x.header, x.count]
                            )}
                            colHeaders={[
                              ["Column header"],
                              ["Number of data points"]
                            ]}
                            rowHeaders={true}
                            readOnly={true}
                            height="500"
                            stretchH="all"
                            minRows="20"
                            afterSelectionEnd={this.AllHeadersSelect}
                            settings={{ outsideClickDeselects: false }}
                            root="hot"
                            ref="allheaderstable"
                            style={{
                              fontSize: "smaller",
                              fontFamily: "Roboto"
                            }}
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <Grid container justify="center" alignItems="center">
                            <Grid item xs={4}>
                              <Button
                                variant="contained"
                                component="span"
                                className={classes.button}
                                disabled={
                                  this.props.newmodel.input_headers_highlighted
                                    .length === 0
                                }
                                onClick={this.InputsToAllHeaders}
                                style={{ marginLeft: 15, marginRight: 15 }}
                              >
                                ◀◀◀
                              </Button>
                              <Button
                                variant="contained"
                                component="span"
                                className={classes.button}
                                disabled={
                                  this.props.newmodel.all_headers_highlighted
                                    .length === 0
                                }
                                onClick={this.AllHeadersToInputs}
                                style={{ marginLeft: 15, marginRight: 15 }}
                                data-cy="add_column_input"
                              >
                                ▶▶▶
                              </Button>
                            </Grid>
                            <Grid item xs={8} style={{ height: 250 }}>
                              <HotTable
                                data={
                                  this.props.newmodel.selected_input_headers
                                    .length > 0
                                    ? this.props.newmodel.selected_input_headers.map(
                                        x => [x.header]
                                      )
                                    : [[""]]
                                }
                                colHeaders={["Inputs (X)"]}
                                rowHeaders={true}
                                readOnly={true}
                                height="240"
                                stretchH="all"
                                minRows="10"
                                afterSelectionEnd={this.InputHeadersSelect}
                                settings={{ outsideClickDeselects: false }}
                                root="hot"
                                ref="inputheaderstable"
                                style={{
                                  fontSize: "smaller",
                                  fontFamily: "Roboto"
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <Button
                                variant="contained"
                                component="span"
                                className={classes.button}
                                disabled={
                                  this.props.newmodel.output_headers_highlighted
                                    .length === 0
                                }
                                onClick={this.OutputsToAllHeaders}
                                style={{ marginLeft: 15, marginRight: 15 }}
                              >
                                ◀◀◀
                              </Button>
                              <Button
                                variant="contained"
                                component="span"
                                className={classes.button}
                                disabled={
                                  this.props.newmodel.all_headers_highlighted
                                    .length === 0
                                }
                                onClick={this.AllHeadersToOutputs}
                                style={{ marginLeft: 15, marginRight: 15 }}
                                data-cy="add_column_output"
                              >
                                ▶▶▶
                              </Button>
                            </Grid>
                            <Grid item xs={8} style={{ height: 250 }}>
                              <HotTable
                                data={
                                  this.props.newmodel.selected_output_headers
                                    .length > 0
                                    ? this.props.newmodel.selected_output_headers.map(
                                        x => [x.header]
                                      )
                                    : [[""]]
                                }
                                colHeaders={["Outputs (Y)"]}
                                rowHeaders={true}
                                readOnly={true}
                                height="240"
                                minRows="10"
                                startCols="1"
                                startRows="10"
                                stretchH="all"
                                afterSelectionEnd={this.OutputHeadersSelect}
                                settings={{ outsideClickDeselects: false }}
                                root="hot"
                                ref="outputheaderstable"
                                style={{
                                  fontSize: "smaller",
                                  fontFamily: "Roboto"
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: 12,
                        marginTop: 0
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "left" }}
                      >
                        Recommended Data Organization
                      </Typography>
                      <Typography
                        style={{ textAlign: "left", marginBottom: 24 }}
                      >
                        The following column organizations can be used to
                        maximize the number of modeled data points.
                      </Typography>
                      {this.props.modeldata.data_organization_recommendations.map(
                        (rec, index) => (
                          <Typography
                            key={"rec" + index}
                            style={{ textAlign: "left" }}
                          >
                            ▪ Experiment Count: <b>{rec.count}</b>. Output
                            Columns:{" "}
                            <Button
                              color="primary"
                              onClick={() => this.SwitchToRecColumns(rec.cols)}
                            >
                              {rec.cols.join(", ")}
                            </Button>
                          </Typography>
                        )
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Card>
              <Grid
                container
                style={{ marginBottom: 0, marginTop: 0 }}
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={12}></Grid>
              </Grid>
              <Card
                className={classes.paperBody}
                elevation={3}
                style={{ marginTop: 24, marginBottom: 24 }}
              >
                <div style={{ margin: 24 }}>
                  <Grid
                    container
                    style={{ marginBottom: 24 }}
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={12} style={{ textAlign: "left" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "left" }}
                      >
                        Data Snapshot
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "left", marginBottom: 8 }}
                      >
                        The data that will be modeled can be seen below. Both
                        the raw data, and encoded data can be viewed.
                      </Typography>
                      {/* <FormControl component="fieldset" className={classes.formControl}>
                                        <FormLabel component="legend" style={{ fontSize: 'small' }}>Plot Type</FormLabel>
                                    </FormControl> */}
                      <div>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                this.props.newmodel.selected_table_view ===
                                "raw"
                              }
                              onChange={this.tableTypeChange}
                              value="raw"
                              name="radio-button-demo"
                              aria-label="View Raw Data"
                              color="primary"
                            />
                          }
                          label="View Raw Data"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                this.props.newmodel.selected_table_view ===
                                "encoded"
                              }
                              onChange={this.tableTypeChange}
                              value="encoded"
                              name="radio-button-demo"
                              aria-label="View Encoded Data"
                              color="primary"
                            />
                          }
                          label="Convert Categorical Encoded Data (Convert text/labeled data to 0 and 1)"
                        />
                      </div>
                      <Typography
                        style={{ textAlign: "right", marginBottom: 8 }}
                      >
                        <b>
                          Complete Rows:{" "}
                          <i>
                            {
                              this.props.modeldata.data_snapshot_table.filter(
                                function(el) {
                                  return el[0] !== null;
                                }
                              ).length
                            }
                          </i>
                        </b>
                        .
                      </Typography>
                    </Grid>
                    {this.props.modeldata.data_snapshot_headers.length > 0 && (
                      <Grid item xs={12} style={{ marginBottom: 8 }}>
                        <div  className={classes.htRoot}>
                          <HotTable
                            data={
                              this.props.newmodel.selected_table_view === "raw"
                                ? this.props.modeldata.data_snapshot_table
                                : this.props.modeldata.data_snapshot_encoded_table
                            }
                            colHeaders={
                              this.props.newmodel.selected_table_view === "raw"
                                ? this.props.modeldata.data_snapshot_headers
                                : this.props.modeldata
                                    .data_snapshot_encoded_headers
                            }
                            rowHeaders={
                              this.props.newmodel.selected_table_view === "raw"
                                ? this.props.modeldata.data_snapshot_rows
                                : this.props.modeldata.data_snapshot_encoded_rows
                            }
                            readOnly={true}
                            height="500"
                            stretchH="all"
                            minRows="20"
                            rowHeaderWidth={80}
                            //colWidths={[180]}
                            cells={this.cellStyle()}
                            settings={{
                              outsideClickDeselects: false,
                              rowHeaderWidth: 180
                            }}
                            root="hot"
                            ref="datasnapshottable"
                            style={{ fontSize: "smaller", fontFamily: "Roboto" }}
                          />
                        </div>
                        <Typography
                          style={{
                            textAlign: "left",
                            marginBottom: 12,
                            marginTop: 24
                          }}
                        >
                          <b>Continuous Data Columns: </b>
                          {this.getSnapshotContinuousColumns().join(", ")}.
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "left",
                            marginBottom: 12,
                            marginTop: 8
                          }}
                        >
                          <b>Categorical Data Columns: </b>
                          {this.getSnapshotCategoricalColumns().length > 0 ? (
                            this.getSnapshotCategoricalColumns().join(", ")
                          ) : (
                            <i>None</i>
                          )}
                          .
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "left",
                            marginBottom: 0,
                            marginTop: 8
                          }}
                        >
                          <b>Excluded Columns (Single Unique Value): </b>
                          {this.props.modeldata.data_snapshot_excluded_columns
                            .length > 0 ? (
                            this.props.modeldata.data_snapshot_excluded_columns.join(
                              ", "
                            )
                          ) : (
                            <i>None</i>
                          )}
                          .
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Card>
              <Card
                className={classes.paperBody}
                elevation={3}
                style={{ marginTop: 24, marginBottom: 24 }}
              >
                <div style={{ margin: 24 }}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={12} style={{ textAlign: "left" }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "left" }}
                      >
                        Generate Model
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "left", marginBottom: 8 }}
                      >
                        Select which models you wish to train, then click the
                        'Generate Models' button.
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "left" }}>
                      <FormGroup row>
                        <Grid container direction="row">
                          <FormControlLabel
                            style={{ marginRight: "0px" }}
                            control={
                              <Checkbox
                                checked={this.props.newmodel.gen_pls}
                                onChange={this.handlePLSChange}
                                //color="primary"
                                classes={{
                                  root: classes.plscheck,
                                  checked: classes.checked
                                }}
                                data-cy="model_pls"
                              />
                            }
                            label="Partial Least Squares (Linear Regression)"
                          />
                          <MuiThemeProvider theme={MuiTooltipTheme}>
                            <Tooltip
                              id="PLS"
                              title="Partial Least Squares (PLS) regression is a linear technique that tries
                            to identify a low dimensional mapping between input and output variables, fitting a linear model
                            over this low dimensional space."
                              placement="top"
                            >
                              <HelpOutlineIcon
                                style={{ fontSize: 14, marginRight: "10px" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </Grid>
                        <Grid container direction="row">
                          <FormControlLabel
                            style={{ marginRight: "0px" }}
                            control={
                              <Checkbox
                                checked={this.props.newmodel.gen_nn}
                                onChange={this.handleNNChange}
                                disabled={false}
                                //color="primary"
                                classes={{
                                  root: classes.nncheck,
                                  checked: classes.checked
                                }}
                                data-cy="model_nn"
                              />
                            }
                            label="Neural Network (Use with >200 data points)"
                          />
                          <MuiThemeProvider theme={MuiTooltipTheme}>
                            <Tooltip
                              id="NN"
                              title="Neural Network (NN) is a non-linear function approximation technique
                              that works well modeling complex variable relationships, but is prone to overfitting when
                              used on small datasets."
                              placement="top"
                            >
                              <HelpOutlineIcon
                                style={{ fontSize: 14, marginRight: "10px" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </Grid>
                        <Grid container direction="row">
                          <FormControlLabel
                            style={{ marginRight: "0px" }}
                            control={
                              <Checkbox
                                checked={this.props.newmodel.gen_krr}
                                onChange={this.handleKRRChange}
                                disabled={false}
                                //color="primary"
                                classes={{
                                  root: classes.krrcheck,
                                  checked: classes.checked
                                }}
                                data-cy="model_krr"
                              />
                            }
                            label="Kernel Ridge Regression (non-Linear Regression)"
                          />
                          <MuiThemeProvider theme={MuiTooltipTheme}>
                            <Tooltip
                              id="KRR"
                              title="Kernel ridge regression (KRR) combines ridge regression (linear
                              least squares with l2-norm regularization) with the kernel trick. It thus learns a linear
                              function in the space induced by the respective kernel and the data. For non-linear kernels,
                              this corresponds to a non-linear function in the original space."
                              placement="top"
                            >
                              <HelpOutlineIcon
                                style={{ fontSize: 14, marginRight: "10px" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </Grid>
                        <Grid container direction="row">
                          <FormControlLabel
                            style={{ marginRight: "0px" }}
                            control={
                              <Checkbox
                                checked={this.props.newmodel.gen_svr}
                                onChange={this.handleSVRChange}
                                disabled={false}
                                //color="primary"
                                classes={{
                                  root: classes.svrcheck,
                                  checked: classes.checked
                                }}
                                data-cy="model_svr"
                              />
                            }
                            label="Support Vector Regression"
                          />
                          <MuiThemeProvider theme={MuiTooltipTheme}>
                            <Tooltip
                              id="SVR"
                              title="Support Vector Regression (SVR) is a type of support vector machine that
                            supports linear and non-linear regression. The mission is to fit as many instances as possible
                            between the lines while limiting the margin violations."
                              placement="top"
                            >
                              <HelpOutlineIcon
                                style={{ fontSize: 14, marginRight: "10px" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </Grid>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginBottom: 24 }}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs={9} style={{ textAlign: "left" }}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.props.newmodel.run_hpo}
                              onChange={this.handleHPOChange}
                              //color="primary"
                              classes={{
                                root: classes.hpocheck,
                                checked: classes.checked
                              }}
                              disabled={
                                this.props.newmodel.selected_input_headers
                                  .length === 0 ||
                                this.props.newmodel.selected_output_headers
                                  .length === 0 ||
                                this.props.modeldata.data_snapshot_encoded_table
                                  .length === 0 ||
                                (!this.props.newmodel.gen_nn &&
                                  !this.props.newmodel.gen_pls &&
                                  !this.props.newmodel.gen_krr &&
                                  !this.props.newmodel.gen_svr)
                              }
                            />
                          }
                          label="Tune parameters for the selected model. (Note: Will increase model training times. We recommend running with only one model selected. Uses Bayesian optimization.)"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          className={buttonClassname}
                          disabled={
                            this.props.newmodel.selected_input_headers
                              .length === 0 ||
                            this.props.newmodel.selected_output_headers
                              .length === 0 ||
                            this.props.modeldata.data_snapshot_encoded_table
                              .length === 0 ||
                            (!this.props.newmodel.gen_nn &&
                              !this.props.newmodel.gen_pls &&
                              !this.props.newmodel.gen_krr &&
                              !this.props.newmodel.gen_svr) ||
                            this.state.loading
                          }
                          //   boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
                          onClick={this.generateModels}
                          style={{
                            // background:
                            //   "linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)",
                            float: "right",
                            marginRight: 0
                          }}
                          data-cy="generate_models_btn"
                        >
                          Generate Models
                        </Button>
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                        <div style={{ float: "right", marginRight: 0 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.save_after_train}
                                onChange={this.handleChangeSaveCheckbox}
                                name="checkedSaveAfterTrain"
                                color="primary"
                              />
                            }
                            style={{ fontSize: 14 }}
                            label={
                              <Typography variant="body2">
                                Auto Save Model
                              </Typography>
                            }
                          />
                          <MuiThemeProvider theme={MuiTooltipTheme}>
                            <Tooltip
                              id="Save_model_tooltip"
                              title="After checking this box, the trained model will be saved even if you close the browser"
                              placement="top"
                            >
                              <HelpOutlineIcon
                                style={{ fontSize: 14, marginRight: "10px" }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NewModel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect((state, props) => {
  return {
    views: state.views,
    newmodel: state.newmodel,
    models: state.models,
    modeldata: state.modeldata,
    existingmodels: state.existingmodels,
    modelevaluation: state.modelevaluation,
    modelusage: state.modelusage,
    modelprediction: state.modelprediction,
    modeloptimization: state.modeloptimization,
  };
})(withStyles(styles)(NewModel));
