import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ResidualsCoefficientsSmall from "./ResidualsCoefficientsSmall";
import ResidualsCoefficientsLarge from "./ResidualsCoefficientsLarge";

//Dev
import * as evaluationActions from "../actions/modelevaluationActions";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

class ModelEvaluation extends Component {
  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "modeleval") {
      return false;
    }
    //additional logic here would be good
    return true;
  }

  componentDidMount() { }

  modelChange = event => {
    let currentModel = this.props.modeldata.models.filter(function (el) {
      return el.name === event.target.value;
    })[0];
    let currentOutputKeys = Object.keys(currentModel.residuals);
    let selectedOutputKey = this.props.modelevaluation.selected_output_key;
    if (currentOutputKeys.indexOf(selectedOutputKey) === -1) {
      selectedOutputKey = currentOutputKeys[0];
    }
    let currentResiduals = currentModel.residuals[selectedOutputKey];
    let selectedResidualsRValue = currentResiduals.R_Value;
    let selectedResidualsSlope = currentResiduals.Slope;
    let currentCoefficients = [];
    if (currentModel.type === "PLS") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    if (currentModel.type === "NN") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    if (currentModel.type === "KRR") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    if (currentModel.type === "SVR") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    this.updateTitlesAndLegends(selectedOutputKey, selectedResidualsSlope, selectedResidualsRValue, currentModel.name);
    this.props.dispatch(
      evaluationActions.UpdateModelData(
        event.target.value,
        currentOutputKeys,
        selectedOutputKey,
        currentResiduals,
        selectedResidualsSlope,
        selectedResidualsRValue,
        currentCoefficients
      )
    );
  };

  outputVarChange = event => {
    let modelName = this.props.modelevaluation.selected_model_name;
    let currentModel = this.props.modeldata.models.filter(function (el) {
      return el.name === modelName;
    })[0];
    let selectedOutputKey = event.target.value;
    let currentResiduals = currentModel.residuals[selectedOutputKey];
    let selectedResidualsRValue = currentResiduals.R_Value;
    let selectedResidualsSlope = currentResiduals.Slope;
    //let currentCoefficients = currentModel.standardCoefficients[selectedOutputKey];
    let currentCoefficients = [];
    if (currentModel.type === "PLS") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    if (currentModel.type === "NN") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    if (currentModel.type === "KRR") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    if (currentModel.type === "SVR") {
      currentCoefficients = currentModel.relativeImportances[selectedOutputKey];
    }
    this.updateTitlesAndLegends(selectedOutputKey, selectedResidualsSlope, selectedResidualsRValue, modelName);
    this.props.dispatch(
      evaluationActions.UpdateSelectedKey(currentResiduals, selectedResidualsSlope, selectedResidualsRValue, currentCoefficients, selectedOutputKey)
    );
  };

  updateTitlesAndLegends = (key, slope, rValue, modelName) => {
    let residualsChartTitle = "R-squared Value: " + (rValue * rValue).toFixed(2) + " | Slope: " + slope.toFixed(2);
    let residualsLegend =
      "Scatter plot displaying observed (experimental Y value) vs predicted (model predicted Y value) generated by '" +
      modelName +
      "' model. Observed and predicted values, and their source data row can be seen by hovering over individual data points.";
    let coefficientsChartTitle = "Relative importance and effect (weight) of input X on output Y";
    let coefficientsLegend =
      "Bar chart displaying the magnitude of effect (coefficients) for input variables. Positive or negative magnitude shows whether the input X are positively or negatively correlated with the output Y.";
    this.props.dispatch(evaluationActions.UpdateTitlesAndLegends(residualsChartTitle, residualsLegend, coefficientsChartTitle, coefficientsLegend));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.layout}>
          <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
              Model Evaluation
            </Typography>

            <Typography variant='subtitle1' gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 0 }}>
              Regression model residuals and sensitivity analysis for all created models can be seen below.
            </Typography>
            <div style={{ margin: 24 }}>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingBottom: 24 }}>
                  <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                    <InputLabel htmlFor="modeleval_model_select">Selected Regression Model</InputLabel>
                    <Select
                      fullWidth
                      style={{ textAlign: "left" }}
                      value={this.props.modelevaluation.selected_model_name}
                      onChange={this.modelChange}
                      input={<Input name="modeleval_model_select" id="modeleval_model_select" />}
                      data-cy="select_models1"
                    >
                      {this.props.modeldata.models.map(model => (
                        <MenuItem key={model.name} value={model.name}>
                          {model.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingBottom: 24 }}>
                  <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                    <InputLabel htmlFor="modeleval_output_select">Selected Output Variable</InputLabel>
                    <Select
                      fullWidth
                      style={{ textAlign: "left" }}
                      value={this.props.modelevaluation.selected_output_key}
                      onChange={this.outputVarChange}
                      input={<Input name="modeleval_output_select" id="modeleval_output_select" />}
                    >
                      {this.props.modelevaluation.current_output_keys.map(key => (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Card>
          {this.props.modeldata.data_snapshot_encoded_headers.length > 50 ? <ResidualsCoefficientsLarge /> : <ResidualsCoefficientsSmall axPost={this.props.axPost} />}

        </div>
      </div>
    );
  }
}

ModelEvaluation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    modelevaluation: state.modelevaluation,
  };
})(withStyles(styles)(ModelEvaluation));
