import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from "@material-ui/core/Divider";


const styles = theme => ({

    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            // width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        flexGrow: 1,
    },
    formControl: {
        margin: 0,
        minWidth: 120,
        flexWrap: 'wrap',
        // underline: 'red',

    },
    paperBody:
    {
        marginTop: 'auto',
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
});


class LinearRegression extends Component {

    componentDidMount() {
        
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.layout}>
                    <Card className={classes.paperBody} elevation={3} >
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'left', marginTop: 24, marginLeft: 24 }}>
                        Linear Regression
                </Typography>

                        <Typography gutterBottom style={{ textAlign: 'left', marginLeft: 24, marginBottom: 15 }}>
                            Pairwise linear regression.
                </Typography>
                        <div style={{ margin: 0 }}>
                            <Divider />

                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

LinearRegression.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default connect((state, props) => {
    return {
        views: state.views,
    }

})(withStyles(styles)(LinearRegression));
