import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";
import { red, green } from "@material-ui/core/colors";

// https://github.com/peterhle/react-session-timeout/tree/master/src
// https://material-ui.com/components/dialogs/#alerts
// https://blog.bitsrc.io/how-to-implement-idle-timeout-in-react-830d21c32942
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
})

const titleStyle = (theme) => ({
  root: {
    borderRadius: 1,
  }
});

const DialogTitle = withStyles(titleStyle)((props) => {
  const { children, classes, ...other } = props;
  return <MuiDialogTitle className={classes.root} {...other}>{children}</MuiDialogTitle>
});

const contentStyle = (theme) => ({
  root: {
    color: "#fff"
  }
});
const DialogContent = withStyles(contentStyle)(MuiDialogContent);

const actionStyle = (theme) => ({
  root: {
    borderRadius: 1
  }
})
const DialogActions = withStyles(actionStyle)(MuiDialogActions);

const buttonStyle = (theme) => ({
  root: {
    marginLeft: 4,
    marginRight: 4,
  },
  logout: {
    color: "#fff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  continue: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  }
})
const StyledButton = withStyles(buttonStyle)((props) => {
  const { children, classes, className, ...other} = props;
  let cls;
  if (className.includes("continue")) {
    cls = clsx(classes.root, classes.continue);
  } else {
    cls = clsx(classes.root, classes.logout);
  }
  return <Button className={cls} {...other}>{children}</Button>
});

export default function IdleTimeoutAlert(props) {
  const {
    open,
    countdown,
    onLogout,
    onContinue
  } = props;
  let countdownString = String(Math.floor(countdown/60)).padStart(1,"0") + ":" + String(countdown % 60).padStart(2,"0");
  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle id="timeout-alert-title">
        Idle Timeout Warning
      </DialogTitle>
      <DialogContent dividers="true">
        <Typography variant="body2">
          The current session is about to expire in {countdownString} seconds.
          </Typography>
        <Typography variant="body2">
          Would you like to Continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onLogout} variant="contained" className="logout">
          Logout
        </StyledButton>
        <StyledButton onClick={onContinue} variant="contained" className="continue">
          Continue
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}

IdleTimeoutAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  countdown: PropTypes.number.isRequired,
  onLogout: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
};
