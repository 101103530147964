export function UpdateModelPredictionInputValues(predictions) {
    return {
        type: 'UPDATE_MODEL_PREDICTION_VALUES',
        data: { prediction_input_values: predictions }
    }
}

export function UpdateModelPredictionOutputValues(values, inputValues, tableData, predictionPlotData, legendText, violated) {
    return {
        type: 'UPDATE_MODEL_PREDICTION_OUTPUT_VALUES',
        data: {
            prediction_output_values: values,
            prediction_input_values: inputValues,
            prediction_table_data: tableData,
            prediction_plot_data: predictionPlotData,
            legend_text: legendText,
            prediction_violated_constraints: violated,
        }
    }
}

export function UpdateSelectedModel(modelName) {
    return {
        type: 'UPDATE_MODEL_PREDICTION_SELECTED_MODEL',
        data: { selected_model_name: modelName }
    }
}

export function UpdatePredictionDisplayColumns(displayColumns, legendText) {
    return {
        type: 'UPDATE_PREDICTION_DISPLAY_COLUMNS',
        data: {
            prediction_display_columns: displayColumns,
            legend_text: legendText,
        }
    }
}

export function UpdatePredictionViolatedConstraints(violated){
    return {
        type: 'UPDATE_PREDICTION_VIOLATED_CONSTRAINTS',
        data: {
            prediction_violated_constraints: violated,
        }
    }
}

export function UpdateBatchPredictionTableData(batchPredictionData){
    return {
        type: 'UPDATE_BATCH_PREDICTION_TABLE_DATA',
        data: {
            batch_prediction_table_data: batchPredictionData
        }
    }
}