import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles, createMuiTheme, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ParallelCoordinatesChart from "./ParallelCoordinatesChart";
import ModelSettings from "./ModelSettings";
import * as HelperFunctions from "./ModelCalculationCode";
import OptimizationChartSpeedDial from "./OptimizationChartSpeedDial";
import "./handsontable.full.css";
import Tooltip from "@material-ui/core/Tooltip";

//Dev
import * as viewActions from "../actions/viewActions";
import * as predictionActions from "../actions/modelPredictionActions";
import * as optimizationActions from "../actions/modeloptimizationActions";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { poll } from "./utils";


const MuiTooltipTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14 pt"
      }
    }
  }
});

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 70,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  barColorPrimary1: {
    backgroundColor: "#e6194B"
  },
  barColorPrimary2: {
    backgroundColor: "#f58231"
  },
  barColorPrimary3: {
    backgroundColor: "#ffe119"
  },
  barColorPrimary4: {
    backgroundColor: "#bfef45"
  },
  barColorPrimary5: {
    backgroundColor: "#3cb44b"
  },
  barColorPrimary6: {
    backgroundColor: "#42d4f4"
  },
  barColorPrimary7: {
    backgroundColor: "#4363d8"
  },
  barColorPrimary8: {
    backgroundColor: "#9911eb4"
  },
  barColorPrimary9: {
    backgroundColor: "#f032e6"
  },
  barColorPrimary10: {
    backgroundColor: "#469990"
  },
  colorPrimary: {
    backgroundColor: "#F0F0F0"
  },
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    margin: theme.spacing(1),
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "100%",
    marginTop: 12,
    marginLeft: -110,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  htRoot: { // for handsontable (hot) overrides
    '& td.outputCell': {
      backgroundColor: "#dcedc8",
    },
    '& td.inputCell': {
      backgroundColor: "#e1f5fe",
    },
  }
});

class ModelOptimization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_results: null,
      task_status: "",
      task_id: "",
      loading: false,
      success: false,
      optTargetValue: "",
      optTargetErr: "",
      optMin: "",
      optMax: ""
    };
  }

  componentDidMount() {
  }

  modelChange = event => {
    this.props.dispatch(optimizationActions.UpdateSelectedModel(event.target.value));
  };

  shouldComponentUpdate(newProps) {
    return newProps.views.currentView === "modelopt";

  }

  componentDidUpdate() {
    let mot = this.refs.modelopttable;
    //let mot2 = this.refs.modelopttable2;
    if (mot !== undefined) {
      mot.hotInstance.render();
      //mot2.hotInstance.render();
      this.updateTableHighlight(this.props.modeloptimization.selected_optimization_row_index);
    }

    // Check task status
    if (this.state.task_id) {
      // check task status
      let interval = 1000; // ms
      poll(
        this.checkTaskStatus,
        (res) => {
          return res
            ? res.data.data.task_status === "finished" ||
            res.data.data.task_status === "failed"
            : false;
        },
        interval,
        this.state.task_id
      ).then((result) => {
        let task_status = result.data.data.task_status;
        if (task_status === "finished") {
          let res = result.data.data.task_result[0];
          this.setState({
            ...this.state,
            loading: false,
            success: true,
            task_results: res,
            task_status: task_status,
          });
          let results = JSON.parse(res.OptimizedResults);
          let targets = JSON.parse(res.Targets);
          let constraints = JSON.parse(res.Constraints);
          let resultObjects = [];
          let resultHeaders = [];
          let resultTableData = [];
          let resultStabilityEvaluations = [];
          let resultStabilityBands = [];
          let headersToRemove = new Set();
          if (results.length > 0) {
            let index = 0;
            results.forEach(function (res) {
              let mergedObj = { ...res };
              delete mergedObj['stdout'];
              delete mergedObj['_valid_result'];
              mergedObj["_dataRow"] = index.toString();
              index++;
              resultObjects.push(mergedObj);
              resultStabilityEvaluations.push(res.adjustments);
              resultStabilityBands.push(res.adjustment_statistics);
            });

            // NOTE: this is hacky, the problem is AS returns just a seemingly random order of columns
            // NOTE: columns not matching the PC plot columns will be dropped (categorical)
            resultHeaders = this.props.modeloptimization.optimization_display_columns.map(v => v.col);
            resultTableData = [];
            resultObjects.forEach(function (el, index) {
              let currData = [];
              resultHeaders.forEach(function (header) {
                if (header in el)
                  currData.push(el[header]);
                else
                  headersToRemove.add(header)
              });
              resultTableData.push(currData);
            });
            //
            resultHeaders = resultHeaders.filter(header => !headersToRemove.has(header));
          }


          this.props.dispatch(
            optimizationActions.UpdateOptimizationData(
              resultTableData,
              resultHeaders,
              constraints,
              targets,
              resultObjects,
              resultStabilityEvaluations,
              resultStabilityBands
            )
          );
          if (resultTableData.length === 0) {
            this.props.dispatch(
              viewActions.UpdateAlert(
                true,
                "Optimal Results Not Found",
                "No results matching the performance targets specified could be found within the ranges/constraints listed above."
              )
            );
          }
        } else if (!task_status || task_status === "failed") {
          this.setState({
            ...this.state,
            task_results: "task failed...",
            task_status: task_status,
            loading: false,
            success: false,
          });
          this.props.dispatch(
            viewActions.UpdateAlert(
              true,
              "Task failed",
              `The optimization task failed. No result found. If issues persist, please report the issue using the link below.`
            )
          );
        }
      })
        .catch((error) => {
          let task_error_header = "Check Task Status Failed";

          let task_result = this.state.task_results;
          if (typeof task_result == "string") {
            task_error_header = "Optimization Failed";
            // use the string as is for the text
          } else if (typeof task_result == "object" && "ErrorMessage" in task_result) {
            // pull the message out of the result object
            task_error_header = "Optimization Failed";
            task_result = task_result["ErrorMessage"];
          } else {
            // default issue response
            task_result = "The task queue responsed with error. Please check your input settings and/or refresh the page and reload your data before trying again. If issues persist, please report the issue using the link below."
          }

          this.setState({
            ...this.state,
            task_results: task_result,
            task_status: "error",
            loading: false,
            success: false,
          });
          console.error(error)
          this.props.dispatch(
            viewActions.UpdateAlert(
              true,
              task_error_header,
              task_result
            )
          );
        })
      // prevent loop
      this.setState({ ...this.state, task_id: '' })
    }

  }

  checkTaskStatus = (task_id) => {
    let url = `task/get`;
    console.log(`checkTaskStatus - task_id:${task_id}`);
    if (task_id === "") {
      console.error("No task id found");
      return "";
    }
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };
    return this.props
      .axPost(url, JSON.stringify({ task_id: task_id }), config)
      .then((res) => res)
      .catch((res) => {
        console.error(res);
      });
  };

  updateTableHighlight(row) {
    if (row === -1) {
      return;
    }
    var tableInstance = this.refs.modelopttable.hotInstance;
    var rows = tableInstance.countRows();
    var cols = tableInstance.countCols();
    for (var i = 0; i < rows; i++) {
      for (var j = 0; j < cols; j++) {
        let cell = tableInstance.getCell(i, j);
        if (cell !== undefined) {
          cell.style.backgroundColor = i === row ? "#dff0d8" : "white";
        }
      }
    }
    tableInstance.deselectCell();
  }

  changeUseColumnInOptimization = (index, event) => {
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    newOptSettings[index].use = !newOptSettings[index].use;
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationTypeChange = (index, event) => {
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    newOptSettings[index].opttype = event.target.value;
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationTargetTargetChange = (index, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    newOptSettings[index].mintargetvalue = parseFloat(event.target.value);
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationTargetPlusMinusRangeChange = (index, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    newOptSettings[index].mintargetvalue = parseFloat(event.target.value);
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };


  optimizationTargetMinimumChange = (index, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    let currentError = (newOptSettings[index].maxtargetvalue - newOptSettings[index].mintargetvalue) * 0.5;
    newOptSettings[index].mintargetvalue = parseFloat(event.target.value) - currentError;
    newOptSettings[index].maxtargetvalue = parseFloat(event.target.value) + currentError;
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationTargetMaximumChange = (index, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    let currentTarget = (newOptSettings[index].maxtargetvalue + newOptSettings[index].mintargetvalue) * 0.5;
    newOptSettings[index].mintargetvalue = currentTarget - parseFloat(event.target.value);
    newOptSettings[index].maxtargetvalue = currentTarget + parseFloat(event.target.value);
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationMinimumChange = (index, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    newOptSettings[index].minvalue = parseFloat(event.target.value);
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationMaximumChange = (index, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let newOptSettings = JSON.parse(JSON.stringify(this.props.modeloptimization.optimization_output_target_settings));
    newOptSettings[index].maxvalue = parseFloat(event.target.value);
    this.props.dispatch(optimizationActions.UpdateOptimizationTargetSettings(newOptSettings));
  };

  optimizationUseSeedChange = event => {
    let newUseSetting = !this.props.modeloptimization.optimization_use_random_seed;
    let seedValue = this.props.modeloptimization.optimization_random_seed_value;
    this.props.dispatch(optimizationActions.UpdateOptimizationRandomSettings(newUseSetting, seedValue));
  };

  optimizationSeedValueChange = event => {
    let newUseSetting = this.props.modeloptimization.optimization_use_random_seed;
    let seedValue = event.target.value;
    if (isNaN(seedValue)) {
      seedValue = 100;
    }
    this.props.dispatch(optimizationActions.UpdateOptimizationRandomSettings(newUseSetting, seedValue));
  };

  submitOptimizationTask = (data) => {
    // submit a task to redis queue
    this.props
      .axPost("task/optimize", JSON.stringify({ data: data }), {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .then((res) => {
        this.setState({ ...this.state, task_id: res.data.task_id });
        this.setState({ ...this.state, loading: true, success: false });
        console.log(`task id: ${res.data.task_id}`);
      })
      .catch((err) => {
        console.log("submit task error")
        console.error(err)
        this.props.dispatch(
          viewActions.UpdateAlert(
            true,
            "Optimization failed",
            "Failed to submit optimization task. If issues persist, please report the issue using the link below."
          )
        );
        this.setState({ ...this.state, loading: false, success: false });
      });
  };

  performOptimization = () => {
    let selectedModelName = this.props.modeloptimization.selected_model_name;
    let optimizationInputs = {
      model_type: this.props.modeldata.models.filter(function (el) {
        return el.name === selectedModelName;
      })[0].type,
      ranges: this.props.modelusage.data_ranges,
      targets: this.props.modeloptimization.optimization_output_target_settings.filter(function (el) {
        return el.use;
      }),
      constraints: this.props.modelusage.settings_all_constraints.filter(function (el) {
        return el.use;
      }),
      variations: this.props.modeloptimization.optimization_stability_variations,
      seed: this.props.modeloptimization.optimization_use_random_seed ? null : parseFloat(this.props.modeloptimization.optimization_random_seed_value)
    };
    const data = {
      source_id: this.props.modeldata.source_id,
      inputs: optimizationInputs, user_name: this.props.user.name, user_id: this.props.user.nickname,
    }
    // submit task
    this.submitOptimizationTask(data)

    // Task status is checked and processed in ComponentDidUpdate()
  };

  tableSelection = (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
    let rowData = this.props.modeloptimization.optimization_table_data[row];
    this.props.dispatch(optimizationActions.UpdateSelectedOptimization(row, rowData));
    this.updateTableHighlight(row);
  };

  viewOptimizationInPredictionWindow = () => {
    let newPredInputObj = {};
    let inputValues = this.props.modeldata.data_columns
      .filter(function (el) {
        return el.type === "input";
      })
      .map(x => x.col);
    let currHeaders = this.props.modeloptimization.optimization_table_headers;
    let currData = this.props.modeloptimization.selected_optimization_row_data;
    for (var i = 0; i < currHeaders.length; i++) {
      let header = currHeaders[i];
      let dataVal = currData[i];
      if (inputValues.indexOf(header) > -1) {
        newPredInputObj[header] = dataVal;
      }
    }
    let currModelName = this.props.modeloptimization.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function (el) {
      return el.name === currModelName;
    })[0];
    let currConstraints = this.props.modelusage.settings_all_constraints.filter(function (el) {
      return el.use;
    });
    let displayColumns = this.props.modelprediction.prediction_display_columns.filter(function (el) {
      return el.display;
    });
    this.props.dispatch(predictionActions.UpdateSelectedModel(selModel.name));
    if (selModel.type === "PLS") {
      let results = HelperFunctions.CalculatePLSRes(
        newPredInputObj,
        selModel.x_mean_, selModel.y_mean_, selModel.x_std_, selModel.coef_,
        selModel.inputOrganization, selModel.outputOrganization, this.props.modelusage.data_ranges,
        this.props.modelusage.categorical_variables, this.props.modelusage.settings_all_constraints
      );
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
        displayColumns
          .filter(function (el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    } if (selModel.type === "NN") {
      let results = HelperFunctions.CalculateNNPred(
        newPredInputObj,
        selModel.outputOrganization.length,
        selModel.coefficients,
        selModel.intercepts,
        selModel.layerCount,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modeldata.categorical_variables,
        selModel.dataMin,
        selModel.dataRange,
        currConstraints
      );
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (green line) within the following dimensions: " +
        displayColumns
          .filter(function (el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }
    if (selModel.type === "KRR") {
      let results = HelperFunctions.CalculateKRRPred(
        newPredInputObj,
        selModel.outputOrganization.length,
        selModel.dual_coef,
        selModel.x_fit,
        selModel.gamma,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modeldata.categorical_variables,
        selModel.dataMin,
        selModel.dataRange,
        currConstraints
      );
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (purple line) within the following dimensions: " +
        displayColumns
          .filter(function (el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }
    if (selModel.type === "SVR") {
      let results = HelperFunctions.CalculateSVRPred(
        newPredInputObj,
        selModel.outputOrganization.length,
        selModel.support_vectors_,
        selModel.dual_coef_,
        selModel.intercept_,
        selModel._gamma,
        selModel.inputOrganization,
        selModel.outputOrganization,
        this.props.modeldata.categorical_variables,
        selModel.dataMin,
        selModel.dataRange,
        currConstraints
      );
      let predictionPlotData = [];
      predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
      predictionPlotData.push(results.completePredObject);
      let newPredText =
        "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (pink line) within the following dimensions: " +
        displayColumns
          .filter(function (el) {
            return el.display;
          })
          .map(x => x.col)
          .join(", ") +
        ". The prediction was generated using the '" +
        selModel.name +
        "' model and inputs/outputs are reflected in the table to the right.";
      this.props.dispatch(
        predictionActions.UpdateModelPredictionOutputValues(
          results.outputValues,
          results.inputValues,
          results.tableData,
          predictionPlotData,
          newPredText,
          results.violatedConstraints
        )
      );
    }

    setTimeout(() => {
      this.props.dispatch(viewActions.changeViewType("modelpred"));
    }, 1);
  };

  copyToClipboard = () => {
    let copyText = "";
    this.props.modeloptimization.optimization_table_headers.forEach(header => {
      copyText += header + "\t";
    });
    copyText += "\r\n";
    this.props.modeloptimization.optimization_table_data.forEach(row => {
      this.props.modeloptimization.optimization_table_headers.forEach((header, headerIndex) => {
        copyText += row[headerIndex] + "\t";
      });
      copyText += "\r\n";
    });
    var textField = document.createElement("textarea");
    textField.innerHTML = copyText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  updateStabilityVariation = (col, event) => {
    let updatedVal = event.target.value;
    if (updatedVal < 0) {
      updatedVal = 0;
    }
    let updatedVariations = Object.assign([], this.props.modeloptimization.optimization_stability_variations);
    updatedVariations.forEach(column => {
      if (column.column === col) {
        column.percent = updatedVal;
      }
    });
    //id={col.column + "_stability_input"}
    document.getElementById(col + "_stability_input").value = updatedVal;
    this.props.dispatch(optimizationActions.UpdateOptimizationStabilityVariations(updatedVariations));
  };

  cellStyle = (columnsInfo) => {
    let nInput = 0
    this.props.modeloptimization.optimization_display_columns.map(x => {
      if (x.type === 'input' && x.display) {
        nInput += 1
      }
      return ''
    })
    return (rowIndex, colIndex, prop) => {
      // Group Header row on top
      if (colIndex >= nInput) {
        return {
          className: "htCenter outputCell"
        }
      } else {
        return {
          className: "htCenter inputCell"
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    const buttonClassname = clsx({
      [classes.buttonSuccess]: this.state.success,
      [classes.button]: !this.state.success,
    });
    return (
      <div>
        <div className={classes.layout}>
          <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
              Experiment Optimization
            </Typography>

            <Typography gutterBottom variant='subtitle1' style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
              Use your trained models to optimize towards specific performance below.
            </Typography>
            <div style={{ margin: 24 }}>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 24 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="modelopt_model_select">Selected Regression Model</InputLabel>
                    <Select
                      style={{ textAlign: "left" }}
                      value={this.props.modeloptimization.selected_model_name}
                      onChange={this.modelChange}
                      input={<Input name="modelopt_model_select" id="modelopt_model_select" />}
                      data-cy="select_models3"
                    >
                      {this.props.modeldata.models.map(model => (
                        <MenuItem key={model.name} value={model.name}>
                          {model.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Card>

          <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
              Outputs (Y) to Optimize
            </Typography>
            <div style={{ margin: 24 }}>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={9}>
                  <div>
                    {this.props.modeloptimization.optimization_output_target_settings.map((col, index) => (
                      <div key={"opt_output_setting_" + index}>
                        <Grid container justify="flex-start" alignItems="flex-start" spacing={8}>
                          <Grid item xs={3}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={col.use}
                                    onChange={() => this.changeUseColumnInOptimization(index)}
                                    value={"Constraint " + index + " Checkbox"}
                                    color="primary"
                                    style={{ textAlign: "right" }}
                                  />
                                }
                                label={
                                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '8rem' }}>
                                    <Typography align='left' noWrap>{col.column}</Typography>
                                  </div>
                                }
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl fullWidth className={classes.formControl} disabled={!col.use}>
                              <InputLabel htmlFor={"model_opt_type_select_" + index}>Optimization Type</InputLabel>
                              <Select
                                style={{ textAlign: "left" }}
                                value={col.opttype}
                                onChange={ev => this.optimizationTypeChange(index, ev)}
                                input={<Input name={"model_opt_type_select_" + index} id={"model_opt_type_select_" + index} />}
                              >
                                {this.props.modelusage.optimization_target_types.map(type => (
                                  <MenuItem key={type.value} value={type.value}>
                                    {type.display}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          {col.opttype !== "target" ? (
                            // type == Min or Max
                            <Grid item xs={3}>
                              <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                  id={"opt_threshold_input_" + index}
                                  label={col.opttype === "maximize" ? "Lower Threshold" : "Upper Threshold"}
                                  defaultValue={col.opttype === "maximize" ? ((col.maxvalue - col.minvalue) * 0.8 + col.minvalue).toFixed(3) : ((col.maxvalue - col.minvalue) * 0.2 + col.minvalue).toFixed(3)}
                                  type="number"
                                  className={classes.textField}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  disabled={!col.use}
                                  margin="normal"
                                  onKeyPress={ev => {
                                    if (ev.key === "Enter") {
                                      if (col.opttype === "maximize") {
                                        this.optimizationMaximumChange(index, ev);
                                      } else {
                                        this.optimizationMinimumChange(index, ev);
                                      }
                                    }
                                  }}
                                  onBlur={ev => {
                                    if (col.opttype === "maximize") {
                                      this.optimizationMaximumChange(index, ev);
                                    } else {
                                      this.optimizationMinimumChange(index, ev);
                                    }
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </FormControl>
                            </Grid>
                          ) : (
                            // type == Target
                            <>
                              <Grid item xs>
                                <FormControl fullWidth className={classes.formControl} style={{ width: "100%" }}>
                                  <TextField
                                    id={"opt_mintarget_input_" + index}
                                    label={"Target Value"}
                                    // defaultValue={((col.mintargetvalue + col.maxtargetvalue) * 0.5).toFixed(3)}
                                    type="number"
                                    //className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled={!col.use}
                                    margin="normal"
                                    onKeyPress={ev => {
                                      if (ev.key === "Enter") {
                                        this.optimizationTargetMinimumChange(index, ev);
                                      }
                                    }}
                                    onBlur={ev => this.optimizationTargetMinimumChange(index, ev)}
                                    onChange={ev => this.optimizationTargetMinimumChange(index, ev)}
                                    value={+((col.mintargetvalue + col.maxtargetvalue) * 0.5).toFixed(5)}
                                    style={{ marginTop: 0, width: "100%" }}
                                    data-cy={"opt_target" + index}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs>
                                <FormControl fullWidth className={classes.formControl} style={{ width: "100%" }}>
                                  <TextField
                                    id={"opt_maxtarget_input_" + index}
                                    label={"±Tolerance"}
                                    // defaultValue={((col.maxtargetvalue - col.mintargetvalue) * 0.5).toFixed(3)}
                                    type="number"
                                    //className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    disabled={!col.use}
                                    margin="normal"
                                    onKeyPress={ev => {
                                      if (ev.key === "Enter") {
                                        this.optimizationTargetMaximumChange(index, ev);
                                      }
                                    }}
                                    onBlur={ev => this.optimizationTargetMaximumChange(index, ev)}
                                    onChange={ev => this.optimizationTargetMaximumChange(index, ev)}
                                    value={+((col.maxtargetvalue - col.mintargetvalue) * 0.5).toFixed(5)}
                                    style={{ marginTop: 0, width: "100%" }}
                                    data-cy={"opt_tol" + index}
                                  />
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        {/* <Divider style={{marginTop:8, marginBottom:8}}/> */}
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div>
                    <Typography variant="button" gutterBottom style={{ textAlign: "left" }}>
                      <u>Current Model Constraints</u>
                    </Typography>
                  </div>
                  {this.props.modelusage.settings_all_constraints.filter(function (el) {
                    return el.use;
                  }).length > 0 &&
                    this.props.modelusage.settings_all_constraints
                      .filter(function (el) {
                        return el.use;
                      })
                      .map((cons, index) => (
                        <div key={"opt_cons_" + index}>
                          {cons.type === "sum" && (
                            <Typography variant="body2" gutterBottom style={{ textAlign: "left", marginBottom: 0 }}>
                              • {"Σ(" + cons.columns.join(", ") + ") " + cons.displaySign + " "}
                              <span style={{ fontWeight: 500 }}>{cons.value}</span>
                            </Typography>
                          )}
                          {cons.type === "ratio" && (
                            <Typography variant="body2" gutterBottom style={{ textAlign: "left", marginBottom: 0 }}>
                              • {"Σ(" + cons.columns.join(", ") + ") / Σ(" + cons.columns_b.join(", ") + ") " + cons.displaySign + " "}
                              <span style={{ fontWeight: 500 }}>{cons.value}</span>
                            </Typography>
                          )}
                        </div>
                      ))}
                  {this.props.modelusage.settings_all_constraints.filter(function (el) {
                    return el.use;
                  }).length === 0 && (
                      <div>
                        <Typography variant="body2" gutterBottom style={{ textAlign: "left" }}>
                          • None
                      </Typography>
                      </div>
                    )}

                  <Typography variant="button" gutterBottom style={{ textAlign: "left", marginTop: 18 }}>
                    <u>Current Data Ranges</u>
                  </Typography>
                  {/*range for input columns*/}
                  {this.props.modelusage.data_ranges
                    .filter(function (el) {
                      return el.categorical === false;
                    })
                    .map((range, index) => (
                      <div key={"opt_datarange_" + index}>
                        <MuiThemeProvider theme={MuiTooltipTheme}>
                          <Tooltip title={range.column} placement="top">
                            <Typography variant="body2" gutterBottom style={{ textAlign: "left", marginBottom: 0 }}>
                              • {range.column.substring(0, 6) + ": "} <span style={{ fontWeight: 500 }}>{range.usermin + "–" + range.usermax}</span>
                            </Typography>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    ))}

                  {/*range for output columns*/}
                  {this.props.modeloptimization.optimization_output_target_settings.map((col, index) => (
                    <div key={"opt_datarange_" + index}>
                      <MuiThemeProvider theme={MuiTooltipTheme}>
                        <Tooltip title={col.column} placement="top">
                          <Typography variant="body2" gutterBottom style={{ textAlign: "left", marginBottom: 0 }}>
                            • {col.column.substring(0, 6) + ": "} <span style={{ fontWeight: 500 }}>{col.mintargetvalue.toFixed(3) + "–" + col.maxtargetvalue.toFixed(3)}</span>
                          </Typography>
                        </Tooltip>
                      </MuiThemeProvider>
                    </div>
                  ))}

                  {this.props.modelusage.categorical_variables.Inputs.length > 0 && (
                    <div>
                      {Object.keys(this.props.modelusage.categorical_variables.Inputs).map((key, index) => (
                        <div key={"opt_catvar_" + index}>
                          <Typography variant="body2" gutterBottom style={{ textAlign: "left" }}>
                            • {key + " :" + this.props.modelusage.categorical_variables.Inputs[key].map(col => col.replace(key, "")).join(", ")}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                  {/*The code below are commented out to remove the random seed input. Might convert to input field for
                  number of optimization results in the future*/}
                  {/*<Typography variant="button" gutterBottom style={{ textAlign: "left", marginTop: 18, marginBottom: 12 }}>
                    <u>Optimization Seed Settings</u>
                  </Typography>
                  <Grid container justify="flex-start" alignItems="flex-start" spacing={8}>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.props.modeloptimization.optimization_use_random_seed}
                              onChange={() => this.optimizationUseSeedChange()}
                              value={"optSeedCheckbox"}
                              color="primary"
                              style={{ textAlign: "left" }}
                            />
                          }
                          label={"Use Random Seed"}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <FormGroup>
                        <TextField
                          id={"opt_seed_input_"}
                          label={"Selected Seed"}
                          defaultValue={100}
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={this.props.modeloptimization.optimization_use_random_seed}
                          margin="normal"
                          onKeyPress={ev => {
                            if (ev.key === "Enter") {
                              this.optimizationSeedValueChange(ev);

                          }}}
                          onBlur={ev => this.optimizationSeedValueChange(ev)}
                          style={{ marginTop: 0, width: "70%" }}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>*/}
                </Grid>
              </Grid>
              <div style={{ marginTop: 24 }}>
                <ModelSettings />
              </div>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={buttonClassname}
                      disabled={
                        (this.props.modeloptimization.optimization_output_target_settings.filter(function (el) {
                          return el.use;
                        }).length === 0) || this.state.loading
                      }
                      //   boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
                      onClick={this.performOptimization}
                      style={{ float: "right", marginRight: 0 }}
                      data-cy="run_optimize_btn"
                    >
                      Find Optimized Inputs
                  </Button>
                    {this.state.loading && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>

          {this.props.modeloptimization.optimization_plot_data.length > 0 && (
            <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
              <div style={{ marginLeft: 24, marginRight: 24, marginBottom: 24 }}>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 0 }}>
                      Optimization Results
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                  </Grid>
                </Grid>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {this.props.modeloptimization.optimization_plot_data.length > 0 && (
                      <Grid item xs={12} sm={12} md={12} lg={12} id="optParallelCoordsDiv">
                        <ParallelCoordinatesChart
                          data={this.props.modeloptimization.optimization_plot_data}
                          dimensions={this.props.modeloptimization.optimization_display_columns}
                          appendDiv={"optParallelCoordsDiv"}
                          chartID={"optParallelCoords_ChartSVG"}
                          chartType={"modelopt"}
                          currentView={this.props.views.currentView}
                          heightToWidthRatio={0.18}
                          legendText={this.props.modeloptimization.legend_text}
                          useFullDataRanges={true}
                          fullDataRanges={this.props.modeldata.data_ranges.concat(this.props.modeldata.output_data_ranges)}
                          useOptimizationScheme={true}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
                    <div className={classes.htRoot}>
                      <HotTable
                        data={this.props.modeloptimization.optimization_table_data}
                        colHeaders={this.props.modeloptimization.optimization_table_headers}
                        rowHeaders={false}
                        columnSorting={false}
                        readOnly={true}
                        height="275"
                        stretchH="all"
                        minRows="10"
                        cells={this.cellStyle()}
                        settings={{ outsideClickDeselects: false }}
                        root="hot"
                        ref="modelopttable"
                        style={{ fontSize: "smaller", fontFamily: "Roboto", color: "black" }}
                        afterSelection={this.tableSelection}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{ paddingTop: 12 }}>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} style={{ paddingTop: 18 }}>
                    {/*<Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                      disabled={this.props.modeloptimization.selected_optimization_row_index === -1}
                      onClick={this.viewOptimizationInPredictionWindow}
                      style={{ float: "right", marginRight: 0, marginTop: 0 }}
                    >
                      View Selected Result in Model Prediction Tab
                    </Button>*/}
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                      //disabled={this.props.modeloptimization.selected_optimization_row_index === -1}
                      onClick={this.copyToClipboard}
                      style={{ float: "right", marginRight: 8, marginTop: 0 }}
                    >
                      Copy Results to Clipboard
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <OptimizationChartSpeedDial
                      style={{ marginBottom: 0 }}
                      chartID={"optParallelCoords_ChartSVG"}
                      chartTitle={"OptimizationChartPNG"}
                      chartType="modelopt"
                      marginBottom={0}
                      direction="left"
                      height={40}
                      optimizationData={this.props.modeloptimization.optimization_table_data}
                      optimizationHeaders={this.props.modeloptimization.optimization_table_headers}
                      optimizationTargets={this.props.modeloptimization.optimization_used_targets}
                    />
                  </Grid>
                </Grid>
              </div>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

ModelOptimization.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeloptimization: state.modeloptimization,
    modelusage: state.modelusage,
    modeldata: state.modeldata,
    modelprediction: state.modelprediction,
    newmodel: state.newmodel,
  };
})(withStyles(styles)(ModelOptimization));
