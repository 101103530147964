import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from "@material-ui/core/CardMedia";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import Overcured from "../images/GameImages/Overcured.png";
import ChemQuest from "../images/GameImages/ChemQuest.png";
import Mixer from "../images/GameImages/Mixer.png";
import MagicSchoolBus from "../images/GameImages/MagicSchoolBus.png";
import SkylabCrush from "../images/GameImages/SkylabCrush.png";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: "#cccccc",
      main: "#ffffff",
      dark: "#cccccc"
    },
    secondary: {
      light: "#4dabf5",
      main: "#2196f3",
      dark: "#1769aa"
    }
  },
});

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  root: {
    display: 'flex',
    marginTop: "auto",
    margin: 24,
    textAlign: "left"
  },
  paperBody: {
    marginTop: "auto"
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  media: {
    minWidth: '512px',
    width: '512px',
    height: '384px'
  },
  cardHeader: {
    textAlign: "left",
    marginTop: 24,
    marginLeft: 24
  },
  cardDesc: {
    marginTop: 24,
    marginLeft: 24
  },
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "70rem"
  },
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
});

const Games = [
  // { name: "Magic School Bus in Polymer Peril", desc: "desc" },
  {
    name: "Overcured!",
    desc: "Overcured is a chaotic couch co-op laboratory game for 1-4 players. Working as a team, you and your fellow lab mates must synthesize, process, and characterize a variety of tantalizing materials before the customer's deadline!",
    img: Overcured
  },
  {
    name: "Chem Quest",
    desc: "The Intergalactic Federation of Chemists' outpost on the remote planet of Bazoik has been overrun by evil polymer-eating creatures from another dimension! These slimy \"Flemoids\" have taken the citizens of Bazoik captive and it is up to you and the Chem Mix Squadron to free them. Thankfully you have a handy array of \"zorching\" devices which can send these invaders back to their home dimension so you can succeed in your mission.",
    img: ChemQuest
  },
  {
    name: "Mixer",
    desc: "Recently shutdown, Mixer was a tool focused on interactivity and low latency streaming and sharing of chemical mixing procedures.",
    img: Mixer
  },
  {
    name: "Skylab Crush",
    desc: "A relaxing match 3 game, following the classic Bejeweled tradition but with a Skylab twist!",
    img: SkylabCrush,
  },
  {
    name: "Magic School Bus in Polymer Peril",
    desc: "Ms. Frizzle and class are in for a surprise as they dive into the topsy-turvy world of polymer rheology. Stuck in a glassy state, you must assist Arnold, Keesha, and the rest of the class to better understand viscoelasticity and dislodge the Magic School Bus so they can return home!",
    img: MagicSchoolBus
  }

]


class GameCard extends Component {
  componentDidMount() { }

  render() {
    const { classes, name, desc, img, onClick } = this.props;
    return (
      <Card className={classes.root} elevation={4}>
        <CardActionArea className={classes.details} onClick={onClick}>
          <CardContent className={classes.content}>
            <Typography className={classes.cardHeader} variant="h5" gutterBottom>
              {name}
            </Typography>
            <hr />
            <Typography className={classes.cardDesc} variant="h6" gutterBottom>
              {desc}
            </Typography>
          </CardContent>
        </CardActionArea>
        {img && (
          <CardMedia
            className={classes.media}
            image={img}
            title={name}
          />
        )
        }
      </Card>
    );
  }
}

GameCard.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  img: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

class GamesPage extends Component {
  state = {
    gameActive: false
  };
  componentDidMount() {
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive(name) {
    if (name === "Skylab Crush" || !name)
      this.setState({ gameActive: !this.state.gameActive })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.layout}>
            <Paper className={classes.paperBody} elevation={3} style={{ margin: 24 }}>
              <Typography variant="h4" gutterBottom style={{ textAlign: "center", marginTop: 24 }}>
                Skylab Games Studio
              </Typography>
              {
                !this.state.gameActive && (
                  Games.map((game) => {
                    return <GameCard classes={classes} name={game.name} desc={game.desc} img={game.img} onClick={() => { this.toggleActive(game.name) }} />
                  })
                )
              }
              {
                this.state.gameActive && (
                  <>
                    <Button color="primary" variant="filled" onClick={() => this.toggleActive(false)}>Close</Button>

                    <div className={classes.container}>


                      {/* <iframe className={classes.responsiveIframe} title="hello" src="http://localhost:8050" /> */}
                      <iframe className={classes.responsiveIframe} title="hello" src="https://viewpoint.dev.mi.cld.3m.com" />
                    </div>
                  </>
                )
              }
            </Paper>
          </div>
        </MuiThemeProvider>
      </div>)
  }
}


GamesPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views
  };
})(withStyles(styles)(GamesPage));
