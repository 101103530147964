export function OrganizePairwiseData(allData, inputCols, outputCols, linRegData, ranges){
    //each obj xCol, yCol, xIndex, yIndex, r2, slope, yIntercept,xMin, xMax, yMin, yMax
    let index = 0;
    let indexMap = {}
    let allCols = inputCols.concat(outputCols);
    allCols.forEach(element => {
        indexMap[element] = index;
        index++;
    });
    let rangeMap = ranges.reduce((map, obj) => {
        map[obj.column] = obj;
        return map;
    }, {});
    let regplots = [];
    let densityplots = [];
    function copyData(el) {
        let datapoints = {
            x: el[this.xCol],
            y: el[this.yCol],
            _dataRow: el['_dataRow']
        };
        return datapoints;
    }
    function createOnePlot(el) {
        let data = el[allCols[this.i]];
        return data;
    }

    for (var i = 0; i < allCols.length; i++)
    {
        for (var j = 0; j < allCols.length; j++)
        {
            let xCol = allCols[i];
            let yCol = allCols[j];
            
            let regData = linRegData.filter(el =>{ return (el.col1===xCol && el.col2===yCol)})[0];
            let r2 = regData.r * regData.r;
            let slope = regData.slope;
            let yIntercept = regData.intercept;

            let datapoints = allData.map(copyData, { xCol, yCol });
            let xMin = rangeMap[xCol].min; let xMax = rangeMap[xCol].max;
            let yMin = rangeMap[yCol].min; let yMax = rangeMap[yCol].max;
            regplots.push({datapoints: datapoints, xCol: xCol, yCol: yCol, r2: r2, slope: slope,
                yIntercept: yIntercept, xMin: xMin, xMax: xMax, yMin: yMin, yMax: yMax});
        }
        let densityObj = {col: allCols[i], data: []};
        densityObj.data = allData.map(createOnePlot, { i });
        densityplots.push(densityObj);
    }
    return {'regplots': regplots, 'densityplots': densityplots};
}
