
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { Auth0Provider } from "@auth0/auth0-react";
import './css/index.css';
import App from './App';
import SiteMaintenance from './maintenance/SiteMaintenance';
import * as serviceWorker from './serviceWorker';
import history from "./utils/history";
import auth0_conf from './Auth0_conf';
import store from './store/store';
import './fonts/segoeuil.ttf';
import './fonts/Roboto-Bold-webfont.woff';
import './fonts/Roboto-Light-webfont.woff';
import './fonts/Roboto-Regular-webfont.woff';

const SITE_MAINTENANCE = false;

if (!SITE_MAINTENANCE) {
  const onRedirectCallback = appState => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  ReactDOM.render(
    <Auth0Provider
      domain={auth0_conf.domain}
      clientId={auth0_conf.client_id}
      audience={auth0_conf.audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="openid profile email offline_access"
      useRefreshTokens={true}
      cacheLocation={`localstorage`}
    >
      <Provider store={store}>
        <App />
      </Provider>

    </Auth0Provider>
    ,
    document.getElementById('root')
  );
}

if (SITE_MAINTENANCE) {
  ReactDOM.render(
    <SiteMaintenance />,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
