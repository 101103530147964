import React, {useState, useRef, useEffect} from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { HotTable } from "@handsontable/react";
import * as actions from "../actions/viewActions";
import * as viewActions from "../actions/viewActions";
import * as modelDataActions from "../actions/modeldataActions";
import * as newModelActions from "../actions/newmodelActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import { CSVLink } from "react-csv";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  plscheck: {
    "&$checked": {
      color: "#0087ec"
    }
  },
  nncheck: {
    "&$checked": {
      color: "#4caf50"
    }
  },
  krrcheck: {
    "&$checked": {
      color: "#9c27b0"
    }
  },
  svrcheck: {
    "&$checked": {
      color: "#e91e63"
    }
  },
  hpocheck: {
    "&$checked": {
      color: "#00695c"
    }
  },
  checked: {},
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    margin: theme.spacing(1),
    color: green[500],
    position: "absolute",
    top: "100%",
    left: "100%",
    marginTop: 12,
    marginLeft: -90
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
});

function EditData(props) {
  const { classes } = props;
  const csvLink = useRef();
  let hotTableComponent = React.createRef();

  const [showRenameColBtn, setShowRenameColBtn] = useState(false)
  const [selectedCol, setSelectedCol] = useState('')
  const [renameButtonText, setRenameButtonText] = useState('Rename Column')
  const [showRenameColInput, setshowRenameColInput] = useState(false)
  const [selectingCell, setSelectingCell] = useState(false)
  const [hoveringRenameBtn, setHoveringRenameBtn] = useState(false)
  const [newColName, setNewColName] = useState('')
  const [HotData, setHotData] = useState({data: [], headers: []})
  const [csvData, setCSVData] = useState({data:[], filename:'download.csv'})

  let selectedSheet = props.newmodel.file_selected_sheet;
  let allData = props.modeldata.all_data
  let sheetData = JSON.parse(allData[selectedSheet])
  const [modifiedColHeaders, setmodifiedColHeaders] = useState(sheetData['columns'])

  let handleSaveData = () => {
    props.dispatch(viewActions.changeLoadingState(true));
    props.dispatch(viewActions.updateLoadingMessage("Saving Data"));
    let newData = hotTableComponent.current.hotInstance.getData();
    // convert empty cell to null
    newData = newData.map(row => {
      row = row.map( item => { return item !== "" ? item: null })
      return row
    })
    let newHeaders = hotTableComponent.current.hotInstance.getColHeader();
    let data = {
      source_id: props.modeldata.source_id,
      new_data: JSON.stringify(newData),
      new_headers: newHeaders,
      sheet_name: props.newmodel.file_selected_sheet
    };
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" }
    };

    props
      .axPost("data/update", data, config)
      .then(res => {
        let response = res.data;
        if (response.Sheets !== undefined) {
          let selectedSheetName = props.newmodel.file_selected_sheet
          let filesheets = JSON.parse(response.Sheets);
          let allData = JSON.parse(response.all_data);
          props.dispatch(modelDataActions.UpdateAllData(allData));
          props.dispatch(newModelActions.UpdateLoadedFileSheets(filesheets));
          props.dispatch(
              modelDataActions.UpdateDataSnapshot([], [], [], [], [], [], [])
          );
          props.dispatch(viewActions.UpdateModelCanBeSaved(false));
          props.dispatch(viewActions.changeLoadingState(false));

          // update current header
          let selectedSheet = filesheets.filter((el) => el.sheet === selectedSheetName)
          props.dispatch(
              newModelActions.UpdateSelectedFileSheet(
                  selectedSheet[0].sheet,
                  selectedSheet[0].columns,
                  selectedSheet[0].excludedColumns
              )
          );
          props.dispatch(modelDataActions.UpdateAllFileHeaders(
              selectedSheet[0].columns,
              props.modeldata.uploaded_file_name,
              props.modeldata.source_id
          ))

        }
      })
      .catch(error => {
        console.error(error);
        props.dispatch(
          viewActions.UpdateAlert(
            true,
            "Update data failed",
            "Can't save modified data. If issues persist, please report the issue using the link below."
          )
        );
      });


  };

  let handleCancel = () => {
    props.dispatch(actions.UpdateEditDataDialog(false));
  };

  let allDataToHotTableData = () => {
    let selectedSheet = props.newmodel.file_selected_sheet;
    let allData = JSON.parse(props.modeldata.all_data[selectedSheet])
    if (allData) {
      let colNames = ''
      if (modifiedColHeaders) {
        colNames = modifiedColHeaders
      } else {
        colNames = allData['columns'];
      }
      let HotTableData = allData['data'];
      setHotData({data: HotTableData, headers: colNames});
    }
  };

  let handleSheetChange = (event) => {
    let currentSheet = props.newmodel.file_sheets.filter(function (el) {
      return el.sheet === event.target.value;
    })[0];
    let filename = props.newmodel.filename;
    props.dispatch(
        newModelActions.UpdateSelectedFileSheet(
            currentSheet.sheet,
            currentSheet.columns,
            currentSheet.excludedColumns
        )
    );
    props.dispatch(
        modelDataActions.UpdateAllFileHeaders(
            currentSheet.columns,
            filename,
            props.modeldata.source_id
        )
    );
    props.dispatch(
        modelDataActions.UpdateDataSnapshot([], [], [], [], [], [], [])
    );
  }

  let handleRenameCol = () => {
    if(renameButtonText==='Rename Column'){
      setShowRenameColBtn(true)
      setshowRenameColInput(true)
      setRenameButtonText('Save new name')
    }
    else {
      setShowRenameColBtn(false)
      setshowRenameColInput(false)
      setRenameButtonText('Rename Column')
      // Temporarily save new column name to local state
      let colNames = modifiedColHeaders;
      // rename if duplicated
      let newColNameUnique = newColName
      while(colNames.includes(newColNameUnique)) {
        newColNameUnique = newColNameUnique + '_1'
      }
      colNames[selectedCol] = newColNameUnique;
      setmodifiedColHeaders(colNames)
    }

  }

  let cellSelected = (row, column, row2, column2, preventSelection, selectionLayerLevel) => {
    setSelectingCell(true)

    if(column === column2){
      setSelectingCell(true)
      setSelectedCol(column)
      setNewColName(HotData.headers[column])
    } else {
      setSelectingCell(false)
      setSelectedCol('')
    }
  }

  let cellDeselected = () => {
    setSelectingCell(false)
  }

  let updateRenameColText = event => {
    let currVal = event.target.value;
    setNewColName(currVal);
  };

  let downloadCSV = () => {
    csvLink.current.link.click()
  }

  let generateCSVData = () => {
    if(Array.isArray(HotData.data[0])) {
      let oriFilename = props.newmodel.selected_file
      let csvName = oriFilename.split('.')[0] + '_MS.csv'
      let newData = HotData.data;
      let newHeaders = HotData.headers;

      // convert " to \"\" so it's correctly written to csv file
      newHeaders = newHeaders.map( (item, id) => {
        if(item.includes("\"")) {
          item = item.replace("\"", "\"\"")
        }
        return item
      })

      // collect data
      let data = [newHeaders]
      newData.map( (item, id) => {
        if(!item.includes(null)) {
          data.push(item)
        }
        return null
      })
      setCSVData({
        data: data,
        filename: csvName
      })
    }
    else setCSVData(
      {data:[], filename: 'test.csv'}
      )
  }

  useEffect(generateCSVData, [HotData])
  useEffect(allDataToHotTableData, [hotTableComponent.current])

  return (
    <div style={{ margin: 24 }}>
      <Typography variant="h6" gutterBottom style={{ textAlign: "left" }}>
        Edit Data
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ textAlign: "left", marginBottom: 16 }}
      >
        Add, remove or edit data below.
      </Typography>
      <Grid
          container
          justify="flex-start"
          alignItems="flex-end"
          spacing={8}
          style={{ marginBottom: 16 }}
      >
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <FormControl
              fullWidth
              className={classes.formControl}
          >
            <InputLabel htmlFor="newmodel_sheet_select">
              Use dropdown menu to select an Excel tab
            </InputLabel>
            <Select
                // autoWidth
                style={{ textAlign: "left" }}
                value={props.newmodel.file_selected_sheet}
                onChange={handleSheetChange}
                input={
                  <Input
                      name="edit_data_sheet_select"
                      id="edit_data_sheet_select"
                  />
                }
            >
              {props.newmodel.file_sheets.map(sheet => (
                  <MenuItem key={sheet.sheet} value={sheet.sheet}>
                    {sheet.sheet}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          {props.newmodel.omitted_headers.length > 0 && (
              <Typography
                  gutterBottom
                  style={{ textAlign: "right" }}
              >
                <b>Omitted Columns:</b>{" "}
                {props.newmodel.omitted_headers.join(", ")}
              </Typography>
          )}
        </Grid>
      </Grid>
      <FormControl className={classes.formControl} fullWidth>
        <HotTable
          id="table-edit-data"
          ref={hotTableComponent}
          data={HotData.data}
          colHeaders={HotData.headers}
          afterSelectionEnd={cellSelected}
          afterDeselect={cellDeselected}
          rowHeaders={true}
          contextMenu={true}
          stretchH="all"
          height={300}
          minRows="15"
          undo={true}
        />
      </FormControl>
      <Grid
          container
          style={{ marginBottom: 14, marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
      >
        {showRenameColInput?
            <TextField
                id="standard-basic"
                label="New column name"
                defaultValue={HotData.headers[selectedCol]}
                onChange={updateRenameColText}
            /> :''}
        {(showRenameColBtn || selectingCell || hoveringRenameBtn)?
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={handleRenameCol}
              onMouseEnter={() => {setHoveringRenameBtn(true)}}
              onMouseLeave={() => {setHoveringRenameBtn(false)}}
            >
              {renameButtonText}
            </Button> :''}

        <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handleSaveData}
        >
          Save Data
        </Button>
        <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={downloadCSV}
        >
          Download CSV
        </Button>
        <div>
          <CSVLink
              data={csvData.data}
              filename={csvData.filename}
              className="hidden"
              ref={csvLink}
              target="_blank"/>

        </div>
        <Button
            variant="contained"
            color="secondary"
            component="span"
            className={classes.button}
            onClick={handleCancel}
            style={{ marginRight: 0 }}
        >
          Cancel
        </Button>
      </Grid>

    </div>
  );
}

// Alerts.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired
// };

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels,
    newmodel: state.newmodel,
  };
})(withStyles(styles, { withTheme: true })(EditData));
