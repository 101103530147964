let defaultState = {
  data_ranges: [],
  categorical_variables: { Inputs: [], Outputs: [] },
  prediction_input_values: [],
  prediction_output_values: [],
  columns_display: [],
  settings_sign_options: [
    { value: "<=", display: "≤ (Less Than or Equal To)" },
    { value: "==", display: "= (Equal To)" },
    { value: ">=", display: "≥ (Greater Than or Equal To)" }
  ],
  settings_selected_sign: "==",
  settings_constraint_value: "",
  settings_all_constraints: [],
  //settings_all_constraints: [{"columns":["UV"],"value":"280","sign":">=","displaySign":"≥","use":true},{"columns":["Total BP"],"value":"0.25","sign":"<=","displaySign":"≤","use":true}],
  settings_selected_columns: [],
  optimization_target_types: [
    { display: "Target", value: "target" },
    { display: "Maximize", value: "maximize" },
    { display: "Minimize", value: "minimize" }
  ],
  constraint_types: [{ display: "Sum", value: "sum" }, { display: "Ratio (A/B)", value: "ratio" }],
  settings_selected_constraint_type: "sum",
  settings_selected_columns_a: [],
  settings_selected_columns_b: []
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_MODEL_USAGE_DATA":
      return {
        ...state,
        data_ranges: action.data.data_ranges,
        categorical_variables: action.data.categorical_variables
        //prediction_input_values: action.data.prediction_input_values,
      };
    case "UPDATE_MODEL_USAGE_DATA_RANGES":
      return {
        ...state,
        data_ranges: action.data.data_ranges
      };
    case "UPDATE_CONSTRAINT_SELECTED_SIGN":
      return {
        ...state,
        settings_selected_sign: action.data.settings_selected_sign
      };
    case "UPDATE_CONSTRAINT_VALUE":
      return {
        ...state,
        settings_constraint_value: action.data.settings_constraint_value
      };
    case "UPDATE_CONSTRAINT_SELECTED_COLUMNS":
      return {
        ...state,
        settings_selected_columns: action.data.settings_selected_columns
      };
    case "UPDATE_CONSTRAINT_SELECTED_COLUMNS_A":
      return {
        ...state,
        settings_selected_columns_a: action.data.settings_selected_columns_a
      };
    case "UPDATE_CONSTRAINT_SELECTED_COLUMNS_B":
      return {
        ...state,
        settings_selected_columns_b: action.data.settings_selected_columns_b
      };
    case "UPDATE_CONSTRAINT_TYPE":
      return {
        ...state,
        settings_selected_constraint_type: action.data.settings_selected_constraint_type
      };
    case "UPDATE_USER_CONSTRAINTS":
      return {
        ...state,
        settings_all_constraints: action.data.settings_all_constraints
      };
    case "ADD_NEW_USER_CONSTRAINT":
      return {
        ...state,
        settings_all_constraints: action.data.settings_all_constraints,
        settings_selected_columns: action.data.settings_selected_columns,
        settings_selected_sign: action.data.settings_selected_sign
      };
    case "RESET_ALL":
      return {
        ...state,
        data_ranges: [],
        categorical_variables: { Inputs: [], Outputs: [] },
        prediction_input_values: [],
        prediction_output_values: [],
        columns_display: [],
        settings_sign_options: [
          { value: "<=", display: "≤ (Less Than or Equal To)" },
          { value: "==", display: "= (Equal To)" },
          { value: ">=", display: "≥ (Greater Than or Equal To)" }
        ],
        settings_selected_sign: "==",
        settings_constraint_value: "",
        settings_all_constraints: [],
        //settings_all_constraints: [{"columns":["UV"],"value":"280","sign":">=","displaySign":"≥","use":true},{"columns":["Total BP"],"value":"0.25","sign":"<=","displaySign":"≤","use":true}],
        settings_selected_columns: [],
        optimization_target_types: [
          { display: "Target", value: "target" },
          { display: "Maximize", value: "maximize" },
          { display: "Minimize", value: "minimize" }
        ],
        constraint_types: [{ display: "Sum", value: "sum" }, { display: "Ratio (A/B)", value: "ratio" }],
        settings_selected_constraint_type: "sum",
        settings_selected_columns_a: [],
        settings_selected_columns_b: []
      };
    default:
      return state;
  }
}

export default views;
