import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { connect } from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import * as HelperFunctions from "./ModelCalculationCode.js";
import * as predictionActions from "../actions/modelPredictionActions";
import * as predictionContourActions from "../actions/modelPredictionContourActions";
import {sleep} from "./utils";

const styles = {
  root: {
    width: 300
  },
  slider: {
    padding: "6px 0px"
  }
};

//The general idea here is to store the value in state (improved rendering performance) and then update the store when a new prediction is generated.
class ModelInputSlider extends React.Component {
  state = {
    value: this.props.currValue,
    textValue: this.props.currValue
  };

  shouldComponentUpdate(newProps) {
    if (newProps.currValue !== this.props.currValue) {
      this.setState({ value: newProps.currValue, textValue: newProps.currValue });
      return false;
    }
    if (
      newProps.views.currentView !== "modelpred" &&
      newProps.views.currentView !== "modelopt" &&
      newProps.views.currentView !== "modelpredcontour"
    ) {
      return false;
    }
    //additional logic here would be good
    return true;
  }

  handleChange = (event, value) => {
    let currValue = value;
    if (isNaN(currValue)) {
      return;
    }
    if (currValue < this.props.usermin) {
      currValue = this.props.usermin;
    }
    if (currValue > this.props.usermax) {
      currValue = this.props.usermax;
    }
    this.setState({ value: currValue, textValue: currValue });
  };

  handleTextChange = event => {
    // This gets called when somebody enters a value and you should allow this to be anything including empty, only update if it is valid
    let currValue = event.target.value;
    let stateValue = this.state.value;
    if (!isNaN(currValue)) {
      if (currValue >= this.props.usermin && currValue <= this.props.usermax) {
        this.setState({ value: parseFloat(currValue), textValue: currValue });
      } else {
        this.setState({ value: stateValue, textValue: currValue });
      }
    } else {
      this.setState({ value: stateValue, textValue: currValue });
    }
  };

  manualInputChange = (column, event) => {
    let currValue = event.target.value;
    let midPoint = Math.round(((this.props.usermax + this.props.usermin) * 1000) / 2) / 1000;
    let useVal = null;
    if (!isNaN(currValue)) {
      if (currValue >= this.props.usermin && currValue <= this.props.usermax) {
        this.setState({ value: parseFloat(currValue), textValue: currValue });
        useVal = parseFloat(currValue);
      }
      if (currValue > this.props.usermax) {
        this.setState({ value: this.props.usermax, textValue: this.props.usermax });
        useVal = this.props.usermax;
      }
      if (currValue < this.props.usermin) {
        this.setState({ value: this.props.usermin, textValue: this.props.usermin });
        useVal = this.props.usermin;
      }
    } else {
      this.setState({ value: midPoint, textValue: midPoint });
      useVal = midPoint;
    }
    setTimeout(() => {
      let newpreds = JSON.parse(JSON.stringify(this.props.modelprediction.prediction_input_values));
      newpreds[column] = useVal;
      //this.props.dispatch(predictionInputActions.UpdateModelPredictionInputValues(newpreds));
      this.updatePrediction(newpreds);
    }, 0);
  };

  sliderFinish = (column, event, value) => {
    //this.props.dispatch(predictionInputActions.UpdateModelPredictionInputValues(newpreds));
    setTimeout(() => {
      let newpreds = JSON.parse(JSON.stringify(this.props.modelprediction.prediction_input_values));
      newpreds[column] = this.state.value;
      this.updatePrediction(newpreds);
    });
  };

  // This function is copied from ModelPredictin.js... Should combine them....
  updatePrediction = predVals => {
    let responseDelay = this.props.views.responseDelay
    sleep(responseDelay)
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];
    let currConstraints = this.props.modelusage.settings_all_constraints.filter(function(el) {
      return el.use;
    });
    let displayColumns = this.props.modelprediction.prediction_display_columns.filter(function(el) {
      return el.display;
    });
    if (this.props.contour) {
      let colDict = {};
      if (selModel.type === "PLS") {
        colDict = HelperFunctions.CalculateContourPLS(
          this.props.modelpredictioncontour.prediction_contour_selected_x,
          this.props.modelpredictioncontour.prediction_contour_selected_y,
          predVals,
          selModel,
          currConstraints,
          this.props.modelusage.categorical_variables,
          this.props.modeldata.data_ranges
        );
      }
      if (selModel.type === "NN") {
        colDict = HelperFunctions.CalculateContourNN(
          this.props.modelpredictioncontour.prediction_contour_selected_x,
          this.props.modelpredictioncontour.prediction_contour_selected_y,
          predVals,
          selModel,
          currConstraints,
          this.props.modelusage.categorical_variables,
          this.props.modeldata.data_ranges
        );
      }
      if (selModel.type === "KRR") {
        colDict = HelperFunctions.CalculateContourKRR(
          this.props.modelpredictioncontour.prediction_contour_selected_x,
          this.props.modelpredictioncontour.prediction_contour_selected_y,
          predVals,
          selModel,
          currConstraints,
          this.props.modelusage.categorical_variables,
          this.props.modeldata.data_ranges
        );
      }
      if (selModel.type === "SVR") {
        colDict = HelperFunctions.CalculateContourSVR(
          this.props.modelpredictioncontour.prediction_contour_selected_x,
          this.props.modelpredictioncontour.prediction_contour_selected_y,
          predVals,
          selModel,
          currConstraints,
          this.props.modelusage.categorical_variables,
          this.props.modeldata.data_ranges
        );
      }
      this.props.dispatch(predictionContourActions.UpdateModelPredictionContourData(colDict));
      this.props.dispatch(predictionActions.UpdateModelPredictionInputValues(predVals));
    } else {
      if (selModel.type === "PLS") {
        let result = HelperFunctions.CalculatePLSRes(
          predVals,
          selModel.x_mean_,
          selModel.y_mean_,
          selModel.x_std_,
          selModel.coef_,
          selModel.inputOrganization,
          selModel.outputOrganization,
          this.props.modelusage.data_ranges,
          this.props.modelusage.categorical_variables,
          this.props.modelusage.settings_all_constraints
        );
        let newPredText =
          "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
          this.props.modelprediction.prediction_display_columns
            .filter(function(el) {
              return el.display;
            })
            .map(x => x.col)
            .join(", ") +
          ". The prediction was generated using the '" +
          this.props.modelprediction.selected_model_name +
          "' model and inputs/outputs are reflected in the table to the right.";
        let predictionPlotData = [];
        predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
        predictionPlotData.push(result.completePredObject);
        this.props.dispatch(
          predictionActions.UpdateModelPredictionOutputValues(
            result.outputValues,
            result.inputValues,
            result.tableData,
            predictionPlotData,
            newPredText,
            result.violatedConstraints
          )
        );
      }
      if (selModel.type === "NN") {
        let results = HelperFunctions.CalculateNNPred(
          predVals,
          selModel.outputOrganization.length,
          selModel.coefficients,
          selModel.intercepts,
          selModel.layerCount,
          selModel.inputOrganization,
          selModel.outputOrganization,
          this.props.modelusage.categorical_variables,
          selModel.dataMin,
          selModel.dataRange,
          this.props.modelusage.settings_all_constraints.filter(function(el) {
            return el.use;
          })
        );
        let predictionPlotData = [];
        predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
        predictionPlotData.push(results.completePredObject);
        let newPredText =
          "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (green line) within the following dimensions: " +
          this.props.modelprediction.prediction_display_columns
            .filter(function(el) {
              return el.display;
            })
            .map(x => x.col)
            .join(", ") +
          ". The prediction was generated using the '" +
          this.props.modelprediction.selected_model_name +
          "' model and inputs/outputs are reflected in the table to the right.";
        this.props.dispatch(
          predictionActions.UpdateModelPredictionOutputValues(
            results.outputValues,
            results.inputValues,
            results.tableData,
            predictionPlotData,
            newPredText,
            results.violatedConstraints
          )
        );
      }
      if (selModel.type === "KRR") {
        let results = HelperFunctions.CalculateKRRPred(
          predVals,
          selModel.outputOrganization.length,
          selModel.dual_coef,
          selModel.x_fit,
          selModel.gamma,
          selModel.inputOrganization,
          selModel.outputOrganization,
          this.props.modelusage.categorical_variables,
          selModel.dataMin,
          selModel.dataRange,
          currConstraints
        );
        let predictionPlotData = [];
        predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
        predictionPlotData.push(results.completePredObject);
        let newPredText =
          "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (purple line) within the following dimensions: " +
          displayColumns
            .filter(function(el) {
              return el.display;
            })
            .map(x => x.col)
            .join(", ") +
          ". The prediction was generated using the '" +
          selModel.name +
          "' model and inputs/outputs are reflected in the table to the right.";
        this.props.dispatch(
          predictionActions.UpdateModelPredictionOutputValues(
            results.outputValues,
            results.inputValues,
            results.tableData,
            predictionPlotData,
            newPredText,
            results.violatedConstraints
          )
        );
      }
      if (selModel.type === "SVR") {
        let results = HelperFunctions.CalculateSVRPred(
          predVals,
          selModel.outputOrganization.length,
          selModel.support_vectors_,
          selModel.dual_coef_,
          selModel.intercept_,
          selModel._gamma,
          selModel.inputOrganization,
          selModel.outputOrganization,
          this.props.modelusage.categorical_variables,
          selModel.dataMin,
          selModel.dataRange,
          currConstraints
        );
        let predictionPlotData = [];
        predictionPlotData = predictionPlotData.concat(this.props.modeldata.plottable_data);
        predictionPlotData.push(results.completePredObject);
        let newPredText =
          "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (pink line) within the following dimensions: " +
          displayColumns
            .filter(function(el) {
              return el.display;
            })
            .map(x => x.col)
            .join(", ") +
          ". The prediction was generated using the '" +
          selModel.name +
          "' model and inputs/outputs are reflected in the table to the right.";
        this.props.dispatch(
          predictionActions.UpdateModelPredictionOutputValues(
            results.outputValues,
            results.inputValues,
            results.tableData,
            predictionPlotData,
            newPredText,
            results.violatedConstraints
          )
        );
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { value, textValue } = this.state;
    return (
      <>
        <FormGroup style={{ marginLeft: 0, width: "100%" }}>
          <TextField
            id={this.props.column + "_pred_select"}
            // label={this.props.column + " (" + this.props.usermin + "–" + this.props.usermax + ")"}
            label={this.props.column}
            value={textValue}
            type="number"
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            disabled={false}
            onKeyPress={ev => {
              if (ev.key === "Enter") {
                this.manualInputChange(this.props.column, ev);
              }
            }}
            onBlur={ev => this.manualInputChange(this.props.column, ev)}
            onChange={this.handleTextChange}
            style={{ marginTop: 0 }}
          />
        </FormGroup>

        <Slider
          // classes={{ container: classes.slider }}
          value={value}
          min={this.props.usermin}
          max={this.props.usermax}
          onChange={this.handleChange}
          step={(this.props.usermax - this.props.usermin)/100}
          //onChange={(ev, val) => this.sliderFinish(this.props.column, ev, val)}
          onChangeCommitted={(ev, val) => this.sliderFinish(this.props.column, ev, val)}
        />
      </>
    );
  }
}

export default connect((state, props) => {
  return {
    views: state.views,
    modelusage: state.modelusage,
    modelprediction: state.modelprediction,
    modeldata: state.modeldata,
    modelpredictioncontour: state.modelpredictioncontour
  };
})(withStyles(styles)(ModelInputSlider));
