import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  link: {
    color: "inherit"
  },
  selected: {
    color: "dodgerblue"
  },
  unselected: {
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class MIMenuList extends React.Component {
  state = {
    anchorEl: null,
    selectedIndex: 1
  };

  // launchModal = event => {
  //   this.props.dispatch(actions.updateShowProjectDialog(!this.props.views.show_newProject_dialog));
  // };

  openNewTab = url => {
    let win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* <MuiThemeProvider theme={theme}> */}
        <Divider />
        <List component="nav" dense={true} subheader={<ListSubheader component="div">Materials Informatics</ListSubheader>}>
          <MenuItem button onClick={() => this.openNewTab("https://materials-manager.mi.cld.3m.com/")}>
            <ListItemIcon>
              <FlashOnIcon className={classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography style={{ fontSize: ".9rem", fontWeight: 500 }} className={classes.unselected}>
                  Materials Manager
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem button onClick={() => this.openNewTab("https://pyro.mi.cld.3m.com/")}>
            <ListItemIcon>
              <DeviceHubIcon className={classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography style={{ fontSize: ".9rem", fontWeight: 500 }} className={classes.unselected}>
                  Experiment Manager
                </Typography>
              }
            />
          </MenuItem>
        </List>
        {/* </MuiThemeProvider> */}
      </div>
    );
  }
}

MIMenuList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels
  };
})(withStyles(styles)(MIMenuList));
