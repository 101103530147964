let defaultState = {
  all_file_headers: [],
  uploaded_file_name: "",
  source_id: "",
  data_snapshot_headers: [],
  data_snapshot_table: [],
  data_snapshot_rows: [],
  data_organization_recommendations: [],
  data_snapshot_encoded_headers: [],
  data_snapshot_encoded_table: [],
  data_snapshot_encoded_rows: [],
  data_snapshot_excluded_columns: [],
  models_are_present: false,
  models: [],
  pca_data: [],
  linear_regression_data: [],
  data_ranges: [],
  categorical_variables: [],
  data_columns: [],
  plottable_data: [],
  output_data_ranges: [],
  model_saved: false,
  model_id: "",
  model_visibility: "",
  user_permission: "",
  user_can_share: false,
  user_can_edit: false,
  all_data: "",
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_ALL_DATA":
      return {
        ...state,
        all_data: action.data.all_data
      }
    case "UPDATE_ALL_FILE_HEADERS":
      return {
        ...state,
        all_file_headers: action.data.all_file_headers,
        uploaded_file_name: action.data.uploaded_file_name,
        source_id: action.data.source_id
      };
    case "UPDATE_DATA_SNAPSHOT":
      return {
        ...state,
        data_snapshot_headers: action.data.data_snapshot_headers,
        data_snapshot_table: action.data.data_snapshot_table,
        data_organization_recommendations: action.data.data_organization_recommendations,
        data_snapshot_rows: action.data.data_snapshot_rows,
        data_snapshot_encoded_headers: action.data.data_snapshot_encoded_headers,
        data_snapshot_encoded_table: action.data.data_snapshot_encoded_table,
        data_snapshot_encoded_rows: action.data.data_snapshot_encoded_rows,
        data_snapshot_excluded_columns: action.data.data_snapshot_excluded_columns
      };
    case "UPDATE_MODEL_DATA":
      return {
        ...state,
        models_are_present: action.data.models_are_present,
        models: action.data.models,
        pca_data: action.data.pca_data,
        linear_regression_data: action.data.linear_regression_data,
        data_ranges: action.data.data_ranges,
        categorical_variables: action.data.categorical_variables,
        data_columns: action.data.data_columns,
        plottable_data: action.data.plottable_data,
        output_data_ranges: action.data.output_data_ranges,
        model_saved: action.data.model_saved,
        model_id: action.data.model_id
      };
    case "UPDATE_MODEL_ACCESS":
      return {
        ...state,
        model_visibility: action.data.model_visibility,
        user_permission: action.data.user_permission,
        user_can_share: action.data.user_can_share,
        user_can_edit: action.data.user_can_edit
      };
      case "UPDATE_SOURCE_ID":
        return {
          ...state,
          source_id: action.data.source_id
        };
    case "UPDATE_MODEL_DATA_SAVED_STATE":
      return {
        ...state,
        model_saved: action.data.model_saved
      };
    case "UPDATE_MODEL_DATA_MODEL_LIST":
      return {
        ...state,
        models: action.data.models
      };
    case "RESET_ALL":
      return {
        ...state,
        all_file_headers: [],
        uploaded_file_name: "",
        source_id: "",
        data_snapshot_headers: [],
        data_snapshot_table: [],
        data_snapshot_rows: [],
        data_organization_recommendations: [],
        data_snapshot_encoded_headers: [],
        data_snapshot_encoded_table: [],
        data_snapshot_encoded_rows: [],
        data_snapshot_excluded_columns: [],
        models_are_present: false,
        models: [],
        pca_data: [],
        linear_regression_data: [],
        data_ranges: [],
        categorical_variables: [],
        data_columns: [],
        plottable_data: [],
        output_data_ranges: [],
        model_saved: false,
        model_id: ""
      };
    default:
      return state;
  }
}

export default views;
