import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import MenuList from "./MenuList.js";
import InfoMenuList from "./InfoMenuList.js";
import ExploreMenuList from "./ExploreMenuList.js";
import MIMenuList from "./MIMenuList.js";
import SiteInfo from "./SiteInfo.js";
import IdleTimeout from "../components/IdleTimeout";
import LoadModel from "./LoadModel.js";
import ModelEvaluation from "./ModelEvalulation.js";
import ModelPrediction from "./ModelPrediction.js";
import ModelOptimization from "./ModelOptimization.js";
import ModelSummary from "./ModelSummary.js";
import DataDistribution from "./DataDistribution.js";
import LinearRegression from "./LinearRegression.js";
import PCA from "./PCA.js";
import DDDVisualization from "./DDDVisualization.js";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import NewModel from "./NewModel";
import RedisQueue from "./DebugSettingsCard";
import DataViz from "./DataViz";
import background from "../images/3MTrifectaMeshBlue.png";
import Spinner from "react-spinkit";
import ModelPredictionContour from "./ModelPredictionContour.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alerts from "./Alerts";
import * as currentModelActions from "../actions/currentmodelsActions";
import { formatAIChemyModelList } from "../api/alchemyAxios";
import * as viewActions from "../actions/viewActions";
import { RedirectModal } from "../components/RedirectModal";

import GamesPage from "./GamesPage";

// NOTE: these are used to define the application timeout window
const TIMEOUT_DURATION = 18000; // in seconds
const CONTINUE_DURATION = 600; // in seconds

const drawerWidth = 240;
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // height: '100vh',
    minHeight: "95vh",
    margin: "auto",
    zIndex: 1,
    //overflow: 'hidden',
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    color: "white",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
    },
    minHeight: "100vh",
  },
  content: {
    minHeight: `calc(100vh - 48px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    width: "100%",
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  loader: {
    // position: 'absolute',
    // top: '40%',
    // left: '50%',
    // margin: '0px',
    marginLeft: "0px",
    [theme.breakpoints.up("md")]: {
      //left: `calc(50% - ${drawerWidth/2}px)`,
      marginLeft: `calc(${drawerWidth}px)`,
    },
  },
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
    },
    secondary: {
      light: "#ff5c53",
      main: "#ee1429",
      dark: "#b30000",
    },
    black: {
      light: "black",
      main: "black",
      dark: "black",
    },
  },
  overrides: {
    MuiListItemText: {
      primary: {
        fontWeight: 500,
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          backgroundColor: "white",
        },
      },
    },
  },
});

const theme2 = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      dark: "#aa2e25",
      main: "#f44336",
      light: "#f6685e",
    },
    primary: {
      light: "#4dabf5",
      main: "#2196f3",
      dark: "#1769aa",
    },
    black: {
      light: "black",
      main: "black",
      dark: "black",
    },
  },
  overrides: {
    MuiListItemText: {
      primary: {
        fontWeight: 500,
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          backgroundColor: "white",
        },
      },
    },
    MuiSlider: {
      root: {
        padding: "16px 0px",
      },
    },
    MuiChip: {
      deleteIconColorSecondary: {
        color: "rgba(255, 255, 255, 0.7)",
        "&:hover": {
          color: "rgba(255, 255, 255, 1)",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(0, 0, 0, .9)",
      },
    },
  },
});

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
    view: "upload",
    createProjOpen: false,
    modelDeleteName: "",
    modelSaveName: "",
    modelDescription: "",
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleViewSwitch = (newView) => {
    this.setState((prevState) => ({ view: newView }));
  };

  updateModelList = () => {
    let params = new URLSearchParams({
      mode: "list",
      user_name: this.props.user.name,
      user_pin: this.props.user.nickname,
    }).toString();
    let url = `modelAccess?${params}`;
    this.props.axPost(url).then((res) => {
      if (res.data !== undefined) {
        let curr_models = formatAIChemyModelList(res.data);
        this.props.UpdateCurrentModels(curr_models);
      }
    });
  }

  componentDidMount = () => {
    this.updateModelList();
    this.props.UpdateDebugMode(this.props.debugMode)
  };

  logoutWithRedirect = () => {
    if (typeof this.props.onLogoutClick === "function") {
      this.props.onLogoutClick();
    }
  };

  render() {
    const { classes } = this.props;
    const { token } = this.props.token;
    const drawer = (
      <div>
        <div
          className={classes.toolbar}
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
          }}
        >
          <Typography
            variant="h6"
            color="inherit"
            style={{
              paddingTop: 17,
              fontWeight: "500",
              fontSize: "x-large",
              color: "white",
              textShadow: "1px 1px 1px black",
            }}
          >
            Mixing Studio
          </Typography>
        </div>
        <MenuList />
        <ExploreMenuList />
        <InfoMenuList axPost={this.props.axPost} />
        <MIMenuList />
      </div>
    );

    return (
      <MuiThemeProvider theme={theme}>
        <Dialog
          open={isIE}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Unsupported Browser Detected
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 24 }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ textAlign: "center" }}
            >
              Internet Explorer is not compatible with some of the underlying
              features of Mixing Studio. Please use Edge, Chrome, or Firefox
              instead.
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <IdleTimeout
          timeoutDuration={TIMEOUT_DURATION}
          continueDuration={CONTINUE_DURATION}
          logout={this.logoutWithRedirect}
        />
        <RedirectModal/>
        <Alerts user={this.props.user} axPost={this.props.axPost} />
        <div className={classes.root}>
          {this.props.views.isLoading === true && (
            <div
              style={{
                backgroundColor: "rgba(0,0,0,.75)",
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px",
                zIndex: 1100,
              }}
              className="overlay"
            >
              {/* http://kyleamathews.github.io/react-spinkit/ */}
              {/* https://github.com/KyleAMathews/react-spinkit */}
              <Spinner
                name="line-scale-pulse-out"
                className={classes.loader}
                style={{ position: "relative", top: "40%", left: "0%" }}
                color="white"
                fadeIn="none"
              />
              <h5
                className={classes.loader}
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  position: "relative",
                  color: "white",
                  fontWeight: "400",
                  textAnchor: "middle",
                  textAlign: "center",
                  top: "40%",
                }}
              >
                {this.props.views.loadingMessage}
              </h5>
            </div>
          )}
          <AppBar className={classes.appBar} color="primary">
            <Toolbar>
              <IconButton
                color="secondary"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.navIconHide}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                style={{ flexGrow: 1 }}
              >
                <img
                  src={require("../images/Logo.png")}
                  alt="3M"
                  style={{ marginLeft: 15, float: "left" }}
                />
              </Typography>
              <Button color="secondary" onClick={this.logoutWithRedirect}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <MuiThemeProvider theme={theme2}>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {this.props.debugMode ?
                <div
                  className={
                    this.props.views.currentView !== "newmodel" ? "hidden" : ""
                  }
                >
                  <RedisQueue user={this.props.user} axPost={this.props.axPost} />
                </div>
                : ""}

              <div
                className={
                  this.props.views.currentView !== "newmodel" ? "hidden" : ""
                }
              >
                <NewModel
                  user={this.props.user}
                  token={{ token }}
                  axPost={this.props.axPost}
                />
              </div>

              <div
                className={
                  this.props.views.currentView !== "loadmodel" ? "hidden" : ""
                }
              >
                <LoadModel user={this.props.user} axPost={this.props.axPost} />
              </div>
              <div
                className={
                  this.props.views.currentView !== "dataviz" ? "hidden" : ""
                }
              >
                <DataViz axPost={this.props.axPost} />
              </div>
              <div
                className={
                  this.props.views.currentView !== "modeleval" ? "hidden" : ""
                }
              >
                <ModelEvaluation axPost={this.props.axPost} />
              </div>
              <div
                className={
                  this.props.views.currentView !== "modelpred" ? "hidden" : ""
                }
              >
                <ModelPrediction axPost={this.props.axPost} />
              </div>
              <div
                className={
                  this.props.views.currentView !== "modelpredcontour"
                    ? "hidden"
                    : ""
                }
              >
                <ModelPredictionContour />
              </div>
              <div
                className={
                  this.props.views.currentView !== "modelopt" ? "hidden" : ""
                }
              >
                <ModelOptimization
                  user={this.props.user}
                  axPost={this.props.axPost}
                />
              </div>
              <div
                className={
                  this.props.views.currentView !== "modelsummary"
                    ? "hidden"
                    : ""
                }
              >
                <ModelSummary />
              </div>
              <div
                className={
                  this.props.views.currentView !== "datadist" ? "hidden" : ""
                }
              >
                <DataDistribution axPost={this.props.axPost} />
              </div>
              <div
                className={
                  this.props.views.currentView !== "datareg" ? "hidden" : ""
                }
              >
                <LinearRegression />
              </div>
              <div
                className={
                  this.props.views.currentView !== "datapca" ? "hidden" : ""
                }
              >
                <PCA />
              </div>
              <div
                className={
                  this.props.views.currentView !== "data3d" ? "hidden" : ""
                }
              >
                <DDDVisualization />
              </div>

              <div
                className={this.props.views.currentView !== "games" ? "hidden" : ""}
              >
                <GamesPage /> {/* dummy push */}
              </div>
              <div
                className={
                  this.props.views.currentView !== "information" ? "hidden" : ""
                }
              >
                <SiteInfo />
              </div>
            </main>
          </MuiThemeProvider>
        </div>
      </MuiThemeProvider>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onLogoutClick: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  views: state.views,
  savemodel: state.savemodel,
  modeldata: state.modeldata,
  currentmodels: state.currentmodels,
});

const mapDispatchToProps = (dispatch) => ({
  UpdateCurrentModels: (models) =>
    dispatch(currentModelActions.UpdateCurrentModels(models)),
  UpdateDebugMode: (mode) => dispatch(viewActions.changeDebugMode(mode)),
});

// export default connect((state, props) => {
//   return {
//     views: state.views,
//     savemodel: state.savemodel,
//     modeldata: state.modeldata,
//     currentmodels: state.currentmodels
//   };
// })(withStyles(styles, { withTheme: true })(ResponsiveDrawer));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ResponsiveDrawer));
