import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import ResidualsScatterChart from "./ResidualsScatterChart";
import CoefficientsBarChart from "./CoefficientsBarChart";
import { HotTable } from "@handsontable/react";

import SaveSVGasPNG from 'save-svg-as-png';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import * as viewActions from "../actions/viewActions";
import * as utils from "./utils.js";


const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

const LargeTooltip = withStyles({
  tooltip: {
    fontSize: '1.5em'
  }
})(Tooltip);

class ResidualsCoefficientsSmall extends Component {
  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "modeleval") {
      return false;
    }
    //additional logic here would be good
    return true;
  }

  componentDidMount() {}

  getCoeffColHeaders() {
    let array = this.props.modelevaluation.current_coefficients;
    return array !== null
      ? Object.keys(array[0]).sort().reverse()
      : null;
  }

  getCoeffData({coeffColHeaders}) {
    let array = this.props.modelevaluation.current_coefficients;
    let tmp = [];
    if (array !== null) {
      array.forEach(
        function iterate(item) {
          const row = [];
          coeffColHeaders.forEach(function (key) {
            typeof(item[key]) === 'string'
            ? row.push(item[key])
            : row.push(Number(item[key]).toFixed(3));
          })
          tmp.push(row);
        })
        return tmp;
    }
  }

  downloadPNG = (chartID, chartTitle) => {
    SaveSVGasPNG.saveSvgAsPng(document.getElementById(chartID), chartTitle + ".png", { scale: 1, backgroundColor: 'white' });
  }

  ExportResultsExcel = event => {
    let coeffColHeaders = this.getCoeffColHeaders();
    let coeffData = this.getCoeffData({coeffColHeaders});
    this.props.dispatch(viewActions.changeLoadingState(true));
    this.props.dispatch(viewActions.updateLoadingMessage("Assembling Results for Export..."));
    let url = `exportbatchexcel`;
    this.props.axPost(url, JSON.stringify({ table_headers: coeffColHeaders, table_data: coeffData, constraints: null }), {
        headers: { "Content-Type": "application/json; charset=utf-8" },
        responseType: "arraybuffer"
      })
      .then(res => {
        this.props.dispatch(viewActions.changeLoadingState(false));
        this.props.dispatch(viewActions.updateLoadingMessage(""));
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        let filename = "MixingStudio_TrainingCoefficients.xlsx";
        utils.SaveNonImageFile(blob, filename);
      });
  };

  render() {
    const { classes } = this.props;
                //data={this.props.modelevaluation.current_coefficients}
    const coeffColHeaders = this.getCoeffColHeaders();
    const coeffData = this.getCoeffData({coeffColHeaders});
    return (
      <div className={classes.layout}>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Goodness of Fit & Model Coefficients
          </Typography>
          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
              <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingBottom: 0 }}>
                {this.props.modelevaluation.current_residuals !== null && (
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={11} sm={11} md={11} lg={11} id="modelEvalResiduals">
                      <ResidualsScatterChart
                        data={this.props.modelevaluation.current_residuals}
                        xLabel={"Observed (experimental Y value)"}
                        yLabel={"Predicted (model Y value)"}
                        xScaleType={"linear"}
                        yScaleType={"linear"}
                        showPoints={true}
                        showToolTips={true}
                        extraLabels={[]}
                        appendDiv={"modelEvalResiduals"}
                        chartID={"modelEvalResiduals_ChartSVG"}
                        chartTitle={this.props.modelevaluation.residuals_chart_title}
                        chartType={"modeleval"}
                        currentView={this.props.views.currentView}
                        heightToWidthRatio={0.58}
                        scaleAllAxes={false}
                        xAxisMin={0}
                        xAxisMax={0}
                        yAxisMin={0}
                        yAxisMax={0}
                        slope={this.props.modelevaluation.current_residuals.Slope}
                        intercept={this.props.modelevaluation.current_residuals.Intercept}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} id="">
                      <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                          <LargeTooltip placement="top" title="Save Prediction Chart PNG">
                            <IconButton color="primary" onClick={() => this.downloadPNG("modelEvalResiduals_ChartSVG", "ChartPNG")}>
                              <SaveIcon fontSize="large" />
                            </IconButton>
                          </LargeTooltip>
                          </Grid>
                        <Grid item>
                          <LargeTooltip placement="top" title={this.props.modelevaluation.residuals_legend_text}>
                            <HelpOutlineIcon/>
                          </LargeTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingBottom: 0 }}>
                {this.props.modelevaluation.current_coefficients !== null && (
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={11} sm={11} md={11} lg={11} id="modelEvalCoefficients">
                      <CoefficientsBarChart
                        data={this.props.modelevaluation.current_coefficients}
                        xLabel={"Input (X) Variables"}
                        yLabel={"Effect on Output"}
                        xScaleType={"linear"}
                        yScaleType={"linear"}
                        showPoints={true}
                        showToolTips={true}
                        extraLabels={[]}
                        appendDiv={"modelEvalCoefficients"}
                        chartID={"modelEvalcoefficients_ChartSVG"}
                        chartTitle={this.props.modelevaluation.coefficients_chart_title}
                        chartType={"modeleval"}
                        currentView={this.props.views.currentView}
                        heightToWidthRatio={0.58}
                        scaleAllAxes={false}
                        xAxisMin={0}
                        xAxisMax={0}
                        yAxisMin={0}
                        yAxisMax={0}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} id="">
                      <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                          <LargeTooltip placement="top" title="Save Chart PNG">
                            <IconButton color="primary" onClick={() => this.downloadPNG("modelEvalcoefficients_ChartSVG", "ChartPNG")}>
                              <SaveIcon fontSize="large" />
                            </IconButton>
                          </LargeTooltip>
                          </Grid>
                        <Grid item>
                          <LargeTooltip placement="top" title={this.props.modelevaluation.coefficients_legend_text}>
                            <HelpOutlineIcon/>
                          </LargeTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>
         {/* Table showing the stat info for data, coming from AIchemy */}
          <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
            <div style={{ margin: 24 }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
                Input Coefficients
            </Typography>
              <HotTable
                data={coeffData}
                colHeaders={coeffColHeaders}
                rowHeaders={true}
                columnSorting={true}
                height="500"
                stretchH="all"
                minRows="20"
                settings={{ outsideClickDeselects: false }}
                root="hot"
                ref="statData"
                beforeChange={this.BeforeChange}
                afterColumnSort={this.AfterColumnSort}
                style={{ fontSize: "smaller", fontFamily: "Roboto" }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.margin}
                onClick={this.ExportResultsExcel}
                // disabled={this.props.prediction.selected_existing_table_rows.length===0}
                style={{ background: "linear-gradient(45deg, #00c853 30%, #00e676 90%)", float: "right", marginTop: 6, marginBottom: 12}}
              >
                <DescriptionIcon style={{ marginRight: 12 }} />
                Export Results to Excel
              </Button>
            </div>
          </Card>
      </div>

    );
  }
}

ResidualsCoefficientsSmall.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    modelevaluation: state.modelevaluation
  };
})(withStyles(styles)(ResidualsCoefficientsSmall));
