import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Plot from "react-plotly.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { plotlyColorSchemeRGBA, wordWrap } from "./utils";
import ListItemText from "@material-ui/core/ListItemText";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 2,
    minWidth: 120,
    flexWrap: "wrap"
    // height: 45,
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  plot: {
    width: "90%"
  }
});


function ScatterPlot(props) {

  const { classes } = props;
  const [currentSheet, setCurrentSheet] = useState('')
  const [headers, setHeaders] = useState([])
  const [plotData, setPlotData] = useState({})
  const [xAxis, setXAxis] = useState('')
  const [yAxis, setYAxis] = useState('')
  const [zAxis, setZAxis] = useState('')
  const [colorAxis, setColorAxis] = useState('')
  const [plotWidth, setPlotWidth] = useState(200)
  const [plotType, setPlotType] = useState('2d')

  const parentRef = useRef(null);

  // get plot width
  useEffect(() => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      setPlotWidth(parentWidth)
    }

  }, [parentRef]);

  const handleSheetChange = (event) => {
    const selectedSheet = event.target.value;
    if (!props.data[selectedSheet]) return
    const currentHeaders = props.data[selectedSheet].columns
    const currentData = props.data[selectedSheet].data
    setCurrentSheet(selectedSheet)
    setHeaders(currentHeaders)
    const currentPlotData = {}
    // add headers
    currentHeaders.map(header => currentPlotData[header] = [])
    // add data
    currentData.forEach((row) => {
      row.forEach((value, idx) => {
        currentPlotData[currentHeaders[idx]].push(value)
      })
    })
    setPlotData(currentPlotData)
    setXAxis(currentHeaders[0])
    setYAxis(currentHeaders[1])
  }

  // assign initial data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSheetChange({ target: { value: Object.keys(props.data)[0] } }), [])

  // plot
  const addColorbar = (trace, colorData, title) => {
    trace["marker"]["color"] = colorData;
    trace['marker']['colorscale'] = 'Viridis'
    trace['marker']['colorbar'] = { 'thickness': 20, title: { text: title, side: 'right' } }
    trace['showscale'] = true

    return trace
  }
  const getTrace = () => {
    const trace = []
    if (plotType === '2d') {
      const x = plotData[xAxis]
      const y = plotData[yAxis]

      if (!x) return []
      let newTrace = {
        x: x,
        y: y,
        mode: 'markers',
        type: 'scatter',
        hovertemplate:
          `${xAxis}: %{x}<br>` +
          `${yAxis}: %{y}<br>`,
        marker: { size: 8 }
      }
      if (colorAxis) {
        newTrace = addColorbar(newTrace, plotData[colorAxis], colorAxis)
        newTrace.hovertemplate += `${colorAxis}: %{marker.color}<br>`
      }
      newTrace.hovertemplate += "<extra></extra>" // clear the trace0 bit from rendering
      trace.push(newTrace)
    }
    else {
      const x = plotData[xAxis]
      const y = plotData[yAxis]
      const z = plotData[zAxis]

      if (!x) return []
      let newTrace = {
        x: x,
        y: y,
        z: z,
        mode: 'markers',
        type: 'scatter3d',
        hovertemplate:
          `${xAxis}: %{x}<br>` +
          `${yAxis}: %{y}<br>` +
          `${zAxis}: %{z}<br>`,
        marker: {
          size: 8,
          color: plotlyColorSchemeRGBA(0, 1),
          line: {
            color: plotlyColorSchemeRGBA(0, 0.14),
            width: 0.5
          },
          opacity: 0.8
        },
      }
      if (colorAxis) {
        newTrace = addColorbar(newTrace, plotData[colorAxis], colorAxis)
        newTrace.hovertemplate += `${colorAxis}: %{marker.color}<br>`
      }
      newTrace.hovertemplate += "<extra></extra>" // clear the trace0 bit from rendering
      trace.push(newTrace)
    }
    console.log(trace)
    return trace
  }

  const getLayout = () => {
    // prepare title
    let title = ''
    if (plotType === '2d' && !colorAxis) {
      title = "Scatter plot of " + xAxis + ' and ' + yAxis
    }
    else if (plotType === '2d' && colorAxis) {
      title = "Scatter plot of " + xAxis + ', ' + yAxis + ' and ' + colorAxis
    }
    else if (!colorAxis) {
      title = "Scatter plot of " + xAxis + ', ' + yAxis + ' and ' + zAxis
    }
    else {
      title = "Scatter plot of " + xAxis + ', ' + yAxis + ', ' + zAxis + ' and ' + colorAxis
    }

    // generate layout
    if (plotType === '2d') {
      return { width: plotWidth, title: wordWrap(title, 50), xaxis: { title: { text: xAxis } }, yaxis: { title: { text: yAxis } }, hovermode: 'closest' }
    }
    else {
      return {
        width: plotWidth, height: plotWidth, title: wordWrap(title, 50),
        scene: {
          xaxis: { title: { text: xAxis } },
          yaxis: { title: { text: yAxis } },
          zaxis: { title: { text: zAxis } },
        },
        margin: { l: 0, r: 0, b: 0, t: 50 },
        hovermode: 'closest',
      }
    }
  }

  const handleTogglePlot = (event, value) => {
    setPlotType(value);
    setXAxis(headers[0])
    setYAxis(headers[1])
    if (value === '3d') {
      setZAxis(headers[2])
    }
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={11}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>sheet</InputLabel>
            <Select
              autoWidth
              style={{ textAlign: "left" }}
              value={currentSheet}
              onChange={handleSheetChange}
            >
              {Object.keys(props.data).map(sheet => (
                <MenuItem key={sheet} value={sheet}>
                  {sheet}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ToggleButtonGroup
            value={plotType}
            exclusive
            onChange={handleTogglePlot}
            aria-label="text alignment"
            size="medium"
            style={{ marginTop: 15 }}
          >
            <Typography
              variant="subtitle1"
              align="center"
              style={{ marginRight: 10, marginTop: 10 }}
            >
              Plot type:{" "}
            </Typography>
            <ToggleButton value="2d">2D</ToggleButton>
            <ToggleButton value="3d">3D</ToggleButton>
          </ToggleButtonGroup>

          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>X-axis</InputLabel>
            <Select
              autoWidth
              style={{ textAlign: "left" }}
              value={xAxis}
              input={<Input />}
              onChange={event => setXAxis(event.target.value)}
            >
              {headers.map(header => (
                <MenuItem key={header} value={header}>
                  <ListItemText primary={header} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Y-axis</InputLabel>
            <Select
              autoWidth
              style={{ textAlign: "left" }}
              value={yAxis}
              input={<Input />}
              onChange={event => setYAxis(event.target.value)}
            >
              {headers.map(header => (
                <MenuItem key={header} value={header}>
                  <ListItemText primary={header} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {plotType === "3d" ? (
            <div>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Z-axis</InputLabel>
                <Select
                  autoWidth
                  style={{ textAlign: "left" }}
                  value={zAxis}
                  input={<Input />}
                  onChange={event => setZAxis(event.target.value)}
                >
                  {headers.map(header => (
                    <MenuItem key={header} value={header}>
                      <ListItemText primary={header} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
              ""
            )}
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Color</InputLabel>
            <Select
              autoWidth
              style={{ textAlign: "left" }}
              value={colorAxis}
              input={<Input />}
              onChange={event => setColorAxis(event.target.value)}
            >
              {headers.map(header => (
                <MenuItem key={header} value={header}>
                  <ListItemText primary={header} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              props.handleRemovePlot(props.idx);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      {/*Plotly figure*/}
      <div className={classes.plot} ref={parentRef}>
        <Plot data={getTrace()} layout={getLayout()} />
      </div>
    </>
  );
}

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels,
  };
})(withStyles(styles)(ScatterPlot));