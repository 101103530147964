export function UpdateCurrentModels(models) {
  return {
    type: "UPDATE_CURRENT_MODELS",
    data: {
      current_models: models,
      current_models_loading: false
    }
  };
}

export function UpdateSelectedModel(model_id, selectedRows) {
  return {
    type: "UPDATE_CURRENT_SELECTED_MODEL",
    data: {
      current_selected_model_id: model_id,
      current_models_selected_model_rows: selectedRows,
    }
  };
}
