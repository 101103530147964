import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import "handsontable/dist/handsontable.full.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ModelSettings from "./ModelSettings";
import ModelInputSlider from "./ModelInputSlider";
import * as HelperFunctions from "./ModelCalculationCode";
import ContourPlot from "./ContourPlot";
import SurfaceChartSpeedDial from './SurfaceChartSpeedDial';

//Dev
import * as usageActions from "../actions/modelusageActions";
import * as predictionActions from "../actions/modelPredictionActions";
import * as predictionContourActions from "../actions/modelPredictionContourActions";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  paddedTextField: {
    marginLeft: 10
  }
});

class ModelPredictionContour extends Component {
  //keep slider values in a local state
  componentDidMount() {}

  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "modelpredcontour") {
      return false;
    }
    if(this.props.views.currentView !== "modelpredcontour"){
      this.forcePredictionUpdate();
    }
    return true;
  }

  componentDidUpdate() {}

  modelChange = event => {
    let newModelName = event.target.value;
    this.props.dispatch(predictionActions.UpdateSelectedModel(newModelName));
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === newModelName;
    })[0];
    let newpreds = Object.assign({}, this.props.modelprediction.prediction_input_values);
    this.updatePrediction(newpreds, selModel);
  };

  updateRangeMinVal = (column, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let dataRanges = Object.assign([], this.props.modelusage.data_ranges);
    let currentCol = dataRanges.filter(function(el) {
      return el.column === column;
    })[0];
    let newUserMin = Math.min(parseFloat(event.target.value), currentCol.usermax);
    let newUserMax = Math.max(parseFloat(event.target.value), currentCol.usermax);
    currentCol.usermin = newUserMin;
    currentCol.usermax = newUserMax;
    this.props.dispatch(usageActions.UpdateModelUsageDataRanges(dataRanges));
    document.getElementById(column + "_pred_min_select").value = newUserMin;
    document.getElementById(column + "_pred_max_select").value = newUserMax;
  };

  updateRangeMaxVal = (column, event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    let dataRanges = Object.assign([], this.props.modelusage.data_ranges);
    let currentCol = dataRanges.filter(function(el) {
      return el.column === column;
    })[0];
    let newUserMin = Math.min(parseFloat(event.target.value), currentCol.usermin);
    let newUserMax = Math.max(parseFloat(event.target.value), currentCol.usermin);
    currentCol.usermin = newUserMin;
    currentCol.usermax = newUserMax;
    this.props.dispatch(usageActions.UpdateModelUsageDataRanges(dataRanges));
    document.getElementById(column + "_pred_min_select").value = newUserMin;
    document.getElementById(column + "_pred_max_select").value = newUserMax;
  };

  columnChartDisplayChanged = column => {
    let newDisplayCols = JSON.parse(JSON.stringify(this.props.modelprediction.prediction_display_columns));
    let selectedCol = newDisplayCols.filter(function(el) {
      return el.col === column;
    })[0];
    selectedCol.display = !selectedCol.display;
    let newPredText =
      "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
      newDisplayCols
        .filter(function(el) {
          return el.display;
        })
        .map(x => x.col)
        .join(", ") +
      ". The prediction was generated using the '" +
      this.props.modelprediction.selected_model_name +
      "' model and inputs/outputs are reflected in the table to the right.";
    this.props.dispatch(predictionActions.UpdatePredictionDisplayColumns(newDisplayCols, newPredText));
  };

  columnEditDisplayChanged = column => {
    let newDisplayCols = JSON.parse(JSON.stringify(this.props.modelprediction.prediction_display_columns));
    let selectedCol = newDisplayCols.filter(function(el) {
      return el.col === column;
    })[0];
    selectedCol.hide = !selectedCol.hide;
    let newPredText =
      "Parallel coordinates chart displaying all user provided input data (gray lines) as well as a model prediction (blue line) within the following dimensions: " +
      newDisplayCols
        .filter(function(el) {
          return el.display;
        })
        .map(x => x.col)
        .join(", ") +
      ". The prediction was generated using the '" +
      this.props.modelprediction.selected_model_name +
      "' model and inputs/outputs are reflected in the table to the right.";
    this.props.dispatch(predictionActions.UpdatePredictionDisplayColumns(newDisplayCols, newPredText));
  };

  categoricalColumnChange = (column, event) => {
    let newpreds = Object.assign({}, this.props.modelprediction.prediction_input_values);
    newpreds[column] = event.target.value;
    //this.props.dispatch(predictionActions.UpdateModelPredictionInputValues(newpreds));
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];
    this.updatePrediction(newpreds, selModel);
    // this.updatePrediction(newpreds);
  };

  forcePredictionUpdate = () => {
    let currModelName = this.props.modelprediction.selected_model_name;
    let selModel = this.props.modeldata.models.filter(function(el) {
      return el.name === currModelName;
    })[0];
    let predVals = this.props.modelprediction.prediction_input_values;
    this.updatePrediction(predVals, selModel);
  };

  updatePrediction = (predVals, selModel) => {
    let currConstraints = this.props.modelusage.settings_all_constraints.filter(function(el) {
      return el.use;
    });
    let colDict = {};
    if (selModel.type === "PLS") {
      colDict = HelperFunctions.CalculateContourPLS(
        this.props.modelpredictioncontour.prediction_contour_selected_x,
        this.props.modelpredictioncontour.prediction_contour_selected_y,
        predVals,
        selModel,
        currConstraints,
        this.props.modelusage.categorical_variables,
        this.props.modeldata.data_ranges
      );
    } if(selModel.type==="NN") {
      colDict = HelperFunctions.CalculateContourNN(
        this.props.modelpredictioncontour.prediction_contour_selected_x,
        this.props.modelpredictioncontour.prediction_contour_selected_y,
        predVals,
        selModel,
        currConstraints,
        this.props.modelusage.categorical_variables,
        this.props.modeldata.data_ranges
      );
    }
    if(selModel.type==="KRR") {
      colDict = HelperFunctions.CalculateContourKRR(
        this.props.modelpredictioncontour.prediction_contour_selected_x,
        this.props.modelpredictioncontour.prediction_contour_selected_y,
        predVals,
        selModel,
        currConstraints,
        this.props.modelusage.categorical_variables,
        this.props.modeldata.data_ranges
      );
    }
    if(selModel.type==="SVR") {
      colDict = HelperFunctions.CalculateContourSVR(
        this.props.modelpredictioncontour.prediction_contour_selected_x,
        this.props.modelpredictioncontour.prediction_contour_selected_y,
        predVals,
        selModel,
        currConstraints,
        this.props.modelusage.categorical_variables,
        this.props.modeldata.data_ranges
      );
    }
    this.props.dispatch(predictionContourActions.UpdateModelPredictionContourData(colDict));
    this.props.dispatch(predictionActions.UpdateModelPredictionInputValues(predVals));
  };

  updateSelectedXAxis = event => {
    this.props.dispatch(predictionContourActions.UpdateModelPredictionSelectedXAxis(event.target.value));
    setTimeout(() => {
      this.forcePredictionUpdate();
    }, 0);
  };

  updateSelectedYAxis = event => {
    this.props.dispatch(predictionContourActions.UpdateModelPredictionSelectedYAxis(event.target.value));
    setTimeout(() => {
      this.forcePredictionUpdate();
    }, 0);
  };

  thresholdChange = (column, event) => {
    if (isFinite(event.target.value)) {
      let updatedThresholdObj = Object.assign({}, this.props.modelpredictioncontour.prediction_contour_output_thresholds);
      updatedThresholdObj[column] = parseFloat(event.target.value);
      this.props.dispatch(predictionContourActions.UpdateModelPredictionContourThresholds(updatedThresholdObj));
    }
  };

  thresholdSignChange = (column, event) => {
    let updatedThresholdSigns = Object.assign({}, this.props.modelpredictioncontour.prediction_contour_output_threshold_signs);
    updatedThresholdSigns[column] = event.target.value;
    this.props.dispatch(predictionContourActions.UpdateModelPredictionContourThresholdSigns(updatedThresholdSigns));
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Model Prediction
          </Typography>

          <Typography gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
            Generate predictions using your trained models below.
          </Typography>
          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 24 }}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="modelpred_model_select">Selected Regression Model</InputLabel>
                  <Select
                    style={{ textAlign: "left" }}
                    value={this.props.modelprediction.selected_model_name}
                    onChange={this.modelChange}
                    input={<Input name="modelpred_model_select" id="contour_modelpred_model_select" />}
                  >
                    {this.props.modeldata.models.map(model => (
                      <MenuItem key={model.name} value={model.name}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Card>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Model Surface
          </Typography>
          <div style={{ margin: 24 }}>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{ marginBottom: 24 }}>
                {/* <Typography variant="body" style={{ textAlign: "left" }}>
                    Select Fixed X & Y Axes Below
                  </Typography> */}
                <FormControl className={classes.formControl} style={{ width: "100%", marginTop: 12 }}>
                  <InputLabel htmlFor={"pred_contour_x_select"}>X-Axis Input Variable</InputLabel>
                  <Select
                    style={{ textAlign: "left" }}
                    value={this.props.modelpredictioncontour.prediction_contour_selected_x}
                    onChange={this.updateSelectedXAxis}
                    input={<Input name={"pred_contour_x_select"} id={"pred_contour_x_select"} />}
                  >
                    {this.props.modelusage.data_ranges
                      .filter(function(el) {
                        return el.categorical === false;
                      })
                      .map(encodedCol => (
                        <MenuItem key={encodedCol.column + "input"} value={encodedCol.column.replace(encodedCol.column + "_", "")}>
                          {encodedCol.column.replace(encodedCol.column + "_", "")}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl} style={{ width: "100%", marginTop: 12 }}>
                  <InputLabel htmlFor={"pred_contour_y_select"}>Y-Axis Input Variable</InputLabel>
                  <Select
                    style={{ textAlign: "left" }}
                    value={this.props.modelpredictioncontour.prediction_contour_selected_y}
                    onChange={this.updateSelectedYAxis}
                    input={<Input name={"pred_contour_y_select"} id={"pred_contour_y_select"} />}
                  >
                    {this.props.modelusage.data_ranges
                      .filter(function(el) {
                        return el.categorical === false;
                      })
                      .map(encodedCol => (
                        <MenuItem key={encodedCol.column + "input"} value={encodedCol.column.replace(encodedCol.column + "_", "")}>
                          {encodedCol.column.replace(encodedCol.column + "_", "")}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {Object.keys(this.props.modelpredictioncontour.prediction_contour_output_thresholds).map(col => (
                  <FormControl className={classes.formControl} style={{ width: "100%", marginTop: 12 }} key={"pred_contour_thresh_" + col}>
                    <InputLabel htmlFor={"pred_contour_thresh_dir" + col}>{col + " Threshold"}</InputLabel>
                    <div style={{ display: "inline" }}>
                      <Select
                        style={{ textAlign: "center", width: "20%", height:'auto' }}
                        value={this.props.modelpredictioncontour.prediction_contour_output_threshold_signs[col]}
                        onChange={ev => this.thresholdSignChange(col, ev)}
                        input={<Input name={"pred_contour_thresh_dir" + col} id={"pred_contour_thresh_dir" + col} />}
                      >
                        {["<", ">"].map(encodedCol => (
                          <MenuItem key={col + encodedCol} value={encodedCol}>
                            {encodedCol}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        id={"pred_contour_" + col + "threshold_input"}
                        // label={col + " Threshold"}
                        defaultValue={this.props.modelpredictioncontour.prediction_contour_output_thresholds[col]}
                        type="number"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        margin="normal"
                        disabled={false}
                        onKeyPress={ev => {
                          if (ev.key === "Enter") {
                            this.thresholdChange(col, ev);
                          }
                        }}
                        onBlur={ev => this.thresholdChange(col, ev)}
                        style={{ marginTop: 16, marginBottom: 0, width: "80%" }}
                      />
                    </div>
                  </FormControl>
                ))}
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1}>
                {/* First three contour plots here... */}
              </Grid>
              {Object.keys(this.props.modelpredictioncontour.prediction_contour_data).length > 3 &&
                this.props.modelpredictioncontour.prediction_contour_selected_x !==
                  this.props.modelpredictioncontour.prediction_contour_selected_y && (
                  <Grid item xs={9} sm={9} md={9} lg={9}>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={24}>
                      {Object.keys(this.props.modelpredictioncontour.prediction_contour_data).map(col => (
                        <Grid item xs={12} sm={12} md={3} lg={3} key={"contour_plot_grid_" + col} id={"contour_plot_grid_" + col}>
                          <ContourPlot
                            data={this.props.modelpredictioncontour.prediction_contour_data[col]}
                            xLabel={this.props.modelpredictioncontour.prediction_contour_selected_x}
                            yLabel={this.props.modelpredictioncontour.prediction_contour_selected_y}
                            xScaleType={"linear"}
                            yScaleType={"linear"}
                            showPoints={true}
                            showToolTips={true}
                            extraLabels={[]}
                            appendDiv={"contour_plot_grid_" + col}
                            chartID={"contour_plot_grid_" + col + "_SVG"}
                            chartTitle={col}
                            chartType={"modelpredcontour"}
                            currentView={this.props.views.currentView}
                            heightToWidthRatio={0.75}
                            scaleAllAxes={false}
                            xAxisMin={0}
                            xAxisMax={0}
                            yAxisMin={0}
                            yAxisMax={0}
                            legendText={""}
                            dataMin={
                              this.props.modeldata.output_data_ranges.filter(el => {
                                return el.column === col;
                              })[0].min
                            }
                            dataMax={
                              this.props.modeldata.output_data_ranges.filter(el => {
                                return el.column === col;
                              })[0].max
                            }
                            dataThreshold={this.props.modelpredictioncontour.prediction_contour_output_thresholds[col]}
                            thresholdDirection={this.props.modelpredictioncontour.prediction_contour_output_threshold_signs[col]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              {Object.keys(this.props.modelpredictioncontour.prediction_contour_data).length <= 3 &&
                this.props.modelpredictioncontour.prediction_contour_selected_x !== this.props.modelpredictioncontour.prediction_contour_selected_y &&
                Object.keys(this.props.modelpredictioncontour.prediction_contour_data).map(col => (
                  <Grid item xs={12} sm={12} md={3} lg={3} key={"contour_plot_grid_" + col} id={"contour_plot_grid_" + col}>
                    <ContourPlot
                      data={this.props.modelpredictioncontour.prediction_contour_data[col]}
                      xLabel={this.props.modelpredictioncontour.prediction_contour_selected_x}
                      yLabel={this.props.modelpredictioncontour.prediction_contour_selected_y}
                      xScaleType={"linear"}
                      yScaleType={"linear"}
                      showPoints={true}
                      showToolTips={true}
                      extraLabels={[]}
                      appendDiv={"contour_plot_grid_" + col}
                      chartID={"contour_plot_grid_" + col + "_SVG"}
                      chartTitle={col}
                      chartType={"modelpredcontour"}
                      currentView={this.props.views.currentView}
                      heightToWidthRatio={0.75}
                      scaleAllAxes={false}
                      xAxisMin={0}
                      xAxisMax={0}
                      yAxisMin={0}
                      yAxisMax={0}
                      legendText={""}
                      dataMin={
                        this.props.modeldata.output_data_ranges.filter(el => {
                          return el.column === col;
                        })[0].min
                      }
                      dataMax={
                        this.props.modeldata.output_data_ranges.filter(el => {
                          return el.column === col;
                        })[0].max
                      }
                      dataThreshold={this.props.modelpredictioncontour.prediction_contour_output_thresholds[col]}
                      thresholdDirection={this.props.modelpredictioncontour.prediction_contour_output_threshold_signs[col]}
                    />
                  </Grid>
                ))}
              {this.props.modelpredictioncontour.prediction_contour_selected_x ===
                this.props.modelpredictioncontour.prediction_contour_selected_y && (
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  <Typography gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15, color: "rgb(244, 67, 54)" }} variant="h6">
                    <i>Error! </i> Selected X & Y input columns must be distinct variables.
                  </Typography>
                </Grid>
              )}
            </Grid>

            {/* {this.props.modelprediction.prediction_violated_constraints.length > 0 && (
              <Grid container variant="body2" justify="flex-start" alignItems="center" spacing={24}>
                <Grid item xs={12} sm={12} md={12} lg={9} style={{ textAlign: "center" }}>
                  <Typography gutterBottom style={{ textAlign: "center", marginLeft: 24, marginBottom: 15, color: "red" }}>
                    <i>Warning!</i> Constraints Violated: {this.props.modelprediction.prediction_violated_constraints.join("; ")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} />
              </Grid>
            )} */}

            <Grid container justify="flex-start" alignItems="flex-start" spacing={10}>
              {this.props.modelusage.data_ranges
                .filter(el => {
                  if (
                    this.props.modelpredictioncontour.prediction_contour_selected_x !== el.column &&
                    this.props.modelpredictioncontour.prediction_contour_selected_y !== el.column
                  ) {
                    return el.categorical === false;
                  }
                  return false;
                })
                .forEach(range => {
                  if (
                    !this.props.modelprediction.prediction_display_columns.filter(function(el) {
                      return el.col === range.column;
                    })[0].hide
                  ) {
                    return (
                      <ModelInputSlider
                        key={range.column + "input"}
                        column={range.column}
                        currValue={this.props.modelprediction.prediction_input_values[range.column]}
                        usermin={range.usermin}
                        usermax={range.usermax}
                        contour={true}
                      />
                    );
                  }
                })}
              {this.props.modelusage.categorical_variables.Inputs !== undefined &&
                Object.keys(this.props.modelusage.categorical_variables.Inputs).forEach(col => {
                  if (
                    !this.props.modelprediction.prediction_display_columns.filter(function(el) {
                      return el.col === col;
                    })[0].hide
                  ) {
                    return (
                      <Grid item xs={12} sm={12} md={6} lg={2} key={col + "as"}>
                        <FormControl fullWidth className={classes.formControl} style={{ width: "100%" }}>
                          <InputLabel htmlFor={col + "_pred_select"}>{col}</InputLabel>
                          <Select
                            style={{ textAlign: "left" }}
                            value={this.props.modelprediction.prediction_input_values[col]}
                            onChange={ev => this.categoricalColumnChange(col, ev)}
                            input={<Input name={col + "_pred_select"} id={col + "_pred_select"} />}
                          >
                            {this.props.modelusage.categorical_variables.Inputs[col].map(encodedCol => (
                              <MenuItem key={encodedCol.replace(col + "_", "")} value={encodedCol.replace(col + "_", "")}>
                                {encodedCol.replace(col + "_", "")}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    );
                  }
                })}
            </Grid>
            <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ marginTop: 12 }}>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 48 }}>
                    <Typography variant="button">Input/Output Display Settings</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ marginLeft: 0, marginRight: 18 }}>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Typography variant="button" style={{ marginTop: 8, textAlign: "left", marginBottom: 16 }}>
                          Output Columns to Display
                        </Typography>
                        <FormGroup>
                          {this.props.modelprediction.prediction_display_columns
                            .filter(function(el) {
                              return el.type === "output" && el.categorical === false;
                            })
                            .map(col => (
                              <FormControlLabel
                                key={col.col}
                                control={
                                  <Checkbox
                                    checked={col.display}
                                    onChange={() => this.columnChartDisplayChanged(col.col)}
                                    value={col.col}
                                    color="primary"
                                    style={{height:24, paddingTop:0, paddingBottom:0}}
                                    disableRipple
                                  />
                                }
                                label={col.col}
                              />
                            ))}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Typography variant="button" style={{ marginTop: 8, textAlign: "left", marginBottom: 16 }}>
                          Input Fields Available to Edit
                        </Typography>
                        <FormGroup>
                          {this.props.modelprediction.prediction_display_columns
                            .filter(function(el) {
                              return el.type === "input";
                            })
                            .map(col => (
                              <FormControlLabel
                                key={col.col + "showinput"}
                                control={
                                  <Checkbox
                                    checked={!col.hide}
                                    onChange={() => this.columnEditDisplayChanged(col.col)}
                                    value={col.col}
                                    color="primary"
                                    style={{height:24, paddingTop:0, paddingBottom:0}}
                                    disableRipple
                                  />
                                }
                                label={col.col}
                              />
                            ))}
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <SurfaceChartSpeedDial
                  style={{ marginBottom: 0 }}
                  chartID={"contour_plot_grid_"}
                  chartTitle={"SurfaceChartPNG"}
                  chartType="modelpredcontour"
                  marginBottom={0}
                  direction='left'
                  height={40}
                  cols={Object.keys(this.props.modelpredictioncontour.prediction_contour_data)}
                  selectedX={this.props.modelpredictioncontour.prediction_contour_selected_x}
                  selectedY={this.props.modelpredictioncontour.prediction_contour_selected_y}
                  setPoints={this.props.modelprediction.prediction_input_values}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <div style={{ margin: 24 }}>
            <ModelSettings />
          </div>
        </Card>
      </div>
    );
  }
}

ModelPredictionContour.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modelusage: state.modelusage,
    modelprediction: state.modelprediction,
    //modelpredictioninputs: state.modelpredictioninputs,
    modeldata: state.modeldata,
    modelpredictioncontour: state.modelpredictioncontour
  };
})(withStyles(styles)(ModelPredictionContour));
