import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import * as viewActions from "../actions/viewActions";
import DeleteModelDialog from './Dialogs/DeleteModel.js'
import ShareModelDialog from './Dialogs/ShareModel.js'
import SaveModelDialog from './Dialogs/SaveModel.js'


const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: '100vh',
    minHeight: "95vh",
    margin: "auto",
    zIndex: 1,
    //overflow: 'hidden',
    position: "relative",
    display: "flex",
    width: "100%"
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    color: "white"
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "fixed"
    },
    minHeight: "100vh"
  },
  content: {
    minHeight: `calc(100vh - 48px)`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    width: "100%",
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  loader: {
    // position: 'absolute',
    // top: '40%',
    // left: '50%',
    // margin: '0px',
    marginLeft: "0px",
    [theme.breakpoints.up("md")]: {
      //left: `calc(50% - ${drawerWidth/2}px)`,
      marginLeft: `calc(${drawerWidth}px)`
    }
  }
});

const alerttheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
  palette: {
    secondary: {
      dark: "#f44336",
      main: "#f44336",
      light: "#f44336"
    },
    primary: {
      light: "#2196f3",
      main: "#2196f3",
      dark: "#2196f3"
    }
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: "none"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 500
      }
    },
    MUIDataTableToolbar: {
      titleText: {
        float: "left"
      }
    },
    MuiToolbar: {
      regular: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiSelect: {
      root: {
        backgroundColor: "white",
        width: "auto"
      },
      icon: {
        top: 6
      }
    }
  }
});


class Alerts extends React.Component {
  state = {
    mobileOpen: false,
    view: "upload",
    createProjOpen: false,
    modelDeleteName: "",
    modelSaveName: "",
    modelDescription: ""
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleViewSwitch = newView => {
    this.setState(prevState => ({ view: newView }));
  };


  updateDeleteName = event => {
    let currValue = event.target.value;
    this.setState({ ...this.state, modelDeleteName: currValue });
  };

  render() {

    return (
      <MuiThemeProvider theme={alerttheme}>
        <DeleteModelDialog  user={this.props.user} axPost={this.props.axPost} />
        <ShareModelDialog  user={this.props.user} axPost={this.props.axPost} />
        <SaveModelDialog user={this.props.user} axPost={this.props.axPost}/>
        {/*<EditDataDialog  user={this.props.user} axPost={this.props.axPost}/>*/}
        {/* Standard Alert */}
        <Dialog open={this.props.views.showAlert} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.props.views.alertTitle}</DialogTitle>
          <DialogContent style={{ paddingBottom: 0 }}>
            <DialogContentText id="alert-dialog-description">{this.props.views.alertText}</DialogContentText>
            <DialogContentText id="alert-dialog-description">{this.props.views.alertURL}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dispatch(viewActions.UpdateAlert(false, "", ""))} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

Alerts.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels
  };
})(withStyles(styles, { withTheme: true })(withRouter(Alerts)));
