let defaultState = {
  distribution_selected_input_columns: [],
  distribution_selected_output_columns: [],
  distribution_source_data: {},
  distribution_regression_selected: false,
  distribution_density_selected: false,
  distribution_selected_regression_column1: "",
  distribution_selected_regression_column2: "",
  distribution_selected_regression_object: {},
  distribution_selected_density_column: "",
  distribution_selected_density_object: {},
  distribution_selected_row_data: {},
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_DATA_DIST_SELECTED_INPUT_COLUMNS":
      return {
        ...state,
        distribution_selected_input_columns: action.data.distribution_selected_input_columns
      };
    case "UPDATE_DATA_DIST_SELECTED_OUTPUT_COLUMNS":
      return {
        ...state,
        distribution_selected_output_columns: action.data.distribution_selected_output_columns
      };
    case "UPDATE_DATA_DIST_SOURCE_DATA":
      return {
        ...state,
        distribution_source_data: action.data.distribution_source_data
      };
    case "UPDATE_DATA_DIST_SELECTION":
      return {
        ...state,
        distribution_selected_density_object: action.data.distribution_selected_density_object,
        distribution_selected_density_column: action.data.distribution_selected_density_column,
        distribution_selected_regression_object: action.data.distribution_selected_regression_object,
        distribution_selected_regression_column1: action.data.distribution_selected_regression_column1,
        distribution_selected_regression_column2: action.data.distribution_selected_regression_column2,
        distribution_regression_selected: action.data.distribution_regression_selected,
        distribution_density_selected: action.data.distribution_density_selected
      };
    case "UPDATE_DATA_DIST_SELECTED_ROW_DATA":
      return{
        ...state,
        distribution_selected_row_data: action.data.distribution_selected_row_data,
      }
    case "RESET_ALL":
      return {
        ...state,
        distribution_selected_input_columns: [],
        distribution_selected_output_columns: [],
        distribution_source_data: {},
        distribution_regression_selected: false,
        distribution_density_selected: false,
        distribution_selected_regression_column1: "",
        distribution_selected_regression_column2: "",
        distribution_selected_regression_object: {},
        distribution_selected_density_column: "",
        distribution_selected_density_object: {},
        distribution_selected_row_data: {}
      };
    default:
      return state;
  }
}

export default views;
