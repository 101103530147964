import React from "react";
import { connect } from "react-redux";
//import { Router, Route, Link, withRouter } from "react-router-dom";
import * as actions from "../actions/viewActions";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ListIcon from "@material-ui/icons/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import BuildIcon from "@material-ui/icons/Build";
import SaveIcon from "@material-ui/icons/Save";
import ShareIcon from "@material-ui/icons/Share";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  link: {
    color: "inherit"
  },
  selected: {
    color: "dodgerblue"
  },
  unselected: {
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class MenuList extends React.Component {
  state = {
    anchorEl: null,
    selectedIndex: 1
  };

  // launchModal = event => {
  //   this.props.dispatch(actions.updateShowProjectDialog(!this.props.views.show_newProject_dialog));
  // };
  handleNewModelClick = event => {
    this.props.dispatch(actions.changeViewType("newmodel"));
  };
  handleLoadModelClick = event => {
    this.props.dispatch(actions.changeViewType("loadmodel"));
  };
  saveModelClick = event => {
    //this.props.dispatch(actions.changeViewType('savemodel'));
    this.props.dispatch(actions.UpdateSaveConfirmDialog(true));
    setTimeout(() => {
      var currentID = this.props.modeldata.model_id;
      var selected_save_model = this.props.currentmodels.current_models.filter(el => {
        return el.id === currentID;
      })[0];
      if (selected_save_model) {
        document.getElementById('save_name_input').value = selected_save_model.model_title;
        document.getElementById('save_description_input').value = selected_save_model.model_description;
      }

    }, 0);
  };
  handleShareModelClick = event => {
    this.props.dispatch(actions.UpdateShowShareDialog(true));
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Divider />
        <List component="nav" dense={true} subheader={<ListSubheader component="div">Input/Output</ListSubheader>}>
          <MenuItem selected={this.props.views.currentView === "newmodel"} button onClick={this.handleNewModelClick}>
            <ListItemIcon>
              <BuildIcon className={this.props.views.currentView === "newmodel" ? classes.selected : classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{ fontSize: ".9rem", fontWeight: 500 }}
                  className={this.props.views.currentView === "newmodel" ? classes.selected : classes.unselected}
                  data-cy="create_model"
                >
                  Create New Model
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            button
            onClick={this.handleLoadModelClick}
            selected={this.props.views.currentView === "loadmodel"}
            //disabled={true}
          >
            <ListItemIcon>
              <ListIcon className={this.props.views.currentView === "loadmodel" ? classes.selected : classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{ fontSize: ".9rem", fontWeight: 500 }}
                  className={this.props.views.currentView === "loadmodel" ? classes.selected : classes.unselected}
                  data-cy="load_model"
                >
                  Load Existing Model
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            button
            onClick={this.saveModelClick}
            disabled={!this.props.views.modelCanBeSaved || !this.props.modeldata.user_can_edit}
            // disabled={true}
            //disabled={!this.props.modeldata.models_are_present}
            selected={this.props.views.currentView === "savemodel"}
          >
            <ListItemIcon>
              <SaveIcon className={this.props.views.currentView === "savemodel" ? classes.selected : classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{ fontSize: ".9rem", fontWeight: 500 }}
                  className={this.props.views.currentView === "savemodel" ? classes.selected : classes.unselected}
                  data-cy="save_update_model"
                >
                  {this.props.modeldata.model_saved ? "Update Current Model" : "Save Current Model"}
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            button
            onClick={this.handleShareModelClick}
            selected={this.props.views.currentView === "sharemodel"}
            //disabled={true}
            disabled={!this.props.modeldata.model_saved || !this.props.modeldata.user_can_share}
          >
            <ListItemIcon>
              <ShareIcon className={this.props.views.currentView === "sharemodel" ? classes.selected : classes.unselected} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  style={{ fontSize: ".9rem", fontWeight: 500 }}
                  className={this.props.views.currentView === "sharemodel" ? classes.selected : classes.unselected}
                  data-cy="share_model"
                >
                  Share Current Model
                </Typography>
              }
            />
          </MenuItem>
        </List>
      </div>
    );
  }
}

MenuList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels
  };
})(withStyles(styles)(MenuList));
