import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
  palette: {
    primary: {
      light: "#cccccc",
      main: "#ffffff",
      dark: "#cccccc"
    },
    secondary: {
      light: "#4dabf5",
      main: "#2196f3",
      dark: "#1769aa"
    }
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: "none"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: 500
      }
    },
    MuiAccordion: {
      root: {
        "&:before": {
          backgroundColor: "white"
        }
      }
    }
  }
});

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

class SiteInfo extends Component {
  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.layout}>
            <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
              <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
                About Mixing Studio
              </Typography>

              <Typography
                gutterBottom
                style={{ textAlign: "center", marginLeft: 36, marginBottom: 24, marginRight: 36, marginTop: 24 }}
                variant="subtitle1"
              >
                Mixing Studio is an online machine learning analysis tool designed to simplify and streamline creation, evaluation, and utilization of
                predictive models to help 3M’ers all over the globe to integrate statistical analysis into their projects, and make data-driven
                decisions more quickly. Mixing Studio accepts data in Excel (.xlsx) format, automatically handles conversion of text-based inputs,
                intelligently selects optimal model parameters, and surfaces model results through interactive visualizations. All trained models can
                be used to make predictions, and to find optimal formulations given target performance ranges. Models can be saved and reloaded at any
                time, shared with others within 3M, or exported into python-based Jupyter notebooks and used offline.
              </Typography>
            </Card>

            <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
              <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                Mixing Studio Team
              </Typography>
              <Typography
                gutterBottom
                style={{ textAlign: "center", marginLeft: 36, marginBottom: 24, marginRight: 36, marginTop: 24 }}
                variant="subtitle1"
              >
                Mixing Studio is developed and managed by the On-a-Roll Team, a part of the Skylab Project.
              </Typography>
              <Typography
                gutterBottom
                style={{ textAlign: "center", marginLeft: 36, marginBottom: 24, marginRight: 36, marginTop: 24 }}
                variant="subtitle1"
              >
                Mixing Studio has benefitted greatly from all the people who provided throughtful feedback, testing, and ideas, and we wish to
                extend our thanks to all those who played a role in building this tool. If you have any issues, comments, questions, or ideas on
                new directions, please feel free to
                <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u">
                reach out to us</a>.
              </Typography>
            </Card>

            <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
              <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                An Example Workflow
              </Typography>
              <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                <u>Loading Data and Creating Models</u>
              </Typography>
              <Typography gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                Navigate to "Create New Model" from the left menu. This is where loading data and creating model take place. <br />
                <br />
                1) Click on the "CHOOSE FILE" button under "File Upload" to choose the Excel file you want to import, and then click on the
                "UPLOAD FILE" button. Mixing Studio takes the first row as the column headers. It allows multiple sheets, but only one
                sheet can be used to create models at once. <br />
                <br />
                2) Move down to the "Data Selection" section. After choosing the desired sheet from the dropdown menu, you would want to pick
                and choose the columns for Inputs (X) and Outputs (Y); use the arrows buttons to select and deselect the column headers. At the
                bottom of this section, you'll find the summary of the number of data points and the list of output columns. Note that Mixing
                Studio would skip any data point with empty cells so pay attention to the final number of data points may be smaller than the
                total number of rows imported. For spreadsheets in which empty cells means zero, you would want to fill those cells with zero
                prior to loading the data. <br />
                <br />
                3) The next section, "Data Snapshot", offers the view of the entire imported dataset. You may choose to view it as is ("View Raw
                Data", the default), or, if you have categorical columns imported, as the encoded data. This means that the categorical column
                is then so-called "one-hot" encoded, with only 0's and 1's as the values. <br />
                <br />
                4) Lastly, you may choose the approapriate model(s) and click "GENERATE MODELS" to start training. If you choose to train multiple
                models at the same time, we suggest that you train those models with "tune parameters" (last checkbox) unchecked, evaluate and pick
                the best one, and perform a second training with the best model and "tune parameters" selected.
              </Typography>
              <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                <u>Evaluating a Trained Model</u>
              </Typography>
              <Typography gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                Once one or more models have been trained, the "Model Evaluation" panel is shown. Here, by selecting the specific trained model and
                each output variable, the R-squared value (between 0 and 1, 1.0 being a perfect fit) and the predicted v. observed plot for each
                data point are shown for you to evaluate the model. Also provided is a sensitivity chart showing how each input variable affects
                the output quantitatively; Positive/negative value indicates the specific input variable affects the output variable
                positively/negatively, whereas its absolute value reflects the correlation. You also have the option to save these plots as PNG images.
              </Typography>
              <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                <u>Predicting Outputs Using a Trained Model</u>
              </Typography>
              <Typography gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                With a trained model that fit well to the existing data, you may then predict the output variables from a new set of input variables,
                via the panel of "Model Prediction". On the top of the page, make sure you select the correct model if you have multiple models
                trained. <br/>
                <b>Single point prediction: </b> Use the slider or directly type in your input value, the parallel coordinate chart will be updated to reflect the
                predicted value. You can add input columns to the chart by changing the display settings on the right. If you want to export the predicted
                value, click the shopping cart button to add the value to batch prediction table below. Or click the save button to save the paraleel coordinate
                chart to PNG image. <br/>
                <b>Batch prediction: </b> In the batch prediction table with the input and output columns already provided, fill in the input variables from which
                you would like to predict outputs. Once all the input values are filled for a certain row, the output values of that rows should appear immediately.
                You may also copy/paste from external spreadsheets to the table instead of typing them out manually. <br />
                <br />
                The section beneath the table allows you to specify the constraints of the input variables, i.e. if the variables are supposed to
                follow additional mathematical restrictions (such as the sum of compositions of all components should add up to 100%). Defining and
                selecting one or more constraints would add a "Constraints Satisfied" column to the table with True or False values indicating if
                the current row of inputs satisfy the selected constraints.
              </Typography>
              <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                <u>Optimizing Using a Trained Model</u>
              </Typography>
              <Typography gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                With a model trained, Mixing Studio also offers in the "Experiment Optimization" the tool to suggest inputs that would optimize one
                or more outputs. After selecting the desired model for optimization, you would define the optimization conditions by selecting all
                the output variables you want to optimize, selecting the optimization type (i.e. maximize, minimize or target) and entering the
                appropriate values. You may also apply all the constraints required for the inputs. Then click "FIND OPTIMIZED INPUTS" and our
                algorithm will try to find the sets of inputs that meet the optimization conditions and constraints. If one or more results are
                found, you would get a plot and a table showing all the values of the optimized results. If no results are found, you would get a
                pop-up dialog box and you may adjust the conditions and constraints and retry.
              </Typography>
              <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                <u>Save, Share and Load a Model</u>
              </Typography>
              <Typography gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                <b>Save a model</b> - Once you have trained a model, you have the option to save the model so that you can load it at a later time.
                To do so, click on "Save Current Model" and enter a model name and a model description and click "SAVE MODEL". <br />
                <br />
                <b>Share a model</b> - With a model trained or loaded, you may choose to share it to other people. Click on "Share Current Model"
                and you're asked how you want to share the model. "Public" means that the model is shared with anyone having access to Mixing
                Studio and "private" means only those you share the model to have access. If you choose "private", you can then start entering the
                name(s) of the people you plan to share the model with. A dropdown list appears as you start typing, with the complete names and
                their departments, and you may click on the one that you want directly from the list if you see it appears. You may add more than
                one name at a time. In the dropdown list to the right of the name(s), select the desired level of access that you would like to
                grant - "read" means they can access the model you shared but they cannot share it with other people, while "share" means they can
                view and they can also share this model. Click "UPDATE MODEL" to complete the share. <br />
                <br />
                <b>Load a model</b> - At anytime you may load a model that you have access to, including the models you saved, all the public models
                available, and private models that are shared with you. You can view the entire list of models available to you under "Load existing
                Model". Select the model you choose to load and click "LOAD SELECTED MODEL". Beware that if you have trained a model without saving
                it and you load another model, your trained model will be lost.
              </Typography>
              <Typography variant="subtitle2" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                <u>Other Helpful Tools</u>
              </Typography>
              <Typography gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                <b>Pairwise Relations</b> - The "Pairwise Relations" panel offers a convenient visualization tool for your imported data. It offers all the
                data plotted with every pair of input/output variables, as well as the histograms of each variable. You may double click on each plot
                to enlarge it, and in the enlarged plot you may double click on each data point to see its detail values of all the variables. <br />
                <br />
                <b>Export to Jupyter Notebook</b> - Mixing Studio also offers all of the data, i.e. the entire imported dataset, the parameters of
                the trained model(s), etc., exported to a Jupyter Notebook, if you choose to play with the data yourself or with your customized
                analysis and visualization that Mixing Studio does not offer. All you do is click on "Export to Jupyter Notebook (Beta)" after a model
                is trained or loaded and the Jupyter Notebook file is downloaded to your local drive.
              </Typography>

            </Card>

            <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
              <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24, marginBottom: 24 }}>
                Troubleshooting and FAQs
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left", marginLeft: 24, marginBottom: 24, marginRight: 24 }}>
                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        I cannot load a file.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography component={'span'} gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          Try one of the following:
                          <ul>
                            <li>Make sure it's a valid Excel file that you're importing. It is also required that all column headers are in Row 1.</li>
                            <li>If the webpage was opened a while ago, your credential may be expired. Click on the "LOGOUT" button on the top right
                              of the page and wait for the website to re-log you in. You may be required to enter your 3M password and perform the
                              multifactor authentication once again.
                            </li>
                            <li>Otherwise, click "Submit Feedback" in the left menu bar to
                              submit an issue, or click <a
                                href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                                target="_blank" rel="noopener noreferrer"
                                >here</a>.
                            </li>
                          </ul>


                          Mixing Studio accepts data in Excel (*.xlsx, *.xls) and CSV format. The standard convention for organizing data is to include
                          each input variable/performance metric as separate column, and each experiment as a new row. Your data can be either numerical or
                          text-based (for categorical variables)—the latter will be automatically encoded using a ‘one-hot’ strategy on the back-end.
                          Blank cells in your data will be interpreted as missing data, and you should include 0’s in cells where included variables
                          were not used, or were not relevant. For more tips on data organization, we recommend Hadley Wickham’s ‘Tidy Data’ or{" "}
                          <a href="https://medium.com/@JosiahParry/tidy-data-a-very-brief-review-6785e410d076" target="_blank"rel="noopener noreferrer" >
                            this summary article.
                          </a>
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        There are missing columns/rows in the file I uploaded.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography component={'span'} gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          This could be caused by one of the following:
                          <ul>
                            <li>You're on a different sheet. Make sure you have selected the correct sheet from the dropdown menu.</li>
                            <li>Your data in the Excel file does not start from the first row, and/or your column headers are not in the first row.</li>
                            <li>There are empty cells in certain columns of the spreadsheets. Mixing Studio only reads in the rows in which all
                              cells are filled; those with empty cells are automatically trimmed out. You may confirm by checking the "Number of data
                              points" column for each header in the "Data Selection" section.
                            </li>
                            <li>If it's not any of the causes above, click "Submit Feedback" in the left menu bar to submit an issue, or
                              click <a
                                href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                                target="_blank" rel="noopener noreferrer"
                                >here</a>.
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        The "GENERATE MODELS" button fails to respond, or fails to train a model successfully.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography component={'span'} gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          This could be caused by one of the following:
                          <ul>
                            <li>If Mixing Studio tries to train for a while but then fails,
                              make sure your numerical column is not mistreated as categorical column, which happens if non-numerical character(s)
                              are present in any cell of the column. You can check that by navigating to the bottom of the "Data Snapshot" section and
                              make sure the column name is not listed under "Categorical data columns".
                            </li>
                            <li>If Mixing Studio trains forever and not returns a result, try reload the page and retrain with fewer models selected,
                              and make sure you only apply parameter tuning when only one model is selected. Very large dataset may slow down the time
                              to train, so you may also try loading a smaller dataset.
                            </li>
                            <li>If the button fails to respond and the webpage was opened a while ago, your credential may be expired. Click on the
                              "LOGOUT" button on the top right of the page and wait for the website to re-log you in. You may be required to enter your
                              3M password and perform the multifactor authentication once again. If the problem persists, click "Submit Feedback" in the
                              left menu bar to submit an issue, or
                              click <a
                                href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                                target="_blank" rel="noopener noreferrer"
                                >here</a>.
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        How do I select the proper models?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography component={'span'} gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          <ul>
                            <li>If you know that it is a linear relationship between the inputs and outputs, select Partial Least Squares (PLS).</li>
                            <li>If you're not sure if the relationship is linear or not, and there are less than ~200 data points, start with PLS
                              and Kernel Ridge Regression (KRR).
                            </li>
                            <li>If you have data points much greater than 200 and you know the relationship you're looking for is nonlinear, you may
                              choose to also try Neural Network (NN). It may pick up much more complicated relationships within the dataset, but it
                              is subject to overfitting and it also lacks a clear mathematical picture.</li>
                            <li>Once you have found a model that works best, go back to the "Create New Model" panel select this model and also check
                              the box for parameter tuning and retrain the model for best results.
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        How do I tell if a model performs a decent fit?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          In the "Model Evaluation" panel, select the model from the dropdown menu if you have trained more than one model. Go
                          through each of the output variables and examine the R-square error (The closer to 1.0 it is the better fit). Visually,
                          all data points should be on or close to the gray dashed line for a good fit.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        How can I determine what inputs drive variation in my system?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          The "Sensitivity Analysis" visualization inside the "Model Evaluation" panel was designed to provide insight into what the key
                          drivers of output performance are in a given system. As displayed large positive bars indicate that increasing that input
                          variable will increase the selected output. Likewise, for large negative bars, increasing that input will decrease the
                          selected output. <br />
                          <br />
                          During the training routine, we evaluate how much changing each input—while holding everything else constant—influences each
                          output. We first iterate over every input variable. For each row of data we make two predictions, one where the selected
                          input is set to the lowest reported value, and the other where the input is set to the highest reported value. The
                          difference between these values (max-min) is stored. After iterating over all rows and all input variables, we find the
                          maximum & minimum differences observed in an output when switching between high and low values and calculate the difference
                          between these two values. For each input we compute the average change from switching across all rows and report the ratio
                          of this average, against the range of max-min differences observed. The magnitude and directionality of this ratio provides
                          insight into what effect increasing or decreasing this variable will have.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        The model appears to have fitted well but the prediction makes no sense.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          Before suspecting that there are some anomalies with the dataset or that there is a bug with the Mixing Studio algorithm,
                          please double check on the model selected if you have trained multiple models. It's a common mistake that you identified
                          one model and accidentally used another for prediction. If you believe there are indeed an issue with Mixing Studio,
                          click "Submit Feedback" in the left menu bar to submit the issue, or
                          click <a
                            href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                            target="_blank" rel="noopener noreferrer"
                            >here</a>.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        I fail to train a good model with any of the algorithm provided.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          Machine learning models are not magic. In many cases, if none of the models provide a fair level of fitting the training
                          data, the problem is likely in the data provided. Are the data properly cleaned (misaligned data, empty cell instead of zero,
                          accidental non-numerical characters in numerical columns, mixed units or %, etc.)? Do you have enough data points? Do you have
                          enough input variables (features), i.e. information, provided (underfitting)? Do you have too many features compared to the
                          number of data points (overfitting, which you would have good fit on training data but poor prediction on test data)? Do you
                          have many contradictory data points (where two or multiple data points with same or very similar input variables have dramatically
                          different output variables)? <br/>
                          <br/>
                          In more rare cases with materials science, the input variables may have a rather complicated relationship with the output
                          variables. If that's the case then you may need algorithms beyond what Mixing Studio offers.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        Optimization fails to find any valid results for me, but I'm confident there are valid solutions within the optimization
                        conditions and constrants.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          First, please double check that the optimization type and value are set correctly, and any constraints (if applicable) are
                          updated properly (you'll need to click "ADD CONSTRAINT" after editing the constraints). Please also note that if you're
                          using "target" as the optimization type for one or more outputs, the target value difference has to be less than 20%. This
                          is a criterion for our optimization algorithm so that our results converge. If you have none of the issues mentioned, please
                          navigate to "Submit Feedback" and report the issue, or
                          click <a
                            href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                            target="_blank" rel="noopener noreferrer"
                            >here</a>.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        I get multiple optimization outputs. How are they obtained?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          The optimization, at this moment, is performed via Particle Swarm Optimization (PSO). It would return top 10 solutions that
                          meet your conditions and constraints. Some or even all of the solutions may be the same or very close to one another.
                          Then all the results that are very close together are combined into one, so that the number of optimized solutions may be
                          less than 10.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        There are some features I would want that's currently not in Mixing Studio. Can you add them?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          We always appreciate feedbacks from our users. If there are suggesstions that you have on new features, issues that you
                          encounters, or confusing user interface / user experience that bothers you, we would want to know of those. Please
                          navigate to "Submit Feedback" panel on the left and submit your feedback to us, or
                          click <a
                            href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                            target="_blank" rel="noopener noreferrer"
                            >here</a>.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        Where are my models saved? I'm worried about the confidential information that's uploaded to the internet.
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          All the models are saved in DynamoDB on the AWS cloud. It is allowed that generic 3M confidential data be used and saved
                          on Mixing Studio, but please refrain from uploading trade secret and/or export controlled data to Mixing Studio. You may consider using
                          non-sensitive column headers to remove the trade secret information from the dataset. At the moment, we're working towards
                          a solution for data governance, so please stay tuned for updates in the future. If you have any concerns or suggestions,
                          please use "Submit Feedback" in the left menu to let us know, or
                          click <a
                            href="https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u"
                            target="_blank" rel="noopener noreferrer"
                            >here</a>
                          . Thank you for your effort on protecting 3M's intellectual
                          property.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={0} style={{ marginTop: 0, marginBottom: 12 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0, paddingRight: 0, height: 30, minHeight: 36 }}>
                      <Typography variant="button" style={{ fontSize: "medium", textTransform: "inherit" }}>
                        How do I use my trained model(s) offline?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={10} style={{ paddingTop: 12 }}>
                        <Typography gutterBottom style={{ textAlign: "left", paddingBottom: 10, marginLeft: 12, marginRight: 12 }}>
                          You can export your trained models to a Jupyter Notebook which can be run locally and edited to create new plots, tests,
                          etc. For more details about running jupyter notebooks we recommend reviewing{" "}
                          <a
                            href="https://medium.com/better-programming/beginners-quick-guide-for-handling-issues-launching-jupyter-notebook-for-python-using-anaconda-8be3d57a209b"
                            target="_blank" rel="noopener noreferrer"
                          >
                            this article
                          </a>
                           . It is worth noting, the models included in these notebooks are the exact same as what you work with inside Mixing Studio,
                          and should give identical predictions offline.
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Card>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

SiteInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views
  };
})(withStyles(styles)(SiteInfo));
