import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import DataDistributionPlot from './DataVizPlots/DataHistPlot'
import ScatterPlot from './DataVizPlots/ScatterPlot'
import BoxPlot from './DataVizPlots/BoxPlot'
import ViolinPlot from './DataVizPlots/ViolinPlot'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { isNumber } from './DataVizPlots/utils'
import * as actions from "../actions/viewActions";
const styles = (theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    marginBottom: 30,
    minWidth: 120,
    flexWrap: "wrap",
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto",
  },
  selectFig: {
    minWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
    width: theme.spacing(20)
  },
});


function DataViz(props) {
  const { classes } = props;
  const [currentPlots, setCurrentPlots] = useState([])
  const [selectedPlots, setSelectedPlots] = useState("")
  const plotLists = {
    'Histogram': 'dataHist',
    'Scatter plot': 'scatter',
    'Box plot': 'box',
    'Violin plot': 'violin',
    // 'Ternary plot': 'ternary', // commented out until we've built it.
  }

  // read and parse data from string
  const getAllData = () => {
    const all_data_str = props.modeldata.all_data
    if (!all_data_str) return ''
    const all_data_keys = Object.keys(all_data_str)

    let allData = {}
    all_data_keys.map(header => {
      // parse string
      let currentData = JSON.parse(all_data_str[header]);

      // remove cat. data
      const isCat = currentData.data[0].map(elem => isNumber(elem))
      currentData.columns = currentData.columns.filter((column, idx) => isCat[idx])
      currentData.data = currentData.data.map((data) => data.filter((column, idx) => isCat[idx]))

      allData[header] = currentData
      return ""
    });

    return allData
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allData = useMemo(() => getAllData(), [props.modeldata.all_data, getAllData])

  const handleSelectChange = (event) => {
    const newValue = event.target.value
    setSelectedPlots(newValue);
  };

  const handleAddPlot = () => {
    setCurrentPlots([...currentPlots, selectedPlots])
  }

  const handleRemovePlot = (idx) => {
    setCurrentPlots(currentPlots.filter((item, i) => i !== idx))
  }


  return (
    <>
      <Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginBottom: 24 }}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
        >
          Data Visualization
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            textAlign: "left",
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 0
          }}
        >
          Select a plot type and click add to visualize data.
        </Typography>
        <Grid container>
          <Grid item xs>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-fig-label">Select a plot type</InputLabel>
              <Select
                value={selectedPlots}
                className={classes.selectFig}
                id="plot-select"
                onChange={handleSelectChange}
              >
                {Object.keys(plotLists).map(name => (
                  <MenuItem key={name} value={plotLists[name]}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleAddPlot}
              style={{ marginTop: 24 }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => setCurrentPlots([])}
              style={{ marginTop: 24 }}
            >
              Close All
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => props.dispatch(actions.changeViewType('newmodel'))}
              style={{ marginTop: 24 }}
            >
              Re-upload or Edit Data
            </Button>
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <Grid container>
          {currentPlots.map((plot, idx) => {
            if (plot === 'dataHist') {
              return (
                <Grid key={"data_viz_div_" + idx} item xs={6} style={{ padding: 10 }}>
                  <DataDistributionPlot data={allData} handleRemovePlot={handleRemovePlot} idx={idx} />
                </Grid>
              );
            }
            else if (plot === 'scatter') {
              return (
                <Grid key={"data_viz_div_" + idx} item xs={6} style={{ padding: 10 }}>
                  <ScatterPlot data={allData} handleRemovePlot={handleRemovePlot} idx={idx} />
                </Grid>
              );
            }
            else if (plot === 'box') {
              return (
                <Grid key={"data_viz_div_" + idx} item xs={6} style={{ padding: 10 }}>
                  <BoxPlot data={allData} handleRemovePlot={handleRemovePlot} idx={idx} />
                </Grid>
              );
            }
            else if (plot === 'violin') {
              return (
                <Grid key={"data_viz_div_" + idx} item xs={6} style={{ padding: 10 }}>
                  <ViolinPlot data={allData} handleRemovePlot={handleRemovePlot} idx={idx} />
                </Grid>
              );
            }
            else {
              return (
                <Grid key={"data_viz_div_" + idx} item xs={6} style={{ padding: 10 }}>
                  <Typography variant="h6">Coming soon...</Typography>
                </Grid>
              );
            }
          })
          }
        </Grid>
      </Card>
    </>
  );
}

export default connect((state, props) => {
  return {
    views: state.views,
    savemodel: state.savemodel,
    modeldata: state.modeldata,
    currentmodels: state.currentmodels,
  };
})(withStyles(styles)(DataViz));