import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import d3Tip from "d3-tip";
import _ from "lodash";
import "./MultiLineChart.css";

class ParallelCoordinatesChart extends Component {
  constructor(props) {
    super(props);

    this.fullWidth = 0;
    this.chartWidth = 0;
    this.chartHeight = 0;
    this.titleHeight = 15;
    this.chartMargins = null;

    this.yScale = null;
    this.yAxis = null;
    this.yAxisGroup = null;
    this.yDomain = [1, 10];

    this.xScale = null;
    this.xAxis = null;
    this.xAxisGroup = null;
    this.xDomain = [1, 10];

    this.xAxisLabel = null;
    this.yAxisLabel = null;
    this.chartTitle = null;
    this.chartLabel = null;

    this.lineGroup = null;
    this.pointGroup = null;

    this.tip = null;

    this.brush = null;
    this.clippingMaskBrush = null;
    this.idleDelay = 350;

    this.animationDuration = 500;
    this.zoomDuration = 500;

    this.chart = null;
    this.clippingMask = null;
    this.lineOfBestFit = null;
    this.dimensions = null;
    this.axisGroup = null;

    this.optimizationScheme = [
      "#e6194B",
      "#f58231",
      "#ffe119",
      "#bfef45",
      "#3cb44b",
      "#42d4f4",
      "#4363d8",
      "#9911eb",
      "#f032e6",
      "#469990",
      "#000075"
    ];
    this.predictionScheme = { PredictionPLS: "#0087ec", PredictionNN: "#4caf50", PredictionKRR: "#9c27b0", PredictionSVR: "#e91e63" };
    this.colorScheme = d3.scaleOrdinal(d3.schemeCategory10);

    this.resize = this.resize.bind(this);
    this.debouncedResize = _.debounce(this.resize, 250);
    this.current_props = null;
  }

  axesAreValid() {
    if (
      !isNaN(this.props.xAxisMin) &&
      this.props.xAxisMin !== "" &&
      !isNaN(this.props.xAxisMax) &&
      this.props.xAxisMax !== "" &&
      !isNaN(this.props.yAxisMin) &&
      this.props.yAxisMin !== "" &&
      !isNaN(this.props.yAxisMax) &&
      this.props.yAxisMax !== ""
    ) {
      return this.props.xAxisMin < this.props.xAxisMax && this.props.yAxisMin < this.props.yAxisMax;
    }
    return false;
  }

  resize() {
    if (document.getElementById(this.props.appendDiv).clientWidth === 0 || this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();

    this.updateAxisGroups(0);
    this.updateLines(0);
    this.current_props = this.props;
  }

  componentDidMount() {
    this.current_props = this.props;
    const el = ReactDOM.findDOMNode(this);
    this.updateDimensions();
    this.createChart(el);
    window.addEventListener("resize", this.debouncedResize);
    this.updateAxisGroups(0);
    this.updateLines(this.animationDuration);
  }

  shouldComponentUpdate(newProps) {
    if (document.getElementById(this.props.appendDiv).clientWidth !== this.fullWidth && newProps.currentView === this.current_props.currentView) {
      this.animationDuration = 0;
      return true;
    }
    if (_.isEqual(newProps, this.current_props) && _.isEqual(newProps.data, this.current_props.data)) {
      return false;
    }
    if (newProps.currentView !== this.current_props.currentView) {
      //if this is not true it suggest tab change which doesnt need an update
      return false;
    }
    this.current_props = newProps;
    return true;
  }

  componentDidUpdate() {
    if (this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();
    this.updateAxisGroups(this.animationDuration);
    this.updateLines(this.animationDuration);
    this.animationDuration = 500;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedResize);
  }

  wrapText() {
    let words = this.chartLabel
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      x = this.chartLabel.attr("x"),
      y = this.chartLabel.attr("y"),
      dy = 0, //parseFloat(text.attr("dy")),
      tspan = this.chartLabel
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
    let maxHeight = 0;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > this.chartWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = this.chartLabel
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
        maxHeight = d3.max([maxHeight, parseFloat(y) + parseFloat(lineNumber * 24)]);
      }
    }
    maxHeight += this.titleHeight + 15;
    if (maxHeight > this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight) {
      this.mainBody.attr("height", maxHeight);
    }
  }

  updateDimensions() {
    //Figure out how much space you need for your longest label...
    let currentWidth = document.getElementById(this.props.appendDiv).clientWidth;
    this.chartMargins = { left: currentWidth * 0.025, right: currentWidth * 0.025, top: 20, bottom: 20 };
    if (this.props.legendText.length > 0) {
      this.chartMargins.bottom = currentWidth * 0.05;
      this.chartMargins.top = 30;
      this.chartMargins.left = currentWidth * 0.05;
      this.chartMargins.left = currentWidth * 0.05;
    }

    this.fullWidth = currentWidth;
    this.chartWidth = currentWidth - (this.chartMargins.left + this.chartMargins.right);
    this.chartHeight = currentWidth * this.props.heightToWidthRatio;
  }

  updateDomains() {
    this.dimensions = this.props.dimensions
      .filter(function(el) {
        return el.display;
      })
      .map(x => x.col);
    this.yDomain = {};
    let currYDomain = this.yDomain;
    let currData = this.props.data;
    this.xDomain = this.dimensions;
    this.dimensions.forEach(function(el) {
      currYDomain[el] = d3.extent(currData, function(p) {
        return +p[el];
      });
    });
    if (this.props.useFullDataRanges) {
      this.props.fullDataRanges.forEach(function(el) {
        if (currYDomain[el.column] !== undefined) {
          currYDomain[el.column][0] = Math.min(el.min, currYDomain[el.column][0]);
          currYDomain[el.column][1] = Math.max(el.max, currYDomain[el.column][1]);
        }
      });
    }
  }

  updateScales() {
    this.xScale = d3
      .scalePoint()
      .range([0, this.chartWidth])
      .padding(0.01)
      .domain(this.xDomain);
    this.yScale = {};
    let currYScale = this.yScale;
    let currYDomain = this.yDomain;
    let currChartHeight = this.chartHeight;
    this.dimensions.forEach(function(el) {
      currYScale[el] = d3
        .scaleLinear()
        .domain(currYDomain[el])
        .range([currChartHeight, 0]);
    });
  }

  updateAxes(duration) {}

  updateAxisGroups(animationDuration) {
    let currXScale = this.xScale;
    let currYScale = this.yScale;
    this.axisGroup
      .selectAll(".dimension")
      .data(this.dimensions, function(d) {
        return d;
      })
      .enter()
      .append("g")
      .attr("class", "dimension")
      .attr("transform", function(d) {
        return "translate(" + currXScale(d) + ")";
      })
      .append("g")
      .attr("class", "axis")
      .each(function(d) {
        d3.select(this).call(d3.axisLeft(currYScale[d]));
      })
      .append("text")
      .attr("class", "label")
      .style("text-anchor", "middle")
      .attr("transform", "rotate(-15)")
      .style("fill", "black")
      .style("font-size", "11px")
      .style("font-family", "Roboto,Sans-Serif")
      .attr("y", -25)
      .text(function(d) {
        return d;
      });

    this.axisGroup
      .selectAll(".dimension")
      .data(this.dimensions, function(d) {
        return d;
      })
      .attr("transform", function(d) {
        return "translate(" + currXScale(d) + ")";
      })
      .selectAll("g.axis")
      .each(function(d) {
        d3.select(this)
          .transition()
          .duration(animationDuration)
          .ease(d3.easeCubic)
          .call(d3.axisLeft(currYScale[d]));
      })
      .select(".label")
      .text(function(d) {
        return d;
      });

    this.axisGroup
      .selectAll(".dimension")
      .data(this.dimensions, function(d) {
        return d;
      })
      .exit()
      .remove();
  }

  updateLines(duration) {
    var currXScale = this.xScale;
    var currYScale = this.yScale;
    var currYDomain = this.yDomain;
    var currData = this.props.data;
    var dimensions = this.dimensions;
    var currLine = d3.line();
    var useOptScheme = this.props.useOptimizationScheme;
    var optScheme = this.optimizationScheme;
    let predScheme = this.predictionScheme;

    this.lineGroup
      .selectAll(".line")
      .data(currData, function(d) {
        return d._dataRow;
      })
      .enter()
      .append("path")
      .attr("class", "line")
      .attr("d", d =>
        currLine(
          dimensions.map(function(p) {
            return [currXScale(p), currYScale[p](currYDomain[p][0])];
          })
        )
      )
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("d", d =>
        currLine(
          dimensions.map(function(p) {
            return [currXScale(p), currYScale[p](d[p])];
          })
        )
      )
      .style("stroke", function(d) {
        return useOptScheme
          ? d._dataRow.startsWith("Prediction")
            ? predScheme[d._dataRow]
            : optScheme[d._dataRow]
          : d._dataRow.startsWith("Prediction")
          ? predScheme[d._dataRow]
          : "#BFBFBF";
      })
      .style("stroke-width", function(d) {
        return useOptScheme ? "2px" : d._dataRow.startsWith("Prediction") ? "3px" : "1px";
      })
      .style("opacity", function(d) {
        return useOptScheme ? 1 : d._dataRow.startsWith("Prediction") ? 1 : 0.4;
      })
      .style("fill", "none");

    this.lineGroup
      .selectAll(".line")
      .data(currData, function(d) {
        return d._dataRow;
      })
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("d", d =>
        currLine(
          dimensions.map(function(p) {
            return [currXScale(p), currYScale[p](d[p])];
          })
        )
      )
      .style("stroke", function(d) {
        return useOptScheme
          ? d._dataRow.startsWith("Prediction")
            ? predScheme[d._dataRow]
            : optScheme[d._dataRow]
          : d._dataRow.startsWith("Prediction")
          ? predScheme[d._dataRow]
          : "#BFBFBF";
      })
      .style("stroke-width", function(d) {
        return useOptScheme ? "2px" : d._dataRow.startsWith("Prediction") ? "3px" : "1px";
      })
      .style("opacity", function(d) {
        return useOptScheme ? 1 : d._dataRow.startsWith("Prediction") ? 1 : 0.4;
      })
      .style("fill", "none");

    this.lineGroup
      .selectAll(".line")
      .data(currData, function(d) {
        return d._dataRow;
      })
      .exit()
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .attr("d", d =>
        currLine(
          dimensions.map(function(p) {
            return [currXScale(p), currYScale[p](currYDomain[p][0])];
          })
        )
      )
      .remove();
  }

  updateTooltips() {
    if (!this.props.showToolTips) {
      return;
    }
    let toolTip = this.tip;
    let xAxisName = this.props.xLabel;
    let yAxisName = this.props.yLabel;

    this.lineGroup
      .selectAll(".line")
      .on("mouseover", function(el) {
        d3.select(this).style("stroke-width", "4px");
      })
      .on("mouseout", function(el) {
        d3.select(this).style("stroke-width", "2px");
      });

    this.pointGroup
      .selectAll("circle")
      .on("mouseover", function(el) {
        d3.select(this)
          .attr("r", "6px")
          .style("stroke", "black")
          .style("fill", "gold")
          .style("stroke-width", "2px");
        let parentID = d3.select(this)._groups[0][0].id;
        toolTip.html(function(i) {
          let tipText =
            "<strong style='color:red;font-size:13px;'>Data Row: </strong><span style='color:white;font-size:13px;font-weight:400'>" +
            parentID +
            "</span> <br>";
          tipText +=
            "<strong style='color:red;font-size:13px;'>" +
            xAxisName +
            ": </strong> <span style='color:white;font-size:13px;font-weight:400'>" +
            Number.parseFloat(el.Obs).toFixed(3) +
            "</span> <br>";
          tipText +=
            "<strong style='color:red;font-size:13px;'>" +
            yAxisName +
            ": </strong> <span style='color:white;font-size:13px;font-weight:400'>" +
            Number.parseFloat(el.Pred).toFixed(3) +
            "</span> <br>";
          return tipText;
        });
        toolTip.show(el, this);
      })
      .on("mouseout", function(el) {
        d3.select(this)
          .attr("r", 5)
          .style("stroke", "black")
          .style("fill", "#35baf6")
          .style("stroke-width", "1px");
        toolTip.hide(el, this);
      });
  }

  chartZoom() {
    this.updateDataPoints(this.zoomDuration);
    this.updateLines(this.zoomDuration);
    this.xAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(this.zoomDuration)
      .call(this.xAxis);
    this.yAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(this.zoomDuration)
      .call(this.yAxis);
  }

  brushEnded = () => {
    let s = d3.event.selection;
    if (!s) {
      if (!this.idleTimeout) {
        return (this.idleTimeout = setTimeout(
          function() {
            this.idled();
          }.bind(this),
          this.idleDelay
        ));
      }
      this.updateDomains();
      this.updateScales();
    } else {
      this.xDomain = [s[0][0], s[1][0]].map(this.xScale.invert, this.xScale);
      this.yDomain = [s[1][1], s[0][1]].map(this.yScale.invert, this.yScale);
      this.xScale.domain(this.xDomain);
      this.yScale.domain(this.yDomain);
      this.clippingMask.select(".brush").call(this.brush.move, null);
    }
    this.xAxis.scale(this.xScale);
    this.yAxis.scale(this.yScale);
    this.chartZoom();
  };

  idled = () => {
    this.idleTimeout = null;
  };

  updateChartSize = () => {
    this.mainBody
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("width", this.fullWidth);

    this.chart
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    if (this.chartLabel !== null) {
      this.chartLabel
        .attr("y", this.chartHeight + 25)
        .attr("x", this.chartWidth / 2)
        .style("fill", "black")
        .style("font-size", "12px")
        .style("font-family", "Roboto,Sans-Serif")
        .text(this.props.legendText);
      this.chartLabel.call(() => this.wrapText());
    }

    this.lineGroup.moveToFront();
    this.axisGroup.moveToFront();
  };

  createChart = el => {
    //Create scales and initialize with domain 0-10
    this.updateDomains();
    this.updateScales();

    //Append a larger svg and create the graph region
    this.mainBody = d3
      .select(el)
      .append("svg")
      .attr("class", "multiLineMultiAxisChart")
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
      .attr("width", this.fullWidth)
      .attr("id", this.props.chartID);

    this.chart = this.mainBody
      .append("g")
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    this.axisGroup = this.chart.append("g");

    //Create axes for yScales
    this.yAxisGroup = this.chart.append("g").attr("class", "axis yaxis");

    if (this.props.legendText.length > 0) {
      this.chartLabel = this.chart
        .append("text")
        .attr("id", "chartTitle")
        .attr("class", "axisLabel")
        .attr("text-anchor", "middle")
        .attr("y", this.chartHeight + 25)
        .attr("x", this.chartWidth / 2)
        .style("fill", "black")
        .style("font-size", "12px")
        .style("font-family", "Roboto,Sans-Serif")
        .text(this.props.legendText);
      this.chartLabel.call(() => this.wrapText());
    }

    this.lineGroup = this.chart.append("g").attr("id", "yLines");

    this.tip = d3Tip()
      .attr("class", "d3-tip")
      .style("z-index", 10000)
      .offset([-10, 0]);
    this.chart.call(this.tip);

    this.lineGroup.moveToFront();
    this.axisGroup.moveToFront();
  };

  render() {
    return <div style={{ color: "black" }}></div>;
  }
}

d3.selection.prototype.moveToFront = function() {
  return this.each(function() {
    this.parentNode.appendChild(this);
  });
};

d3.selection.prototype.moveToBack = function() {
  return this.each(function() {
    var firstChild = this.parentNode.firstChild;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  });
};

export default ParallelCoordinatesChart;
