export function ChangeUploadedFiles(files) {
  return {
    type: "CHANGE_UPLOADED_FILES",
    data: { uploaded_files: files }
  };
}

export function UpdateUnselectedAllHeaders(headers) {
  return {
    type: "UPDATE_UNSELECTED_ALL_HEADERS",
    data: { unselected_all_headers: headers }
  };
}

export function UpdateInputHeaders(inputHeaders) {
  return {
    type: "UPDATE_INPUT_HEADERS",
    data: { selected_input_headers: inputHeaders }
  };
}

export function UpdateOutputHeaders(outputHeaders) {
  return {
    type: "UPDATE_OUTPUT_HEADERS",
    data: { selected_output_headers: outputHeaders }
  };
}

export function UpdateHighlightedAllHeaders(selection) {
  return {
    type: "UPDATE_ALL_HEADERS_HIGHLIGHTED",
    data: { all_headers_highlighted: selection }
  };
}

export function UpdateHighlightedInputHeaders(selection) {
  return {
    type: "UPDATE_INPUT_HEADERS_HIGHLIGHTED",
    data: { input_headers_highlighted: selection }
  };
}

export function UpdateHighlightedOutputHeaders(selection) {
  return {
    type: "UPDATE_OUTPUT_HEADERS_HIGHLIGHTED",
    data: { output_headers_highlighted: selection }
  };
}

export function UpdateRecommendendOutputHeaders(recommendations) {
  return {
    type: "UPDATE_RECOMMENDED_HEADERS",
    data: { recommended_outputs: recommendations }
  };
}

export function UpdateSelectedTableView(selection) {
  return {
    type: "UPDATE_SELECTED_TABLEVIEW",
    data: { selected_table_view: selection }
  };
}

export function UpdateDataLoading(loading) {
  return {
    type: "UPDATE_DATA_LOADING",
    data: { data_is_loading: loading }
  };
}

export function UpdateSelectedFile(file) {
  return {
    type: "UPDATE_SELECTED_FILE",
    data: { selected_file: file }
  };
}

export function UpdateProgress(progress) {
  return {
    type: "UPDATE_PROGRESS",
    data: { upload_progress: progress }
  };
}

export function UpdateLoadedFileSheets(sheets) {
  return {
    type: "UPDATE_LOADED_FILE_SHEETS",
    data: {
      file_sheets: sheets
    }
  };
}

export function UpdateSelectedFileSheet(sheet, headers, omitted) {
  return {
    type: "UPDATE_SELECTED_SHEET",
    data: {
      file_selected_sheet: sheet,
      unselected_all_headers: headers,
      omitted_headers: omitted
    }
  };
}

export function UpdateGenerateNeuralNetwork(val) {
  return {
    type: "UPDATE_NN",
    data: {
      gen_nn: val
    }
  };
}

export function UpdateGeneratePLSModel(val) {
  return {
    type: "UPDATE_PLS",
    data: {
      gen_pls: val
    }
  };
}

export function UpdateGenerateKRRModel(val) {
  return {
    type: "UPDATE_KRR",
    data: {
      gen_krr: val
    }
  };
}

export function UpdateGenerateSVRModel(val) {
  return {
    type: "UPDATE_SVR",
    data: {
      gen_svr: val
    }
  };
}

export function UpdateRunHyperparameterOptimization(val) {
  return {
    type: "UPDATE_HPO",
    data: {
      run_hpo: val
    }
  };
}

export function ResetAll() {
  return {
    type: "RESET_ALL",
    data: {}
  };
}
