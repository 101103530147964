import React from "react";


export function changeViewType(newView) {
  return {
    type: "CHANGE_VIEW",
    data: { currentView: newView }
  };
}

export function changeLoadingState(loadingState) {
  return {
    type: "CHANGE_LOADING_STATE",
    data: {
      isLoading: loadingState
    }
  };
}

export function changeAlertDisplay(showAlert) {
  return {
    type: "UPDATE_ALERT_STATUS",
    data: {
      showAlert: showAlert
    }
  };
}

export function UpdateAlert(showAlert, alertTitle, alertText){
  return {
    type: 'UPDATE_ALERT',
    data: {
      showAlert: showAlert,
      alertText: alertText,
      alertTitle: alertTitle,
      alertURL: <a target="_blank" rel="noopener noreferrer" href='https://forms.office.com/Pages/ResponsePage.aspx?id=xMPK-qXiV0KvdiBcioId27fNkS_5nE5NgtlIMtDZN-FUQ0FUMjNTRTM2TFJFS0pRVDI2Rkc4WUVXWi4u'>Report Error Here</a>
    }
  }
}

export function updateLoadingMessage(loadingMessage) {
  return {
    type: "CHANGE_LOADING_MESSAGE",
    data: {
      loadingMessage: loadingMessage
    }
  };
}

export function UpdateModelCanBeSaved(state) {
  return {
    type: "UPDATE_MODEL_CAN_BE_SAVED",
    data: {
      modelCanBeSaved: state
    }
  };
}

export function UpdateConfirmDialog(show, title, body) {
  return {
    type: "UPDATE_CONFIRM_DIALOG",
    data: {
      showConfirm: show,
      confirmText: body,
      confirmTitle: title
    }
  };
}

export function UpdateSaveConfirmDialog(show) {
  return {
    type: "UPDATE_SAVE_DIALOG",
    data: {
      showSaveConfirm: show
    }
  };
}

export function UpdateEditDataDialog(show) {
  return {
    type: "UPDATE_EDIT_DATA_DIALOG",
    data: {
      showEditData: show
    }
  };
}

export function UpdateShowShareDialog(show){
  return {
    type: "UPDATE_SHOW_SHARE_DIALOG",
    data: {
      showShareDialog: show
    }
  }
}

export function UpdateShareUsers(users){
  return {
    type: "UPDATE_SHARE_USERS",
    data: {
      shareUsers: users
    }
  }
}

export function UpdateSelectedShareUsers(users){
  return {
    type: "UPDATE_SELECTED_SHARE_USERS",
    data: {
      selectedShareUsers: users
    }
  }
}

export function UpdateShareUserSearching(status){
  return {
    type: 'UPDATE_SHARE_USER_SEARCHING',
    data: {
      shareUserSearching: status
    }
  }
}

export function UpdateSaveModelVisibility(visibility){
  return{
    type: 'UPDATE_MODEL_SAVE_VISIBILITY',
    data:{
      savePrivate: visibility
    }
  }
}

export function UpdateSelectedSharePermission(permission){
  return{
    type:'UPDATE_SELECTED_SHARE_PERMISSION',
    data:{
      selectedSharePermission: permission
    }
  }
}

export function UpdateCurrentlySharedWith(sharedWith){
  return{
    type: 'UPDATE_CURRENTLY_SHARED_WITH',
    data:{
      currentlySharedWith: sharedWith
    }
  }
}

export function UpdateModifiedSharedWith(sharedWith){
  return{
    type: 'UPDATE_MODIFIED_SHARED_WITH',
    data: {
      modifiedSharedWith: sharedWith
    }
  }
}

export function changeDebugMode(debugMode) {
  return {
    type: "CHANGE_DEBUG_MODE",
    data: { debugMode: debugMode }
  };
}

export function changeResponseDelay(delay) {
  return {
    type: "CHANGE_RESPONSE_DELAY",
    data: { delay: delay }
  };
}