let defaultState = {
  optimization_input_values: [],
  optimization_output_values: [],
  selected_model_name: "",

  optimization_display_columns: [],

  optimization_table_data: [],
  optimization_table_headers: [],
  optimization_used_constraints: [],
  optimization_used_targets: [],
  optimization_plot_data: [],

  legend_text: '',
  selected_optimization_row_index: -1,
  selected_optimization_row_data: [],

  optimization_output_target_settings: [],

  optimization_stability_variations: [],
  optimization_stability_table_headers: [],
  optimization_stability_table_data: [],
  optimization_stability_evaluation_data: [],
  optimization_stability_band_data: [],
  optimization_stability_selected_band_data: [],

  optimization_use_random_seed: true,
  optimization_random_seed_value: 100
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_MODEL_OPTIMIZATION_VALUES":
      return {
        ...state,
        optimization_input_values: action.data.optimization_input_values
      };
    case "UPDATE_MODEL_OPTIMIZATION_SELECTED_MODEL":
      return {
        ...state,
        selected_model_name: action.data.selected_model_name
      };
    case "UPDATE_MODEL_OPTIMIZATION_OUTPUT_VALUES":
      return {
        ...state,
        optimization_output_values: action.data.optimization_output_values,
        optimization_table_data: action.data.optimization_table_data,
        optimization_plot_data: action.data.optimization_plot_data,
        legend_text: action.data.legend_text
      };
    case "UPDATE_OPTIMIZATION_DISPLAY_COLUMNS":
      return {
        ...state,
        optimization_display_columns: action.data.optimization_display_columns,
        legend_text: action.data.legend_text
      };
    case "UPDATE_OPTIMIZATION_OUTPUT_TARGET_SETTINGS":
      return {
        ...state,
        optimization_output_target_settings: action.data.optimization_output_target_settings
      };
    case "UPDATE_OPTIMIZATION_DATA":
      return {
        ...state,
        optimization_table_data: action.data.optimization_table_data,
        optimization_table_headers: action.data.optimization_table_headers,
        optimization_used_constraints: action.data.optimization_used_constraints,
        optimization_used_targets: action.data.optimization_used_targets,
        optimization_plot_data: action.data.optimization_plot_data,
        optimization_stability_evaluation_data: action.data.optimization_stability_evaluation_data,
        optimization_stability_band_data: action.data.optimization_stability_band_data,
        selected_optimization_row_index: -1,
        selected_optimization_row_data: []
      };
    case "UPDATE_OPTIMIZATION_SELECTED_OPTIMIZATION":
      return {
        ...state,
        selected_optimization_row_index: action.data.selected_optimization_row_index,
        selected_optimization_row_data: action.data.selected_optimization_row_data
      };
    case "UPDATE_OPTIMIZATION_STABILITY_VARIATIONS":
      return {
        ...state,
        optimization_stability_variations: action.data.optimization_stability_variations
      };
    case "UPDATE_OPTIMIZATION_STABILITY_TABLE_DATA":
      return {
        ...state,
        optimization_stability_table_headers: action.data.optimization_stability_table_headers,
        optimization_stability_table_data: action.data.optimization_stability_table_data
      };
    case "UPDATE_OPTIMIZATION_RANDOMIZATION":
      return {
        ...state,
        optimization_use_random_seed: action.data.optimization_use_random_seed,
        optimization_random_seed_value: action.data.optimization_random_seed_value
      };
    case "RESET_ALL":
      return {
        ...state,
        optimization_input_values: [],
        optimization_output_values: [],
        selected_model_name: "",
        optimization_display_columns: [],
        optimization_table_data: [],
        optimization_table_headers: [],
        optimization_used_constraints: [],
        optimization_used_targets: [],
        optimization_plot_data: [],
        legend_text: "",
        selected_optimization_row_index: -1,
        selected_optimization_row_data: [],
        optimization_output_target_settings: [],
        optimization_stability_variations: [],
        optimization_stability_table_headers: [],
        optimization_stability_table_data: [],
        optimization_stability_evaluation_data: [],
        optimization_stability_band_data: [],
        optimization_stability_selected_band_data: [],
        optimization_use_random_seed: true,
        optimization_random_seed_value: 100
      };
    default:
      return state;
  }
}

export default views;
