import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from "d3";
import d3Tip from "d3-tip";
import _ from 'lodash';


class RegressionScatterChart extends Component {

    constructor(props) {
        super(props)

        this.fullWidth = 0;
        this.chartWidth = 0;
        this.chartHeight = 0;
        this.titleHeight = 15;
        this.chartMargins = null;

        this.yScale = null;
        this.yAxis = null;
        this.yAxisGroup = null;
        this.yDomain = [1, 10];

        this.xScale = null;
        this.xAxis = null;
        this.xAxisGroup = null;
        this.xDomain = [1, 10];

        this.xAxisLabel = null;
        this.yAxisLabel = null;
        this.chartTitle = null;
        this.chartLabel = null;

        this.lineGroup = null;
        this.pointGroup = null;

        this.tip = null;

        this.brush = null;
        this.clippingMaskBrush = null;
        this.idleDelay = 350;

        this.animationDuration = 1000;
        this.zoomDuration = 500;

        this.chart = null;
        this.clippingMask = null;
        this.lineOfBestFit = null;

        this.colorScheme = d3.scaleOrdinal(d3.schemeCategory10);

        this.resize = this.resize.bind(this);
        this.debouncedResize = _.debounce(this.resize, 250);
        this.current_props = null;
        //this.resizeFlag = true;
    }

    axesAreValid() {
        if (
            !isNaN(this.props.xAxisMin) && this.props.xAxisMin !== "" &&
            !isNaN(this.props.xAxisMax) && this.props.xAxisMax !== "" &&
            !isNaN(this.props.yAxisMin) && this.props.yAxisMin !== "" &&
            !isNaN(this.props.yAxisMax) && this.props.yAxisMax !== ""
        ) {
            return (this.props.xAxisMin < this.props.xAxisMax && this.props.yAxisMin < this.props.yAxisMax)
        }
        return false;
    }

    resize() {
        if (document.getElementById(this.props.appendDiv).clientWidth === 0 || this.props.currentView !== this.props.chartType) {
            return;
        }
        this.updateDimensions();
        this.updateChartSize();
        this.updateDomainsAndScales();
        //this.updateScales();
        this.updateAxes(0);
        this.updateAxisLabels();
        this.updateLines(0);
        //this.updateDataPoints(0);
        this.current_props = this.props;
    };

    componentDidMount() {
        this.current_props = this.props;
        const el = ReactDOM.findDOMNode(this);
        this.updateDimensions();
        this.createChart(el);
        this.updateAxisLabels();
        window.addEventListener("resize", this.debouncedResize);
        this.updateLines(this.animationDuration);
        //this.updateDataPoints(this.animationDuration);
        this.updateTooltips();
    };

    shouldComponentUpdate(newProps) {
        if (document.getElementById(this.props.appendDiv).clientWidth !== this.fullWidth
            && newProps.currentView === this.current_props.currentView) {
            this.animationDuration = 1000;
            return true;
        }
        if (_.isEqual(newProps, this.current_props)) {
            return false;
        }
        if (newProps.currentView !== this.current_props.currentView) //if this is not true it suggest tab change which doesnt need an update
        {
            return false;
        }
        this.current_props = newProps;
        return true;
    }

    componentDidUpdate() {
        if (this.props.currentView !== this.props.chartType) {
            return;
        }
        this.updateDimensions();
        this.updateChartSize();
        this.updateDomainsAndScales();
        //this.updateScales();
        this.updateAxes(this.animationDuration);
        this.updateLines(this.animationDuration);
        //this.updateDataPoints(this.animationDuration);
        this.updateAxisLabels();
        this.updateTooltips();
        this.animationDuration = 1000;
    };

    componentWillUnmount() {
        //this.debouncedResize.cancel();
        window.removeEventListener("resize", this.debouncedResize);
    }

    wrapText() {
        let words = this.chartLabel.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = this.chartLabel.attr("x"),
            y = this.chartLabel.attr("y"),
            dy = 0, //parseFloat(text.attr("dy")),
            tspan = this.chartLabel.text(null)
                .append("tspan")
                .attr("x", x)
                .attr("y", y)
                .attr("dy", dy + "em");
        let maxHeight = 0;
        while (words.length > 0) {
            word = words.pop()
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > this.chartWidth) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = this.chartLabel.append("tspan")
                    .attr("x", x)
                    .attr("y", y)
                    .attr("dy", ((++lineNumber * lineHeight) + dy) + "em")
                    .text(word);
                maxHeight = d3.max([maxHeight, (parseFloat(y) + parseFloat(lineNumber * 24))]);
            }
        }
        maxHeight += this.titleHeight;
        if (maxHeight > (this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)) {
            this.mainBody.attr('height', maxHeight);
        }
    }

    wrapTitle() {
        let words = this.chartTitle.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = this.chartTitle.attr("x"),
            y = this.chartTitle.attr("y"),
            dy = 0, //parseFloat(text.attr("dy")),
            tspan = this.chartTitle.text(null)
                .append("tspan")
                .attr("x", x)
                .attr("y", y)
                .attr("dy", dy + "em");
        let maxHeight = 0;
        while (words.length > 0) {
            word = words.pop();
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > this.chartWidth) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = this.chartTitle.append("tspan")
                    .attr("x", x)
                    .attr("y", y)
                    .attr("dy", ((++lineNumber * lineHeight) + dy) + "em")
                    .text(word);
                maxHeight = d3.max([maxHeight, (parseFloat(y) + parseFloat(lineNumber * 24))]);
            }
        }
        this.titleHeight = maxHeight + 15;

        // if (maxHeight > (this.chartHeight + this.chartMargins.top + this.chartMargins.bottom)) {
        //     this.mainBody.attr('height', maxHeight);
        // }
    }

    updateDimensions() {
        //Figure out how much space you need for your longest label...
        let currentWidth = document.getElementById(this.props.appendDiv).clientWidth * 0.9;
        this.chartMargins = { 'left': currentWidth * 0.05, 'right': currentWidth * 0.05, 'top': 0, 'bottom': currentWidth * 0.07 };

        this.fullWidth = currentWidth;
        this.chartWidth = (currentWidth);
        this.chartHeight = currentWidth * this.props.heightToWidthRatio;

        if (this.chartMargins.left < 54) {
            let tmpChartWidth = (currentWidth - 64);
            let tmpChartHeight = (currentWidth * this.props.heightToWidthRatio) - 64;
            if (tmpChartHeight > 0 && tmpChartWidth > 0) {
                this.chartMargins = { 'left': 54, 'right': 10, 'top': 10, 'bottom': 64 };
                this.chartWidth = tmpChartWidth;
                this.chartHeight = tmpChartHeight;
            }
        }
    };

    updateAxisLabels() {
        let maxHeights = [];
        this.xAxisLabel.text(this.props.xLabel);
        this.yAxisLabel.text(this.props.yLabel).each(function (d, j) {
            let thisWidth = this.getComputedTextLength();
            maxHeights.push(thisWidth);
        });
        this.chartTitle.text(this.props.chartTitle);
        this.chartTitle.call(() => this.wrapTitle());
        this.chartLabel.text(this.props.legendText);
        this.chartLabel.call(() => this.wrapText());
        var maxHeight = d3.max(maxHeights);
        if (maxHeight > this.chartHeight) {
            this.mainBody.attr('height', maxHeight + this.chartMargins.top + this.chartMargins.bottom);
        }
    };

    updateDomainsAndScales() {
        this.xDomain = [1, 10];
        this.yDomain = [1, 10];
        if (this.props.scaleAllAxes && this.axesAreValid()) {
            let currXRange = (Number(this.props.xAxisMax) - Number(this.props.xAxisMin));
            let currYRange = (Number(this.props.yAxisMax) - Number(this.props.yAxisMin));
            this.xDomain = this.props.xScaleType === 'linear' ? [Number(this.props.xAxisMin) - (currXRange * 0.03), Number(this.props.xAxisMax) + (currXRange * 0.03)] : [Number(this.props.xAxisMin) / 1.3, Number(this.props.xAxisMax) * 1.3];
            this.yDomain = this.props.yScaleType === 'linear' ? [Number(this.props.yAxisMin) - (currYRange * 0.03), Number(this.props.yAxisMax) + (currYRange * 0.03)] : [Number(this.props.yAxisMin) / 1.3, Number(this.props.yAxisMax) * 1.3];
            return;
        }
        if (this.props.data.length > 0) {

            let xMin = d3.min(this.props.data);
            let xMax = d3.max(this.props.data);
            let range = xMax-xMin;
            this.xDomain = [xMin - (range*0.1), xMax+(range*0.1)];
            this.xScale = d3.scaleLinear().range([0, this.chartWidth]).domain(this.xDomain);
            let densityHistogramData = d3.histogram().domain(this.xDomain).thresholds(this.xScale.ticks(30))(this.props.data);
            let yMin = 0;
            let yMax = d3.max(densityHistogramData.map(d => { return d.length })) * 1.05;
            let densityLinePts = [];
            let updatedDomain = [yMin, yMax];
            this.yScale = d3.scaleLinear().range([this.chartHeight, 0]).domain(updatedDomain);
            densityLinePts.push({x: this.xDomain[0], y: 0});
            densityHistogramData.forEach(el => {
                let midX = (el.x0 + el.x1) / 2;
                let prob = el.length;
                densityLinePts.push({ x: midX, y: ((prob)) });
            });
            densityLinePts.push({x:this.xDomain[1],y: 0});
            this.linePoints = densityLinePts;
            console.log(this.linePoints);

        }
    };

    updateScales() {
        this.xScale = this.props.xScaleType === 'linear' ? d3.scaleLinear().range([0, this.chartWidth]).domain(this.xDomain) : d3.scaleLog().base(10).range([0, this.chartWidth]).domain(this.xDomain);
        this.yScale = this.props.yScaleType === 'linear' ? d3.scaleLinear().range([this.chartHeight, 0]).domain(this.yDomain) : d3.scaleLog().base(10).range([this.chartHeight, 0]).domain(this.yDomain);
    };

    updateAxes(duration) {
        this.xAxis.scale(this.xScale);
        this.xAxisGroup.transition().ease(d3.easeCubic).duration(duration).call(this.xAxis);
        this.yAxis.scale(this.yScale);
        this.yAxisGroup.transition().ease(d3.easeCubic).duration(duration).call(this.yAxis);
    };

    updateLines(duration) {
        var currXScale = this.xScale;
        var currYScale = this.yScale;
        var currXDomain = this.xDomain;
        var currYDomain = this.yDomain;
        var lineFunction = d3.line().x(function (d) { return currXScale(d.x) }).y(function (d) { return currYScale(d.y) }).curve(d3.curveBasis);
        var lineFunctionInitial = d3.line().x(function (d) { return currXScale(currXDomain[0]) }).y(function (d) { return currYScale(currYDomain[0]) }).curve(d3.curveBasis);
        var linePts = this.linePoints;

        this.lineGroup.selectAll('.line')
            .data([linePts])
            .enter()
            .append('path')
            .attr('class', 'line')
            .attr('d', d => lineFunctionInitial(d))
            .transition()
            .ease(d3.easeCubic)
            .duration(duration)
            .attr('d', d => lineFunction(d))
            .style('stroke', '#1976d2')
            .style('stroke-width', 2)
            .style('fill', '#bbdefb');

        this.lineGroup.selectAll('.line')
            .data([linePts])
            .transition()
            .ease(d3.easeCubic)
            .duration(duration)
            .attr('d', d => lineFunction(d))
            .style('stroke', '#1976d2')
            .style('stroke-width', 2)
            .style('fill', '#bbdefb');

        this.lineGroup.selectAll('.line')
            .data([linePts])
            .exit()
            .transition()
            .ease(d3.easeCubic)
            .duration(duration)
            .attr('d', d => lineFunctionInitial(d.dataPoints))
            .remove();
    };

    updateDataPoints(duration) {
        if (!this.props.showPoints) {
            this.pointGroup.selectAll('.circle').remove();
            return;
        }

        this.pointGroup.selectAll('circle')
            .data(this.props.data)
            .enter()
            .append('circle')
            .attr('class', 'circle')
            .attr('id', function (d, i) {
                return i;
            })
            .attr('cx', d => this.xScale(this.xDomain[0]))
            .attr('cy', d => this.yScale(this.yDomain[0]))
            .attr('r', 5)
            .style('stroke-width', 1)
            .style('stroke', 'white')
            .style('fill', '#1e88e5')
            .style('stroke', 'white');

        this.pointGroup.selectAll('circle')
            .data(this.props.data)
            .transition()
            .ease(d3.easeCubic)
            .duration(duration)
            .attr('cx', d => this.xScale(d.x))
            .attr('cy', d => this.yScale(d.y))
            .attr('r', 5)
            .style('stroke-width', 1)
            .style('stroke', 'white');

        this.pointGroup.selectAll('.circle')
            .selectAll('circle')
            .data(this.props.data)
            .exit()
            .transition()
            .ease(d3.easeCubic)
            .duration(duration)
            .attr('cx', d => this.xScale(this.xDomain[0]))
            .attr('cy', d => this.yScale(this.yDomain[0]))
            .remove();
    };

    updateTooltips() {
        if (!this.props.showToolTips) {
            return;
        }
        let toolTip = this.tip;
        let xAxisName = this.props.xLabel;
        let yAxisName = this.props.yLabel;

        this.lineGroup.selectAll('.line')
            .on('mouseover', function (el) {
                d3.select(this).style('stroke-width', '4px');
            })
            .on('mouseout', function (el) {
                d3.select(this).style('stroke-width', '2px');
            });

        this.pointGroup.selectAll('circle')
            .on('mouseover', function (el) {
                d3.select(this).attr("r", "6px").style('stroke', 'black').style('fill', 'gold').style('stroke-width', '2px');
                toolTip.html(function (i) {
                    let tipText = "";
                    //let tipText = "<strong style='color:red;font-size:13px;'>Data Row: </strong><span style='color:white;font-size:13px;font-weight:400'>" + parentID + "</span> <br>";
                    tipText += "<strong style='color:red;font-size:13px;'>" + xAxisName + ": </strong> <span style='color:white;font-size:13px;font-weight:400'>" + Number.parseFloat(el.x).toFixed(3) + "</span> <br>";
                    tipText += "<strong style='color:red;font-size:13px;'>" + yAxisName + ": </strong> <span style='color:white;font-size:13px;font-weight:400'>" + Number.parseFloat(el.y).toFixed(3) + "</span> <br>";
                    return tipText;
                });
                toolTip.show(el, this);
            })
            .on('mouseout', function (el) {
                d3.select(this).attr("r", 5).style('stroke', 'white').style('fill', '#1e88e5').style('stroke-width', '1px');
                toolTip.hide(el, this);
            });
    };

    chartZoom() {
        //this.updateDataPoints(this.zoomDuration);
        this.updateLines(this.zoomDuration);
        this.xAxisGroup.transition().ease(d3.easeCubic).duration(this.zoomDuration).call(this.xAxis);
        this.yAxisGroup.transition().ease(d3.easeCubic).duration(this.zoomDuration).call(this.yAxis);
    };

    brushEnded = () => {
        let s = d3.event.selection;
        if (!s) {
            if (!this.idleTimeout) { return this.idleTimeout = setTimeout(function () { this.idled() }.bind(this), this.idleDelay) };
            this.updateDomainsAndScales();
            //this.updateScales();

        } else {
            this.xDomain = [s[0][0], s[1][0]].map(this.xScale.invert, this.xScale);
            this.yDomain = [s[1][1], s[0][1]].map(this.yScale.invert, this.yScale);
            this.xScale.domain(this.xDomain);
            this.yScale.domain(this.yDomain);
            this.clippingMask.select(".brush").call(this.brush.move, null);
        }
        this.xAxis.scale(this.xScale);
        this.yAxis.scale(this.yScale);
        this.chartZoom();
    };

    idled = () => {
        this.idleTimeout = null;
    };

    updateChartSize = () => {
        this.mainBody
            .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
            .attr("width", this.fullWidth);

        //this.chartTitle.call(() => this.wrapText());

        this.chart
            .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
            .attr("height", this.chartHeight)
            .attr("width", this.chartWidth)
            .attr("id", "graphBody");


        this.xAxisGroup.attr("transform", "translate(0," + this.chartHeight + ")");
        this.xAxisGroup.call(this.xAxis);

        this.yAxisGroup.call(this.yAxis);

        //Append a clipping mask to avoid data from falling off the chart
        this.clippingMask
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", this.chartWidth)
            .attr("height", this.chartHeight);

        //Initialize tooltip here

        //Append axis labels here
        this.xAxisLabel
            .attr("y", this.chartHeight + 35)
            .attr("x", this.chartWidth);

        this.yAxisLabel.attr("y", -this.chartMargins.left + 10)
            .attr("x", -1)
            .attr("transform", "rotate(-90)");

        this.chartLabel
            .attr("y", this.chartHeight + 55)
            .attr("x", (this.chartWidth / 2));

        this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded);
            //this.idleTimeout,
            this.idleDelay = 350;

        this.clippingMaskBrush.call(this.brush);
        this.lineGroup.moveToFront();
        this.pointGroup.moveToFront();
    };

    createChart = (el) => {
        //Create scales and initialize with domain 0-10
        this.updateDomainsAndScales();
        //this.updateScales();

        //Append a larger svg and create the graph region
        this.mainBody = d3.select(el).append("svg")
            .attr("class", "multiLineMultiAxisChart")
            .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight)
            .attr("width", this.fullWidth)
            .attr("id", this.props.chartID);

        this.chartTitle = this.mainBody.append("text")
            .attr("id", "chartTitle")
            .attr("class", "axisLabel")
            .attr("text-anchor", "start")
            .attr("y", 10)
            .attr("x", 0)
            .style("fill", "black")
            .style("font-size", "14px")
            .style("font-family", "Roboto,Sans-Serif")
            .style('font-weight', 500)
            .text(this.props.chartTitle);
        this.chartTitle.call(() => this.wrapTitle());

        this.chart = this.mainBody
            .append("g")
            .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
            .attr("height", this.chartHeight)
            .attr("width", this.chartWidth)
            .attr("id", "graphBody");

        //Create axes and append to chart.
        this.xAxis = d3.axisBottom(this.xScale);
        this.xAxisGroup = this.chart.append("g").attr("class", "axis xaxis").attr("transform", "translate(0," + this.chartHeight + ")");
        this.xAxisGroup.call(this.xAxis);

        this.yAxis = d3.axisLeft(this.yScale);
        this.yAxisGroup = this.chart.append("g").attr("class", "axis yaxis");
        this.yAxisGroup.call(this.yAxis);

        //Append a clipping mask to avoid data from falling off the chart
        this.clippingMask = this.chart
            .append("svg")
            .attr("id", "cmask")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", this.chartWidth)
            .attr("height", this.chartHeight)
            .style("fill", "transparent");

        //Initialize tooltip here

        //Append axis labels here
        this.xAxisLabel = this.chart.append("text")
            .attr("id", "xAxisLabel")
            .attr("class", "axisLabel")
            .attr("text-anchor", "end")
            .attr("y", this.chartHeight + 35)
            .attr("x", this.chartWidth)
            .style("fill", "black")
            .style("font-size", "14px")
            .style("font-family", "Roboto,Sans-Serif")
            .text(this.props.xLabel);

        this.yAxisLabel = this.chart.append("text")
            .attr("id", "yAxisLabel")
            .attr("class", "axisLabel")
            .attr("text-anchor", "end")
            .attr("y", -this.chartMargins.left + 10)
            .attr("x", -1)
            .attr("transform", "rotate(-90)")
            .style("fill", "black")
            .style("font-size", "14px")
            .style("font-family", "Roboto,Sans-Serif")
            .text(this.props.yLabel);

        this.chartLabel = this.chart.append("text")
            .attr("id", "chartTitle")
            .attr("class", "axisLabel")
            .attr("text-anchor", "middle")
            .attr("y", this.chartHeight + 55)
            .attr("x", (this.chartWidth / 2))
            .style("fill", "black")
            .style("font-size", "12px")
            .style("font-family", "Roboto,Sans-Serif")
            .text(this.props.legendText);
        this.chartLabel.call(() => this.wrapText());

        // console.log(this.props.y2Label.getComputedTextLength());

        this.lineGroup = this.clippingMask.append("g").attr("id", "yLines");
        this.pointGroup = this.clippingMask.append("g").attr("id", "yPoints");

        this.tip = d3Tip().attr('class', 'd3-tip').style('z-index', 10000).offset([-10, 0]);
        this.chart.call(this.tip);

        this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded);
            //this.idleTimeout,
            this.idleDelay = 350;

        this.clippingMaskBrush = this.clippingMask.append("g").attr("class", "brush");
        this.clippingMaskBrush.call(this.brush);
        this.lineGroup.moveToFront();
        this.pointGroup.moveToFront();
    };

    render() {
        return <div style={{ color: 'black' }}></div>
    }
}

d3.selection.prototype.moveToFront = function () {
    return this.each(function () {
        this.parentNode.appendChild(this);
    });
};

d3.selection.prototype.moveToBack = function () {
    return this.each(function () {
        var firstChild = this.parentNode.firstChild;
        if (firstChild) {
            this.parentNode.insertBefore(this, firstChild);
        }
    });
};

export default RegressionScatterChart
