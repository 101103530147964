import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles, createMuiTheme, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DataDistributionChart from "./DataDistributionChart";
import RegressionScatterChart from "./RegressionScatterChart";
import DistributionLinePlot from "./DistributionLinePlot";
import CorrelationHeatMapPlot from "./DataVizPlots/PlotlyHeatMap";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DescriptionIcon from "@material-ui/icons/Description";
import Switch from '@material-ui/core/Switch';
//Dev
import * as dataDistActions from "../actions/datadistributionActions";
import * as viewActions from "../actions/viewActions";
import * as utils from "./utils.js";


const MuiTooltipTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14 pt"
      }
    }
  }
});

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      // width: 1400,
      marginLeft: "auto",
      marginRight: "auto"
    },
    flexGrow: 1
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    flexWrap: "wrap"
    // underline: 'red',
  },
  paperBody: {
    marginTop: "auto"
  },
  input: {
    display: "none"
  },
  button: {
    margin: theme.spacing(1)
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

class DataDistribution extends Component {

  constructor(props) {
    super(props);
    this.state = { showHeatMap: false };
  }

  getDataDistColHeaders() {
    let array = this.props.datadistribution.distribution_source_data.regplots;
    return array !== undefined
      ? Object.keys(array[0]).filter((header) => header !== "datapoints").sort()
      : null;
  }

  getDataDistData({ dataDistColHeaders }) {
    let array = this.props.datadistribution.distribution_source_data.regplots;
    let selected_input = this.props.datadistribution.distribution_selected_input_columns
    let selected_output = this.props.datadistribution.distribution_selected_output_columns
    let selected_cols = selected_input.concat(selected_output)

    let results = [];
    for (let i = 0; i < selected_cols.length; i++) {
      for (let j = 0; j < selected_cols.length; j++) {
        let xCol = selected_cols[i]
        let yCol = selected_cols[j]
        array.forEach((item) => {
          if (item.xCol === xCol && item.yCol === yCol) {
            const row = [];
            dataDistColHeaders.forEach(function (key) {
              typeof (item[key]) === 'string'
                ? row.push(item[key])
                : row.push(Number(item[key]).toFixed(3));
            })
            results.push(row);
          }
        })
      }
    }
    return results
  }

  getHeatMapData() {
    let dataDistData = this.getDataDistData({ dataDistColHeaders: ['r2'] });
    dataDistData = dataDistData.map((el) => Number(el[0]))
    let colNames = []
    this.props.datadistribution.distribution_selected_input_columns.forEach((el) => colNames.push(el))
    this.props.datadistribution.distribution_selected_output_columns.forEach((el) => colNames.push(el))

    let data = []
    const nRow = this.props.datadistribution.distribution_selected_input_columns.length + this.props.datadistribution.distribution_selected_output_columns.length
    while (dataDistData.length) data.push(dataDistData.splice(0, nRow).reverse());
    return { data, colNames };
  }

  ExportResultsExcel = event => {
    let dataDistColHeaders = this.getDataDistColHeaders();
    let dataDistData = this.getDataDistData({ dataDistColHeaders });
    let use_constraints = null;
    this.props.dispatch(viewActions.changeLoadingState(true));
    this.props.dispatch(viewActions.updateLoadingMessage("Assembling Results for Export..."));
    let url = `exportbatchexcel`;
    this.props.axPost(url, JSON.stringify({ table_headers: dataDistColHeaders, table_data: dataDistData, constraints: use_constraints }), {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      responseType: "arraybuffer"
    })
      .then(res => {
        this.props.dispatch(viewActions.changeLoadingState(false));
        this.props.dispatch(viewActions.updateLoadingMessage(""));
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        let filename = "MixingStudio_ScatterPlots.xlsx";
        utils.SaveNonImageFile(blob, filename);
      });
  };

  componentDidUpdate() {
    let mot = this.refs.statData;
    if (mot !== undefined) {
      mot.hotInstance.render();
    }
  }

  componentDidMount() { }

  shouldComponentUpdate(newProps) {
    if (newProps.views.currentView !== "datadist") {
      return false;
    }
    return true;
  }

  inputColumnsChanged = col => {
    let currColumns = JSON.parse(JSON.stringify(this.props.datadistribution.distribution_selected_input_columns));
    if (currColumns.indexOf(col) !== -1) {
      currColumns.splice(currColumns.indexOf(col), 1);
    } else {
      currColumns.push(col);
    }
    this.props.dispatch(dataDistActions.UpdateSelectedInputColumns(currColumns));
  };
  outputColumnsChanged = col => {
    let currColumns = JSON.parse(JSON.stringify(this.props.datadistribution.distribution_selected_output_columns));
    if (currColumns.indexOf(col) !== -1) {
      currColumns.splice(currColumns.indexOf(col), 1);
    } else {
      currColumns.push(col);
    }
    this.props.dispatch(dataDistActions.UpdateSelectedOutputColumns(currColumns));
  };

  gridClicked = (col1, col2) => {
    this.props.dispatch(dataDistActions.UpdateDistributionSelectedRowData({}));
    if (col1 === col2) {
      let selectedDensityObj = this.props.datadistribution.distribution_source_data.densityplots.filter(el => {
        return el.col === col1;
      })[0];
      this.props.dispatch(dataDistActions.UpdateDistributionDensitySelection(col1, selectedDensityObj));
    } else {
      let selectedRegressionObj = this.props.datadistribution.distribution_source_data.regplots.filter(el => {
        return el.xCol === col1 && el.yCol === col2;
      })[0];
      this.props.dispatch(dataDistActions.UpdateDistributionRegressionSelection(col1, col2, selectedRegressionObj));
    }
  };

  pointClicked = dataRow => {
    let clickedRowData = this.props.modeldata.plottable_data.filter(el => {
      return el._dataRow === dataRow;
    })[0];
    this.props.dispatch(dataDistActions.UpdateDistributionSelectedRowData(clickedRowData));
  };

  allColumnsOn = () => {
    let inputCols = this.props.modeldata.data_columns
      .filter(function (el) {
        return el.type === "input" && el.categorical === false;
      })
      .map(col => {
        return col.col;
      });
    let outputCols = this.props.modeldata.data_columns
      .filter(function (el) {
        return el.type === "output" && el.categorical === false;
      })
      .map(col => {
        return col.col;
      });
    this.props.dispatch(dataDistActions.UpdateSelectedInputColumns(inputCols));
    this.props.dispatch(dataDistActions.UpdateSelectedOutputColumns(outputCols));
  };

  allColumnsOff = () => {
    this.props.dispatch(dataDistActions.UpdateSelectedInputColumns([]));
    this.props.dispatch(dataDistActions.UpdateSelectedOutputColumns([]));
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.layout}>
        <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
            Pairwise Relations
            </Typography>

          <Typography variant='subtitle1' gutterBottom style={{ textAlign: "left", marginLeft: 24, marginBottom: 15 }}>
            Scatter plots of input({<span style={{ color: "#00c853" }}>▲</span>}) and output({<span style={{ color: "#f50057" }}>▲</span>}) data.
            </Typography>
          <div style={{ margin: 20 }}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={3} style={{ paddingBottom: 24 }}>
                <Typography variant="button" style={{ marginTop: 8, textAlign: "left", marginBottom: 16 }}>
                  Plot type:
                  </Typography>
                <div>
                  <Grid component="label" container justify="center" alignItems="center" spacing={1}>
                    <Grid item>Scatter Plot</Grid>
                    <Grid item>
                      <Switch
                        checked={this.state.showHeatMap}
                        onChange={() => this.setState(state => ({ ...state, showHeatMap: !state.showHeatMap }))}
                        name="checkedB"
                        color="primary"
                      />
                    </Grid>
                    <Grid item>R2 Heat Map</Grid>
                  </Grid>
                </div>
                <Typography variant="button" style={{ marginTop: 24, textAlign: "left", marginBottom: 16, padding: 10 }}>
                  Output Columns to Display
                  </Typography>
                <FormGroup style={{ textAlign: "left" }}>
                  {this.props.modeldata.data_columns
                    .filter(function (el) {
                      return el.type === "output" && el.categorical === false;
                    })
                    .map((col, index) => (
                      <MuiThemeProvider theme={MuiTooltipTheme} key={index}>
                        <Tooltip title={col.col} placement="top">
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={this.props.datadistribution.distribution_selected_output_columns.indexOf(col.col) !== -1}
                                onChange={() => this.outputColumnsChanged(col.col)}
                                value={col.col}
                                color="primary"
                                disableRipple
                                style={{ height: 24, paddingTop: 0, paddingBottom: 0 }}
                              />
                            }
                            label={col.col}
                          />
                        </Tooltip>
                      </MuiThemeProvider>
                    ))}
                </FormGroup>
                <Typography variant="button" style={{ marginTop: 24, textAlign: "left", marginBottom: 16, padding: 10 }}>
                  Input Columns to Display
                  </Typography>
                <FormGroup style={{ textAlign: "left" }}>
                  {this.props.modeldata.data_columns
                    .filter(function (el) {
                      return el.type === "input" && el.categorical === false;
                    })
                    .map((col, index) => (
                      <MuiThemeProvider theme={MuiTooltipTheme} key={index}>
                        <Tooltip title={col.col} placement="top">
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={this.props.datadistribution.distribution_selected_input_columns.indexOf(col.col) !== -1}
                                onChange={() => this.inputColumnsChanged(col.col)}
                                value={col.col}
                                color="primary"
                                disableRipple
                                style={{ height: 24, paddingTop: 0, paddingBottom: 0 }}
                              />
                            }
                            label={col.col}
                          />
                        </Tooltip>
                      </MuiThemeProvider>
                    ))}
                </FormGroup>
                <FormGroup>
                  <Button
                    variant="contained"
                    color="inherit"
                    component="span"
                    className={classes.button}
                    onClick={this.allColumnsOn}
                    style={{ background: "white", float: "left", marginRight: 0, marginLeft: 0, marginTop: 24 }}
                  >
                    All Columns On
                    </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    component="span"
                    className={classes.button}
                    onClick={this.allColumnsOff}
                    style={{ background: "white", float: "left", marginRight: 0, marginLeft: 0, marginTop: 12 }}
                  >
                    All Columns Off
                    </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.margin}
                    onClick={this.ExportResultsExcel}
                    // disabled={this.props.prediction.selected_existing_table_rows.length===0}
                    style={{ background: "linear-gradient(45deg, #00c853 30%, #00e676 90%)", float: "right", marginTop: 6, marginBottom: 12 }}
                  >
                    <DescriptionIcon style={{ marginRight: 12 }} />
                      Export Results to Excel
                    </Button>
                </FormGroup>
                {/* <DistributionChartSpeedDial TODO
                        style={{ marginBottom: 0, marginTop:40 }}
                        canvasid={"myCanvas"}
                        chartTitle={"DataDistributionChartPNG"}
                        chartType="datadist"
                        marginBottom={0}
                        direction="up"
                        //height={40}
                        You can export two layers (labels and data that can be stitched together...)
                      /> */}
              </Grid>
              {!this.state.showHeatMap ? this.props.datadistribution.distribution_selected_input_columns.length +
                this.props.datadistribution.distribution_selected_output_columns.length >
                1 && (
                  <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingBottom: 24 }} id="dataDistributionChart">
                    <DataDistributionChart
                      columns={this.props.datadistribution.distribution_selected_output_columns.concat(
                        this.props.datadistribution.distribution_selected_input_columns
                      )}
                      inputColumns={this.props.datadistribution.distribution_selected_input_columns}
                      outputColumns={this.props.datadistribution.distribution_selected_output_columns}
                      columnRanges={this.props.modeldata.data_ranges.concat(this.props.modeldata.output_data_ranges)}
                      showPoints={true}
                      showToolTips={true}
                      extraLabels={[]}
                      appendDiv={"dataDistributionChart"}
                      chartID={"dataDistribution_ChartSVG"}
                      chartTitle={" Scatter Plots"}
                      chartType={"datadist"}
                      currentView={this.props.views.currentView}
                      heightToWidthRatio={1}
                      scaleAllAxes={false}
                      sourceScatterData={this.props.datadistribution.distribution_source_data.regplots}
                      sourceDensityData={this.props.datadistribution.distribution_source_data.densityplots}
                      //legendText={"This is a review of all the data within " + this.props.modeldata.uploaded_file_name}
                      gridClick={this.gridClicked}
                    />
                  </Grid>
                ) :
                <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingBottom: 24 }} id="dataDistributionHeatMap">
                  <CorrelationHeatMapPlot data={this.getHeatMapData()} />
                </Grid>
              }
              {this.props.datadistribution.distribution_regression_selected &&
                this.props.datadistribution.distribution_selected_input_columns.length +
                this.props.datadistribution.distribution_selected_output_columns.length >
                1 && (
                  <Grid item xs={12} sm={12} md={12} lg={3} style={{ paddingBottom: 24 }} id="selectedRegressionChart">
                    <MuiThemeProvider theme={MuiTooltipTheme}>
                      <Tooltip title={this.props.datadistribution.distribution_selected_regression_column1} placement="top">
                        <Typography variant="button" style={{ textAlign: "center", marginBottom: 16 }}>
                          {this.props.datadistribution.distribution_selected_regression_column1.substring(0, 11)}  vs.{" "}
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    <MuiThemeProvider theme={MuiTooltipTheme}>
                      <Tooltip title={this.props.datadistribution.distribution_selected_regression_column2} placement="top">
                        <Typography variant="button" style={{ textAlign: "center", marginBottom: 16 }}>
                          {this.props.datadistribution.distribution_selected_regression_column2.substring(0, 11)}
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    <RegressionScatterChart
                      data={this.props.datadistribution.distribution_selected_regression_object.datapoints}
                      xLabel={this.props.datadistribution.distribution_selected_regression_column1}
                      yLabel={this.props.datadistribution.distribution_selected_regression_column2}
                      xScaleType={"linear"}
                      yScaleType={"linear"}
                      showPoints={true}
                      showToolTips={true}
                      extraLabels={[]}
                      appendDiv={"selectedRegressionChart"}
                      chartID={"selectedRegressionChart_ChartSVG"}
                      chartTitle={""}
                      chartType={"datadist"}
                      currentView={this.props.views.currentView}
                      heightToWidthRatio={0.8}
                      scaleAllAxes={false}
                      xAxisMin={0}
                      xAxisMax={0}
                      yAxisMin={0}
                      yAxisMax={0}
                      legendText={""}
                      slope={this.props.datadistribution.distribution_selected_regression_object.slope}
                      intercept={this.props.datadistribution.distribution_selected_regression_object.yIntercept}
                      pointClicked={this.pointClicked}
                    />
                    {Object.keys(this.props.datadistribution.distribution_selected_row_data)
                      .filter(el => {
                        return el !== "_dataRow";
                      })
                      .map(key => (
                        <Typography
                          variant="body2"
                          gutterBottom
                          key={key}
                          style={{ textAlign: "left", marginBottom: 0, marginLeft: 60, marginRight: 20, fontSize: "small" }}
                        >
                          {key}:
                          <span style={{ fontWeight: 500, float: "right", fontSize: "small" }}>
                            {this.props.datadistribution.distribution_selected_row_data[key]}
                          </span>
                        </Typography>
                      ))}
                  </Grid>
                )}

              {this.props.datadistribution.distribution_density_selected &&
                this.props.datadistribution.distribution_selected_input_columns.length +
                this.props.datadistribution.distribution_selected_output_columns.length >
                1 && (
                  <Grid item xs={12} sm={12} md={12} lg={3} style={{ paddingBottom: 24 }} id="selectedDensityChart">
                    <MuiThemeProvider theme={MuiTooltipTheme}>
                      <Tooltip title={this.props.datadistribution.distribution_selected_density_column} placement="top">
                        <Typography variant="button" style={{ textAlign: "center", marginBottom: 16 }}>
                          Distribution of Values for {this.props.datadistribution.distribution_selected_density_column.substring(0, 13)}
                        </Typography>
                      </Tooltip>
                    </MuiThemeProvider>
                    <DistributionLinePlot
                      data={this.props.datadistribution.distribution_selected_density_object.data}
                      xLabel={this.props.datadistribution.distribution_selected_density_column}
                      yLabel={"Proportion"}
                      xScaleType={"linear"}
                      yScaleType={"linear"}
                      showPoints={true}
                      showToolTips={true}
                      extraLabels={[]}
                      appendDiv={"selectedDensityChart"}
                      chartID={"selectedDensityChart_ChartSVG"}
                      chartTitle={""}
                      chartType={"datadist"}
                      currentView={this.props.views.currentView}
                      heightToWidthRatio={0.8}
                      scaleAllAxes={false}
                      xAxisMin={0}
                      xAxisMax={0}
                      yAxisMin={0}
                      yAxisMax={0}
                      legendText={""}
                    />

                  </Grid>
                )}
            </Grid>
          </div>
        </Card>
        {/* Table showing the stat info for data, coming from AIchemy */}
        {/* <Card className={classes.paperBody} elevation={3} style={{ marginBottom: 24 }}>
            <div style={{ margin: 24 }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}>
                Statistical Summary
            </Typography>
              <HotTable
                data={dataDistData}
                colHeaders={dataDistColHeaders}
                rowHeaders={true}
                columnSorting={true}
                height="500"
                stretchH="all"
                minRows="20"
                settings={{ outsideClickDeselects: false }}
                root="hot"
                ref="statData"
                beforeChange={this.BeforeChange}
                afterColumnSort={this.AfterColumnSort}
                style={{ fontSize: "smaller", fontFamily: "Roboto" }}
              />

            </div>
          </Card>*/}
      </div>
    );
  }
}

DataDistribution.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect((state, props) => {
  return {
    views: state.views,
    modeldata: state.modeldata,
    datadistribution: state.datadistribution
  };
})(withStyles(styles)(DataDistribution));
