import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';

import rootReducer from '../reducers/index';

let logger = createLogger({
    collapsed: true
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;   // Add chrome redux-devtools extension support
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
   applyMiddleware(logger)
));

export default store;