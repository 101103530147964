let defaultState = {
  save_model_name: '',
  save_model_description: '',
  save_model_column_annotations: [],
  save_model_attached_files: []
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_SAVE_MODEL_NAME":
      return {
        ...state,
        save_model_name: action.data.save_model_name
      };
    case "UPDATE_SAVE_MODEL_DESCRIPTION":
      return {
        ...state,
        save_model_description: action.data.save_model_description
      };
    case "UPDATE_SAVE_MODEL_ANNOTATIONS":
      return {
        ...state,
        save_model_column_annotations: action.data.save_model_column_annotations
      };
    case "UPDATE_SAVE_MODEL_ATTACHED_FILES":
      return {
        ...state,
        save_model_attached_files: action.data.save_model_attached_files
      };
    case "RESET_ALL":
      return {
        ...state,
        save_model_name: '',
        save_model_description: '',
        save_model_column_annotations: [],
        save_model_attached_files: []
      };
    default:
      return state;
  }
}

export default views;
