import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import _ from "lodash";
import "./MultiLineChart.css";

class ContourPlot extends Component {
  constructor(props) {
    super(props);

    this.fullWidth = 0;
    this.chartWidth = 0;
    this.chartHeight = 0;
    this.titleHeight = 15;
    this.chartMargins = null;

    this.yScale = null;
    this.yAxis = null;
    this.yAxisGroup = null;
    this.yDomain = [1, 10];

    this.xScale = null;
    this.xAxis = null;
    this.xAxisGroup = null;
    this.xDomain = [1, 10];

    this.zDomain = [1, 10];

    this.xAxisLabel = null;
    this.yAxisLabel = null;
    this.chartTitle = null;
    this.chartLabel = null;

    this.lineGroup = null;
    this.pointGroup = null;
    this.contourGroup = null;

    this.tip = null;

    this.brush = null;
    this.clippingMaskBrush = null;
    this.idleDelay = 350;

    this.animationDuration = 1500;
    this.zoomDuration = 500;

    this.chart = null;
    this.clippingMask = null;

    this.legendRegion = null;
    this.legendHeight = 0;
    this.legendWidth = 0;
    this.legendMargins = {};

    // this.colorScheme = d3.scaleOrdinal(["#01838f",
    //     "#f82001",
    //     "#0077ff",
    //     "#00cb2e",
    //     "#812ede",
    //     "#00ca75",
    //     "#b600cc",
    //     "#367000",
    //     "#000492",
    //     "#9f8100",
    //     "#ef88ff",
    //     "#b45100",
    //     "#01cff0",
    //     "#ff2385",
    //     "#8abeff",
    //     "#b0003e",
    //     "#01568f",
    //     "#a20069",
    //     "#44181e",
    //     "#3a154a"]); //Generated at http://tools.medialab.sciences-po.fr/iwanthue/

    this.colorScheme = d3.scaleOrdinal(d3.schemeCategory10);

    this.resize = this.resize.bind(this);
    this.debouncedResize = _.debounce(this.resize, 250);
    this.current_props = null;
    //this.resizeFlag = true;
  }

  axesAreValid() {
    if (
      !isNaN(this.props.xAxisMin) &&
      this.props.xAxisMin !== "" &&
      !isNaN(this.props.xAxisMax) &&
      this.props.xAxisMax !== "" &&
      !isNaN(this.props.yAxisMin) &&
      this.props.yAxisMin !== "" &&
      !isNaN(this.props.yAxisMax) &&
      this.props.yAxisMax !== ""
    ) {
      return this.props.xAxisMin < this.props.xAxisMax && this.props.yAxisMin < this.props.yAxisMax;
    }
    return false;
  }

  resize() {
    if (document.getElementById(this.props.appendDiv).clientWidth === 0 || this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();
    this.updateAxes(0);
    this.updateAxisLabels();
    this.updateContours(this.animationDuration);
    //this.updateLines(0);
    //this.updateDataPoints(0);
    this.current_props = this.props;
  }

  componentDidMount() {
    this.current_props = this.props;
    const el = ReactDOM.findDOMNode(this);
    this.updateDimensions();
    this.createChart(el);
    this.updateAxisLabels();
    window.addEventListener("resize", this.debouncedResize);
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();
    this.updateAxes(this.animationDuration);
    this.updateContours(this.animationDuration);
    this.updateAxisLabels();
    this.animationDuration = 1500;
    // this.updateLines(this.animationDuration);
    // this.updateDataPoints(this.animationDuration);
    // this.updateTooltips();
  }

  shouldComponentUpdate(newProps) {
    if (document.getElementById(this.props.appendDiv).clientWidth !== this.fullWidth && newProps.currentView === this.current_props.currentView) {
      this.animationDuration = 0;
      return true;
    }
    if (_.isEqual(newProps, this.current_props)) {
      return false;
    }
    if (newProps.currentView !== this.current_props.currentView) {
      //if this is not true it suggest tab change which doesnt need an update
      return false;
    }
    this.current_props = newProps;
    return true;
  }

  componentDidUpdate() {
    if (this.props.currentView !== this.props.chartType) {
      return;
    }
    this.updateDimensions();
    this.updateChartSize();
    this.updateDomains();
    this.updateScales();
    this.updateAxes(this.animationDuration);
    this.updateContours(this.animationDuration);
    this.updateAxisLabels();
    this.animationDuration = 1500;
  }

  componentWillUnmount() {
    //this.debouncedResize.cancel();
    window.removeEventListener("resize", this.debouncedResize);
  }

  wrapText() {
    let words = this.chartLabel
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      x = this.chartLabel.attr("x"),
      y = this.chartLabel.attr("y"),
      dy = 0, //parseFloat(text.attr("dy")),
      tspan = this.chartLabel
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
    let maxHeight = 0;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > this.chartWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = this.chartLabel
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
        maxHeight = d3.max([maxHeight, parseFloat(y) + parseFloat(lineNumber * 24)]);
      }
    }
    maxHeight += this.titleHeight;
    if (maxHeight > this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight + this.legendHeight) {
      this.mainBody.attr("height", maxHeight);
    }
  }

  wrapTitle() {
    let words = this.chartTitle
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      x = this.chartTitle.attr("x"),
      y = this.chartTitle.attr("y"),
      dy = 0, //parseFloat(text.attr("dy")),
      tspan = this.chartTitle
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
    let maxHeight = 0;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > this.chartWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = this.chartTitle
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
        maxHeight = d3.max([maxHeight, parseFloat(y) + parseFloat(lineNumber * 24)]);
      }
    }
    this.titleHeight = maxHeight + 15;

    // if (maxHeight > (this.chartHeight + this.chartMargins.top + this.chartMargins.bottom)) {
    //     this.mainBody.attr('height', maxHeight);
    // }
  }

  updateDimensions() {
    //Figure out how much space you need for your longest label...
    let currentWidth = document.getElementById(this.props.appendDiv).clientWidth;
    this.chartMargins = { left: currentWidth * 0.05, right: currentWidth * 0.05, top: 0, bottom: currentWidth * 0.07 };
    this.legendMargins = { left: currentWidth * 0.05, right: currentWidth * 0.05, top: 0, bottom: currentWidth * 0.07 };

    this.fullWidth = currentWidth;
    this.chartWidth = currentWidth * 0.8;
    this.chartHeight = currentWidth * this.props.heightToWidthRatio;
    this.legendHeight = this.chartHeight * 0.2;
    this.legendWidth = this.chartWidth;

    if (this.chartMargins.left < 54) {
      let tmpChartWidth = currentWidth - 74;
      let tmpChartHeight = currentWidth * this.props.heightToWidthRatio - 74;
      if (tmpChartHeight > 0 && tmpChartWidth > 0) {
        this.chartMargins = { left: 44, right: 30, top: 10, bottom: 64 };
        this.legendMargins = { left: 44, right: 30, top: 10, bottom: 64 };
        this.chartWidth = tmpChartWidth;
        this.chartHeight = tmpChartHeight;
        this.legendHeight = this.chartHeight * 0.2;
        this.legendWidth = this.chartWidth;
      }
    }
  }

  updateAxisLabels() {
    let maxHeights = [];
    this.xAxisLabel.text(this.props.xLabel);
    this.yAxisLabel.text(this.props.yLabel).each(function(d, j) {
      let thisWidth = this.getComputedTextLength();
      maxHeights.push(thisWidth);
    });
    this.chartTitle.text(this.props.chartTitle);
    this.chartTitle.call(() => this.wrapTitle());
    // this.chartLabel.text(this.props.legendText);
    // this.chartLabel.call(() => this.wrapText());
    var maxHeight = d3.max(maxHeights);
    if (maxHeight > this.chartHeight) {
      this.mainBody.attr("height", maxHeight + this.chartMargins.top + this.chartMargins.bottom + this.legendHeight);
    }
  }

  updateDomains() {
    this.xDomain = [1, 10];
    this.yDomain = [1, 10];
    this.zDomain = [1, 10];
    let xMin = Infinity;
    let xMax = -Infinity;
    if (this.props.scaleAllAxes && this.axesAreValid()) {
      let currXRange = Number(this.props.xAxisMax) - Number(this.props.xAxisMin);
      let currYRange = Number(this.props.yAxisMax) - Number(this.props.yAxisMin);
      this.xDomain =
        this.props.xScaleType === "linear"
          ? [Number(this.props.xAxisMin) - currXRange * 0.03, Number(this.props.xAxisMax) + currXRange * 0.03]
          : [Number(this.props.xAxisMin) / 1.3, Number(this.props.xAxisMax) * 1.3];
      this.yDomain =
        this.props.yScaleType === "linear"
          ? [Number(this.props.yAxisMin) - currYRange * 0.03, Number(this.props.yAxisMax) + currYRange * 0.03]
          : [Number(this.props.yAxisMin) / 1.3, Number(this.props.yAxisMax) * 1.3];
      return;
    }
    if (this.props.data.length > 0) {
      let yMin = d3.min(this.props.data, function(d) {
        return d.y;
      });
      let yMax = d3.max(this.props.data, function(d) {
        return d.y;
      });
      xMin = d3.min(this.props.data, function(d) {
        return d.x;
      });
      xMax = d3.max(this.props.data, function(d) {
        return d.x;
      });
      let zMin = d3.min(this.props.data, function(d) {
        return +d.z;
      });
      let zMax = d3.max(this.props.data, function(d) {
        return +d.z;
      });
      // let yRange = yMax - yMin;
      // if (this.props.yScaleType === "linear") {
      //   yMin -= yRange * 0.1;
      //   yMax += yRange * 0.1;
      // } else {
      //   yMin /= 1.3;
      //   yMax *= 1.3;
      // }
      // let zRange = zMax - zMin;
      // if (this.props.xScaleType === "linear") {
      //   zMin -= zRange * 0.04;
      //   zMax += zRange * 0.04;
      // } else {
      //   zMin /= 1.05;
      //   zMax *= 1.05;
      // }
      this.yDomain = [yMin, yMax];
      this.xDomain = [xMin, xMax];
      this.zDomain = [zMin, zMax];
    }
  }

  updateScales() {
    this.xScale =
      this.props.xScaleType === "linear"
        ? d3
            .scaleLinear()
            .range([0, this.chartWidth])
            .domain(this.xDomain)
        : d3
            .scaleLog()
            .base(10)
            .range([0, this.chartWidth])
            .domain(this.xDomain);
    this.yScale =
      this.props.yScaleType === "linear"
        ? d3
            .scaleLinear()
            .range([this.chartHeight, 0])
            .domain(this.yDomain)
        : d3
            .scaleLog()
            .base(10)
            .range([this.chartHeight, 0])
            .domain(this.yDomain);
  }

  updateAxes(duration) {
    this.xAxis.scale(this.xScale);
    this.xAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .call(this.xAxis);
    this.yAxis.scale(this.yScale);
    this.yAxisGroup
      .transition()
      .ease(d3.easeCubic)
      .duration(duration)
      .call(this.yAxis);
  }

  updateContours(duration) {
    var chartWidth = this.chartWidth;
    var chartHeight = this.chartHeight;
    var dataMin = this.props.dataMin;
    var dataMax = this.props.dataMax;
    var data = this.props.data;
    var halfPt = this.props.dataThreshold;
    var realMin = d3.min([this.zDomain[0], dataMin, halfPt]);
    var realMax = d3.max([this.zDomain[1], halfPt, dataMax]);
    var steps = (realMax - realMin) / 100;
    var thresholds = _.range(realMin, realMax, steps);
    if (halfPt !== realMin && halfPt !== realMax) {
      thresholds.push(halfPt);
    }
    thresholds = thresholds.sort();

    var values = data.map(el => {
      return +el.z;
    });
    var color = d3
      .scaleLinear()
      .domain(d3.extent(thresholds))
      .interpolate(d => d3.interpolateCool);

    var grid = values;
    grid.x = 0;
    grid.y = 0;
    grid.k = 0;
    grid.n = Math.sqrt(data.length);
    grid.m = Math.sqrt(data.length);

    let contours = d3
      .contours()
      .size([Math.sqrt(values.length), Math.sqrt(values.length)])
      .smooth(true)
      .thresholds(thresholds)(values);

    this.contourGroup.selectAll("path").remove();
    this.contourGroup.selectAll(".line").remove();
    this.contourGroup.selectAll(".line2").remove();

    this.contourGroup
      .selectAll(".line")
      .data(contours)
      .enter()
      .append("path")
      .attr("class", "line")
      .attr("d", d3.geoPath(d3.geoIdentity().reflectY(true)))
      .style(
        "transform",
        "translate(0px, " +
          chartHeight +
          "px) scaleY(" +
          chartHeight / Math.sqrt(values.length) +
          ") scaleX(" +
          chartWidth / Math.sqrt(values.length) +
          ")"
      )
      .attr("fill", d => color(d.value));

    let filteredContours = null;
    let drawBoundary = false;
    if (this.props.thresholdDirection === ">") {
      let currMax = Math.max(...values) + 1;
      filteredContours = d3
        .contours()
        .size([Math.sqrt(values.length), Math.sqrt(values.length)])
        .smooth(true)
        .thresholds([halfPt, currMax])(values);
      if (
        filteredContours.filter(el => {
          return el.value === halfPt;
        })[0].coordinates.length > 0
      ) {
        drawBoundary = true;
      }
    } else {
      let neg_values = values.map(x => {
        return -x;
      });
      let currMin = Math.min(...values) - 1;
      filteredContours = d3
        .contours()
        .size([Math.sqrt(values.length), Math.sqrt(values.length)])
        .smooth(true)
        .thresholds([-halfPt, -currMin])(neg_values);
      if (
        filteredContours.filter(el => {
          return el.value === -halfPt;
        })[0].coordinates.length > 0
      ) {
        drawBoundary = true;
      }
    }

    // Added this line to prevent small circles from popping up on the maps
    let filteredContours_update = [];
    filteredContours.forEach(el => {
      el.coordinates = el.coordinates.filter(el => {
        return el[0].length > 5;
      });
      filteredContours_update.push(el);
    });
    filteredContours = filteredContours_update;

    if (drawBoundary) {
      if (this.props.thresholdDirection === ">") {
        // if (filteredContours.length > 1) {
        //   if (filteredContours[1].coordinates.length > 0) {
        //     let checkList = filteredContours[1].coordinates[0][0].map(x => JSON.stringify(x));
        //     let holdCoordinates = filteredContours[0].coordinates[0][0]
        //       .filter(el => {
        //         return checkList.indexOf(JSON.stringify(el)) === -1;
        //       })
        //       .map(x => JSON.stringify(x));
        //     filteredContours[1].coordinates[0][0] = filteredContours[0].coordinates[0][0].filter(el => {
        //       return holdCoordinates.indexOf(JSON.stringify(el)) === -1;
        //     });
        //     filteredContours = [filteredContours[1]];
        //   }
        // }
      } else {
        // if (filteredContours.length > 1) {
        //   // console.log(filteredContours);
        //   if (filteredContours[1].coordinates.length > 0) {
        //     let checkList = {};
        //     for (var i =0; i < filteredContours[1].coordinates.length; i++)
        //     {
        //       let currPart = filteredContours[1].coordinates[i][0].map(x => JSON.stringify(x));
        //       currPart.forEach(el => {
        //         if (checkList[el]===undefined)
        //         {
        //           checkList[el] = 0;
        //         }
        //         checkList[el]+=1;
        //       })
        //     }
        //     for (var i =0; i < filteredContours[0].coordinates.length; i++)
        //     {
        //       let currPart = filteredContours[0].coordinates[i][0].map(x => JSON.stringify(x));
        //       currPart.forEach(el => {
        //         if (checkList[el]===undefined)
        //         {
        //           checkList[el] = 0;
        //         }
        //         checkList[el]+=1;
        //       })
        //     }
        //     for (var i =0; i < filteredContours[0].coordinates.length; i++)
        //     {
        //       filteredContours[0].coordinates[i][0] = filteredContours[0].coordinates[i][0].filter(el => {
        //         return checkList[(JSON.stringify(el))] === 1;
        //       });
        //     }
        //     for (var i =0; i < filteredContours[1].coordinates.length; i++)
        //     {
        //       filteredContours[1].coordinates[i][0] = filteredContours[1].coordinates[i][0].filter(el => {
        //         return checkList[(JSON.stringify(el))] === 1;
        //       });
        //       filteredContours[0].coordinates[0][0] = filteredContours[0].coordinates[0][0].concat(filteredContours[1].coordinates[i][0].reverse());
        //     }
        //     //let checkList = filteredContours[1].coordinates[0][0].map(x => JSON.stringify(x));
        //     // filteredContours[0].coordinates[0][0] = filteredContours[0].coordinates[0][0].filter(el => {
        //     //   return checkList.indexOf(JSON.stringify(el)) === -1;
        //     // });
        //     filteredContours[0].coordinates[0][0] = this.sortPoints(filteredContours[0].coordinates[0][0])
        //     filteredContours = [filteredContours[0]];
        //   }
        // }
      }
      // if (filteredContours.length > 0) {
      //   if (filteredContours[0].coordinates[0].length > 0) {
          this.contourGroup
            .selectAll(".line2")
            .data(filteredContours)
            .enter()
            .append("path")
            .attr("class", "line2")
            .attr("d", d3.geoPath(d3.geoIdentity().reflectY(true)))
            .style(
              "transform",
              "translate(0px, " +
                chartHeight +
                "px) scaleY(" +
                chartHeight / Math.sqrt(values.length) +
                ") scaleX(" +
                chartWidth / Math.sqrt(values.length) +
                ")"
            )
            .style("fill", "none")
            .style("stroke", "black")
            .style("stroke-width", ".3px")
            .style("shape-rendering", "geometricPrecision")
            .moveToFront();
      //   }
      // }
    }

    this.xAxisGroup.moveToFront();
    this.yAxisGroup.moveToFront();

    let legendBoxWidth = this.chartWidth / 1.5;
    let legendScale = d3.scaleSequential(d3.interpolateCool).domain([0, legendBoxWidth]);
    this.legendRegion.selectAll(".legend").remove();
    this.legendRegion
      .selectAll(".legend")
      .data(d3.range(legendBoxWidth), function(d) {
        return d;
      })
      .enter()
      .append("rect")
      .attr("class", "legend")
      .attr("width", 1)
      .attr("height", this.legendHeight / 3)
      .attr("x", function(d, i) {
        return i + 1;
      })
      .attr("y", 6)
      .attr("shape-rendering", "crispEdges")
      .style("fill", function(d, i) {
        return legendScale(d);
      });

    //add a couple of text labels...
    //var thresholds = _.range(Math.min(this.zDomain[0], dataMin), Math.max(dataMax, this.zDomain[1]), steps);
    this.legendRegion.selectAll(".legendLabel").remove();
    let minDisplay = Math.round(realMin);
    let maxDisplay = Math.round(realMax);
    this.legendRegion
      .append("text")
      .attr("class", "legendLabel")
      .attr("x", 1)
      .attr("y", this.legendHeight / 3 + 17)
      .style("font-size", "10px")
      .style("fill", "black")
      .text(minDisplay);

    this.legendRegion
      .append("text")
      .attr("class", "legendLabel")
      .attr("x", legendBoxWidth + 1)
      .attr("y", this.legendHeight / 3 + 17)
      .style("font-size", "10px")
      .style("fill", "black")
      .style("text-anchor", "end")
      .text(maxDisplay);

    //lay a rectangle on top of this where you are bound...
    let percent = (this.props.dataThreshold - minDisplay) / (maxDisplay - minDisplay);
    let xStart = percent * legendBoxWidth;

    if (this.props.thresholdDirection === ">") {
      this.legendRegion
        .append("rect")
        .attr("class", "legend")
        .attr("width", legendBoxWidth - xStart)
        .attr("height", this.legendHeight / 3)
        .attr("x", xStart + 1)
        .attr("y", 6)
        .attr("shape-rendering", "crispEdges")
        .style("fill", "transparent")
        .style("stroke", "black")
        .style("stroke-width", "2px");
    } else {
      this.legendRegion
        .append("rect")
        .attr("class", "legend")
        .attr("width", xStart)
        .attr("height", this.legendHeight / 3)
        .attr("x", 1)
        .attr("y", 6)
        .attr("shape-rendering", "crispEdges")
        .style("fill", "transparent")
        .style("stroke", "black")
        .style("stroke-width", "2px");
    }
  }

  //Helper function from stackexchange
  sortPoints(points) {
    let avg = [0, 0];
    points.forEach(el => {
      avg[0] += el[0];
      avg[1] += el[1];
    });
    avg[0] /= points.length;
    avg[1] /= points.length;
    points.sort((a, b) => this.getAngle(avg, a, b));
    return points;
  }
  // https://cs.stackexchange.com/questions/52606/sort-a-list-of-points-to-form-a-non-self-intersecting-polygon
  eucDist(a, b) {
    return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
  }
  angleCompare(p0, a, b) {
    var left = this.isLeft(p0, a, b);
    if (left === 0) return this.distCompare(p0, a, b);
    return left;
  }

  getAngle(p0, a, b) {
    return Math.atan2(a[1] - p0[1], a[0] - p0[0]) - Math.atan2(b[1] - p0[1], b[0] - p0[0]);
  }

  isLeft(p0, a, b) {
    return (a[0] - p0[0]) * (b[1] - p0[1]) - (b[0] - p0[0]) * (a[1] - p0[1]);
  }

  distCompare(p0, a, b) {
    var distA = (p0[0] - a[0]) * (p0[0] - a[0]) + (p0[1] - a[1]) * (p0[1] - a[1]);
    var distB = (p0[0] - b[0]) * (p0[0] - b[0]) + (p0[1] - b[1]) * (p0[1] - b[1]);
    return distA - distB;
  }

  updateChartSize = () => {
    this.mainBody
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight + this.legendHeight)
      .attr("width", this.fullWidth);

    //this.chartTitle.call(() => this.wrapText());

    this.chart
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    this.xAxisGroup.attr("transform", "translate(0," + this.chartHeight + ")");
    this.xAxisGroup.call(this.xAxis);
    this.yAxisGroup.call(this.yAxis);

    //Append a clipping mask to avoid data from falling off the chart
    this.clippingMask
      .attr("x", -1)
      .attr("y", -1)
      .style("display", "block")
      .attr("width", this.chartWidth + 10)
      .attr("height", this.chartHeight + 10);

    this.legendRegion
      .style("width", this.legendWidth)
      .style("height", this.legendHeight)
      .attr("x", 0)
      .attr("y", this.chartHeight + 35);

    //Initialize tooltip here

    //Append axis labels here
    this.xAxisLabel
      .attr("y", this.chartHeight + 35)
      .attr("x", this.chartWidth)
      .text(this.props.xLabel);

    this.yAxisLabel
      .attr("y", -this.chartMargins.left + 10)
      .attr("x", -1)
      .attr("transform", "rotate(-90)")
      .text(this.props.yLabel);

    // this.chartLabel
    //   .attr("y", this.chartHeight + 55)
    //   .attr("x", this.chartWidth / 2)
    //   .text(this.props.chartTitle);

    // this.brush = d3.brush().extent([[0, 0], [this.chartWidth, this.chartHeight]]).on("end", this.brushEnded),
    //     this.idleTimeout,
    //     this.idleDelay = 350;

    // this.clippingMaskBrush.call(this.brush);
    // this.lineGroup.moveToFront();
    // this.pointGroup.moveToFront();
  };

  createChart = el => {
    //Create scales and initialize with domain 0-10
    this.updateDomains();
    this.updateScales();

    //Append a larger svg and create the graph region
    this.mainBody = d3
      .select(el)
      .append("svg")
      .attr("class", "multiLineMultiAxisChart")
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom + this.titleHeight + this.legendHeight)
      .attr("width", this.fullWidth)
      .attr("id", this.props.chartID);

    this.chartTitle = this.mainBody
      .append("text")
      .attr("id", "chartTitle")
      .attr("class", "axisLabel")
      .attr("text-anchor", "start")
      .attr("y", 10)
      .attr("x", 0)
      .style("fill", "black")
      .style("font-size", "14px")
      .style("font-family", "Roboto,Sans-Serif")
      .style("font-weight", 500)
      .text(this.props.chartTitle);
    this.chartTitle.call(() => this.wrapTitle());

    this.chart = this.mainBody
      .append("g")
      .attr("transform", "translate(" + this.chartMargins.left + "," + (this.chartMargins.top + this.titleHeight) + ")")
      .attr("height", this.chartHeight + this.chartMargins.top + this.chartMargins.bottom)
      .attr("width", this.chartWidth)
      .attr("id", "graphBody");

    //Create axes and append to chart.
    this.xAxis = d3.axisBottom(this.xScale);
    this.xAxisGroup = this.chart
      .append("g")
      .attr("class", "axis xaxis")
      .attr("transform", "translate(0," + this.chartHeight + ")");
    this.xAxisGroup.call(this.xAxis);

    this.yAxis = d3.axisLeft(this.yScale);
    this.yAxisGroup = this.chart.append("g").attr("class", "axis yaxis");
    this.yAxisGroup.call(this.yAxis);

    //Append a clipping mask to avoid data from falling off the chart
    // this.clippingMask = this.chart
    //   .append("svg")
    //   .attr("id", "cmask")
    //   .attr("x", 0)
    //   .attr("y", 0)
    //   .attr("width", this.chartWidth)
    //   .attr("height", this.chartHeight)
    //   .style("fill", "transparent");
    this.clippingMask = this.chart
      .append("svg")
      .attr("viewBox", [-1, -1, this.chartWidth + 10, this.chartHeight + 10])
      .style("display", "block")
      .style("margin", "0 -14px")
      .style("width", "calc(100% + 28px)")
      .style("height", "auto");

    this.legendRegion = this.chart
      .append("svg")
      .style("width", this.legendWidth)
      .style("height", this.legendHeight)
      .attr("x", 0)
      .attr("y", this.chartHeight + 35)
      .style("display", "block")
      .style("fill", "lightgray");

    //Initialize tooltip here

    //Append axis labels here
    this.xAxisLabel = this.chart
      .append("text")
      .attr("id", "xAxisLabel")
      .attr("class", "axisLabel")
      .attr("text-anchor", "end")
      .attr("y", this.chartHeight + 35)
      .attr("x", this.chartWidth)
      .style("fill", "black")
      .style("font-size", "14px")
      .style("font-family", "Roboto,Sans-Serif")
      .text(this.props.xLabel);

    this.yAxisLabel = this.chart
      .append("text")
      .attr("id", "yAxisLabel")
      .attr("class", "axisLabel")
      .attr("text-anchor", "end")
      .attr("y", -this.chartMargins.left + 10)
      .attr("x", -1)
      .attr("transform", "rotate(-90)")
      .style("fill", "black")
      .style("font-size", "14px")
      .style("font-family", "Roboto,Sans-Serif")
      .text(this.props.yLabel);

    this.contourGroup = this.clippingMask.append("g").attr("id", "yContours");

    // this.chartLabel = this.chart
    //   .append("text")
    //   .attr("id", "chartTitle")
    //   .attr("class", "axisLabel")
    //   .attr("text-anchor", "middle")
    //   .attr("y", this.chartHeight + 55)
    //   .attr("x", this.chartWidth / 2)
    //   .style("fill", "black")
    //   .style("font-size", "12px")
    //   .style("font-family", "Roboto,Sans-Serif")
    //   .text(this.props.legendText);
    // this.chartLabel.call(() => this.wrapText());
  };

  render() {
    return <div style={{ color: "black" }} />;
  }
}

d3.selection.prototype.moveToFront = function() {
  return this.each(function() {
    this.parentNode.appendChild(this);
  });
};

d3.selection.prototype.moveToBack = function() {
  return this.each(function() {
    var firstChild = this.parentNode.firstChild;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  });
};

export default ContourPlot;
