export function UpdateModelData(modelName, outputKeys, selectedKey, residuals, residualsSlope, residualsRValue, coefficients) {
    return {
        type: 'UPDATE_MODEL_EVALUATION_DATA',
        data: {
            selected_model_name: modelName,
            current_residuals: residuals,
            current_output_keys: outputKeys,
            selected_residuals_slope: residualsSlope,
            selected_residuals_rvalue: residualsRValue,
            selected_output_key: selectedKey,
            current_coefficients: coefficients,
        }
    }
}

export function UpdateSelectedKey(residuals, residualsSlope, residualsRValue, coefficients, selectedKey) {
    return {
        type: 'UPDATE_MODEL_EVALUATION_KEY',
        data: {
            current_residuals: residuals,
            selected_residuals_slope: residualsSlope,
            selected_residuals_rvalue: residualsRValue,
            selected_output_key: selectedKey,
            current_coefficients: coefficients,
        }
    }
}

export function UpdateTitlesAndLegends(residualsTitle, residualsLegend, coefficientsTitle, coefficientsLegend) {
    return {
        type: 'UPDATE_MODEL_EVALUATION_TITLES',
        data: {
            residuals_chart_title: residualsTitle,
            coefficients_chart_title: coefficientsTitle,
            residuals_legend_text: residualsLegend,
            coefficients_legend_text: coefficientsLegend,
        }
    }
}
