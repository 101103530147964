import { combineReducers } from 'redux';
import views from './views';
import modeldata from './modeldata';
import newmodel from './newmodel';
import modelevaluation from './modelevaluation';
import modelusage from './modelusage';
import modelprediction from './modelprediction';
import modelpredictioninputs from './modelpredictioninputs';
import modeloptimization from './modeloptimization';
import threedviz from './threedviz';
import datadistribution from './datadistribution';
import modelpredictioncontour from './modelpredictioncontour';
import savemodel from './savemodel';
import currentmodels from './currentmodels'

export default combineReducers({
    views: views,
    modeldata: modeldata,
    newmodel : newmodel,
    modelevaluation: modelevaluation,
    modelusage: modelusage,
    modelprediction: modelprediction,
    modelpredictioninputs: modelpredictioninputs,
    modeloptimization: modeloptimization,
    threedviz: threedviz,
    datadistribution: datadistribution,
    modelpredictioncontour: modelpredictioncontour,
    savemodel: savemodel,
    currentmodels: currentmodels,
});
