import React from "react";
import { connect } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as viewActions from "../../actions/viewActions";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ShareUserMultiSelect from "../ShareUserMultiSelect";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {Grid} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import * as currentModelActions from "../../actions/currentmodelsActions";
import * as modelDataActions from "../../actions/modeldataActions";

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
        // height: '100vh',
        minHeight: "95vh",
        margin: "auto",
        zIndex: 1,
        //overflow: 'hidden',
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        position: "fixed",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        color: "white"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "fixed"
        },
        minHeight: "100vh"
    },
    content: {
        minHeight: `calc(100vh - 48px)`,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    loader: {
        // position: 'absolute',
        // top: '40%',
        // left: '50%',
        // margin: '0px',
        marginLeft: "0px",
        [theme.breakpoints.up("md")]: {
            //left: `calc(50% - ${drawerWidth/2}px)`,
            marginLeft: `calc(${drawerWidth}px)`
        }
    }
});

function DeleteModelDialog(props)  {

    const { classes } = props;

    let visibilityChange = event => {
        let updatedVisibility = !props.views.savePrivate;
        props.dispatch(viewActions.UpdateSaveModelVisibility(updatedVisibility));
    };

    let selectedSharePermissionChange = event => {
        props.dispatch(viewActions.UpdateSelectedSharePermission(event.target.value));
    };

    let DeleteUser = (user, event) => {
        let modifiedSharedWith = JSON.parse(JSON.stringify(props.views.modifiedSharedWith));
        modifiedSharedWith = modifiedSharedWith.filter(el => {
            return el.id !== user;
        });
        props.dispatch(viewActions.UpdateModifiedSharedWith(modifiedSharedWith));
    };
    let updateUserPermissionLevel = (user, event) => {
        let modifiedSharedWith = JSON.parse(JSON.stringify(props.views.modifiedSharedWith));
        modifiedSharedWith.filter(el => {
            return el.displayName === user;
        })[0].permission = event.target.value;
        props.dispatch(viewActions.UpdateModifiedSharedWith(modifiedSharedWith));
    };
    
    let UpdateShareSettings = () => {
        props.dispatch(viewActions.UpdateShowShareDialog(false));
        props.dispatch(viewActions.changeLoadingState(true));
        props.dispatch(viewActions.updateLoadingMessage("Updating Model Sharing Settings..."));
        props.dispatch(viewActions.UpdateSelectedShareUsers([]));
        let currentModel = props.currentmodels.current_models.filter(el => {
            return el.id === props.modeldata.model_id;
        })[0];
        let data = {
            old_visibility: props.modeldata.model_visibility,
            new_visibility: props.views.savePrivate ? "private" : "public",
            new_share_users: props.views.selectedShareUsers,
            new_share_level: props.views.selectedSharePermission,
            old_user_permissions: props.views.currentlySharedWith,
            new_user_permissions: props.views.modifiedSharedWith,
            post_id: currentModel.post_id,
            user_pin: props.user.nickname
        };

        let url = `model/updatesharing`;
        props.axPost(url, JSON.stringify({data: data}), { headers: { "Content-Type": "application/json; charset=utf-8" } })
            .then(res => {
                if (res.data.CurrentModels !== undefined) {
                    let curr_models = JSON.parse(res.data.CurrentModels);
                    props.dispatch(currentModelActions.UpdateCurrentModels(curr_models));
                    let selectedModel = curr_models.filter(el => el.id === props.modeldata.model_id)[0];
                    let newSharePermissions = [];
                    Object.keys(selectedModel.users_access).forEach(key => {
                        let userData = selectedModel.users_access[key];
                        newSharePermissions.push({ displayName: userData[1], permission: userData[0], editable: userData[0] !== "Owner", id: key });
                    });
                    props.dispatch(viewActions.UpdateCurrentlySharedWith(newSharePermissions));
                    props.dispatch(viewActions.UpdateModifiedSharedWith(newSharePermissions));
                    props.dispatch(
                        modelDataActions.UpdateModelAccessSettings(
                            selectedModel.model_visibility,
                            props.modeldata.user_permission,
                            props.modeldata.user_can_share,
                            props.modeldata.user_can_edit
                        )
                    );
                }
            })
            .finally(() => {
                props.dispatch(viewActions.changeLoadingState(false));
                props.dispatch(viewActions.updateLoadingMessage(""));
            });
    };


    let shareHasChanged = () => {
        if (
            props.views.savePrivate !== (props.modeldata.model_visibility === "private") ||
            JSON.stringify(props.views.modifiedSharedWith) !== JSON.stringify(props.views.currentlySharedWith) ||
            props.views.selectedShareUsers.length > 0
        ) {
            return false;
        }
        return true;
    };


    return(
        <Dialog
            open={props.views.showShareDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">Share Current Model</DialogTitle>
            <DialogContent style={{ paddingBottom: 0, maxHeight: 650 }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography variant="subtitle1" align="left" style={{ fontWeight: 500 }}>
                        Update Model Visbility{" "}
                    </Typography>
                    {/* <InfoIcon style={{ fontSize: 18, marginLeft: 6 }} /> */}
                </div>
                <Typography align="left">Private models are only seen by you and your chosen colleagues, public models can be viewed by all.</Typography>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%", marginTop: 0, marginBottom: 0 }}>
                    {/* <FormLabel style={{fontSize:'small'}}>Model Visibility (Private models are seen only by you, public models can be viewed by all)</FormLabel> */}
                    <RadioGroup row>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={!props.views.savePrivate}
                                    onChange={visibilityChange}
                                    value={false}
                                    name="visibility"
                                    aria-label="Public"
                                    color="primary"
                                />
                            }
                            label="Public"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={props.views.savePrivate}
                                    onChange={visibilityChange}
                                    value={true}
                                    name="visibility"
                                    aria-label="Private"
                                    color="primary"
                                />
                            }
                            label="Private"
                        />
                    </RadioGroup>
                </FormControl>
                {props.views.savePrivate ? (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="subtitle1" align="left" style={{ fontWeight: 500, marginTop: 6 }}>
                                Grant Model Access{" "}
                            </Typography>
                            {/* <InfoIcon style={{ fontSize: 18, marginLeft: 6 }} /> */}
                        </div>
                        <Typography align="left" style={{ marginBottom: 12 }}>
                            Enter the names of your colleagues you wish to share this model with, and select an appropriate access level below.
                        </Typography>
                        {/* Access level on AIchemy is read/write/share (reader/owner/editor on your side, change to read, write, share) */}
                        {/* Update user privleges format... {'pin@mmm.com'[accesslevel, displayname], 'pin2@mmm.com':[accesslevel, displayname] */}
                        <FormControl className={classes.formControl} fullWidth style={{ width: "100%", marginTop: 0, flexDirection: "row" }}>
                            <ShareUserMultiSelect />
                            <Select
                                fullWidth
                                style={{ textAlign: "left", backgroundColor: "white", select: { backgroundColor: "white" }, width: "auto", marginLeft: 12, height: 36 }}
                                value={props.views.selectedSharePermission}
                                onChange={selectedSharePermissionChange}
                                input={<Input name="share_permission_select" id="share_permission_select" />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }
                                }}
                                data-cy="update_share_permission"
                            >
                                {props.views.sharePermissions
                                    .filter(el => {
                                        return el.value !== "Owner";
                                    })
                                    .map(key => (
                                        <MenuItem key={"main_select_" + key.value} value={key.value}
                                                  data-cy={"permission_" + key.value}>
                          <span>
                            <span style={{ fontWeight: 400 }}>{key.display}</span>
                          </span>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="subtitle1" align="left" style={{ fontWeight: 500, marginTop: 20, marginBottom: 6 }}>
                                Who Has Access{" "}
                            </Typography>
                            {/* <Tooltip title="Delete">
                  <InfoIcon style={{ fontSize: 18, marginLeft: 6, marginTop:20, marginBottom: 6 }} />
                  </Tooltip> */}
                        </div>

                        {props.views.modifiedSharedWith.length === 0 ? (
                            <div>
                                <Typography align="left" style={{ marginLeft: 0 }}>
                                    <i>Model Not Currently Shared</i>
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <Grid container>
                                    {props.views.modifiedSharedWith.map((user, index) => (
                                        <Grid key={index} item xs={6} spacing={6}>
                                            <FormControl
                                                className={classes.formControl}
                                                fullWidth
                                                style={{ width: "100%", marginTop: 0, flexDirection: "row", alignItems: "center", display: "flex" }}
                                            >
                                                {/* <IconButton onClick={this.DeleteUser(user.id)} disableRipple="true" size="small"><ClearIcon></ClearIcon></IconButton> */}
                                                <div onClick={() => DeleteUser(user.id)} style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.87)", height: 24 }}>
                                                    <ClearIcon></ClearIcon>
                                                </div>
                                                <Typography variant="subtitle1"> {user.displayName} – </Typography>
                                                <Select
                                                    fullWidth
                                                    style={{
                                                        textAlign: "left",
                                                        backgroundColor: "white",
                                                        select: { backgroundColor: "white" },
                                                        width: "auto",
                                                        marginLeft: 4
                                                    }}
                                                    value={user.permission}
                                                    onChange={ev => updateUserPermissionLevel(user.displayName, ev)}
                                                    input={<Input name={"share_permission_select_" + index} id={"share_permission_select_" + index} />}
                                                    disabled={!user.editable}
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        }
                                                    }}
                                                >

                                                {user.permission === "Owner"
                                                  ? props.views.sharePermissions
                                                    .filter(el => {
                                                        return el.value === "Owner";
                                                    })
                                                    .map(key => (
                                                      <MenuItem key={"main_select_" + key.value} value={key.value}>
                                                          <span>{key.display}</span>
                                                      </MenuItem>
                                                    ))
                                                  : props.views.sharePermissions
                                                    .filter(el => {
                                                        return el.value !== "Owner";
                                                    })
                                                    .map(key => (
                                                      <MenuItem key={"main_select_" + key.value} value={key.value}>
                                                          <span>{key.display}</span>
                                                      </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.dispatch(viewActions.UpdateShowShareDialog(false))} color="secondary">
                    Cancel
                </Button>
                <Button onClick={UpdateShareSettings} color="primary" autoFocus
                        disabled={shareHasChanged()} data-cy="update_share">
                    Update Model
                </Button>
            </DialogActions>
        </Dialog>
    )

}

// Alerts.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired
// };

export default connect((state, props) => {
    return {
        views: state.views,
        savemodel: state.savemodel,
        modeldata: state.modeldata,
        currentmodels: state.currentmodels
    };
})(withStyles(styles, { withTheme: true })(DeleteModelDialog));