import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as THREE from 'three';


class DDDScatterPlot extends Component {

    constructor(props) {
        super(props)
        this.spheres = [];
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.animate = this.animate.bind(this)
        this.hasMounted = false;
    }

    shouldComponentUpdate(newProps) {
        if (newProps.views.currentView !== 'data3d') {
            return false;
        }
        if (!this.hasMounted) {
            return false;
        }
        return true;
        //return false;
    }

    componentDidUpdate() {
        if (this.props.currentView !== this.props.chartType) {
            return;
        }
        //this.stop();
        this.addSpheres();

    };

    addGrid = (scene, currfont) => {
        var linematerial = new THREE.LineBasicMaterial({
            color: 'darkgray'
        });
        var textmaterial = new THREE.MeshBasicMaterial({ color: 'black' });

        var xPlane = new THREE.Geometry();
        xPlane.vertices.push(
            new THREE.Vector3(-.5, -.5, -.5),
            new THREE.Vector3(.5, -.5, -.5),
            new THREE.Vector3(.5, -.5, .5),
            new THREE.Vector3(-.5, -.5, .5),

        );
        var xPlaneLine = new THREE.Line(xPlane, linematerial);
        scene.add(xPlaneLine);
        if (this.props.columnLabels[0]) {
            var selCol = this.props.columnLabels[0];
            var selRange = this.props.ranges[selCol];
            var currVal = selRange.min;
            while (currVal <= selRange.max) {
                var xGrid = new THREE.Geometry();
                let adjVal = (currVal - selRange.min) / (selRange.max - selRange.min);
                adjVal -= 0.5;
                xGrid.vertices.push(
                    new THREE.Vector3(adjVal, -.5, .5),
                    new THREE.Vector3(adjVal, -.5, -.5),
                    new THREE.Vector3(adjVal, .5, -.5),
                );
                var xGridLine = new THREE.Line(xGrid, linematerial);
                scene.add(xGridLine);

                //create and add a label here
                var xLabelGeo = new THREE.TextGeometry(currVal.toPrecision(2).toString(), {
                    size: .025,
                    height: .0005,
                    curveSegments: 1,
                    font: currfont,
                    textAlign: 'center'
                });
                xLabelGeo.center();
                var xLabelMesh = new THREE.Mesh(xLabelGeo, textmaterial);
                xLabelMesh.position.set(adjVal, -.50, .53);
                xLabelMesh.rotateX(THREE.Math.degToRad(-90));
                scene.add(xLabelMesh);
                currVal += selRange.step;
            }
        }

        var yPlane = new THREE.Geometry();
        yPlane.vertices.push(
            new THREE.Vector3(-.5, -.5, -.5),
            new THREE.Vector3(-.5, .5, -.5),
            new THREE.Vector3(.5, .5, -.5),
            new THREE.Vector3(.5, -.5, -.5),
        );
        var yPlaneLine = new THREE.Line(yPlane, linematerial);
        scene.add(yPlaneLine);
        if (this.props.columnLabels[1]) {
            let selCol = this.props.columnLabels[1];
            let selRange = this.props.ranges[selCol];
            let currVal = selRange.min;
            while (currVal <= selRange.max) {
                var yGrid = new THREE.Geometry();
                let adjVal = (currVal - selRange.min) / (selRange.max - selRange.min);
                adjVal -= 0.5;
                yGrid.vertices.push(
                    new THREE.Vector3(.5, adjVal, -.5),
                    new THREE.Vector3(-.5, adjVal, -.5),
                    new THREE.Vector3(-.5, adjVal, .5),
                );
                var yGridLine = new THREE.Line(yGrid, linematerial);
                scene.add(yGridLine);

                var yLabelGeo = new THREE.TextGeometry(currVal.toPrecision(2).toString(), {
                    size: .025,
                    height: .0005,
                    curveSegments: 1,
                    font: currfont,
                    textAlign: 'center'
                });
                yLabelGeo.center();
                var yLabelMesh = new THREE.Mesh(yLabelGeo, textmaterial);
                yLabelMesh.position.set(0.53, adjVal, -0.50);
                yLabelMesh.rotateZ(THREE.Math.degToRad(90))
                scene.add(yLabelMesh);

                currVal += selRange.step;
            }
        }

        var zPlane = new THREE.Geometry();
        zPlane.vertices.push(
            new THREE.Vector3(-.5, -.5, -.5),
            new THREE.Vector3(-.5, -.5, .5),
            new THREE.Vector3(-.5, .5, .5),
            new THREE.Vector3(-.5, .5, -.5),
        );
        var zPlaneLine = new THREE.Line(zPlane, linematerial);
        scene.add(zPlaneLine);
        if (this.props.columnLabels[2]) {
            let selCol = this.props.columnLabels[2];
            let selRange = this.props.ranges[selCol];
            let currVal = selRange.min;
            while (currVal <= selRange.max) {
                var zGrid = new THREE.Geometry();
                let adjVal = (currVal - selRange.min) / (selRange.max - selRange.min);
                adjVal -= 0.5;
                adjVal *= -1;
                zGrid.vertices.push(
                    new THREE.Vector3(.5, -.5, adjVal),
                    new THREE.Vector3(-.5, -.5, adjVal),
                    new THREE.Vector3(-.5, .5, adjVal),
                );
                var zGridLine = new THREE.Line(zGrid, linematerial);
                scene.add(zGridLine);
                var zLabelGeo = new THREE.TextGeometry(currVal.toPrecision(2).toString(), {
                    size: .025,
                    height: .0005,
                    curveSegments: 1,
                    font: currfont,
                    textAlign: 'center'
                });
                zLabelGeo.center();
                var zLabelMesh = new THREE.Mesh(zLabelGeo, textmaterial);
                zLabelMesh.position.set(0.53, -0.50, adjVal);
                zLabelMesh.rotateY(THREE.Math.degToRad(90))
                zLabelMesh.rotateX(THREE.Math.degToRad(-90));
                scene.add(zLabelMesh);
                currVal += selRange.step;
            }
        }
    }

    addSpheres() {
        setTimeout(() => {
            this.mount.removeChild(this.renderer.domElement);
            this.frameId = null;
            let OrbitControls = require('three-orbit-controls')(THREE);
            let width = document.getElementById(this.props.appendDiv).clientWidth
            let height = 0.6 * document.getElementById(this.props.appendDiv).clientWidth
            let scene = new THREE.Scene()
            let camera = new THREE.PerspectiveCamera(
                35,
                width / height,
                .01,
                100
            );
            let renderer = new THREE.WebGLRenderer({ antialias: true })
            let geometry = new THREE.SphereBufferGeometry(.015, 32, 16);
            let xExtent = [0, 0];
            if (this.props.columnLabels[0]) {
                let selCol = this.props.ranges[this.props.columnLabels[0]];
                xExtent = [selCol.min, selCol.max];
            }
            let yExtent = [0, 0];
            if (this.props.columnLabels[1]) {
                let selCol = this.props.ranges[this.props.columnLabels[1]];
                yExtent = [selCol.min, selCol.max];
            }
            let zExtent = [0, 0];
            if (this.props.columnLabels[2]) {
                let selCol = this.props.ranges[this.props.columnLabels[2]];
                zExtent = [selCol.min, selCol.max];
            }
            let xRange = xExtent[1] - xExtent[0];
            let yRange = yExtent[1] - yExtent[0];
            let zRange = zExtent[1] - zExtent[0];
            xRange = xRange === 0 ? 1 : xRange;
            yRange = yRange === 0 ? 1 : yRange;
            zRange = zRange === 0 ? 1 : zRange;
            let spheres = this.spheres = [];
            let adjustZ = this.props.columnLabels.length===3;
            this.props.chartData.forEach(function (pt) {
                let material = new THREE.MeshStandardMaterial({
                    map: null,
                    bumpMap: null,
                    bumpScale: 1,
                    metalness: .35,
                    roughness: .85,
                    envMap: null,
                    color: new THREE.Color().setHSL(pt.color, 0.5, .6),
                });
                var mesh = new THREE.Mesh(geometry, material);
                mesh.position.x = ((pt.x - xExtent[0]) / xRange) - .5;
                mesh.position.y = ((pt.y - yExtent[0]) / yRange) - .5;
                mesh.position.z = ((pt.z - zExtent[0]) / zRange) - .5;
                if (adjustZ) {
                    mesh.position.z *= -1;
                }
                scene.add(mesh);
                spheres.push(mesh);
            });

            var textmaterial = new THREE.MeshBasicMaterial({ color: 'black' });
            let currfont = this.font;

            //add lines
            this.addGrid(scene, currfont);

            var xLabelGeo = new THREE.TextGeometry(this.props.columnLabels.length > 0 ? this.props.columnLabels[0] : '', {
                size: .03,
                height: .0005,
                curveSegments: 1,
                font: currfont,
                textAlign: 'center'
            });
            xLabelGeo.center();
            var xLabelMesh = new THREE.Mesh(xLabelGeo, textmaterial);
            xLabelMesh.position.set(0, -.50, .58);
            xLabelMesh.rotateX(THREE.Math.degToRad(-90));
            scene.add(xLabelMesh);

            var yLabelGeo = new THREE.TextGeometry(this.props.columnLabels.length > 1 ? this.props.columnLabels[1] : '', {
                size: .03,
                height: .0005,
                curveSegments: 1,
                font: currfont,
                textAlign: 'center'
            });
            yLabelGeo.center();
            var yLabelMesh = new THREE.Mesh(yLabelGeo, textmaterial);
            yLabelMesh.position.set(0.58, 0, -0.50);
            yLabelMesh.rotateZ(THREE.Math.degToRad(90))
            scene.add(yLabelMesh);

            var zLabelGeo = new THREE.TextGeometry(this.props.columnLabels.length > 2 ? this.props.columnLabels[2] : '', {
                size: .03,
                height: .0005,
                curveSegments: 1,
                font: currfont,
                textAlign: 'center'
            });
            zLabelGeo.center();
            var zLabelMesh = new THREE.Mesh(zLabelGeo, textmaterial);
            zLabelMesh.position.set(0.58, -0.50, 0);
            zLabelMesh.rotateY(THREE.Math.degToRad(90))
            zLabelMesh.rotateX(THREE.Math.degToRad(-90));
            scene.add(zLabelMesh);

            camera.position.z = this.z;
            camera.position.x = this.x;
            camera.position.y = this.y;
            renderer.setClearColor('white')
            renderer.setSize(width, height)
            scene.add(camera)
            let directionalLight = new THREE.DirectionalLight(0xffffff, 1.75);
            directionalLight.position.set(10, 0.5, 0.5).normalize();
            scene.add(directionalLight);

            this.scene = scene
            this.camera = camera
            this.renderer = renderer
            this.geometry = geometry;
            let controls = new OrbitControls(camera, this.renderer.domElement)
            controls.dampingFactor = 0.1
            controls.enableZoom = true
            this.controls = controls;
            this.directionalLight = directionalLight;
            this.angle = 0;
            controls.update();
            this.mount.style = { 'outline': 'gray 0.5px solid' }
            this.mount.appendChild(this.renderer.domElement);
            this.hasMounted = true;
            this.start();
        }, 0);
    }

    //https://stackoverflow.com/questions/41248287/how-to-connect-threejs-to-react
    componentDidMount() {

        setTimeout(() => {
            let OrbitControls = require('three-orbit-controls')(THREE);
            let width = document.getElementById(this.props.appendDiv).clientWidth
            let height = 0.6 * document.getElementById(this.props.appendDiv).clientWidth
            let scene = new THREE.Scene()
            let camera = new THREE.PerspectiveCamera(
                35,
                width / height,
                .01,
                100
            );
            let renderer = new THREE.WebGLRenderer({ antialias: true, outline: 'gray 0.5px solid' })
            let geometry = new THREE.SphereBufferGeometry(.02, 32, 16);
            let fontJson = require("three/examples/fonts/helvetiker_regular.typeface.json");

            let font = new THREE.Font(fontJson);
            this.font = font;

            // camera.position.z = 3;
            // camera.position.x = 0;
            // camera.position.y = 0.5;
            camera.position.z = 2;
            camera.position.x = 2;
            camera.position.y = 1;
            renderer.setClearColor('white')
            renderer.setSize(width, height)
            scene.add(camera)
            let directionalLight = new THREE.DirectionalLight(0xffffff, 1.75);
            directionalLight.position.set(10, 0.5, 0.5).normalize();
            scene.add(directionalLight);

            this.scene = scene
            this.camera = camera
            this.renderer = renderer
            this.geometry = geometry;
            let controls = new OrbitControls(camera, this.renderer.domElement)
            controls.dampingFactor = 0.1
            controls.enableZoom = true
            this.controls = controls;
            this.directionalLight = directionalLight;
            this.angle = 0;
            controls.update();
            this.mount.appendChild(this.renderer.domElement);
            this.hasMounted = true;
            this.start();
        }, 0)
    }

    componentWillUnmount() {

    }

    start() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }

    stop() {
        cancelAnimationFrame(this.frameId)
    }

    animate() {
        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate)
        this.controls.update();
        this.camera.position.set(this.camera.position.x, this.camera.position.y, this.camera.position.z)
        this.directionalLight.position.set(this.camera.position.x, this.camera.position.y, this.camera.position.z).normalize();
        this.x = this.camera.position.x;
        this.y = this.camera.position.y;
        this.z = this.camera.position.z;
    }

    renderScene() {
        this.renderer.render(this.scene, this.camera)
    }

    render() {
        return (
            <div
                style={{ width: this.width, height: this.height }}
                ref={(mount) => { this.mount = mount }}
            />
        );
    }
}

export default connect((state, props) => {
    return {
        views: state.views,
        modeldata: state.modeldata,
        threedviz: state.threedviz,
    }

})(DDDScatterPlot);
