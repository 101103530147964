let defaultState = {
  debugMode: false,
  responseDelay: 0,  // 0 or positive number or 'random'
  currentView: "newmodel",
  isLoading: false,
  loadingMessage: "",
  showAlert: false,
  alertType: "success",
  alertText: "",
  alertTitle: "",
  alertURL: "",
  modelCanBeSaved: false,

  showConfirm: false,
  confirmTitle: "",
  confirmText: "",

  showSaveConfirm: false,
  savePrivate: true,
  showEditData: false,
  showShareDialog: false,
  shareUsers: [],
  selectedShareUsers: [],
  shareUserSearching: true,
  sharePermissions: [
    { display: "Read", value: "Read" },
    // { display: "Write", value: "Write" },
    { display: "Share", value: "Share" },
    {display: "Owner", value: "Owner"}
  ],
  selectedSharePermission: "Read",
  currentlySharedWith: [],
  modifiedSharedWith: []
};

function views(state = defaultState, action) {
  switch (action.type) {
    case "CHANGE_VIEW":
      return {
        ...state,
        currentView: action.data.currentView
      };
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        isLoading: action.data.isLoading
      };
    case "CHANGE_ALERT_MESSAGE":
      return {
        ...state,
        alertType: action.data.alertType,
        alertText: action.data.alertText,
        alertTitle: action.data.alertTitle
      };
    case "CHANGE_LOADING_MESSAGE":
      return {
        ...state,
        loadingMessage: action.data.loadingMessage
      };
    case "UPDATE_ALERT_STATUS":
      return {
        ...state,
        showAlert: action.data.showAlert
      };
    case "UPDATE_ALERT":
      return {
        ...state,
        showAlert: action.data.showAlert,
        alertText: action.data.alertText,
        alertTitle: action.data.alertTitle,
        alertURL: action.data.alertURL
      };
    case "UPDATE_MODEL_CAN_BE_SAVED":
      return {
        ...state,
        modelCanBeSaved: action.data.modelCanBeSaved
      };
    case "UPDATE_CONFIRM_DIALOG": {
      return {
        ...state,
        showConfirm: action.data.showConfirm,
        confirmText: action.data.confirmText,
        confirmTitle: action.data.confirmTitle
      };
    }
    case "UPDATE_SAVE_DIALOG": {
      return {
        ...state,
        showSaveConfirm: action.data.showSaveConfirm
      };
    }
    case "UPDATE_EDIT_DATA_DIALOG": {
      return {
        ...state,
        showEditData: action.data.showEditData
      };
    }
    case "UPDATE_SHOW_SHARE_DIALOG": {
      return {
        ...state,
        showShareDialog: action.data.showShareDialog
      };
    }
    case "UPDATE_SHARE_USERS": {
      return {
        ...state,
        shareUsers: action.data.shareUsers
      };
    }
    case "UPDATE_SELECTED_SHARE_USERS": {
      return {
        ...state,
        selectedShareUsers: action.data.selectedShareUsers
      };
    }
    case "UPDATE_SHARE_USER_SEARCHING": {
      return {
        ...state,
        shareUserSearching: action.data.shareUserSearching
      };
    }
    case "UPDATE_MODEL_SAVE_VISIBILITY": {
      return {
        ...state,
        savePrivate: action.data.savePrivate
      };
    }
    case "UPDATE_SELECTED_SHARE_PERMISSION": {
      return {
        ...state,
        selectedSharePermission: action.data.selectedSharePermission
      };
    }
    case "UPDATE_CURRENTLY_SHARED_WITH": {
      return {
        ...state,
        currentlySharedWith: action.data.currentlySharedWith
      };
    }
    case "UPDATE_MODIFIED_SHARED_WITH": {
      return{
        ...state,
        modifiedSharedWith: action.data.modifiedSharedWith
      }
    }
    case "RESET_ALL":
      return {
        ...state,
        isLoading: false,
        loadingMessage: "",
        showAlert: false,
        alertType: "success",
        alertText: "",
        alertTitle: "",
        modelCanBeSaved: false,
        showConfirm: false,
        confirmTitle: "",
        confirmText: "",
        showSaveConfirm: false,
        showShareDialog: false,
        shareUsers: [],
        selectedShareUsers: [],
        shareUserSearching: false,
        currentlySharedWith: [],
        modifiedSharedWith: []
      };
    case "CHANGE_DEBUG_MODE":
      return {
        ...state,
        debugMode: action.data.debugMode
      }

    case "CHANGE_RESPONSE_DELAY":
      return {
        ...state,
        responseDelay: action.data.delay
      }
    default:
      return state;
  }
}

export default views;
